import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Trash } from "lucide-react";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";
import ChatbotService from "../../../services/ChatbotService";
import ToastService from "../../../services/ToastService";
import UtilityService from "../../../services/UtilityService";

import Modal from "../../../components/common/DHModal";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import Input from "../../../components/common/Input";
import Action from "../../../components/common/Action";
import Toggle from "../../../components/common/Toggle";

import AIAgentPlayground from "./AIAgentPlayground";

import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";
import { AI_AGENT_SOURCE_TYPES, AI_AGENT_TABS } from "../../../constants/Chatbots";

import "./edit-ai-agent-modal.css";

class EditAIAgentModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			loading: true,
			isSaving: false,
			selectedTab: AI_AGENT_TABS.sources.id,

			showConfirmAlert: false,
			showConfirmDeleteAlert: false,

			chatbotId: null,
			name: "",
			config: {},

			selectedSourceType: AI_AGENT_SOURCE_TYPES.general.id
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchAIAgent();
	};

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			await this.resetComponent();
		}
	}

	fetchAIAgent = async () => {
		let { show, chatbotId } = this.props;

		if (!chatbotId) {
			return;
		}

		let chatbot = await ChatbotService.fetchChatbot({ chatbotId });

		if (!chatbot) {
			return;
		}

		await this.update({
			show,
			chatbotId,
			name: chatbot.name,
			config: chatbot.config,

			selectedTab: AI_AGENT_TABS.sources.id,
			selectedSourceType: AI_AGENT_SOURCE_TYPES.general.id
		});
	};

	onSave = async () => {
		let { chatbotId, name, config } = this.state;
		let { t } = this.props;

		await this.update({ isSaving: true });

		await UtilityService.timeout(2000);

		let user = UserService.get();

		if (!user.GroupPermission.update_chatbots) {
			ToastService.info(t("Invalid Permissions."));
			return;
		}

		let success = await ChatbotService.updateChatbot({ chatbotId, name, config });

		if (!success) {
			ToastService.info(t("Failed to update AI Agent."));
			return;
		}

		ToastService.info(t("AI Agent has been retrained!"));

		// Set the Modal back to the details view before closing
		await this.update({
			selectedTab: AI_AGENT_TABS.sources.id
		});

		await this.update({ isSaving: false });
	};

	onTabSelect = tab => {
		this.update({ selectedTab: tab.id });

		GAService.GAEvent({
			category: GA_CATEGORIES.editAIAgentModal.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
	};

	onChange = async (key, value) => {
		await this.update({
			[key]: value
		});
	};

	onSystemPromptChange = async value => {
		let { config } = this.state;

		let systemPrompt = value;

		await this.update({
			config: {
				...config,
				systemPrompt
			}
		});
	};

	onShouldResumeChange = async value => {
		let { config } = this.state;

		let shouldResume = value;

		await this.update({
			config: {
				...config,
				shouldResume
			}
		});
	};

	onResumeAfterChange = async value => {
		let { config } = this.state;

		let resumeAfter = value;

		await this.update({
			config: {
				...config,
				resumeAfter
			}
		});
	};

	onClose = async () => {
		await this.update({
			selectedTab: AI_AGENT_TABS.sources.id
		});

		if (this.props.onClose) {
			this.props.onClose(false);
		}
	};

	renderGeneralSource = () => {
		let { t } = this.props;
		let { config } = this.state;

		let generalInformation = config.sources?.general || [];

		return <Input fullWidth label={t("General Information")} type="textarea" className="eaiam__settings__system-prompt" disabled value={generalInformation} />;
	};

	onTextSourceChange = async event => {
		let { config } = this.state;
		let textSource = event.target.value;

		await this.update({
			config: {
				...config,
				sources: {
					...config.sources,
					text: textSource
				}
			}
		});
	};

	renderTextSource = () => {
		let { t } = this.props;
		let { config } = this.state;

		let textSource = config.sources?.text;

		return (
			<Input
				fullWidth
				label={t("Text Data")}
				type="textarea"
				className="eaiam__settings__system-prompt"
				onChange={this.onTextSourceChange}
				value={textSource}
			/>
		);
	};

	onWebPageChange = (event, index) => {
		let { config } = this.state;
		let { value } = event.target;

		let pages = config.sources?.web?.pages || [];

		pages[index].url = value;

		this.update({
			config: {
				...config,
				sources: {
					...config.sources,
					web: {
						...config.sources.web,
						pages
					}
				}
			}
		});
	};

	onAddWebPage = () => {
		let { config } = this.state;

		let pages = config.sources?.web?.pages || [];
		let web = config.sources?.web || {};

		pages.push({ url: "", content: "" });

		this.update({
			config: {
				...config,
				sources: {
					...config.sources,
					web: {
						...web,
						pages
					}
				}
			}
		});
	};

	onRemoveWebPage = index => {
		let { config } = this.state;

		let pages = config.sources?.web?.pages || [];

		pages.splice(index, 1);

		this.update({
			config: {
				...config,
				sources: {
					...config.sources,
					web: {
						...config.sources.web,
						pages
					}
				}
			}
		});
	};

	renderWebSource = () => {
		let { t } = this.props;
		let { config } = this.state;

		let pages = config.sources?.web?.pages || [];

		return (
			<div className="eaiam__sources__web">
				{pages.map((page, index) => {
					return (
						<div key={index} className="eaiam__sources__web__page">
							<Input fullWidth value={page.url} placeholder="https://example.com" onChange={event => this.onWebPageChange(event, index)} />
							<Action darkMode key={`remove`} id={`remove`} label={t("Remove")} icon={Trash} onClick={() => this.onRemoveWebPage(index)} />
						</div>
					);
				})}
				<div className="eaiam__sources__web__add">
					<div className="mb-button" onClick={this.onAddWebPage}>
						{t("Add Web page")}
					</div>
				</div>
			</div>
		);
	};

	onSourceTypeSelected = async sourceType => {
		await this.update({
			selectedSourceType: sourceType.id
		});
	};

	renderSourceTypes = () => {
		let { selectedSourceType } = this.state;
		let sourceTypes = Object.values(AI_AGENT_SOURCE_TYPES);

		return (
			<div className="eaiam__sources__types">
				<div className="eaiam__sources__types__title">Source Types</div>
				{sourceTypes.map((sourceType, index) => {
					let classes = ["eaiam__sources__types__item"];

					if (selectedSourceType === sourceType.id) {
						classes.push("eaiam__sources__types__item--active");
					}

					return (
						<div key={index} className={classes.join(" ")} onClick={() => this.onSourceTypeSelected(sourceType)}>
							{sourceType.value}
						</div>
					);
				})}

				{this.renderTrainState()}
			</div>
		);
	};

	renderSources = () => {
		let { selectedSourceType } = this.state;

		return (
			<div className="eaiam__sources">
				{this.renderSourceTypes()}
				{selectedSourceType === AI_AGENT_SOURCE_TYPES.general.id && this.renderGeneralSource()}
				{selectedSourceType === AI_AGENT_SOURCE_TYPES.text.id && this.renderTextSource()}
				{selectedSourceType === AI_AGENT_SOURCE_TYPES.web.id && this.renderWebSource()}
			</div>
		);
	};

	renderTrainState = () => {
		let { t } = this.props;
		let { isSaving } = this.state;

		return (
			<div className="eaiam__sources__types__train">
				<div className="mb-button" onClick={this.onSave}>
					{isSaving ? t("Training agent...") : t("Retrain Agent")}
				</div>
			</div>
		);
	};

	renderSettings = () => {
		let { t } = this.props;
		let { name, config } = this.state;

		return (
			<div className="eaiam__settings">
				<Input fullWidth label={t("Name")} name="name" id="name" type="text" onChange={event => this.onChange("name", event.target.value)} value={name} />

				<Input
					fullWidth
					label={t("Instructions")}
					name="systemPrompt"
					id="systemPrompt"
					type="textarea"
					className="eaiam__settings__system-prompt"
					onChange={event => this.onSystemPromptChange(event.target.value)}
					value={config.systemPrompt}
				/>

				<Toggle
					id="shouldResume"
					label={t("Should Resume")}
					description={t("If the conversation was interrupted by a human, the AI will attempt to resume the conversation after a given threshold")}
					checked={config.shouldResume}
					onChange={this.onShouldResumeChange}
				/>

				{config.shouldResume && (
					<Input
						fullWidth
						label={t("Resume After (seconds)")}
						placeholder="(eg. 300)"
						name="resumeAfter"
						id="resumeAfter"
						type="number"
						onChange={event => this.onResumeAfterChange(event.target.value)}
						value={config.resumeAfter}
					/>
				)}
			</div>
		);
	};

	renderPlayground = () => {
		let { chatbotId } = this.state;

		return <AIAgentPlayground chatbotId={chatbotId} />;
	};

	render() {
		let { show, name, selectedTab } = this.state;
		return (
			<>
				<Modal show={show} onHide={this.onClose} title={name}>
					<div className="eaiam">
						<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
							<Tab id={AI_AGENT_TABS.sources.id} value={AI_AGENT_TABS.sources.value} />
							<Tab id={AI_AGENT_TABS.playground.id} value={AI_AGENT_TABS.playground.value} />
							<Tab id={AI_AGENT_TABS.settings.id} value={AI_AGENT_TABS.settings.value} />
						</Tabs>
						{selectedTab === AI_AGENT_TABS.sources.id && this.renderSources()}
						{selectedTab === AI_AGENT_TABS.playground.id && this.renderPlayground()}
						{selectedTab === AI_AGENT_TABS.settings.id && this.renderSettings()}
					</div>
				</Modal>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditAIAgentModal);
