import i18n from "../i18n/config";

export const LOCAL_POSTS_TOPIC_TYPES = {
	LOCAL_POST_TOPIC_TYPE_UNSPECIFIED: "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED", // No post type is specified.
	STANDARD: "STANDARD", //  Post contains basic information, like summary and images.
	EVENT: "EVENT", // Post contains basic information and an event.
	OFFER: "OFFER" // Post contains basic information, an event and offer related content (e.g. coupon code)
	// TODO: Local Post Alerts
	// ALERT: "ALERT" // High-priority, and timely announcements related to an ongoing event. These types of posts are not always available for authoring.
};

export const LOCAL_POSTS_TOPIC_TYPES_OPTIONS = [
	{ value: "STANDARD", label: i18n.t("Standard") },
	{ value: "EVENT", label: i18n.t("Event") },
	{ value: "OFFER", label: i18n.t("Offer") }
	// { value: "ALERT", label: "Alert" }
];

export const LOCAL_POSTS_STATES = {
	LOCAL_POST_STATE_UNSPECIFIED: "LOCAL_POST_STATE_UNSPECIFIED", // 	State not specified.
	REJECTED: "REJECTED", // 	This post was rejected due to content policy violation.
	LIVE: "LIVE", // 	This post is published and is currently appearing in search results.
	PROCESSING: "PROCESSING" // 	This post is being processed and is not appearing in search results.
};

export const LOCAL_POSTS_ACTIONS = {
	ACTION_TYPE_UNSPECIFIED: "ACTION_TYPE_UNSPECIFIED", //	Type unspecified.
	BOOK: "BOOK", //	This post wants a user to book an appointment/table/etc.
	ORDER: "ORDER", //	This post wants a user to order something.
	SHOP: "SHOP", //	This post wants a user to browse a product catalog.
	LEARN_MORE: "LEARN_MORE", //	This post wants a user to learn more (at their website).
	SIGN_UP: "SIGN_UP", //	This post wants a user to register/sign up/join something.
	GET_OFFER: "GET_OFFER", // This item is deprecated!
	CALL: "CALL" //	This post wants a user to call the business.
};

export const LOCAL_POSTS_ACTIONS_OPTIONS = [
	{ value: "ACTION_TYPE_UNSPECIFIED", label: i18n.t("None") },
	{ value: "BOOK", label: i18n.t("Book") },
	{ value: "ORDER", label: i18n.t("Order") },
	{ value: "SHOP", label: i18n.t("Shop") },
	{ value: "LEARN_MORE", label: i18n.t("Learn More") },
	{ value: "SIGN_UP", label: i18n.t("Sign Up") },
	{ value: "CALL", label: i18n.t("Call") }
];

export const LOCAL_POSTS_ACTIONS_LABELS = {
	ACTION_TYPE_UNSPECIFIED: "None",
	BOOK: "Book",
	ORDER: "Order",
	SHOP: "Shop",
	LEARN_MORE: "Learn More",
	SIGN_UP: "Sign Up",
	CALL: "Call"
};

export const LOCAL_POSTS_MEDIA_FORMATS = {
	PHOTO: "PHOTO",
	VIDEO: "VIDEO"
};

// Images
//
// Min 400 X 300
// Size max unknown. 5 MB Is our media limit
export const LOCAL_POSTS_PHOTO_LIMITS = {
	sizeLimit: 10000000, // 10 MB
	maxPixelSize: 10000, // Max 10000 × 10000 pixels in size
	minPixelWidth: 400,
	minPixelHeight: 300
};

// Videos
// Videos not yet supported via API
// Maximum duration is 60 seconds
// Video Must Be 400 x 300 pixels
// Max 75 MB.
// No cropping capabilities
