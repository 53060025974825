import React, { Component } from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";
import { getServices } from "service-fetch";

import UserService from "../../../services/UserService";
import WorkflowService from "../../../services/WorkflowService";
import ChatbotService from "../../../services/ChatbotService";
import CrmIntegrationsService from "../../../services/CrmIntegrationsService";
import InboxService from "../../../services/InboxService";
import TemplateService from "../../../services/TemplateService";
import TagService from "../../../services/TagService";
import TeamChatService from "../../../services/TeamChatService";

const { WebhooksService } = getServices();

import { TAG_TYPES } from "../../../constants/Tags";
import { CONVERSATION } from "../../../constants/Messenger";

import "../../../styles/css/components/commons/workflows.css";

class ActionField extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: false,
			options: [],
			value: null,
			isMultiSelectable: false,
			fieldMessage: ""
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	componentDidUpdate(prevProps) {
		let { workflow, field } = this.props;

		// Is this needed? action type check?
		if (prevProps.workflow.action_type !== workflow.action_type) {
			this.resetComponent();
		}
		if (field && prevProps.field && field.id !== prevProps.field.id) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			checked: false,
			options: [],
			value: null,
			isMultiSelectable: false,
			fieldMessage: ""
		});
		await this.fetchOptions();
	};

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_workflows;
	};

	fetchOptions = async () => {
		try {
			let { workflow, field, t } = this.props;

			if (!workflow || !field) {
				return;
			}

			let locationId = UserService.getActiveLocation().id;
			let workflowsConfig = await WorkflowService.getCachedWorkflowsConfig();

			let value = null; // Field value
			let options = []; // The options if any
			let isMultiSelectable = false; // Whether the dropdown should allow selecting multiple options
			let fieldMessage = "";

			// If it's the action_template_id workflow field
			if (workflowsConfig.actionFields.actionTemplateId && field.id === workflowsConfig.actionFields.actionTemplateId.id) {
				let types = "all";
				// Based on the action type, fetch different message templates
				if (workflow.action_type === workflowsConfig.actions.sendTeamchatMessage) {
					types = ["team_chat"];
				}

				// Fetch the options
				options = await TemplateService.fetchTemplates({
					locationId,
					types,
					sortField: "name",
					sortOrder: "asc",
					limit: 100
				});
				if (workflow.action_type === workflowsConfig.actions.sendTeamchatMessage && options && options.length < 1) {
					fieldMessage = t("No Teamchat Message Templates Found");
				}
				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				// Find the selected template if any
				value = options.find(t => t.value === workflow.action_template_id);
			}
			// If it's the action_crm_id workflow field
			else if (workflowsConfig.actionFields.actionCrmId && field.id === workflowsConfig.actionFields.actionCrmId.id) {
				// Fetch the options
				options = await CrmIntegrationsService.fetch({
					locationId: locationId,
					params: { sortField: "name", sortOrder: "asc", limit: 100 },
					ignoreCache: true
				});
				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_crm_id);
			}
			// If it's the action_bot_id workflow field
			else if (workflowsConfig.actionFields.actionBotId && field.id === workflowsConfig.actionFields.actionBotId.id) {
				// Fetch the options
				options = await ChatbotService.fetchChatbots({ locationId, sortField: "name", sortOrder: "asc", limit: 100 });
				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_bot_id);
			}
			// If it's the action_inbox_id workflow field
			else if (workflowsConfig.actionFields.actionInboxId && field.id === workflowsConfig.actionFields.actionInboxId.id) {
				// Fetch the options
				options = await InboxService.fetchInboxes(locationId);
				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});
				value = options.find(t => t.value === workflow.action_inbox_id);
			}
			// If it's the action_users workflow field
			else if (field.id === workflowsConfig.actionFields.actionUsers.id) {
				// Fetch the options
				options = await UserService.fetchUsers({
					companyId: UserService.getActiveCompany().id,
					sortField: "name",
					sortOrder: "asc",
					limit: 100
				});
				// Format for select
				options = options.users.map(item => {
					return { value: item.id, label: `${item.first_name} ${item.last_name}`.trim() };
				});

				value = options.filter(t => workflow.action_users && workflow.action_users.includes(t.value));
				isMultiSelectable = true;
			}
			// If it's the assign_to_tag workflow field
			else if (workflowsConfig.actionFields.actionTagId && field.id === workflowsConfig.actionFields.actionTagId.id) {
				// Fetch the options
				options = await TagService.getTags(null, { type: TAG_TYPES.tag });

				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_tag_id);
			}
			// If it's the assign_to_group workflow field
			else if (workflowsConfig.actionFields.actionGroupId && field.id === workflowsConfig.actionFields.actionGroupId.id) {
				// Fetch the options
				options = await TagService.getTags(null, { type: TAG_TYPES.group });

				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_group_id);
			}
			// If it's the action_conversation_id
			else if (workflowsConfig.actionFields.actionConversationId && field.id === workflowsConfig.actionFields.actionConversationId.id) {
				// Fetch the options
				options = await TeamChatService.fetchConversations({ userId: UserService.get().id, type: CONVERSATION.channel });

				// Format for select
				options = options.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_conversation_id);
			}
			// If it's the action_webhook_id
			else if (workflowsConfig.actionFields.actionWebhookId && field.id === workflowsConfig.actionFields.actionWebhookId.id) {
				// Fetch the options
				let { data } = await WebhooksService.fetch({
					params: {
						locationId
					}
				});

				// Format for select
				options = data.map(item => {
					return { value: item.id, label: item.name };
				});

				value = options.find(t => t.value === workflow.action_webhook_id);
			}

			await this.update({
				options,
				value,
				isMultiSelectable,
				fieldMessage
			});
		} catch (error) {
			console.log(error);
		}
	};

	onChange = (field, value) => {
		this.update({
			value
		});

		if (this.props.onChange) {
			this.props.onChange(value, field);
		}
	};

	onSelectChange = (field, option) => {
		let value = null;

		if (Array.isArray(option)) {
			value = option.map(o => o.value);
		} else if (option) {
			value = option.value;
		}

		if (this.props.onChange) {
			this.props.onChange(value, field);
		}

		this.update({ value: option });
	};

	render() {
		let { workflow, field, t } = this.props;
		let { value, options, isMultiSelectable, fieldMessage } = this.state;

		return (
			<div className="waf">
				<div className="modal__field waf__field">
					{field.required && <div className="waf__field__required">*</div>}
					<div data-tip data-for={`rtt-${field.id}`}>
						{field.name}
					</div>
					<ReactTooltip id={`rtt-${field.id}`} className="mb-react-tooltip waf__tooltip" type="info" effect="solid" place="top" arrowColor="#333">
						{field.description}
					</ReactTooltip>
					{fieldMessage && (
						<>
							<Icon.AlertCircle size={12} data-tip data-for={`rtt-field-message-${field.id}`} />
							<ReactTooltip
								id={`rtt-field-message-${field.id}`}
								className="mb-react-tooltip waf__tooltip"
								type="info"
								effect="solid"
								place="top"
								arrowColor="#333"
							>
								{fieldMessage}
							</ReactTooltip>
						</>
					)}
				</div>

				{field.inputType === "select" && (
					<Select
						id={`${field.id}-select`}
						options={options}
						value={value}
						onChange={option => this.onSelectChange(field.field, option)}
						placeholder={t("Not Assigned")}
						isDisabled={this.isReadOnly()}
						isClearable={true}
						isMulti={isMultiSelectable || false}
					/>
				)}
				{field.inputType === "text" && (
					<input
						id={`${field.id}-txt`}
						name={field.field}
						className="Common__input"
						placeholder={t("Value ...")}
						value={typeof workflow[field.field] !== "undefined" ? workflow[field.field] : ""}
						onChange={event => this.onChange(field.field, event.target.value)}
						disabled={this.isReadOnly()}
					/>
				)}
				{field.inputType === "switch" && (
					<ReactSwitch
						id={`${field.id}-switch`}
						checked={workflow[field.field] ? workflow[field.field] : false}
						uncheckedIcon={false}
						checkedIcon={false}
						onColor="#4A90E2"
						onChange={checked => this.onChange(field.field, checked)}
						disabled={this.isReadOnly()}
					/>
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ActionField);
