import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import withLocation from "../../../components/common/WithLocation";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/reviews-config.css";

class ReviewPreviewBubble extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {}

	componentDidMount() {}

	render() {
		const { forceMode } = this.props;

		// Choose which preview to show
		let previewImageSrc = "https://cdn.demandhub.co/widgets/reviews-widget/prod/assets/images/reviews-widget-preview.png";
		if (forceMode === "mobile") {
			previewImageSrc = "https://cdn.demandhub.co/widgets/reviews-widget/prod/assets/images/review-widget-icon-mobile.svg";
		}

		return (
			<div id="dh-webchat-bubble-iframe">
				<div className="rwc__preview__container__image">
					<img src={previewImageSrc} alt="review widget preview" />
					{forceMode === "mobile" && <span className="rwc__preview__container__image__rating">4.8</span>}
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(ReviewPreviewBubble));
