import i18n from "../i18n/config";

export const CALENDAR_EVENT_TYPES = {
	calendarEvents: {
		value: "calendarEvents",
		label: i18n.t("Calendar Events")
	},
	syncedAppointments: {
		value: "syncedAppointments",
		label: i18n.t("CRM Appointments")
	},
	bookingRequests: {
		value: "bookingRequests",
		label: i18n.t("Booking Requests")
	},
	external: {
		value: "external",
		label: i18n.t("External")
	}
};

export const CALENDAR_TYPES = {
	generic: "generic"
};

export const CALENDAR_EVENT_MODAL_TABS = {
	general: {
		id: "general",
		value: i18n.t("General")
	},
	guests: {
		id: "guests",
		value: i18n.t("Guests")
	},
	schedule: {
		id: "schedule",
		value: i18n.t("Schedule")
	},
	settings: {
		id: "settings",
		value: i18n.t("Settings")
	}
};

export const CALENDAR_EVENT_ATTENDANCE_TYPES = {
	yes: {
		value: "yes",
		label: i18n.t("Yes")
	},
	no: {
		value: "no",
		label: i18n.t("No")
	},
	maybe: {
		value: "maybe",
		label: i18n.t("Maybe")
	}
};
