import i18n from "../i18n/config";

export const CONNECTIONS_TABS = {
	connected: {
		id: "connected",
		value: i18n.t("Connected")
	},
	apps: {
		id: "apps",
		value: i18n.t("Apps")
	}
};
