import i18n from "../i18n/config";

export const PAYMENT_TABS = {
	paymentRequests: {
		id: "paymentRequests",
		value: i18n.t("Payment Requests & Estimates"),
		route: "/payments/payment-request"
	},
	invoices: {
		id: "invoices",
		value: i18n.t("Invoices"),
		route: "/payments/invoices"
	}
};

export const PAYMENT_TYPES = {
	general: {
		id: "general",
		display: i18n.t("Payment Request")
	},
	credit_card: {
		id: "credit_card",
		display: i18n.t("Credit Card Request")
	},
	charge: {
		id: "charge",
		display: i18n.t("Payment Charge")
	},
	estimate: {
		id: "estimate",
		display: i18n.t("Estimate")
	}
};

export const PAYMENT_STATUS = {
	all: {
		id: "all",
		display: i18n.t("All")
	},
	not_paid: {
		id: "not_paid",
		display: i18n.t("Not Paid")
	},
	confirmed: {
		id: "confirmed",
		display: i18n.t("Confirmed")
	},
	paid: {
		id: "paid",
		display: i18n.t("Paid")
	},
	refunded: {
		id: "refunded",
		display: i18n.t("Refunded")
	},
	cancelled: {
		id: "cancelled",
		display: i18n.t("Cancelled")
	}
};

export const INVOICE_STATUS = {
	all: {
		id: "all",
		display: i18n.t("All")
	},
	not_paid: {
		id: "not_paid",
		display: i18n.t("Not Paid")
	},
	paid: {
		id: "paid",
		display: i18n.t("Paid")
	},
	partially_paid: {
		id: "partially_paid",
		display: i18n.t("Partially Paid")
	},
	refunded: {
		id: "refunded",
		display: i18n.t("Refunded")
	},
	cancelled: {
		id: "cancelled",
		display: i18n.t("Cancelled")
	}
};

export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const SORT_FIELD = {
	contactName: "contactName",
	invoiceNumber: "invoiceNumber",
	status: "status",
	amount: "amount",
	invoiceDate: "invoiceDate",
	chargeDate: "chargeDate"
};

export const CURRENCY = {
	US: "usd",
	CA: "cad"
};

export const COUNTRY_CODE = {
	USA: "US",
	"United States of America": "US",
	"United States": "US",
	Canada: "CA"
};

export const STATE_CODE = {
	Arizona: "AZ",
	Alabama: "AL",
	Alaska: "AK",
	Arkansas: "AR",
	California: "CA",
	Colorado: "CO",
	Connecticut: "CT",
	Delaware: "DE",
	Florida: "FL",
	Georgia: "GA",
	Hawaii: "HI",
	Idaho: "ID",
	Illinois: "IL",
	Indiana: "IN",
	Iowa: "IA",
	Kansas: "KS",
	Kentucky: "KY",
	Louisiana: "LA",
	Maine: "ME",
	Maryland: "MD",
	Massachusetts: "MA",
	Michigan: "MI",
	Minnesota: "MN",
	Mississippi: "MS",
	Missouri: "MO",
	Montana: "MT",
	Nebraska: "NE",
	Nevada: "NV",
	"New Hampshire": "NH",
	"New Jersey": "NJ",
	"New Mexico": "NM",
	"New York": "NY",
	"North Carolina": "NC",
	"North Dakota": "ND",
	Ohio: "OH",
	Oklahoma: "OK",
	Oregon: "OR",
	Pennsylvania: "PA",
	"Rhode Island": "RI",
	"South Carolina": "SC",
	"South Dakota": "SD",
	Tennessee: "TN",
	Texas: "TX",
	Utah: "UT",
	Vermont: "VT",
	Virginia: "VA",
	Washington: "WA",
	"West Virginia": "WV",
	Wisconsin: "WI",
	Wyoming: "WY",

	Alberta: "AB",
	"British Columbia": "BC",
	Manitoba: "MB",
	"New Brunswick": "NB",
	"Newfoundland and Labrador": "NL",
	"Northwest Territories": "NT",
	"Nova Scotia": "NS",
	Nunavut: "NU",
	Ontario: "ON",
	"Prince Edward Island": "PE",
	Quebec: "QC",
	Saskatchewan: "SK",
	"Yukon Territory": "YT"
};

export const PAYMENT_COLUMNS = {
	contact: {
		id: "contact",
		value: i18n.t("Contact"),
		sortable: true,
		sortField: "contactName",
		width: 4
	},
	type: {
		id: "type",
		value: i18n.t("Type"),
		sortable: true,
		sortField: "type",
		width: 1
	},
	invoice: {
		id: "invoice",
		value: i18n.t("Invoice"),
		sortable: true,
		sortField: "invoiceNumber",
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 2
	},
	amount: {
		id: "amount",
		value: i18n.t("Amount"),
		sortable: true,
		sortField: "amount",
		width: 2,
		widthMd: 0
	},
	paidAmount: {
		id: "paid_amount",
		value: i18n.t("Paid Amount"),
		sortable: true,
		sortField: "paidAmount",
		width: 2,
		widthMd: 0
	},
	insuranceAmount: {
		id: "insurance_amount",
		value: i18n.t("Insurance Amount"),
		sortable: true,
		sortField: "insuranceAmount",
		width: 2,
		widthMd: 0
	},
	creditCard: {
		id: "payment_method_id",
		value: "CC",
		sortable: false,
		sortField: "hasPaymentMethod",
		width: 1,
		widthMd: 0
	},
	chargeDate: {
		id: "charge_date",
		value: i18n.t("Charge Date"),
		sortable: true,
		sortField: "chargeDate",
		width: 2,
		widthMd: 0
	},
	date: {
		id: "created_at",
		value: i18n.t("Date"),
		sortable: true,
		sortField: "createdAt",
		width: 3
	},
	actions: {
		id: "actions",
		value: "",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const INVOICE_COLUMNS = {
	contact: {
		id: "contact",
		value: i18n.t("Contact"),
		sortable: true,
		sortField: "contactName",
		width: 4
	},
	invoice: {
		id: "invoice",
		value: i18n.t("Invoice"),
		sortable: true,
		sortField: "invoiceNumber",
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 2
	},
	amount: {
		id: "amount",
		value: i18n.t("Amount"),
		sortable: true,
		sortField: "amount",
		width: 2,
		widthMd: 0
	},
	paidAmount: {
		id: "paid_amount",
		value: i18n.t("Paid Amount"),
		sortable: true,
		sortField: "paidAmount",
		width: 2,
		widthMd: 0
	},
	insuranceAmount: {
		id: "insurance_amount",
		value: i18n.t("Insurance Amount"),
		sortable: true,
		sortField: "insuranceAmount",
		width: 2,
		widthMd: 0
	},
	date: {
		id: "invoice_date",
		value: i18n.t("Date"),
		sortable: true,
		sortField: "invoiceDate",
		width: 3
	},
	actions: {
		id: "actions",
		value: "",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const INVOICE_LINE_ITEM_TYPE = {
	general: "general",
	tax: "tax",
	tip: "tip"
};
