import i18n from "../i18n/config";

export const FEATURE_MATRIX_TABS = {
	summary: {
		id: "summary",
		value: i18n.t("Summary")
	},
	locations: {
		id: "locations",
		value: i18n.t("Locations")
	}
};

export const LOCATION_LIST_COLUMNS = {
	company_name: {
		id: "company_name",
		value: i18n.t("Company"),
		sortable: true,
		sortField: "company_name",
		width: 1
	},
	location_name: {
		id: "location_name",
		value: i18n.t("Location"),
		sortable: true,
		sortField: "location_name",
		width: 1
	},
	// Messaging
	messenger: {
		id: "messenger",
		value: i18n.t("Messenger"),
		sortable: true,
		sortField: "messenger",
		width: 1
	},
	messenger_google: {
		id: "messenger_google",
		value: i18n.t("Google"),
		sortable: true,
		sortField: "messenger_google",
		width: 1
	},
	messenger_facebook: {
		id: "messenger_facebook",
		value: i18n.t("Facebook"),
		sortable: true,
		sortField: "messenger_facebook",
		width: 1
	},
	messenger_secure_chat: {
		id: "messenger_secure_chat",
		value: i18n.t("Secure Chat"),
		sortable: true,
		sortField: "messenger_secure_chat",
		width: 1
	},
	teamchat: {
		id: "teamchat",
		value: i18n.t("Team Chat"),
		sortable: false,
		sortField: "teamchat",
		width: 1
	},
	messenger_scheduled: {
		id: "messenger_scheduled",
		value: i18n.t("Scheduled Messages"),
		sortable: true,
		sortField: "messenger_scheduled",
		width: 1
	},
	messenger_scheduled_mms: {
		id: "messenger_scheduled_mms",
		value: "SM MMS",
		sortable: true,
		sortField: "messenger_scheduled_mms",
		width: 1
	},
	messenger_scheduled_general: {
		id: "messenger_scheduled_general",
		value: i18n.t("SM General"),
		sortable: true,
		sortField: "messenger_scheduled_general",
		width: 1
	},
	messenger_scheduled_nps: {
		id: "messenger_scheduled_nps",
		value: "SM NPS",
		sortable: true,
		sortField: "messenger_scheduled_nps",
		width: 1
	},
	messenger_scheduled_review_invites: {
		id: "messenger_scheduled_review_invites",
		value: i18n.t("SM Review Invites"),
		sortable: true,
		sortField: "messenger_scheduled_review_invites",
		width: 1
	},
	messenger_scheduled_reengagement: {
		id: "messenger_scheduled_reengagement",
		value: i18n.t("SM Reengagements"),
		sortable: true,
		sortField: "messenger_scheduled_reengagement",
		width: 1
	},
	messenger_voice: {
		id: "messenger_voice",
		value: i18n.t("Voice"),
		sortable: true,
		sortField: "messenger_voice",
		width: 1
	},
	messenger_voice_voicemail: {
		id: "messenger_voice_voicemail",
		value: i18n.t("Voicemail"),
		sortable: true,
		sortField: "messenger_voice_voicemail",
		width: 1
	},
	messenger_voice_voicemail_transcription: {
		id: "messenger_voice_voicemail_transcription",
		value: i18n.t("Voicemail Transcription"),
		sortable: true,
		sortField: "messenger_voice_voicemail_transcription",
		width: 1
	},
	messenger_voice_enhanced: {
		id: "messenger_voice_enhanced",
		value: "Enhanced Voice",
		sortable: true,
		sortField: "messenger_voice_enhanced",
		width: 1
	},
	messenger_voice_call_forwarding: {
		id: "messenger_voice_call_forwarding",
		value: i18n.t("Call Forwarding"),
		sortable: true,
		sortField: "messenger_voice_call_forwarding",
		width: 1
	},
	messenger_voice_missed_call_response: {
		id: "messenger_voice_missed_call_response",
		value: i18n.t("Missed Call Response"),
		sortable: true,
		sortField: "messenger_voice_missed_call_response",
		width: 1
	},

	// Reviews
	reviews: {
		id: "reviews",
		value: i18n.t("Reviews"),
		sortable: true,
		sortField: "reviews",
		width: 1
	},
	reviews_invites: {
		id: "reviews_invites",
		value: i18n.t("Invites"),
		sortable: true,
		sortField: "reviews_invites",
		width: 1
	},
	reviews_auto_requests: {
		id: "reviews_auto_requests",
		value: i18n.t("Auto Requests"),
		sortable: true,
		sortField: "reviews_auto_requests",
		width: 1
	},
	reviews_auto_response: {
		id: "reviews_auto_response",
		value: i18n.t("Auto Response"),
		sortable: true,
		sortField: "reviews_auto_response",
		width: 1
	},
	reviews_invites_queue: {
		id: "reviews_invites_queue",
		value: i18n.t("Invite Queue"),
		sortable: true,
		sortField: "reviews_invites_queue",
		width: 1
	},
	reviews_widget: {
		id: "reviews_widget",
		value: i18n.t("Widget"),
		sortable: true,
		sortField: "reviews_widget",
		width: 1
	},
	reviews_embedded_reviews_widget: {
		id: "reviews_embedded_reviews_widget",
		value: i18n.t("Embedded Widget"),
		sortable: true,
		sortField: "reviews_embedded_reviews_widget",
		width: 1
	},
	reviews_invites_site_rotation: {
		id: "reviews_invites_site_rotation",
		value: i18n.t("Reviews - Invites - Site Rotation"),
		sortable: true,
		sortField: "reviews_invites_site_rotation",
		width: 1
	},
	// widgets
	webchat: {
		id: "webchat",
		value: i18n.t("Web Chat"),
		sortable: true,
		sortField: "webchat",
		width: 1
	},
	bookings: {
		id: "bookings",
		value: i18n.t("Bookings"),
		sortable: true,
		sortField: "bookings",
		width: 1
	},
	micro_site: {
		id: "micro_site",
		value: i18n.t("Micro Site"),
		sortable: true,
		sortField: "micro_site",
		width: 1
	},
	// nps
	nps: {
		id: "nps",
		value: "NPS",
		sortable: true,
		sortField: "nps",
		width: 1
	},
	nps_auto_requests: {
		id: "nps_auto_requests",
		value: i18n.t("NPS Auto Requests"),
		sortable: true,
		sortField: "nps_auto_requests",
		width: 1
	},
	// Other
	payments: {
		id: "payments",
		value: i18n.t("Payments"),
		sortable: true,
		sortField: "payments",
		width: 1
	},
	leaderboard: {
		id: "leaderboard",
		value: i18n.t("Leaderboard"),
		sortable: true,
		sortField: "leaderboard",
		width: 1
	},
	custom_fields: {
		id: "custom_fields",
		value: i18n.t("Custom Fields"),
		sortable: true,
		sortField: "custom_fields",
		width: 1
	}
};

export const FILTER_COLUMN_IS_ENABLED_OPTIONS = [
	{ value: true, label: i18n.t("Is Enabled") },
	{ value: false, label: i18n.t("Is Not Enabled") }
];
