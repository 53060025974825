import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import moment from "moment";
import c3 from "c3";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";

import DashboardCard from "../../Dashboard/DashboardCard";
import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";

import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class MessagesAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messagesStats: undefined,
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			messengerAnalyticsCsv: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.fetchMessagesStats();
	}

	async fetchMessagesStats() {
		try {
			this.update({ loading: true });

			const { start, end } = this.state;

			const messagesStats = await AnalyticsService.getMessagesAnalytics(UserService.getActiveLocation().id, start, end);

			await this.update({ messagesStats, loading: false });

			this.updateChart();
		} catch (error) {
			console.log(error);
		}
	}

	async updateChart() {
		await this.update({ loading: false });
		let { t } = this.props;

		try {
			const { start, end, messagesStats } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;

			const totalInboundChatsX = messagesStats.inbound_chat.map(anInbound => anInbound.date);
			totalInboundChatsX.unshift("x");
			const totalInboundChatsY = messagesStats.inbound_chat.map(anInbound => anInbound.count);
			totalInboundChatsY.unshift(t("Total Inbound Chats"));

			const totalReceivedX = messagesStats.received.map(aReceived => aReceived.date);
			totalReceivedX.unshift("x");
			const totalReceivedY = messagesStats.received.map(aReceived => aReceived.count);
			totalReceivedY.unshift(t("Total Messages Received"));

			const totalSentX = messagesStats.sent.map(aSent => aSent.date);
			totalSentX.unshift("x");
			const totalSentY = messagesStats.sent.map(aSent => aSent.count);
			totalSentY.unshift(t("Total Messages Sent"));

			let dateColumns = [...totalInboundChatsX];
			dateColumns[0] = "Date";

			let csvData = [dateColumns, totalInboundChatsY, totalReceivedY, totalSentY];
			csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

			this.update({
				messengerAnalyticsCsv: csvData
			});

			c3.generate({
				bindto: "#messages-analytics-chart",
				data: {
					x: "x",
					columns: [totalInboundChatsX, totalInboundChatsY, totalReceivedX, totalReceivedY, totalSentX, totalSentY],
					type: "bar"
				},
				size: {
					height: 260
				},
				axis: {
					x: {
						label: t("Day"),
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								//return monthString[x.getMonth()];
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
							//format: '%Y' // format string is also available for timeseries data
						}
					},
					y: {
						label: t("Counts")
					}
				}
			});
		} catch (error) {
			console.log(`Error charting messenger analytics - ${error}`);
		}
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			let { t } = this.props;

			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "bar"
				},
				axis: {
					x: {
						label: t("Day"),
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: t("Counts")
					}
				}
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	onLocationChanged = location => {
		this.fetchMessagesStats();
	};

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};
	handleToChange = end => {
		this.update({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fetchMessagesStats();
	}

	renderHeader = () => {
		const { messengerAnalyticsCsv, start, end } = this.state;
		const { cardName } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : t("Messages")}</div>
					<div className="dashboard__card__header__actions">
						{messengerAnalyticsCsv && (
							<CSVLink data={messengerAnalyticsCsv} filename={`${locationName}-messenger_analytics-messages.csv`} target="_self">
								<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<div className="dashboard__card___datepicker">
					<label>{t("Date Range")}&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder={t(" From")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder={t(" To")}
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						{t("Update Date")}
					</button>
				</div>
			</>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Messenger is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Messenger reporting data is restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div>
				<div id="messages-analytics-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.messagesAnalytics.className} height={DASHBOARD_CARDS.messagesAnalytics.h}>
				{this.renderHeader()}
				<div className="analytics__section__chart">{this.renderBody()}</div>
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(MessagesAnalytics)));
