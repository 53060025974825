import React, { Component } from "react";
import Select from "react-select";
import { withTranslation, Trans } from "react-i18next";

import InboxService from "../../../../services/InboxService";
import UserService from "../../../../services/UserService";
import ToastService from "../../../../services/ToastService";
import WorkflowService from "../../../../services/WorkflowService";

import Modal from "../../../../components/common/DHModal";
import Alert from "../../../../components/common/Alert";

import "./delete-inbox-modal.css";

class DeleteInboxModal extends Component {
	state = {
		disabled: true,
		inputName: "",
		confirmDelete: false,
		assignableInboxes: [],
		relatedWorkflows: [],
		selectedInbox: null,
		inboxDeletedAlert: false,
		deleting: false
	};

	componentDidUpdate(prevProps) {
		if (prevProps.inboxId !== this.props.inboxId && this.props.inboxId && this.props.inboxId > 0) {
			this.fetchInboxes();
			this.fetchWorkflows();
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = async () => {
		await this.update({
			disabled: true,
			inputName: "",
			confirmDelete: false,
			assignableInboxes: [],
			inboxDeletedAlert: false,
			selectedInbox: null
		});
	};

	fetchInboxes = async () => {
		let user = UserService.get();
		let inboxes = await InboxService.fetchInboxes(UserService.getActiveLocation().id, user.id);
		let { t } = this.props;

		// Only get inboxes that aren't this inbox that is being deleted, and properly format for the react select component options
		inboxes = inboxes
			.filter(i => i.id !== this.props.inboxId)
			.map(i => {
				return {
					value: i.id,
					label: i.name
				};
			});

		let selectedInbox = {
			label: t("No Inbox"),
			value: 0
		};
		inboxes.push(selectedInbox);

		await this.update({ assignableInboxes: inboxes, selectedInbox });
	};

	fetchWorkflows = async () => {
		let workflows = await WorkflowService.fetchWorkflows({
			locationId: UserService.getActiveLocation().id,
			status: ["active"],
			actionInboxId: this.props.inboxId
		});
		await this.update({ relatedWorkflows: workflows });
	};

	onNameChange = event => {
		let { name } = this.props;
		let value = event.target.value;

		let disabled = true;

		if (name && name.length > 0 && value && value.length > 0) {
			disabled = name.toLowerCase().trim() !== value.toLowerCase().trim();
		}
		this.update({ inputName: value, disabled });
	};

	onHide = async deleted => {
		if (this.props.onHide) {
			this.props.onHide(deleted);
		}

		await this.resetComponent();
	};

	onContinueClick = async () => {
		if (this.state.disabled) {
			return;
		}

		if (this.state.assignableInboxes && this.state.assignableInboxes.length < 1) {
			await this.fetchInboxes();
		}

		await this.update({ confirmDelete: true });
	};

	onDeleteInbox = async () => {
		let { selectedInbox, disabled } = this.state;
		let { t } = this.props;

		if (disabled || !selectedInbox) {
			return;
		}

		await this.update({ deleting: true, disabled: true });

		// Toast service if something goes wrong with delewtion
		let success = await InboxService.deleteInbox({ inboxId: this.props.inboxId, contactsToInboxId: selectedInbox.value });

		await this.update({ deleting: false, disabled: false });

		if (this.props.onHide) {
			this.props.onHide(true);
		}
		await this.resetComponent();

		// On failure
		if (!success) {
			ToastService.error(t("An error occurred trying to delete the inbox. "));
			return;
		}
		// On deletion success
		await this.update({ inboxDeletedAlert: true });
	};

	onDeletedAlertClose = () => {
		this.update({ inboxDeletedAlert: false });
		this.resetComponent();
	};

	renderTypeNameToContinue() {
		let { name } = this.props;
		let { disabled, inputName, relatedWorkflows } = this.state;
		let { t } = this.props;

		let workflowsUsingActionText = "";
		if (relatedWorkflows && relatedWorkflows.length > 0) {
			if (relatedWorkflows.length === 1) {
				workflowsUsingActionText = t("There is 1 workflow action that uses this inbox to perform an action.");
			} else {
				workflowsUsingActionText = t("There are {{numberOfWorkflows}} workflow actions that use this inbox to perform an action.", {
					numberOfWorkflows: relatedWorkflows.length
				});
			}
		}

		return (
			<>
				<div>
					<Trans
						i18nKey="Are you sure you would like to delete the <1>{{name}}</1> inbox?"
						values={{ name }}
						components={{
							1: <strong />
						}}
					>
						Are you sure you would like to delete the <strong>{name}</strong> inbox?
					</Trans>
				</div>
				{workflowsUsingActionText && <div className="delete-inbox-modal__workflows">{workflowsUsingActionText}</div>}
				<div className="delete-inbox-modal__type-name">{t("Type the name of the Inbox in the following text input:")}</div>
				<input className="delete-inbox-modal__input fnctst-delete-inbox-name" value={inputName} onChange={this.onNameChange} />

				<div className="delete-inbox-modal__actions">
					<div className={`mb-button mb-button--cancel`} onClick={() => this.onHide(false)}>
						{t("Cancel")}
					</div>
					<div className={`mb-button ${!disabled ? "" : "mb-button--disabled"}`} onClick={this.onContinueClick}>
						{t("Continue")}
					</div>
				</div>
			</>
		);
	}

	renderConfirmDelete() {
		let { disabled, assignableInboxes, selectedInbox, deleting } = this.state;
		let { t } = this.props;

		return (
			<>
				<div>{t("Move contacts to inbox")}</div>
				<Select
					className="delete-inbox-modal__select"
					value={selectedInbox}
					placeholder={t("Select an Inbox")}
					options={assignableInboxes}
					onChange={value => this.update({ selectedInbox: value })}
				/>
				<div className="delete-inbox-modal__actions">
					<div className={`mb-button mb-button--cancel`} onClick={() => this.onHide(false)}>
						{t("Cancel")}
					</div>
					<div className={`mb-button ${!disabled ? "" : "mb-button--disabled"}`} onClick={this.onDeleteInbox}>
						{deleting ? t("Deleting") : t("Delete")}
					</div>
				</div>
			</>
		);
	}

	render() {
		let { show, title } = this.props;
		let { confirmDelete, inboxDeletedAlert } = this.state;
		let { t } = this.props;

		return (
			<>
				<Modal show={show} title={title} onHide={() => this.onHide(false)}>
					<div className="delete-inbox-modal">
						{!confirmDelete && this.renderTypeNameToContinue()}
						{confirmDelete && this.renderConfirmDelete()}
					</div>
				</Modal>
				<Alert type="success" show={inboxDeletedAlert} title={t("Inbox Deleted")} onClose={this.onDeletedAlertClose}>
					{t("Inbox successfully deleted.")}
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(DeleteInboxModal);
