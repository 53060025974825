import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";
import moment from "moment";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import List from "../../components/common/List";
import withLocation from "../../components/common/WithLocation";
import Action from "../../components/common/Action";

import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { USER_INVITES_COLUMNS } from "../../constants/Users";
import CompanyService from "../../services/CompanyService";
import ToastService from "../../services/ToastService";

class ManageInvites extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadedAll: true,
			data: [],
			sortField: USER_INVITES_COLUMNS.created_at.id,
			sortOrder: SORT_ORDER.desc,
			limitDefault: 25,
			limit: 25,
			pageSize: 50
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.fetchData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchData = async () => {
		let { limit, sortField, sortOrder } = this.state;

		await this.update({ loading: true });

		let companyId = UserService.getActiveCompany().id;

		let data = await CompanyService.fetchUserInvites({
			companyId,
			sortField,
			sortOrder,
			limit
		});

		await this.update({
			data,
			loading: false,
			loadedAll: data && data.length < limit
		});
	};

	onInviteUsersClick = async () => {
		this.props.history.push(`/invite-users`);
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let headers = {
			items: USER_INVITES_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	resendInvite = async inviteId => {
		try {
			let companyId = UserService.getActiveCompany().id;
			let newInvite = await CompanyService.resendUserInvite({ companyId, inviteId });

			if (!newInvite) {
				ToastService.error("Failed to resend invite");
				return;
			}

			ToastService.info("Invite resent successfully");

			await this.fetchData();
		} catch (error) {
			ToastService.error("Failed to resend invite");
			console.log(error);
		}
	};

	cancelInvite = async inviteId => {
		try {
			let companyId = UserService.getActiveCompany().id;
			let result = await CompanyService.cancelUserInvite({ companyId, inviteId });

			if (!result) {
				ToastService.error("Failed to cancel invite");
				return;
			}

			ToastService.info("Invite cancelled successfully");

			await this.fetchData();
		} catch (error) {
			ToastService.error("Failed to cancel invite");
			console.log(error);
		}
	};

	renderActions = recordData => {
		let isPending = recordData.status === "pending";

		return (
			<div className="dh-list__actions">
				{isPending && (
					<Action
						key={`resend-${recordData.id}`}
						id={`resend-${recordData.id}`}
						label="Resend"
						icon={Icon.RefreshCcw}
						onClick={() => this.resendInvite(recordData.id)}
					/>
				)}
				{isPending && (
					<Action
						key={`cancel-${recordData.id}`}
						id={`cancel-${recordData.id}`}
						label="Cancel"
						icon={Icon.X}
						onClick={() => this.cancelInvite(recordData.id)}
					/>
				)}
			</div>
		);
	};

	renderRecord = recordData => {
		let { t } = this.props;

		let userEmail = recordData.email;
		let userStatus = UtilityService.toTitleCase(recordData.status);
		let expiryDate = recordData.expiry_date ? moment(recordData.expiry_date).format("MMMM Do YYYY") : "";
		let createdAt = recordData.created_at ? moment(recordData.created_at).format("MMMM Do YYYY, h:mm A") : "";
		let isTeamChatEnabled = recordData.enable_teamchat ? t("Yes") : t("No");

		return [userEmail, isTeamChatEnabled, userStatus, expiryDate, createdAt, this.renderActions(recordData)];
	};

	render() {
		let { data, loading, loadedAll, sortField, sortOrder } = this.state;
		let user = UserService.get();
		let { t } = this.props;

		return (
			<Page>
				<div className="users__header">
					<div className="users__header__actions">
						{user.GroupPermission.create_user_invites && (
							<Action id="invite-users" label={t("Invite Users")} icon={Icon.Send} onClick={this.onInviteUsersClick} />
						)}
					</div>
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("User Invites not found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ManageInvites)));
