import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class InboundChatLeads extends Component {
	state = { inboundMessagesCount: 0 };
	async fetchInboundMessages() {
		const locationId = UserService.getActiveLocation().id;
		let inboundMessagesCount = await AnalyticsService.getInboundConversationsForLastXDays({ locationId, allTimeStats: true, days: 0 });
		if (!inboundMessagesCount) {
			this.setState({ inboundMessagesCount: 0 });
			return;
		}
		this.setState({ inboundMessagesCount });
	}

	componentDidMount() {
		this.fetchInboundMessages();
	}

	onLocationChanged = () => {
		this.fetchInboundMessages();
	};

	render() {
		const { inboundMessagesCount } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.inboundChatLeads.className} height={DASHBOARD_CARDS.inboundChatLeads.h}>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.MessageCircle size="42" />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
						{cardName ? cardName : t("Inbound Chat Leads ")}
						<Icon.Info size={20} data-tip data-for="dashboard-widget-inbound-chat-leads" />{" "}
					</div>
					<ReactTooltip
						id="dashboard-widget-inbound-chat-leads"
						className="mb-react-tooltip mb-react-tooltip--medium"
						arrowColor="#333"
						type="info"
						effect="solid"
						place="right"
					>
						{t("Shows cumulative data for all time.")}
					</ReactTooltip>
				</div>
				<div className="dashboard__card__stat">
					{isEnabled() && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">
								<Trans i18nKey="{{inboundMessagesCount}} <0>since joining</0>" values={{ inboundMessagesCount }} components={{ 0: <small /> }}>
									{inboundMessagesCount} <small>since joining</small>
								</Trans>
							</div>
						</>
					) : (
						`${isEnabled() ? t("Messenger is not enabled. Contact support to get started.") : t("Messenger report data restricted.")}`
					)}
				</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(InboundChatLeads));
