import Kichiri from "./KichiriService";
import UserService from "./UserService";
import CompanyService from "./CompanyService";
import GROUP_PERMISSIONS from "../constants/GroupPermissions";

const current = {
	version: 255,
	name: "rev255"
};

const VersionService = {
	/**
	 * Gets current local version of the web-app in the browser.
	 *
	 * @return {String}
	 */
	getLocalVersion() {
		return current.version;
	},

	/**
	 * Gets current local version name of the web-app in the browser.
	 *
	 * @return {String}
	 */
	getLocalVersionName() {
		return current.name;
	},

	/**
	 * Indicates whether the local version is behind what has been deployed.
	 *
	 * @return {Promise}
	 */
	async shouldUpgrade() {
		try {
			const response = await Kichiri.version.latest();
			return current.version < response.data.version;
		} catch (error) {
			return false;
		}
	},

	/**
	 * Fetch latest user details in order to update localstorage with new user data.
	 *
	 * @return {Promise}
	 */
	async updateUserData() {
		try {
			const user = UserService.get();

			if (!user) {
				return;
			}

			let userId = user.id;

			let newUserData = await UserService.fetchUser(userId);

			if (!newUserData) {
				return;
			}

			UserService.set(newUserData);
			// Set active location
			let activeLocation = UserService.getActiveLocation();
			let updatedLocation = newUserData.Locations.find(l => {
				return l.id === activeLocation.id;
			});

			if (
				newUserData.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type ||
				newUserData.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type
			) {
				let otherLocations = await CompanyService.fetchLocations(activeLocation.company_id);
				updatedLocation = otherLocations.find(l => {
					return l.id === activeLocation.id;
				});

				if (!updatedLocation) {
					return;
				}
			}

			if (!updatedLocation) {
				updatedLocation = newUserData.Locations[0];
			}

			UserService.setActiveLocation(updatedLocation, false);
		} catch (error) {
			console.log(error);
		}
	}
};

export default VersionService;
