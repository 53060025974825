import React, { Component } from "react";
import * as Icon from "react-feather";
import posed, { PoseGroup } from "react-pose";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import { MOBILE_WIDTH } from "../../constants/CommonConstants";
import { BOOKING_REQUESTS_STATUS } from "../../constants/BookingRequests";

import LocationService from "../../services/LocationService";
import BookingRequestService from "../../services/BookingRequestService";
import AppointmentsService from "../../services/AppointmentsService";
import UserService from "../../services/UserService";
import WorkflowService from "../../services/WorkflowService";
import NotificationService from "../../services/NotificationService";
import DashboardService from "../../services/DashboardService";
import CalendarService from "../../services/CalendarService";
import UtilityService from "../../services/UtilityService";

import "../../styles/css/components/commons/nav.css";

import config from "../../config/app/web-app.config";

const NavContainer = posed.div({
	visible: {
		minWidth: 240,
		width: 240,
		opacity: 1
	},
	collapsed: {
		minWidth: 60,
		width: 60,
		opacity: 1
	},
	hidden: {
		opacity: 0
	}
});

const SubMenu = posed.div({
	enter: {
		height: "auto",
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		height: "0px",
		opacity: 0,
		transition: {
			duration: 200
		}
	}
});

class Nav extends Component {
	constructor(props) {
		super(props);
		let isSmallDevice = document.body.clientWidth < MOBILE_WIDTH;

		this.state = {
			isSmallDevice,
			isHidden: isSmallDevice,
			showRedDot: false,
			collapsed: isSmallDevice,
			collapsedHover: false,
			hover: null,
			contactCount: 0,
			teamChatCount: 0,
			bookingRequestCount: 0,
			selected: this.props.history.location.pathname,
			items: this.generateItems()
		};

		this.positions = {};
		this.urlChangeListener = null;

		this.collapsedSubMenu = React.createRef();
		this.navMenuContainer = React.createRef();
	}

	generateItems() {
		let { t } = this.props;

		let user = UserService.get();

		return [
			{
				id: "/home",
				label: t("Home"),
				icon: Icon.Home,
				showRedDot: true,
				enabled: DashboardService.canViewDashboard()
			},
			{
				id: "/inbox",
				label: t("Inbox"),
				icon: Icon.MessageSquare,
				showRedDot: true,
				enabled: LocationService.isMessengerPermissible()
			},

			{
				id: "/customers",
				label: t("Contacts"),
				icon: Icon.UserCheck,
				enabled: LocationService.isMessengerPermissible(),
				subMenu: [
					{
						id: "/contacts",
						icon: Icon.Users,
						label: t("Contacts"),
						enabled: user.GroupPermission.view_contacts
					},
					{
						id: "/voicemail",
						icon: Icon.Voicemail,
						label: t("Voicemail"),
						enabled: LocationService.isMessengerPermissible()
					},
					{
						id: "/scheduled-messages", //broken?
						label: t("Campaigns"),
						icon: Icon.Clock,
						enabled: LocationService.isScheduledMessagesEnabled()
					}
				]
			},

			{
				id: "/reputation",
				label: t("Reputation"),
				icon: Icon.Star,
				enabled: LocationService.isReviewsEnabled(),
				subMenu: [
					{
						id: "/reviews",
						label: t("Reviews"),
						icon: Icon.Star,
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/invites",
						label: t("Invites"),
						icon: Icon.Mail,
						enabled: LocationService.isViewReviewInvitesEnabled()
					},
					{
						id: "/nps",
						label: t("NPS"),
						icon: Icon.Activity
					}
				]
			},

			{
				id: "/scheduling",
				label: t("Scheduling"),
				icon: Icon.Calendar,
				enabled: AppointmentsService.isPermissible(),
				showRedDot: true,
				subMenu: [
					{
						id: "/calendar",
						label: t("Calendar"),
						icon: Icon.Calendar,
						enabled: CalendarService.isCalendarEnabled(),
						showRedDot: true
					},
					{
						id: "/appointments",
						label: t("Appointments"),
						icon: Icon.Calendar,
						enabled: AppointmentsService.isPermissible()
					},
					{
						id: "/booking-requests",
						label: t("Booking Requests"),
						icon: Icon.Book,
						showRedDot: true
					}
				]
			},
			{
				id: "/more",
				label: t("More"),
				icon: Icon.MoreHorizontal,
				subMenu: [
					{
						id: "/dashboards",
						label: t("Dashboards"),
						icon: Icon.Grid,
						enabled: DashboardService.canViewDashboard()
					},
					{
						id: "/payments",
						label: t("Payments"),
						icon: Icon.DollarSign
					},
					{
						id: "/tasks",
						label: t("Tasks"),
						icon: Icon.List,
						enabled: LocationService.isLocationTasksPermissible()
					},
					{
						id: "/posts",
						label: t("Posts"),
						icon: Icon.Rss
					},
					{
						id: "/analytics",
						label: t("Analytics"),
						icon: Icon.BarChart,
						enabled: user.GroupPermission.view_analytic_reports
					},
					{
						id: "/settings",
						label: t("Settings"),
						icon: Icon.Settings
					}
				]
			},
			{
				id: "/customer-success",
				label: t("Customer Success"),
				icon: Icon.UserCheck,
				enabled: user.GroupPermission.view_cs_reports
			}
		];
	}

	async componentDidMount() {
		NotificationService.subscribe("contactCountUpdate", contactCount => {
			// The all count is handled by platfrom, depending on permissions of the user
			let count = contactCount.all;

			this.setState({
				contactCount: count
			});
		});

		NotificationService.subscribe("teamChatCountUpdate", event => {
			this.setState({
				teamChatCount: event.count
			});
		});

		NotificationService.subscribe("toggleNavHide", () => {
			let { isHidden, isSmallDevice } = this.state;

			if (!isSmallDevice) {
				return;
			}

			this.setState({
				isHidden: !isHidden
			});
		});

		NotificationService.subscribe("collapseNav", () => {
			this.collapseNav();
		});

		NotificationService.subscribe("locationChanged", () => {
			this.setState({
				items: this.generateItems()
			});
			BookingRequestService.clearBookingRequestInterval();
			BookingRequestService.setBookingRequestInterval();
			this.fetchBookingRequestCount();
		});

		BookingRequestService.clearBookingRequestInterval();
		BookingRequestService.setBookingRequestInterval();
		this.fetchBookingRequestCount();
		NotificationService.subscribeOnce("bookingRequestCount", "bookingRequestCountNav", count => {
			this.setState({ bookingRequestCount: count });
		});

		window.addEventListener("resize", this.onResize);
		document.addEventListener("mousedown", this.onMouseDown, false);

		if (this.props.history) {
			this.urlChangeListener = this.props.history.listen((location, action) => {
				this.update({
					selected: location.pathname
				});

				NotificationService.publish("onUrlChange", { location, action });
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
		document.removeEventListener("mousedown", this.onMouseDown, false);
		BookingRequestService.clearBookingRequestInterval();

		if (this.urlChangeListener) {
			this.urlChangeListener();
			this.urlChangeListener = null;
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchBookingRequestCount = async () => {
		let bookingRequestCount = await BookingRequestService.countBookingRequests({
			locationId: UserService.getActiveLocation().id,
			status: BOOKING_REQUESTS_STATUS.awaiting
		});
		await this.update({ bookingRequestCount });
	};

	onResize = event => {
		let isSmallDevice = document.body.clientWidth < MOBILE_WIDTH;
		this.setState({
			isSmallDevice,
			isHidden: isSmallDevice
		});
	};

	onMouseDown = event => {
		let { collapsed } = this.state;

		// If the menu is collapsed, and the submenu is not clicked, and the nav ment is not cliked, then we will stop showing the submenu
		if (
			collapsed &&
			!(this.collapsedSubMenu && this.collapsedSubMenu.contains && this.collapsedSubMenu.contains(event.target)) &&
			!(this.navMenuContainer && this.navMenuContainer.contains && this.navMenuContainer.contains(event.target))
		) {
			this.setState({ hover: null });
		}
	};

	toggleHover(clickedNavItem, sub, event) {
		let { hover } = this.state;

		// If there is a sub menu, we still want it to that the nav item is being hovered
		if (sub) {
			return;
		}

		// Determine if the current nav item is the hover element, and if it is toggle it to null
		hover = hover === clickedNavItem ? null : clickedNavItem;

		this.setState({
			hover
		});
	}

	onNavClicked = item => {
		if (item.subMenu) {
			return;
		}

		this.setState({
			selected: item.id
		});

		this.props.history.push(item.id);
	};

	renderRedDot(item, collapsed = false) {
		if (!item.showRedDot) {
			return null;
		}

		let className = "dh-nav__item__container__red-dot";

		if (collapsed) {
			className += " dh-nav__item__container__red-dot--collapsed";
		}

		let isBookingRequest = item.id === "/booking-requests" || item.id === "/scheduling";

		if (item.id !== "/inbox" && !isBookingRequest) {
			return null;
		}

		if (item.id === "/inbox") {
			let { contactCount, teamChatCount } = this.state;
			let unread = contactCount + teamChatCount > 0;
			if (unread) {
				return <div className={className} />;
			}
		}

		if (isBookingRequest) {
			let { bookingRequestCount } = this.state;
			if (bookingRequestCount) {
				return <div className={className} />;
			}
		}

		return null;
	}

	isChildSelected = currentItem => {
		let { selected } = this.state;

		// If the current item does not have a submenu it means it is a child, and hence cannot have a child that is selected, no grand kids
		if (!currentItem.subMenu) {
			return false;
		}

		// Go through all subMenu items and see if any are the selected, if so, that means the child is a selected item
		for (let subMenuItem of currentItem.subMenu) {
			if (selected.indexOf(subMenuItem.id) === 0) {
				return true;
			}
		}

		return false;
	};

	renderNavItem(item, sub = false) {
		let { t } = this.props;
		let { hover, selected } = this.state;

		let isHover = hover === item.id;

		if (typeof item.enabled !== "undefined" && !item.enabled) {
			return null;
		}

		let isSelected = selected.indexOf(item.id) === 0;
		let isChildSelected = this.isChildSelected(item);

		let containerClasses = ["dh-nav__item__container"];

		if (isSelected || isChildSelected) {
			containerClasses.push("dh-nav__item__container--active");
		}

		return (
			<div key={item.id} className="dh-nav__item" onClick={event => this.toggleHover(item.id, sub, event)}>
				<div className={containerClasses.join(" ")} onClick={() => this.onNavClicked(item)}>
					{item.icon && (
						<div className="dh-nav__item__container__icon">
							<item.icon size="16" />
						</div>
					)}
					<div className="dh-nav__item__container__title">{item.label}</div>
					<div className="dh-nav__item__container__spacer" />
					{item.new && <div className="dh-nav__item__container__new">{t("NEW")}</div>}
					{this.renderRedDot(item)}
					{item.subMenu && <div className="dh-nav__item__container__chevron">{isHover ? <Icon.ChevronDown size="12" /> : <Icon.ChevronLeft size="12" />}</div>}
				</div>
				{this.renderSubMenu(item)}
			</div>
		);
	}

	renderSubMenu(item) {
		let { hover } = this.state;
		return (
			<PoseGroup flipMove={false}>
				{hover === item.id && item.subMenu && item.subMenu.length > 0 && (
					<SubMenu key={item.id} className="dh-nav__item__sub">
						{item.subMenu.map(subItem => this.renderNavItem(subItem, true))}
					</SubMenu>
				)}
			</PoseGroup>
		);
	}

	renderCollapsedNavItem(item, sub = false) {
		let { selected, hover } = this.state;

		if (typeof item.enabled !== "undefined" && !item.enabled) {
			return null;
		}

		let isSelected = selected.indexOf(item.id) === 0;
		let isChildSelected = this.isChildSelected(item);
		let isHover = hover === item.id;

		// XXX - Gross since this logic should live at the config level, we should move this at some point
		// let unread = contactCount + teamChatCount > 0;

		let containerStyles = ["dh-nav__item__container"];

		if (isSelected || isChildSelected) {
			containerStyles.push("dh-nav__item__container--active");
		}

		if (item.new) {
			containerStyles.push("dh-nav__item__container--new");
		}

		return (
			<div
				key={item.id}
				ref={ref => (this.positions[item.id] = ref)}
				className="dh-nav__item dh-nav__item--relative"
				onClick={() => this.toggleHover(item.id, sub)}
			>
				<div data-tip data-for={`rtt-${item.id}`} className={containerStyles.join(" ")} onClick={() => this.onNavClicked(item)}>
					{item.icon && (
						<div className={`dh-nav__item__container__icon`}>
							<item.icon size="16" />
						</div>
					)}
					{this.renderRedDot(item, true)}
				</div>
				{!isHover && (
					<ReactTooltip id={`rtt-${item.id}`} className="mb-react-tooltip" type="info" effect="solid" place="right" offset={{ right: 10 }} arrowColor="#333">
						{item.label}
					</ReactTooltip>
				)}
				{this.renderCollapsedSubMenu(item)}
			</div>
		);
	}

	renderCollapsedSubMenu(item) {
		let { hover } = this.state;

		let styles = {};

		if (hover === item.id && item.subMenu && this.positions[item.id]) {
			let subMenuHeight = 50 * item.subMenu.length;

			let { top, bottom } = this.positions[item.id].getBoundingClientRect();

			if (top + subMenuHeight > window.innerHeight) {
				styles.bottom = window.innerHeight - bottom;
			}
		}

		return (
			<PoseGroup flipMove={false}>
				{hover === item.id && item.subMenu && item.subMenu.length > 0 && (
					<SubMenu ref={ref => (this.collapsedSubMenu = ref)} key={item.id} className="dh-nav__item__sub__collapsed" style={styles}>
						{item.subMenu.map(subItem => this.renderNavItem(subItem, true))}
					</SubMenu>
				)}
			</PoseGroup>
		);
	}

	collapseNav = () => {
		UtilityService.setNavigationCollapsed(true);
		this.setState({
			collapsed: true
		});

		NotificationService.publish("onNavCollapsed", true);
	};

	toggleCollapse = () => {
		let { collapsed, isSmallDevice, hover, items } = this.state;

		if (isSmallDevice) {
			return;
		}

		let newCollapsedValue = !collapsed;

		UtilityService.setNavigationCollapsed(newCollapsedValue);

		this.setState({
			collapsed: newCollapsedValue
		});

		NotificationService.publish("onNavCollapsed", newCollapsedValue);

		// When collapsing the entire navigation, we should find if any of the submenus are open and we should close them
		for (let item of items) {
			if (hover === item.id) {
				this.toggleHover(item);
			}
		}
	};

	onCollapseHover(value) {
		let { isSmallDevice } = this.state;

		if (isSmallDevice) {
			return;
		}

		this.setState({
			collapsedHover: value
		});
	}

	render() {
		let { collapsed, collapsedHover, isHidden, items } = this.state;

		let styles = ["dh-nav"];

		if (isHidden) {
			styles.push("dh-nav--hide");
		}

		return (
			<NavContainer className={styles.join(" ")} initialPose="hidden" pose={collapsed ? "collapsed" : "visible"} ref={ref => (this.navMenuContainer = ref)}>
				<div
					className="dh-nav__collapse"
					onClick={this.toggleCollapse}
					onMouseEnter={() => this.onCollapseHover(true)}
					onMouseLeave={() => this.onCollapseHover(false)}
				>
					{collapsed &&
						(!collapsedHover ? (
							<img alt="DemandHub" className="dh-nav__collapsed-logo" src="https://cdn.demandhub.co/img/logo/final-dark-svg.svg" />
						) : (
							<Icon.Menu size="20" />
						))}
					{!collapsed && (
						<div className="dh-nav__collapse__header">
							<img alt="DemandHub" className="dh-nav__collapse__header__logo" src="https://cdn.demandhub.co/img/logo/logo-dark.svg" />
							<div className="dh-nav__collapse__header__close">
								<Icon.ChevronLeft size="18" />
							</div>
						</div>
					)}
				</div>

				{collapsed && items.map(item => this.renderCollapsedNavItem(item))}
				{!collapsed && items.map(item => this.renderNavItem(item))}
			</NavContainer>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(Nav));
