import React, { Component } from "react";
import UserService from "../../services/UserService";
import moment from "moment";
import Spinners from "../../components/common/Spinners";
import { DATE_FORMAT } from "../../constants/CommonConstants";
import { AnalyticsService } from "../../services/AnalyticsService";
import c3 from "c3";
import { withTranslation } from "react-i18next";

import withLocation from "../../components/common/WithLocation";

import "../../styles/css/scenes/analyticsNps.css";

class AnalyticsNps extends Component {
	state = {
		npsData: null,
		dateRange: "this_month",
		promotersRatio: 0,
		passivesRatio: 0,
		detractorsRatio: 0,
		loading: true
	};

	handleChangeLocation() {
		if (this.props.handleChangeLocation) {
			this.props.handleChangeLocation();
			this.refreshData(this.state.dateRange);
		}
	}

	onLocationChanged = async location => {
		this.refreshData(this.state.dateRange);
	};

	async refreshData(dateRange) {
		const location = UserService.getActiveLocation();
		const dateRanges = { start: "", end: "" };

		if (dateRange === "this_week") {
			// Get the date at start and end of the current week (Sunday to Saturday)
			dateRanges.start = moment()
				.startOf("week")
				.format(DATE_FORMAT);
			dateRanges.end = moment()
				.endOf("week")
				.format(DATE_FORMAT);
		} else if (dateRange === "this_month") {
			// Get the date at start and end days of the current month
			dateRanges.start = moment()
				.startOf("month")
				.format(DATE_FORMAT);
			dateRanges.end = moment()
				.endOf("month")
				.format(DATE_FORMAT);
		} else if (dateRange === "last_month") {
			// Get the date at start and end days of the previous month
			dateRanges.start = moment()
				.subtract(1, "month")
				.startOf("month")
				.format(DATE_FORMAT);

			dateRanges.end = moment()
				.subtract(1, "month")
				.endOf("month")
				.format(DATE_FORMAT);
		} else if (dateRange === "all_time") {
			// Get the date range from 2017-01-01 to present
			dateRanges.start = moment("2017-01-01").format(DATE_FORMAT);
			dateRanges.end = moment()
				.endOf("month")
				.format(DATE_FORMAT);
		}
		this.setState({ dateRange });

		try {
			let response = await AnalyticsService.fetchNps(location.id, dateRanges.start, dateRanges.end);
			let promotersRatio = response.totalRespondents !== 0 ? (response.promoters / response.totalRespondents) * 100 : 0;
			let passivesRatio = response.totalRespondents !== 0 ? (response.passives / response.totalRespondents) * 100 : 0;
			let detractorsRatio = response.totalRespondents !== 0 ? (response.detractors / response.totalRespondents) * 100 : 0;

			this.setState({
				npsData: response,
				loading: false,
				promotersRatio: promotersRatio,
				passivesRatio: passivesRatio,
				detractorsRatio: detractorsRatio,
				nps: response.nps
			});
			c3.generate({
				bindto: "#npsChart",
				data: {
					columns: [["NPS", response.nps]],
					type: "gauge"
				},
				gauge: {
					label: {
						format: function(value, ratio) {
							return value;
						}
					},
					min: -100,
					max: 100
				},
				color: {
					pattern: ["#FF0000", "#F97600", "#F6C600", "#a0de8c", "#60B044"],
					threshold: {
						unit: "value",
						values: [-50, 0, 1, 50]
					}
				},
				size: {
					height: 180
				}
			});
		} catch (error) {
			this.setState({ loading: false });
			console.log(error);
		}
	}

	componentDidMount() {
		this.refreshData(this.state.dateRange);
	}

	render() {
		let { t } = this.props;

		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}
		const location = UserService.getActiveLocation();

		const { promotersRatio, passivesRatio, detractorsRatio } = this.state;
		return (
			<div>
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>
							{t("Net Promoter Score")}
							<span className="hidden-xxs"> - {location.name}</span>
						</h2>
					</div>
				</div>

				<div className="wrapper wrapper-content border-bottom main-table-margin">
					<div className="row">
						<div className="col-lg-12">
							<div className="ibox float-e-margins">
								<div className="dh-table-content ibox-content">
									{this.state.npsData !== null ? (
										<div className="Analytics__nps--padding  row">
											<ul className="leaderboard-nav col-lg-12">
												<li role="presentation" className={this.state.dateRange === "this_week" ? "active" : ""}>
													{/* eslint-disable-next-line */}
													<a id="weekly" onClick={event => this.refreshData("this_week")}>
														{t("This Week")}
													</a>
												</li>
												<li role="presentation" className={this.state.dateRange === "this_month" ? "active" : ""}>
													{/* eslint-disable-next-line */}
													<a id="monthly" onClick={event => this.refreshData("this_month")}>
														{t("This Month")}
													</a>
												</li>
												<li role="presentation" className={this.state.dateRange === "last_month" ? "active" : ""}>
													{/* eslint-disable-next-line */}
													<a id="lastMonth" onClick={event => this.refreshData("last_month")}>
														{t("Last Month")}
													</a>
												</li>
												<li role="presentation" className={this.state.dateRange === "all_time" ? "active" : ""}>
													{/* eslint-disable-next-line */}
													<a id="allTime" onClick={event => this.refreshData("all_time")}>
														{t("All Time")}
													</a>
												</li>
											</ul>
											<div className="Analytics__nps--padding col-sm-12 col-md-5 text-center">
												<h2 style={{ margin: "0 0 -10px 0" }}>
													<strong>{t("Net Promoter Score")}</strong>
												</h2>
												<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
													<div id="npsChart" />
												</div>
											</div>
											<div className="Analytics__nps--padding col-sm-12 col-md-6">
												<div className="row Analytics__nps--breakdown">
													<div className="Analytics__nps--progress-label col-xs-3">
														<h2>
															<strong>{this.state.npsData.promoters}</strong>
														</h2>
														<small className="Analytics__nps--promoters">
															<strong>{t("Promoters")}</strong>
														</small>
													</div>
													<div className="progress progress-large col-xs-8" style={{ padding: "0" }}>
														<div style={{ width: `${promotersRatio}%` }} className="progress-bar progress-bar-success Analytics__nps--promoters" />
													</div>
												</div>
												<div className="row Analytics__nps--breakdown">
													<div className="Analytics__nps--progress-label col-xs-3">
														<h2>
															<strong>{this.state.npsData.passives}</strong>
														</h2>
														<small className="Analytics__nps--passives">
															<strong>{t("Passives")}</strong>
														</small>
													</div>
													<div className="progress progress-large col-xs-8" style={{ padding: "0" }}>
														<div style={{ width: `${passivesRatio}%` }} className="progress-bar progress-bar-success Analytics__nps--passives" />
													</div>
												</div>
												<div className="row Analytics__nps--breakdown">
													<div className="Analytics__nps--progress-label col-xs-3">
														<h2>
															<strong>{this.state.npsData.detractors}</strong>
														</h2>
														<small className="Analytics__nps--detractors">
															<strong>{t("Detractors")}</strong>
														</small>
													</div>
													<div className="progress progress-large col-xs-8" style={{ padding: "0" }}>
														<div style={{ width: `${detractorsRatio}%` }} className="progress-bar progress-bar-success Analytics__nps--detractors" />
													</div>
												</div>
											</div>
										</div>
									) : (
										<h2 className="forum-post-container">
											{t("Sorry, no data was found")}{" "}
											<span role="img" aria-label="sad-face">
												😞
											</span>
										</h2>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(AnalyticsNps));
