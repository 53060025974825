import React, { Component } from "react";
import { PoseGroup } from "react-pose";
import { Pin } from "lucide-react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { withTranslation, Trans } from "react-i18next";

import ToastService from "../../../../services/ToastService";
import UserService from "../../../../services/UserService";
import MessagesService from "../../../../services/MessagesService";

import Action from "../../../../components/common/Action";

import { ContextMenu } from "../../../../constants/Messenger";

import "./message-pins.css";

class MessagePinsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			messagePins: []
		};

		this.cMenu = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onMouseDown, false);

		this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.resetComponent();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	onMouseDown = async e => {
		if (this.cMenu && this.cMenu.contains && this.cMenu.contains(e.target)) {
			return;
		}

		this.onHide();
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	async resetComponent() {
		await this.update({
			loading: true
		});

		await this.fetchMessagePins();

		await this.update({
			loading: false
		});
	}

	fetchMessagePins = async () => {
		let { conversationId } = this.props;

		let user = UserService.get();

		let messagePins = await MessagesService.fetchMessagePins({
			locationId: conversationId ? null : UserService.getActiveLocation().id, // Do not include the location Id if it's a teamchat conversation
			userId: user.id,
			conversationId: conversationId,
			includeMessages: true
		});

		await this.update({ messagePins });
	};

	onPinMessage = async messagePin => {
		let { t } = this.props;

		let success = await MessagesService.pinMessage({
			locationId: messagePin.location_id,
			userId: messagePin.user_id,
			conversationId: messagePin.conversation_id,
			messageId: messagePin.message_id
		});

		if (!success) {
			ToastService.error(t("An error occurred trying to pin the message."));
			return;
		}

		this.resetComponent();

		if (this.props.onMessagePinned) {
			this.props.onMessagePinned(messagePin);
		}
	};

	onMessageSelected = messagePin => {
		let { isTeamChat } = this.props;

		if (!isTeamChat) {
			return;
		}

		if (this.props.onMessageSelected) {
			this.props.onMessageSelected(messagePin.Message.id);
		}

		this.onHide();
	};

	renderLoading = () => {
		return (
			<ContentLoader height={200} width={"400px"}>
				{/* The tabs */}
				<rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />

				<rect x="0" y="80" rx="5" ry="5" width="100%" height="70" />

				<rect x="0" y="150" rx="5" ry="5" width="100%" height="70" />

				<rect x="0" y="230" rx="5" ry="5" width="100%" height="70" />
			</ContentLoader>
		);
	};

	renderList = () => {
		let { messagePins } = this.state;
		let { isTeamChat } = this.props;
		let { t } = this.props;

		let user = UserService.get();

		if (messagePins && messagePins.length < 1) {
			return (
				<div className="message-pins__list">
					<div className="message-pins__list__none">{t("No messages pinned for this conversation.")}</div>
				</div>
			);
		}

		return (
			<div className="message-pins__list">
				{messagePins.map(messagePin => {
					let createdAt = moment(messagePin.Message.contact_read_at).format("h:mm a YYYY-MM-DD");

					return (
						<div className="message-pins__list__item">
							<div
								className={`message-pins__list__item__message ${
									user.id === messagePin.Message.sender_user_id ? "message-pins__list__item__message__sent-by--out" : ""
								}`}
								onClick={isTeamChat ? () => this.onMessageSelected(messagePin) : undefined}
							>
								<div className="message-pins__list__item__message__content">{messagePin.Message.content}</div>
								<div className={`message-pins__list__item__message__sent-by`}>
									{t("Sent by")} <span className="message-pins__list__item__message__sent-by__name">{messagePin.Message.sender_user_name}</span> - {createdAt}
								</div>
							</div>
							<div>
								<Action key={`unpin-${messagePin.message_id}`} icon={Pin} label={"Unpin"} onClick={() => this.onPinMessage(messagePin)} />
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	renderContextMenu() {
		let { loading } = this.state;

		return (
			<ContextMenu
				key="container"
				className="mb-more-options-list message-pins"
				ref={ref => {
					this.cMenu = ref;
				}}
			>
				{!loading && this.renderList()}
				{loading && this.renderLoading()}
			</ContextMenu>
		);
	}

	render() {
		let { show } = this.props;

		return <PoseGroup>{show && this.renderContextMenu()}</PoseGroup>;
	}
}

export default withTranslation(null, { withRef: true })(MessagePinsModal);
