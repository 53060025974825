import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactSwitch from "react-switch";
import moment from "moment";
import * as Icon from "react-feather";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import JSONService from "../../services/JSONService";
import LocationService from "../../services/LocationService";
import GAService from "../../services/GAService";

import TimeDropdown from "../../components/common/TimeDropdown";
import Alert from "../../components/common/Alert";
import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";

import { SCHEDULE } from "../../constants/LocationConstants";

import "../../styles/css/components/commons/business-hours.css";
import "react-datepicker/dist/react-datepicker.css";

class SpecialHours extends Component {
	state = {
		schedules: [],
		locationId: UserService.getActiveLocation().id,
		gmbConnected: false,

		fetchError: false,
		hoursLoading: true,
		savingSpecialHours: false,
		updateError: false,
		updateSuccess: false,
		timeInvalid: false,

		showConfirmDelete: false,
		scheduleToDelete: null
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.getSchedulesData();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async getSchedulesData() {
		try {
			const { locationId } = this.state;
			if (!locationId) {
				this.setState({ fetchError: true, hoursLoading: false });
				return;
			}

			let response = await LocationService.getSchedulesForLocation(locationId, SCHEDULE.types.specialBusinessHours);
			if (!response) {
				this.update({ fetchError: true, hoursLoading: false });
				return;
			}

			let schedules = response;

			let gmbConnected = (await LocationService.hasIntegrations(locationId)).gmb ? true : false;

			await this.update({
				schedules: JSONService.sortByDay(schedules, "start_date", "ASC"),
				gmbConnected,
				hoursLoading: false
			});
		} catch (error) {
			console.log(error);
		}
	}

	onLocationChanged = async location => {
		let locationId = UserService.getActiveLocation().id;
		await this.update({ locationId });
		await this.getSchedulesData();
	};

	handleSpecialClosedOnChange = (index, checked) => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Update the open or close time
		newSchedules[index].closed = !checked;

		this.update({
			schedules: newSchedules
		});
	};

	handleDropdownSelect = (startDate, timingIndex, openOrCloseTime, newValue) => {
		let { t } = this.props;

		if (!newValue) {
			return;
		}

		newValue = newValue.target.value;

		let isValid = true;
		let hours = parseInt(newValue.split(":")[0]);
		let minutes = parseInt(newValue.split(":")[1]);

		if (!(0 <= hours && hours <= 24)) {
			isValid = false;
		}
		if (!(0 <= minutes && minutes <= 59)) {
			isValid = false;
		}

		if (!isValid) {
			this.setState({ [startDate + timingIndex + "OpenError"]: `${t("Invalid time entered")} ${hours} ${minutes}` });
		} else {
			this.setState({ [startDate + timingIndex + "OpenError"]: "" });
		}

		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.start_date === startDate);

		// Update the open or close time
		if (openOrCloseTime === "open") {
			newSchedules[indexOfSchedule].timingsArray[timingIndex].open = newValue;
		} else {
			newSchedules[indexOfSchedule].timingsArray[timingIndex].close = newValue;
		}

		this.update({
			schedules: newSchedules
		});
	};

	/**
	 * Check if a start and end time indicate that the hours are night hours.
	 * For example: 10pm to 3am.
	 *
	 * @param {String} startTime - A string representing the message start time (in HH:mm format) (eg. "09:00")
	 * @param {String} endTime - A string representing the message end time (in HH:mm format) (eg. "20:00")
	 * @returns {Boolean}
	 *
	 */
	areNightHours({ startTime, endTime }) {
		// Extract the range's start time
		let startHour = parseInt(startTime.split(":")[0], 10);
		let startMinute = parseInt(startTime.split(":")[1], 10);

		// Extract the range's end time
		let endHour = parseInt(endTime.split(":")[0], 10);
		let endMinute = parseInt(endTime.split(":")[1], 10);

		// A business either has
		// A: Day hours where the startTime is less than the endTime: Like 3pm open and 5pm close
		// B: Night hours where the startTime is more than the endTime: Like 11pm open and 2am close
		// Note: If the hour is the same, check to see if the start minute is before the end minute
		const isStartTimeLargerThanEndTime = startHour > endHour || (startHour === endHour && startMinute >= endMinute);

		return isStartTimeLargerThanEndTime;
	}

	isTimeValid = () => {
		try {
			let { schedules } = this.state;
			let { t } = this.props;

			let scheduleDaysSeen = {};

			// Loop through each day
			for (let i = 0; i < schedules.length; i++) {
				let aSchedule = schedules[i];
				let startDate = aSchedule.start_date; // ex: Like 1993-11-02
				// let closed = aSchedule.closed; // 0 or 1
				// let friendlyName = aSchedule.friendly_name;
				let timingsArray = aSchedule.timingsArray; // [ { open: "00:00", close: "02:00" }, ... ]

				// Keep track of dates we have seen in a hashmap
				if (scheduleDaysSeen[startDate]) {
					throw new Error(t("Invalid multiple entries with day {{startDate}}", { startDate: startDate }));
				} else {
					scheduleDaysSeen[startDate] = true;
				}

				// Go through all the timings
				for (let j = 0; j < timingsArray.length; j++) {
					let timePair = timingsArray[j];
					let openTime = timePair.open;
					let closeTime = timePair.close;

					// Make sure the opening time is a proper time
					const isOpenValid = moment(openTime, ["h:mm A", "HH:mm", "HH:mm A", "h:mm", "HH:mmA", "h:mmA"], true).isValid();
					if (!isOpenValid) {
						throw new Error(t("Invalid timing on {{startDate}}: {{openTime}} is not valid", { startDate: startDate, openTime: openTime }));
					}

					// Make sure the closing time is a proper time
					const isCloseValid = moment(closeTime, ["h:mm A", "HH:mm", "HH:mm A", "h:mm", "HH:mmA", "h:mmA"], true).isValid();
					if (!isCloseValid) {
						throw new Error(t("Invalid timing on {{startDate}}: {{closeTime}} is not valid", { startDate: startDate, closeTime: closeTime }));
					}

					// Make sure the start time is always less than the end time
					let isNightHours = this.areNightHours({ startTime: openTime, endTime: closeTime });
					if (isNightHours) {
						throw new Error(
							t("Invalid timing on {{startDate}}: The close time {{closeTime}} must be later than the open time {{openTime}}", {
								startDate: startDate,
								closeTime: closeTime,
								openTime: openTime
							})
						);
					}
				}
			}

			return true;
		} catch (e) {
			console.log(e.message);
			return false;
		}
	};

	addHoursRow = startDate => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.start_date === startDate);

		// Declare a blank new row
		let blankPair = { open: "00:00", close: "00:00" };

		// Add this row to the timings
		newSchedules[indexOfSchedule].timingsArray.push(blankPair);

		this.update({
			schedules: newSchedules
		});
	};

	removeHoursRow = (startDate, timingsIndex) => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.start_date === startDate);

		// Remove the timing pair
		newSchedules[indexOfSchedule].timingsArray.splice(timingsIndex, 1);

		this.update({
			schedules: newSchedules
		});
	};

	async handleSpecialHoursOnSave(e) {
		try {
			const { schedules, locationId } = this.state;

			if (!this.isTimeValid()) {
				this.setState({ timeInvalid: true });
				return;
			}

			await this.update({ savingSpecialHours: true });

			let response = await LocationService.updateSchedule({
				locationId,
				type: SCHEDULE.types.specialBusinessHours,
				schedule: schedules
			});

			if (!response) {
				await this.update({ updateError: true, savingSpecialHours: false });
				return;
			}

			await this.update({ savingSpecialHours: false });

			await this.getSchedulesData();
		} catch (error) {
			console.log(error);
		}
	}

	addSpecialHour() {
		let { schedules } = this.state;
		let { t } = this.props;

		let day = moment();
		let dayExists = true;

		while (dayExists) {
			let found = schedules.find(s => s.start_date === day.format("YYYY-MM-DD"));
			if (found) {
				day.add(1, "day");
			} else {
				dayExists = false;
			}
		}

		let schedule = {
			day: null,
			closed: 0,
			timingsArray: [{ open: "09:00", close: "17:00" }],
			start_date: day.format("YYYY-MM-DD"),
			friendly_name: t("New Holiday"),
			created: true,
			type: SCHEDULE.types.specialBusinessHours
		};

		schedules.push(schedule);

		this.setState({ schedules });
	}

	handleGenericEventHandler(event, i) {
		let { schedules } = this.state;
		let schedule = schedules[i];
		schedule[event.target.name] = event.target.value;
		this.setState({ schedules });
	}

	confirmDelete(index) {
		this.setState({ showConfirmDelete: true, scheduleToDelete: index });
	}

	onDateChange = async (index, value) => {
		let { schedules } = this.state;
		let schedule = schedules[index];

		if (!value) {
			schedule.start_date = moment().format("YYYY-MM-DD");
		} else {
			schedule.start_date = moment(value).format("YYYY-MM-DD");
		}

		this.update({ schedules });
	};

	async deleteSchedule(confirmed) {
		let { scheduleToDelete, schedules, locationId } = this.state;

		await this.update({ savingSpecialHours: true });

		if (!confirmed) {
			await this.update({ showConfirmDelete: false, scheduleToDelete: null, savingSpecialHours: false });
			return;
		}

		await this.update({ showConfirmDelete: false });

		let schedule = schedules[scheduleToDelete];
		let unsavedSchedule = schedule.created;

		// Remove from our schedules
		let newSchedule = schedules.slice();
		newSchedule.splice(scheduleToDelete, 1);

		// If we created it just now, just remove it in the front end
		if (unsavedSchedule) {
			await this.update({ schedules: newSchedule, scheduleToDelete: null, savingSpecialHours: false });
			return;
		}

		// Push the full schedule
		let response = await LocationService.updateSchedule({
			locationId,
			type: SCHEDULE.types.specialBusinessHours,
			schedule: newSchedule
		});

		if (!response) {
			await this.update({ scheduleToDelete: null, savingSpecialHours: false, updateError: true });
			return;
		}

		await this.update({ scheduleToDelete: null });

		await this.getSchedulesData();

		await this.update({ savingSpecialHours: false });
	}

	getPermissions = () => {
		let { gmbConnected } = this.state;

		let user = UserService.get();

		let allowCreate = !gmbConnected && user.GroupPermission.create_business_hours;
		let allowUpdate = !gmbConnected && user.GroupPermission.update_business_hours;
		let allowDelete = !gmbConnected && user.GroupPermission.delete_business_hours;

		return { allowCreate, allowUpdate, allowDelete };
	};

	render() {
		const { schedules, gmbConnected } = this.state;
		const { fetchError, hoursLoading, savingSpecialHours, updateError, showConfirmDelete, scheduleToDelete, timeInvalid } = this.state;
		let { t } = this.props;

		let { allowCreate, allowUpdate, allowDelete } = this.getPermissions();

		if (hoursLoading || savingSpecialHours) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}

		if (fetchError) {
			return (
				<div className="special-hours__container ">
					<div>
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
						<button className="mb-button" onClick={() => this.props.history.push("/settings")}>
							{t("Go Back")}
						</button>
					</div>
				</div>
			);
		}

		let descriptionText = gmbConnected
			? t("Special hours and holidays will be pulled from your Google Business settings.")
			: t("Add special hours for holidays or days when you have irregular hours.");

		return (
			<div className="special-hours__container">
				<div className="business-hours__box business-hours__special-hours business-hours__table--responsive">
					<div className="business-hours__description">{descriptionText}</div>
					<table className="table business-hours__table business-hours__table--wide">
						<thead>
							<tr>
								{allowDelete && <th className="business-hours__table--borderless" />}
								<th className="business-hours__table--borderless">{t("Day")}</th>
								<th className="business-hours__table--borderless">{t("Friendly Name")}</th>
								<th className="business-hours__table--borderless">{t("Status")}</th>
								<th className="business-hours__table--borderless" />
								<th className="business-hours__table--borderless">{t("Open & Close Times")}</th>
							</tr>
						</thead>
						<tbody>
							{schedules.map((aSchedule, index) => {
								let startDate = aSchedule.start_date; // ex: Wednesday
								let closed = aSchedule.closed; // 0 or 1
								let friendlyName = aSchedule.friendly_name;
								let timingsArray = aSchedule.timingsArray; // [ { open: "00:00", close: "02:00" }, ... ]

								return (
									<tr key={index}>
										{allowDelete && (
											<td className="business-hours__table--borderless">
												<div className="business-hours__table__delete">
													<Icon.X onClick={() => this.confirmDelete(index)} />
												</div>
											</td>
										)}
										<td className="business-hours__table--borderless fnctst-special-date">
											<DatePicker
												selected={new Date(moment(startDate).toISOString())}
												onChange={e => this.onDateChange(index, e)}
												dateFormat="yyyy-MM-dd"
												className="Common__datepicker"
												disabled={!allowUpdate}
											/>
										</td>
										<td className="business-hours__table--borderless fnctst-special-name">
											<input
												name="friendly_name"
												className="special-hours__table__name__input form-control"
												value={friendlyName}
												onChange={e => this.handleGenericEventHandler(e, index)}
												disabled={!allowUpdate}
											/>
										</td>
										<td className="business-hours__table--borderless business-hours__table--tdPadding">{closed ? t("Closed") : t("Open")}</td>
										<td className="business-hours__table--borderless">
											<ReactSwitch
												id={"business-open-switch-" + startDate}
												className="statusSwitch"
												checked={!closed}
												uncheckedIcon={false}
												checkedIcon={false}
												onColor="#4A90E2"
												onChange={checked => this.handleSpecialClosedOnChange(index, checked)}
												disabled={!allowUpdate}
											/>
										</td>
										<td className="business-hours__table--borderless ">
											<>
												{timingsArray.map((aOpeningHoursPair, timingIndex) => {
													// A opening and close time for this day
													let openTime = aOpeningHoursPair.open;
													let closeTime = aOpeningHoursPair.close;

													return (
														<div key={`business-timing-row-${timingIndex}`} className="business-hours__table__timingRow">
															<div className="business-hours__table__timingRow__timing">
																<TimeDropdown
																	id={`open-time-${startDate}-${timingIndex}`}
																	isDisabled={closed || !allowUpdate}
																	handleChange={newValue => this.handleDropdownSelect(startDate, timingIndex, "open", newValue)}
																	value={openTime}
																/>
																<span className="text-danger">{this.state[startDate + timingIndex + "OpenError"]}</span>
															</div>
															<div className="business-hours__table__timingRow__divider">To</div>
															<div className="business-hours__table__timingRow__timing">
																<TimeDropdown
																	id={`close-time-${startDate}-${timingIndex}`}
																	isDisabled={closed || !allowUpdate}
																	handleChange={newValue => this.handleDropdownSelect(startDate, timingIndex, "close", newValue)}
																	value={closeTime}
																	allow24thHour={true}
																/>
																<span className="text-danger">{this.state[startDate + timingIndex + "CloseError"]}</span>
															</div>
															{allowUpdate && timingIndex !== 0 && (
																<div className="business-hours__table__timingRow__delete">
																	<Icon.X onClick={() => this.removeHoursRow(startDate, timingIndex)} />
																</div>
															)}
														</div>
													);
												})}
												{allowUpdate && (
													<div className="business-hours__table__add-hours" onClick={() => this.addHoursRow(startDate)}>
														<Icon.PlusCircle size="16" />
													</div>
												)}
											</>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{allowCreate && (
						<div className="business-hours__add-special-hours" onClick={() => this.addSpecialHour()}>
							+ {t("Add Special Days")}
						</div>
					)}
					{allowUpdate && (
						<div className="business-hours__save">
							<button id="save" className="mb-button" onClick={() => this.handleSpecialHoursOnSave()}>
								{t("Save")}
							</button>
						</div>
					)}
				</div>
				<Alert
					type="error"
					show={updateError}
					title={t("Error updating business hours")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ updateError: false });
					}}
				>
					<div>{t("Please verify the data and try again or contact us directly at support@demandhub.co.")}</div>
				</Alert>
				{scheduleToDelete !== null && schedules[scheduleToDelete] && (
					<Alert type="info" show={showConfirmDelete} title={t("Are you sure?")} confirm={t("Yes")} onClose={confirmed => this.deleteSchedule(confirmed)}>
						<div>{t("Are you sure you want to delete the schedule for {{date}}?", { date: schedules[scheduleToDelete].start_date })}</div>
					</Alert>
				)}
				<Alert
					type="error"
					show={timeInvalid}
					title={t("Invalid Time Entered")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ timeInvalid: false });
					}}
				>
					<div>{t("Please verify the data entered and try again or contact us directly at support@demandhub.co.")}</div>
				</Alert>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(SpecialHours)));
