import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import withLocation from "../../../components/common/WithLocation";
import ReviewPreviewBubble from "./ReviewPreviewBubble";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/reviews-config.css";

class ReviewsWidgetPreview extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	renderTooltip() {
		return null;
	}

	render() {
		let { t } = this.props;

		return (
			<div className="rwc__preview__core">
				<div className="rwc__preview__core__header">
					<Icon.Eye size="18" /> {t("Preview")}
				</div>
				<div className="rwc__preview__iframe">
					<ReviewPreviewBubble forceMode={this.props.forceMode} positioning={this.props.positioning} position={this.props.position} />
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ReviewsWidgetPreview)));
