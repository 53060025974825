import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import GAService from "../../services/GAService";

import Header from "../../components/common/Header";
import Page from "../../components/common/Page";
import ReengagementAttribution from "../Dashboard/ReengagementAttribution";
import ConversionsBarChart from "../Dashboard/ConversionsBarChart";
import ConversionAppointments from "../Dashboard/ConversionAppointments";
import LifetimeReengagementRevenue from "../Dashboard/LifetimeReengagementRevenue";
import ReengagementRevenue from "../Dashboard/ReengagementRevenue";

class ConversionAnalytics extends React.Component {
	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	renderBody = () => {
		return (
			<div className="dh-page__cards">
				<ConversionsBarChart />
				<ConversionAppointments />
				<ReengagementRevenue />
				<ReengagementAttribution />
			</div>
		);
	};

	render = () => {
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Conversion Analytics")} />
				{this.renderBody()}
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { withRef: true })(ConversionAnalytics));
