import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import withLocation from "../../../components/common/WithLocation";
import EditAIAgentModal from "./EditAIAgentModal";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ChatbotService from "../../../services/ChatbotService";

import "./ai-agents.css";

class AIAgents extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			agents: [],
			selectedChatbotId: null,
			showEditAIAgentModal: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		await this.resetComponent();
	}

	async resetComponent() {
		let aiAgents = await ChatbotService.fetchActiveChatbots();

		await this.update({
			agents: aiAgents
		});
	}

	onAgentClick = agent => {
		this.update({
			selectedChatbotId: agent.id,
			showEditAIAgentModal: true
		});
	};

	onNewAgentClick = async () => {
		let { t } = this.props;

		let newAgent = await ChatbotService.createChatbot({ name: t("New AI Agent") });

		await this.resetComponent();

		this.onAgentClick(newAgent);
	};

	onAIAgentModalClose = async () => {
		this.update({
			showEditAIAgentModal: false
		});

		await this.resetComponent();
	};

	renderAgentCard = agent => {
		return (
			<div className="ai-agents__card" onClick={() => this.onAgentClick(agent)}>
				<div className="ai-agents__card__title">{agent.name}</div>
				<div>{agent.description}</div>
			</div>
		);
	};

	renderNewAgentCard = agent => {
		let { t } = this.props;

		return (
			<div className="ai-agents__card" onClick={this.onNewAgentClick}>
				<div className="ai-agents__card__title">{t("Create a new AI agent")}</div>
				<div className="ai-agents__card__action">+</div>
			</div>
		);
	};

	renderAgents = () => {
		let { agents } = this.state;

		return (
			<div className="ai-agents">
				{agents.map(agent => this.renderAgentCard(agent))}

				{this.renderNewAgentCard()}
			</div>
		);
	};

	render() {
		let { showEditAIAgentModal, selectedChatbotId } = this.state;
		let { t } = this.props;

		const user = UserService.get();

		return (
			<>
				<Page>
					<Header title={t("AI Agents")} />
					{this.renderAgents()}
				</Page>
				<EditAIAgentModal chatbotId={selectedChatbotId} show={showEditAIAgentModal} onClose={this.onAIAgentModalClose} />
			</>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(AIAgents)));
