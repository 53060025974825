import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import SearchInput from "../../components/common/SearchInput";
import withLocation from "../../components/common/WithLocation";

import ConnectionsCard from "./ConnectionsCard";

import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { CONNECTIONS_TABS } from "../../constants/Connections";

import "../../styles/css/scenes/connections.css";
import ConnectionsOverview from "./ConnectionsOverview";
import ConnectionsApps from "./ConnectionsApps";

class Connections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: CONNECTIONS_TABS.apps.id
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		let user = UserService.get();
		let canViewConnections = user.GroupPermission.view_crm_integrations;

		if (canViewConnections) {
			this.update({ selectedTab: CONNECTIONS_TABS.connected.id });
		} else {
			this.update({ selectedTab: CONNECTIONS_TABS.apps.id });
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.connections,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { t } = this.props;
		const { selectedTab } = this.state;

		let user = UserService.get();
		let canViewConnections = user.GroupPermission.view_crm_integrations;

		return (
			<Page>
				<Header title={t("Connections")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					{canViewConnections && <Tab id={CONNECTIONS_TABS.connected.id} value={CONNECTIONS_TABS.connected.value} />}
					<Tab id={CONNECTIONS_TABS.apps.id} value={CONNECTIONS_TABS.apps.value} />
				</Tabs>
				{canViewConnections && CONNECTIONS_TABS.connected.id === selectedTab && <ConnectionsOverview />}
				{CONNECTIONS_TABS.apps.id === selectedTab && <ConnectionsApps />}
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Connections)));
