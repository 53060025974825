import React from "react";
import _ from "lodash";
import isEmail from "validator/lib/isEmail";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Modal from "../../components/common/DHModal";
import TextBubble from "../../components/common/TextBubble";
import Input from "../../components/common/Input";
import AttachmentItem from "../../components/common/AttachmentItem";
import withLocation from "../../components/common/WithLocation";

import UserService from "../../services/UserService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";
import UtilityService from "../../services/UtilityService";

import { MODE, MEDIUM } from "../../constants/Messenger";

import "../../styles/css/components/commons/send-draft-modal.css";

class SendDraftModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			phoneOrEmail: "",
			message: "",

			loading: false
		};

		this.debouncedChanged = _.debounce(
			async () => {
				await this.replaceMessage();
			},
			500,
			{
				leading: false,
				trailing: true
			}
		);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show && show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let user = UserService.get();

		await this.update({
			name: UserService.getCurrentUserFullName(),
			phoneOrEmail: user.phone || user.email || "",
			message: this.props.message
		});

		await this.replaceMessage();
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	replaceMessage = async () => {
		let { name } = this.state;
		const { message } = this.props;

		await this.update({ loading: true });
		let replacedMessage = await MessagesService.replaceMessage({
			locationId: UserService.getActiveLocation().id,
			message,
			replacements: { contactFull: name }
		});

		await this.update({ message: replacedMessage, loading: false });
	};

	onHide = () => {
		const { onHide } = this.props;

		if (onHide) {
			onHide();
		}
	};

	handlePhoneOrEmailChange = async event => {
		await this.update({
			phoneOrEmail: event.target.value
		});

		this.debouncedChanged();
	};

	onNameChange = async event => {
		await this.update({ name: event.target.value });

		this.debouncedChanged();
	};

	isDisabled() {
		let { loading } = this.state;
		return loading;
	}

	onSendDraft = async () => {
		let { name, phoneOrEmail, message } = this.state;
		const { mediaIds, t } = this.props;

		if (this.isDisabled()) {
			return;
		}

		if (name === "") {
			this.nameInput.setCustomValidity(t("Name field is required."));
			this.nameInput.reportValidity();
			return;
		}

		// If the phone or email input has not been filled out
		if (phoneOrEmail === "") {
			this.phoneOrEmailInput.setCustomValidity(t("Phone or Email field is required."));
			this.phoneOrEmailInput.reportValidity();
			return;
		}

		if (message === "") {
			ToastService.error(t("Message is required."));
			return;
		}

		let phone = null;
		let email = null;

		if (isEmail(phoneOrEmail)) {
			email = phoneOrEmail;
		} else if (UtilityService.isMobilePhoneValid(phoneOrEmail)) {
			phone = phoneOrEmail;
		} else {
			ToastService.error(t("Phone or email field is invalid."));
			return;
		}

		await this.update({ loading: true });

		let media = [];

		if (mediaIds && mediaIds.length > 0) {
			let locationId = UserService.getActiveLocation().id;
			let companyId = UserService.getActiveCompany().id;

			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: mediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1,
				companyId,
				locationId
			});

			if (!uploadedMedia) {
				ToastService.error(t("Error uploading media."));
				await this.update({ loading: false });
				return;
			}

			media = uploadedMedia.map(m => m.id);
		}

		let result = await MessagesService.sendDraft({ phone, email, message, media });

		if (!result) {
			ToastService.error(t("Failed to send draft. Please try again."));
			await this.update({ loading: false });
			return;
		}

		ToastService.info(t("Sent draft message."));

		this.resetComponent();
		this.onHide();
	};

	render = () => {
		const { show, mediaIds, t } = this.props;
		let { name, phoneOrEmail, message } = this.state;
		let hasMedia = mediaIds && mediaIds.length > 0;

		return (
			<Modal show={show} onHide={this.onHide} title={t("Send Draft")} className="sdm">
				<div>
					<div className="modal__flex-container">
						<Input
							ref={input => (this.nameInput = input)}
							name="name"
							id="name-field"
							type="text"
							placeholder={t("Name")}
							label={t("Name")}
							onChange={this.onNameChange}
							value={name}
							required={true}
							autoFocus={true}
							fullWidth={true}
						/>

						<Input
							ref={input => (this.phoneOrEmailInput = input)}
							name="phoneOrEmail"
							id="contact-field"
							type="text"
							placeholder={t("Phone number or email")}
							label={t("Phone or Email")}
							onChange={this.handlePhoneOrEmailChange}
							value={phoneOrEmail}
							required={true}
							fullWidth={true}
						/>
					</div>
					{message && message.length > 0 && (
						<div className="sdm__text-bubble">
							<TextBubble text={message} blue={true} />
						</div>
					)}

					{hasMedia && (
						<div className="modal__attachments">
							<div className="modal__field">{t("Attachment")}</div>
							{mediaIds.map(mediaId => (
								<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.onHideAttachment} disabled={true} />
							))}
						</div>
					)}

					<div className="modal__actions">
						<div className={`mb-button ${this.isDisabled() ? "mb-button--disabled" : ""}`} onClick={this.onSendDraft}>
							{t("Send")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(SendDraftModal)));
