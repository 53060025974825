import React, { Component } from "react";
import moment from "moment";
import * as Icon from "react-feather";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import ContactService from "../../services/ContactService";
import MessagesService from "../../services/MessagesService";
import LocationService from "../../services/LocationService";

import Checkbox from "../../components/common/Checkbox";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import Alert from "../../components/common/Alert";
import SearchInput from "../../components/common/SearchInput";
import ContactFiltersSideModal from "./ContactFiltersSideModal";
import UpdateContactsSideModal from "./UpdateContactsSideModal";
import EditContactModal from "../../components/common/EditContactModal";
import Filters from "../../components/common/Filters";
import ExportContactsModal from "./ExportContactsModal";
import BulkContactUpload from "../Bulk/Contacts/BulkContactUpload";
import ScheduledMessageModal from "../ScheduledMessages/ScheduledMessageModal";
import DHDropdown from "../../components/common/DHDropdown";
import DHDropdownOption from "../../components/common/DHDropdownOption";
import withLocation from "../../components/common/WithLocation";

import { DATE_FORMAT, OPERATORS, SHORT_TIME_FORMAT, SORT_ORDER } from "../../constants/CommonConstants";
import { SM_TYPES } from "../../constants/ScheduledMessages";
import { CONTACT_COLUMNS, CONTACT_SELECTOR_FILTERS, CONTACT_STATES_OPTIONS, SELECTED_CONTACTS_COLUMNS } from "../../constants/Contacts";

import "../../styles/css/scenes/contact-selector.css";
import "react-day-picker/lib/style.css";
import "../../styles/css/scenes/contacts.css";
import TranslationService from "../../services/TranslationService";

class ContactSelector extends Component {
	constructor(props) {
		super(props);

		let { searchTerm } = this.getUrlParams();

		// sort order and sort field
		this.state = {
			loading: false,
			contacts: [],
			searchTerm: searchTerm,
			limitDefault: 50,
			limit: 50,
			pageSize: 100,
			sortField: CONTACT_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			selectedContacts: {},
			selectAll: false,
			cachedSelectAll: false,
			showActions: typeof this.props.showActions !== "undefined" ? this.props.showActions : true,
			showEditContactModal: false,
			showDeleteContactModal: false,
			selectedContact: null,
			previousFilter: null,
			showContactFilters: false,
			showUpdateContactsSideModal: false,

			showScheduledMessageModal: false,
			scheduledMessageRecipients: [],

			scheduledMessageType: null,
			showScheduledMessageDropdown: false,

			showExportContactsModal: false,
			exportContacts: [],
			selectedFilter: "all",
			tagsChange: false,
			showDownload: typeof this.props.showDownload !== "undefined" ? this.props.showDownload : true,
			showEdit: typeof this.props.showEdit !== "undefined" ? this.props.showEdit : true,
			showScheduleMessage: typeof this.props.showScheduleMessage !== "undefined" ? this.props.showScheduleMessage : true,
			showScheduleNps: typeof this.props.showScheduleNps !== "undefined" ? this.props.showScheduleNps : true,
			showScheduleReviewInvite: typeof this.props.showScheduleReviewInvite !== "undefined" ? this.props.showScheduleReviewInvite : true,
			showBulkUploadContacts: false
		};

		this.scheduledMessageModal = React.createRef();
	}

	componentDidMount() {
		this.fetchContacts();
	}

	onLocationChanged = () => {
		this.fetchContacts();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchContacts = async (filters, usePreviousFilter = false) => {
		let { searchTerm, sortField, sortOrder, limit, previousFilter } = this.state;

		if (!filters) {
			filters = usePreviousFilter && previousFilter ? previousFilter : { lastActivityFilter: { type: "all" } };
		}

		if (searchTerm) {
			if (!filters || !filters.conditions) {
				filters = {
					op: OPERATORS.and,
					conditions: []
				};
			}

			let searchCondition = {
				op: OPERATORS.or,
				conditions: [
					{
						op: OPERATORS.like,
						field: "name",
						value: `%${searchTerm}%`
					},
					{
						op: OPERATORS.like,
						field: "phone",
						value: `%${searchTerm}%`
					},
					{
						op: OPERATORS.like,
						field: "email",
						value: `%${searchTerm}%`
					}
				]
			};
			let orConditionIndex = filters.conditions.findIndex(condition => {
				return condition.op === OPERATORS.or;
			});

			// If the search condition is found, replace it with the current searchTerm
			if (orConditionIndex >= 0) {
				filters.conditions[orConditionIndex] = searchCondition;
			} else {
				filters.conditions.push(searchCondition);
			}
		} else if (filters && filters.conditions) {
			// If there is no searchTerm, ensure that it's not in the conditions

			let orConditionIndex = filters.conditions.findIndex(condition => {
				return condition.op === OPERATORS.or;
			});
			if (orConditionIndex >= 0) {
				filters.conditions.splice(orConditionIndex, 1);
			}
		}

		let contacts = [];
		await this.update({ loading: true });

		try {
			const locationId = UserService.getActiveLocation().id;
			contacts = await ContactService.getContactsByLocation({ locationId, filters, status: null, sortField, sortOrder, limit });
		} catch (error) {
			console.log(error);
		}

		await this.update({ loading: false, contacts, loadedAll: contacts.length < limit, previousFilter: filters });

		await this.updateSelectedContacts();
	};

	selectAllContactsWithFilters = async () => {
		await this.update({
			limit: 10000
		});
		await this.fetchContacts(null, true);
		await this.update({ selectAll: false });
		this.selectAllContacts();
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchContacts(null, true);
	};

	getUrlParams() {
		let searchTerm = "";

		let { query } = queryString.parseUrl(window.location.href);
		let filters = null;

		if (query && query.filters) {
			filters = JSON.parse(query.filters);
		}

		if (!filters || !filters.conditions || filters.conditions.length < 1) {
			return { searchTerm };
		}

		let searchIndexOf = filters.conditions.findIndex(c => c.op === OPERATORS.or);

		if (searchIndexOf >= 0) {
			let condition = filters.conditions[searchIndexOf].conditions[0];

			searchTerm = condition.value.replace(/%/g, "");
		}

		return { searchTerm };
	}

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let { query } = queryString.parseUrl(window.location.href);

		let filters = {
			op: OPERATORS.and,
			conditions: []
		};

		if (query && query.filters) {
			filters = JSON.parse(query.filters);
		}

		let { searchTerm } = this.state;

		if (!filters) {
			filters = {
				op: OPERATORS.and,
				conditions: []
			};
		}

		// Try to find the search term condition
		let searchIndexOf = filters.conditions.findIndex(c => c.op === OPERATORS.or);

		if (!searchTerm && searchIndexOf >= 0) {
			filters.conditions.splice(searchIndexOf, 1);
		} else {
			let condition = {
				op: OPERATORS.or,
				conditions: [
					{
						op: OPERATORS.like,
						field: "name",
						value: `%${searchTerm}%`
					},
					{
						op: OPERATORS.like,
						field: "phone",
						value: `%${searchTerm}%`
					},
					{
						op: OPERATORS.like,
						field: "email",
						value: `%${searchTerm}%`
					}
				]
			};
			if (searchIndexOf >= 0) {
				filters.conditions[searchIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		let params = new URLSearchParams();
		if (filters) {
			params.set("filters", JSON.stringify(filters));
		}

		// If there are no filters
		if (Object.keys(filters.conditions).length < 1) {
			params = new URLSearchParams();
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
			return;
		}

		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchContacts(null, true);
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchContacts(null, true);
	};

	async selectAllContacts() {
		let { contacts, selectAll } = this.state;
		selectAll = !selectAll;
		let selectedContacts = {};

		let selectPromises = [];

		if (!this.isShowAllContactsSelected() && !selectAll) {
			selectAll = true;
			selectedContacts = {};
		} else if (this.isShowAllContactsSelected()) {
			for (const contact of contacts) {
				selectPromises.push(this.contactSelected(contact.id, selectAll, contact, false));
			}
			if (selectPromises.length > 0) {
				await Promise.all(selectPromises);
				selectedContacts = this.state.selectedContacts;
			}
		}
		if (this.props.onSelectedContactsChange) {
			this.props.onSelectedContactsChange();
		}
		await this.update({ selectedContacts, selectAll: selectAll, cachedSelectAll: selectAll });
	}

	async updateSelectedContacts() {
		const { selectedContacts, selectAll } = this.state;
		if (!selectAll) {
			return;
		}

		let contactIds = Object.keys(selectedContacts);
		// Fetch all of the selected contacts
		const locationId = UserService.getActiveLocation().id;

		let filters = {
			op: OPERATORS.and,
			conditions: [
				{
					op: OPERATORS.in,
					field: "contact_id",
					value: contactIds
				}
			]
		};

		let contactsToSelect = await ContactService.getContactsByLocation({ locationId, filters });

		// Select all contacts
		let selectPromises = [];
		let updatedSelectedContacts = {};
		for (const contact of contactsToSelect) {
			selectPromises.push(this.contactSelected(contact.id, true, contact, false));
		}

		if (selectPromises.length > 0) {
			await Promise.all(selectPromises);
			updatedSelectedContacts = this.state.selectedContacts;
		}

		await this.update({ updatedSelectedContacts });
	}

	formatContact(contact) {
		const createdAt = moment(contact.created_at).format(DATE_FORMAT) + " at " + moment(contact.created_at).format(SHORT_TIME_FORMAT);
		return {
			id: contact.id,
			name: contact.name,
			phone: contact.phone,
			email: contact.email,
			status: contact.status,
			is_blocked: contact.is_blocked,
			tags: contact.tags,
			groups: contact.groups,
			created_at: createdAt,
			last_message_sent: contact.last_message_sent,
			preferred_medium: contact.preferred_medium
		};
	}

	contactSelected = async (id, checked, contact, updateSelectedContacts = true) => {
		let { selectedContacts, selectAll } = this.state;
		if (checked) {
			selectedContacts[`${id}`] = this.formatContact(contact);
		} else {
			delete selectedContacts[`${id}`];
			if (selectAll && this.isShowAllContactsSelected()) {
				await this.update({ selectAll: false, cachedSelectAll: false });
			}
		}

		if (updateSelectedContacts) {
			await this.update({ selectedContacts });
			if (this.props.onSelectedContactsChange) {
				this.props.onSelectedContactsChange();
			}
			return null;
		}
	};

	handleOnContactSelect = (event, contact) => {
		let { checked } = event.target;
		this.contactSelected(contact.id, checked, contact);
	};

	onRecordClicked = async item => {
		// TODO: show contact details
	};

	onEditContactHide = async () => {
		await this.update({ showEditContactModal: false });
	};

	selectedContactsList = () => {
		return Object.values(this.state.selectedContacts).sort((a, b) => {
			let aName = a.name.toLowerCase();
			let bName = b.name.toLowerCase();
			if (aName < bName) {
				return -1;
			}
			if (aName > bName) {
				return 1;
			}
			return 0;
		});
	};

	getSelectedContacts() {
		return this.state.selectedContacts;
	}

	isShowAllContactsSelected = () => {
		return this.state.selectedFilter === CONTACT_SELECTOR_FILTERS.all.id;
	};

	onFilterSelect = item => {
		let numSelectedContacts = Object.keys(this.state.selectedContacts).length;
		if (item.id === CONTACT_SELECTOR_FILTERS.selectedContacts.id) {
			this.update({ selectAll: true, cachedSelectAll: this.state.selectAll, showContactFilters: false });
		} else if (item.id === CONTACT_SELECTOR_FILTERS.all.id && numSelectedContacts > 0) {
			this.update({ selectAll: this.state.cachedSelectAll, showUpdateContactsSideModal: false });
		} else {
			this.update({ selectAll: false, cachedSelectAll: false, showUpdateContactsSideModal: false });
		}
		this.update({ selectedFilter: item.id });
	};

	isFilterSelected = item => {
		return item === this.state.selectedFilter;
	};

	getFilters = () => {
		let numSelectedContacts = Object.keys(this.state.selectedContacts).length;

		let contacts = Object.keys(CONTACT_SELECTOR_FILTERS)
			.map(item => {
				return {
					id: item,
					value: CONTACT_SELECTOR_FILTERS[item].display,
					order: CONTACT_SELECTOR_FILTERS[item].order,
					badge: item === CONTACT_SELECTOR_FILTERS.selectedContacts.id && numSelectedContacts > 0 ? numSelectedContacts : false
				};
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			contacts: {
				title: "",
				items: contacts,
				onClick: this.onFilterSelect,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	};

	getHeaders = () => {
		let { selectAll, showActions } = this.state;
		let numSelectedContacts = Object.keys(this.state.selectedContacts).length;

		let language = TranslationService.getCurrentlySetLanguage();

		let columns = Object.assign({}, this.isShowAllContactsSelected() ? CONTACT_COLUMNS : SELECTED_CONTACTS_COLUMNS);

		if (!showActions) {
			delete columns["actions"];
		}
		for (const key in columns) {
			const column = columns[key];
			if (column.id === CONTACT_COLUMNS.contactSelect.id) {
				column.value = (
					<span className="contacts__record-checkbox">
						{this.isShowAllContactsSelected() || numSelectedContacts > 0 ? (
							<Checkbox id={`select-all-checkbox`} name={`select-all-checkbox`} checked={selectAll} onChange={() => this.selectAllContacts()} />
						) : (
							<div style={{ width: 19 }}> </div>
						)}
					</span>
				);
			}
		}
		return {
			items: columns,
			sortBy: this.sortBy
		};
	};

	filtersButtonActive = () => {
		let { previousFilter } = this.state;
		let filters = Object.assign({}, previousFilter);

		delete filters["searchTerm"]; // ignore search term

		if (
			(filters && filters.lastActivityFilter && filters.lastActivityFilter.type === "all" && !filters.lastActivityFilter["include"]) ||
			filters.lastActivityFilter === null
		) {
			delete filters["lastActivityFilter"];
		}

		if (filters && filters.status && filters.status.includes("all")) {
			delete filters["status"];
		}

		if (filters && filters.tagFilters && filters.tagFilters.length < 1) {
			delete filters["tagFilters"];
		}

		if (filters && filters.tagFilters && filters.tagFilters.length < 1) {
			delete filters["tagFilters"];
		}

		if (filters && filters.assignedUsersFilter && filters.assignedUsersFilter.length < 1) {
			delete filters["assignedUsersFilter"];
		}

		// if there are any filters
		return Object.keys(filters).length > 0;
	};

	onFiltersChanged = async filters => {
		this.fetchContacts(filters, false);
	};

	onHideFilters = async () => {
		this.update({ showContactFilters: false });
	};

	onContactsUpdated = async () => {
		await this.fetchContacts(null, true);
	};

	confirmDeleteContact = async confirm => {
		try {
			if (!this.state.selectedContact) {
				return;
			}
			if (confirm) {
				await ContactService.deleteContact(this.state.selectedContact.id);
				await this.fetchContacts(null, true);
			}

			this.update({
				showDeleteContactModal: false,
				selectedContact: null
			});
		} catch (error) {
			throw error;
		}
	};

	renderStatus = status => {
		let { t } = this.props;

		return (
			<div>
				{status === "active" && <span>{t("Open")}</span>}
				{status === "inactive" && <span>{t("Closed")}</span>}
				{/* Technically, deleted contacts should not be showing up */}
				{status === "deleted" && <span>{t("Deleted")}</span>}
			</div>
		);
	};

	renderTags(tags) {
		if (!tags) {
			return null;
		}
		let tagList = tags.split(",");
		return tagList.map((t, i) => (
			<span key={`tag-${i}`}>
				<span className="badge">{t}</span>{" "}
			</span>
		));
	}

	renderActions(contact) {
		let { t } = this.props;

		const user = UserService.get();
		contact.contact_id = contact.id;

		return (
			<div className="contacts__actions ">
				{user.GroupPermission.update_contacts && (
					<Action
						key={`updateContacts-${contact.id}`}
						id={`updateContacts-${contact.id}`}
						label={t("Edit")}
						icon={Icon.Edit}
						onClick={() => {
							this.setState({
								selectedContact: contact,
								showEditContactModal: true
							});
						}}
					/>
				)}
				{user.GroupPermission.delete_contacts && (
					<Action
						key={`deleteContacts-${contact.id}`}
						id={`deleteContacts-${contact.id}`}
						label={t("Delete")}
						icon={Icon.Trash2}
						onClick={() => {
							this.setState({
								selectedContact: contact,
								showDeleteContactModal: true
							});
						}}
					/>
				)}
			</div>
		);
	}

	renderRecord = recordData => {
		let { selectedContacts, showActions } = this.state;
		let { t } = this.props;

		// if 'Selected Contacts' filter is selected, don't show contacts that aren't selected
		if (!this.isShowAllContactsSelected() && !selectedContacts[`${recordData.id}`]) {
			return null;
		}

		try {
			let record = [
				<div className="contacts__record-checkbox">
					<Checkbox
						id={`${recordData.id}-checkbox`}
						name={`${recordData.id}-checkbox`}
						checked={selectedContacts[`${recordData.id}`] ? true : false}
						onChange={e => this.handleOnContactSelect(e, recordData)}
					/>
				</div>,
				recordData.name,
				<span className="dh-tip" tip={recordData.phone}>
					{recordData.phone}
				</span>,
				<span className="dh-tip" tip={recordData.email}>
					{recordData.email}
				</span>,
				this.renderStatus(recordData.status),
				recordData.is_blocked ? <span className="badge">{t("Blocked")}</span> : "",
				this.renderTags(recordData.tags),
				this.renderTags(recordData.groups),
				recordData.last_message_sent ? moment(recordData.last_message_sent).format("MMM Do YYYY, h:mm a") : t("No Activity")
			];

			if (showActions) {
				record.push(this.renderActions(recordData));
			}
			return record;
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	onDownloadContacts() {
		let { t } = this.props;

		let dateCreateString = t("Date Created");
		let lastActivityString = t("Last Activity Date");

		let contacts = this.getSelectedContacts();
		contacts = Object.keys(contacts).map(key => {
			return {
				Name: contacts[key].name,
				Phone: contacts[key].phone,
				Email: contacts[key].email,
				Status: contacts[key].status,
				Blocked: contacts[key].is_blocked ? t("Yes") : t("No"),
				Tags: contacts[key].tags,
				[dateCreateString]: contacts[key].created_at,
				[lastActivityString]: contacts[key].last_activity_at
			};
		});
		let contactsCsv = [];
		let isHeader = true;
		for (const contact of contacts) {
			let newContact = { ...contact };
			if (isHeader) {
				let headerRow = Object.keys(newContact);
				contactsCsv.push(headerRow);
				isHeader = false;
			}
			let row = Object.values(newContact);

			contactsCsv.push(row);
		}
		this.setState({
			exportContacts: contactsCsv,
			showExportContactsModal: true
		});
	}

	onToggleShowContactFilters() {
		this.update({ showContactFilters: !this.state.showContactFilters });
	}

	onToggleShowUpdateContactsModal() {
		this.update({ showUpdateContactsSideModal: !this.state.showUpdateContactsSideModal });
	}

	toggleContactUploadModal = () => {
		this.update({
			showBulkUploadContacts: !this.state.showBulkUploadContacts
		});
	};

	onScheduleMessageSelect = async scheduledMessageType => {
		await this.update({ showScheduledMessageDropdown: false });
		await this.onOpenGroupMessaging(scheduledMessageType);
	};

	onOpenGroupMessaging = async scheduledMessageType => {
		let { selectedContacts } = this.state;
		let { t } = this.props;

		if (this.scheduledMessageModal) {
			let recipients = Object.values(selectedContacts).map(c => {
				let labelStart = t("Contact: ") + c.name;
				let labelMedium = MessagesService.getMediumData(c.preferred_medium, c.phone, c.email);

				return {
					label: `${labelStart} - ${labelMedium}`,
					type: "contact",
					value: c.id
				};
			});

			await this.update({ scheduledMessageRecipients: recipients });
		}

		await this.update({
			showScheduledMessageModal: true,
			scheduledMessageType
		});
	};

	onContactUploadCompleted = async uploadedContacts => {
		// Close and fetch the latest contact data
		await this.update({ showBulkUploadContacts: false });

		// If there were any contacts updated
		if (!uploadedContacts || uploadedContacts.length < 1) {
			return;
		}

		// Get the id of all contacts
		let uploadedContactIds = uploadedContacts.map(c => c.contact_id);

		// Fetch all of those contacts
		let filters = {
			op: OPERATORS.and,
			conditions: [
				{
					op: OPERATORS.in,
					field: "contact_id",
					value: uploadedContactIds
				}
			]
		};

		const locationId = UserService.getActiveLocation().id;
		let contactsToSelect = await ContactService.getContactsByLocation({ locationId, filters });

		// Select all contacts
		let selectPromises = [];
		let selectedContacts = {};
		for (const contact of contactsToSelect) {
			selectPromises.push(this.contactSelected(contact.id, true, contact, false));
		}

		if (selectPromises.length > 0) {
			await Promise.all(selectPromises);
			selectedContacts = this.state.selectedContacts;
		}

		await this.update({ selectedContacts });
		await this.onFilterSelect({ id: CONTACT_SELECTOR_FILTERS.selectedContacts.id });
	};

	renderScheduleMessageDropdown = () => {
		let { showScheduledMessageDropdown } = this.state;
		let { t } = this.props;

		let isScheduledMessagesEnabled = LocationService.isMessengerPermissible && LocationService.isScheduledMessagesEnabled();
		let isGeneralEnabled = LocationService.isScheduledGeneralEnabled();
		let isNpsEnabled = LocationService.isNpsPermissible() && LocationService.isScheduledNpsEnabled();
		let isReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();
		let atLeastOneEnabled = isGeneralEnabled || isNpsEnabled || isReviewInvitesEnabled;

		let showScheduleMessageButton = isScheduledMessagesEnabled && atLeastOneEnabled && this.state.showScheduleMessage;

		if (!showScheduleMessageButton) {
			return null;
		}

		return (
			<div className="contacts-search__actions__send-message__dropdown">
				<DHDropdown
					show={showScheduledMessageDropdown}
					onChange={({ show }) => {
						this.update({ showScheduledMessageDropdown: show });
					}}
					trigger={
						<div className="mb-button contacts-search__actions__send-message__dropdown-button">
							<div className="contacts-search__actions__send-message__dropdown-button__text">{t("Send a Message")}</div>
							{showScheduledMessageDropdown && <Icon.ChevronUp className="contacts-search__actions__send-message__dropdown-button__icon" size="16" />}
							{!showScheduledMessageDropdown && <Icon.ChevronDown className="contacts-search__actions__send-message__dropdown-button__icon" size="16" />}
						</div>
					}
					options={
						<>
							{isGeneralEnabled && (
								<DHDropdownOption icon={Icon.MessageCircle} title={t("Message")} action={() => this.onScheduleMessageSelect(SM_TYPES.general)} />
							)}
							{isReviewInvitesEnabled && (
								<DHDropdownOption icon={Icon.Star} title={t("Review Invite")} action={() => this.onScheduleMessageSelect(SM_TYPES.reviewInvite)} />
							)}
							{isNpsEnabled && <DHDropdownOption icon={Icon.Activity} title={t("NPS Request")} action={() => this.onScheduleMessageSelect(SM_TYPES.nps)} />}
						</>
					}
				/>
			</div>
		);
	};

	render() {
		let location = UserService.getActiveLocation();
		const user = UserService.get();

		let { actions } = this.props;

		let {
			loading,
			selectedContacts,
			contacts,
			loadedAll,
			searchTerm,
			sortField,
			sortOrder,
			showEditContactModal,
			selectedContact,
			showContactFilters,
			showUpdateContactsSideModal,
			showExportContactsModal,
			exportContacts,
			tagsChange,
			showDownload,
			showEdit,
			selectAll,
			showDeleteContactModal,
			showBulkUploadContacts,
			showScheduledMessageModal,
			scheduledMessageRecipients,
			scheduledMessageType
		} = this.state;
		let { t } = this.props;

		let numSelectedContacts = Object.keys(selectedContacts).length;

		return (
			<React.Fragment>
				<div className="contacts-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} initValue={searchTerm} />
					{actions && (
						<div>
							{actions.map(action => (
								<Action key={action.id} id={action.id} label={action.label} icon={action.icon} onClick={action.onClick} />
							))}
						</div>
					)}
					<div className="contacts-search__actions">
						{this.isShowAllContactsSelected() && (
							<>
								<Action
									id="filterContacts"
									label={t("Filter Contacts")}
									active={this.filtersButtonActive()}
									icon={Icon.Filter}
									onClick={() => this.onToggleShowContactFilters()}
								/>
								{user.GroupPermission.create_contacts && (
									<Action id="filterContacts" label={t("Upload Contacts")} icon={Icon.UploadCloud} onClick={() => this.toggleContactUploadModal()} />
								)}
							</>
						)}
						{!this.isShowAllContactsSelected() && numSelectedContacts > 0 && (
							<>
								{this.renderScheduleMessageDropdown()}
								{showDownload && (
									<Action id="download" label={t("Download Selected Contacts")} icon={Icon.Download} onClick={() => this.onDownloadContacts()} />
								)}
								{showEdit && user && user.GroupPermission.update_contacts && (
									<Action id="updateContacts" label={t("Update Contacts")} icon={Icon.Edit2} onClick={() => this.onToggleShowUpdateContactsModal()} />
								)}
							</>
						)}
					</div>
				</div>

				<div className="contact-selector__space-between">
					<Filters filters={this.getFilters()} />
					{!loadedAll && selectAll && this.isShowAllContactsSelected() && (
						<div className="contact-selector__select-all">
							<div>{t("All {{numberOfContacts}} on this page are selected. ", { numberOfContacts: contacts.length })} </div>{" "}
							<span onClick={() => this.selectAllContactsWithFilters()}>{t(`Select all contacts with applied filters`)}</span>
						</div>
					)}
				</div>
				<List
					items={this.isShowAllContactsSelected() ? contacts : this.selectedContactsList()}
					loading={loading}
					loadedAll={loadedAll || !this.isShowAllContactsSelected()}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={this.isShowAllContactsSelected() ? t("No contacts found...") : t("No contacts are selected. Go to 'All' to select contacts.")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert type="error" show={showDeleteContactModal} title={t("Delete contact?")} confirm={t("Yes")} cancel={t("No")} onClose={this.confirmDeleteContact}>
					<div>{t("Are you sure you want to delete this contact?")}</div>
				</Alert>
				<ExportContactsModal show={showExportContactsModal} onHide={() => this.setState({ showExportContactsModal: false })} exportContacts={exportContacts} />
				<ContactFiltersSideModal refreshContacts={this.onFiltersChanged} show={showContactFilters} onHide={this.onHideFilters} tagsChange={tagsChange} />
				<UpdateContactsSideModal
					show={showUpdateContactsSideModal}
					onHide={() => this.setState({ showUpdateContactsSideModal: false })}
					selectedContacts={selectedContacts}
					numSelectedContacts={selectedContacts ? selectedContacts.length : 0}
					refreshContacts={this.onContactsUpdated}
					refreshTags={() => {
						this.setState({ tagsChange: !tagsChange });
					}}
				/>
				<EditContactModal
					show={showEditContactModal}
					contactId={selectedContact ? selectedContact.id : null}
					onClose={() => {
						this.setState({ showEditContactModal: false });
					}}
					onSave={contact => {
						this.setState({ showEditContactModal: false });
						this.onContactsUpdated();
					}}
				/>
				<ScheduledMessageModal
					ref={ref => (this.scheduledMessageModal = ref)}
					scheduledMessageId={null}
					show={showScheduledMessageModal}
					recipients={scheduledMessageRecipients}
					onHide={() => this.setState({ showScheduledMessageModal: false, scheduledMessageRecipients: [] })}
					type={scheduledMessageType}
				/>

				<BulkContactUpload
					show={showBulkUploadContacts}
					onClose={this.toggleContactUploadModal}
					title={t("Upload Contacts to DemandHub")}
					location={location}
					onCompleted={this.onContactUploadCompleted}
				/>
			</React.Fragment>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ContactSelector)));
