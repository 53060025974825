import React from "react";
import moment from "moment";
import Message from "../Message/Message";
import * as Icon from "react-feather";
import Action from "../../../../components/common/Action";
import { withTranslation, Trans } from "react-i18next";

import "./customer-chat-scheduled-message-list-item.css";

class CustomerChatScheduledMessagesListItem extends React.PureComponent {
	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	render = () => {
		let { queuedMessage, onDeleteMessage } = this.props;
		let { t } = this.props;

		return (
			<div className="mb-ccsmli__item">
				<div className="mb-ccsmli__item__header">
					<div className="mb-ccsmli__item__header__text">
						<div className="mb-ccsmli__item__header__text__name">{queuedMessage.Contact.name} -</div>
						<div className="mb-ccsmli__item__header__text__send-after">
							{t("Scheduled to send on {{date}}", { date: moment(queuedMessage.send_after).format("dddd, MMMM Do YYYY @ h:mm a") })}
						</div>
					</div>
					<div className="mb-ccsmli__item__header__actions">
						<Action id={`delete-${queuedMessage.id}`} label={t("Delete")} icon={Icon.Trash2} onClick={onDeleteMessage} />
					</div>
				</div>

				<div className="mb-ccsmli__item__content">
					<Message key={queuedMessage.id} message={queuedMessage} hideContextMenu={true} />
				</div>
			</div>
		);
	};
}

export default withTranslation(null, { withRef: true })(CustomerChatScheduledMessagesListItem);
