import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import SideModal from "../../components/common/SideModal";
import ContactSelectorUpdate from "./ContactSelectorUpdate";
import TagService from "../../services/TagService";
import UserService from "../../services/UserService";
import ContactService from "../../services/ContactService";
import ContactSelectorFlagUpdate from "./ContactSelectorFlagUpdate";

import { TAG_ACTIONS, TAG_TYPES } from "../../constants/Tags";

class UpdateContactsSideModal extends Component {
	updateTagsForContacts = async (tags, action, type = TAG_TYPES.tag) => {
		let { selectedContacts } = this.props;
		let contacts = Object.values(selectedContacts);

		if (contacts && contacts.length < 1) {
			return;
		}

		contacts = contacts.map(c => c.id);

		try {
			let response = null;
			switch (action) {
				case TAG_ACTIONS.addToContacts:
					response = await TagService.addTagsForContacts(tags, contacts);
					break;
				case TAG_ACTIONS.overwriteToContacts:
					response = await TagService.overwriteTagsForContacts(tags, contacts, type);
					break;
				case TAG_ACTIONS.removeToContacts:
					response = await TagService.removeTagsForContacts(tags, contacts);
					break;
				default:
					break;
			}
			if (!response) {
				return false;
			}
			if (this.props.refreshContacts) {
				this.props.refreshContacts(null, true);
			}
			return true;
		} catch (error) {
			return false;
		}
	};

	updateFlagsForContacts = async flags => {
		let { selectedContacts } = this.props;
		let contacts = Object.values(selectedContacts);

		if (contacts && contacts.length < 1) {
			return;
		}

		let contactIds = contacts.map(c => c.id);

		try {
			let response = await ContactService.bulkUpdateContacts({ flags, contactIds });

			if (!response) {
				return false;
			}
			if (this.props.refreshContacts) {
				this.props.refreshContacts(null, true);
			}
			return true;
		} catch (error) {
			return false;
		}
	};

	render() {
		const user = UserService.get();
		const { show, onHide, numSelectedContacts, refreshTags } = this.props;
		let { t } = this.props;

		return (
			<SideModal
				show={show && user && user.GroupPermission.update_contacts}
				title={t("Update Contacts")}
				titleIcon={Icon.Edit2}
				onHide={onHide}
				closeOnClick={false}
			>
				<ContactSelectorUpdate
					showHeader={true}
					updateTagsForContacts={this.updateTagsForContacts}
					numSelectedContacts={numSelectedContacts}
					refreshTags={refreshTags}
					type={TAG_TYPES.tag}
				/>
				<ContactSelectorUpdate
					showHeader={true}
					updateTagsForContacts={this.updateTagsForContacts}
					numSelectedContacts={numSelectedContacts}
					refreshTags={refreshTags}
					type={TAG_TYPES.group}
				/>

				<ContactSelectorFlagUpdate showHeader={true} updateFlagsForContacts={this.updateFlagsForContacts} numSelectedContacts={numSelectedContacts} />
			</SideModal>
		);
	}
}

export default withTranslation(null, { withRef: true })(UpdateContactsSideModal);
