import React, { Component } from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";

import Modal from "../../components/common/DHModal";
import TextArea from "../../components/common/TextArea";
import Checkbox from "../../components/common/Checkbox";

import { CUSTOM_FIELD_TYPES, CUSTOM_FIELD_TYPES_LABELS, CUSTOM_FIELD_MAX_FAVORITES } from "../../constants/CustomFields";

class UpdateCustomFieldsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.data ? this.props.data.name : "",
			field: this.props.data ? this.props.data.field : "",
			originalField: this.props.data ? this.props.data.field : "",
			type: this.props.data ? this.props.data.type : CUSTOM_FIELD_TYPES.string,
			description: this.props.data ? this.props.data.description : "",
			favorite: this.props.data ? this.props.data.favorite : false,
			canFavorite: this.props.data ? this.props.data.canFavorite : false, // represents that ability to favorite based on the number currently favorited custom fields and the maximum allowed
			fieldExists: false,
			types: Object.values(CUSTOM_FIELD_TYPES),
			edited: false
		};

		this.debouncedChanged = _.debounce(
			value => {
				this.checkIfExists(value);
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.canFavorite();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.update({ show: this.props.show });
		}
		if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
			if (this.props.data) {
				this.update({
					name: this.props.data.name,
					originalField: this.props.data.field,
					field: this.props.data.field,
					type: this.props.data.type,
					description: this.props.data.description,
					favorite: this.props.data.favorite
				});
			} else {
				this.update({ name: "", field: "", originalField: "", type: CUSTOM_FIELD_TYPES.string, description: "", favorite: false });
			}
			this.canFavorite();
		}
	}

	canFavorite = async () => {
		let customFields = await LocationService.getCustomFields({
			locationId: UserService.getActiveLocation().id,
			favorites: true,
			limit: CUSTOM_FIELD_MAX_FAVORITES + 1
		});
		let canFavorite = false;

		if (customFields && customFields.filter(cf => cf.favorite).length < CUSTOM_FIELD_MAX_FAVORITES) {
			canFavorite = true;
		}
		this.update({ canFavorite });
	};

	isFormValid = () => {
		let { name, originalField, field, fieldExists } = this.state;
		let user = UserService.get();
		if (!user.GroupPermission.update_custom_fields) {
			return false;
		}

		// The name and field must be filled out, and field needs to be alpha numeric.
		return !(name === "" || field === "" || field.match(/^[A-Za-z0-9_]+$/) === null || (fieldExists && field !== originalField));
	};

	handleOnSubmit = async () => {
		if (!this.isFormValid()) {
			return;
		}

		if (this.props.onSubmit) {
			const { name, field, description, type, favorite } = this.state;
			const { data } = this.props;

			let customField = { name, description, field, type, favorite };
			let createNew = true;

			if (data) {
				customField.id = data.id;
				createNew = false;
			}

			await this.props.onSubmit(createNew, customField);
			this.setState({ name: "", field: "", description: "", originalField: "" });
		}
	};

	handleFieldChange = event => {
		try {
			let field = event.target.value;

			// replace any spaces, dashes or special characters with underscore
			field = field.toLowerCase().replace(/\s|-|~|!|\?|\./gi, "_");

			this.debouncedChanged(field);
			this.setState({ field: field });
		} catch (error) {
			console.log(error);
		}
	};

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	checkIfExists = async field => {
		await this.update({
			fieldExists: false
		});

		field = field.toLowerCase();
		let locationId = UserService.getActiveLocation().id;
		let exists = false;

		// only check if the tag exists if there is anything in the field
		if (field && field.trim() !== "") {
			exists = await LocationService.customFieldExists({ locationId, field });
		}

		await this.update({ fieldExists: exists });
	};

	isFavoriteCheckboxDisabled = () => {
		const { favorite, canFavorite } = this.state;

		let user = UserService.get();
		if (!user.GroupPermission.update_custom_fields) {
			return true;
		}

		// If are are at the max limit of favorited custom fields, and this custom field is enabled, then we should be able to disable it
		if (!canFavorite && favorite) {
			return false;
		}

		// If we have not reach the max limit
		if (canFavorite) {
			return false;
		}

		return true;
	};

	render() {
		const { data, onHide } = this.props;
		let { t } = this.props;
		const { name, field, description, show, fieldExists, originalField, type, types, favorite, canFavorite } = this.state;

		return (
			<Modal show={show || false} onHide={() => onHide()} title={!data ? t("Create a Custom Field") : t("Update a Custom Field")}>
				<div className="modal__flex-container">
					<div className="modal__field">{t("Display Name")}</div>
					<input
						name="name"
						id="custom-field-name"
						type="text"
						onChange={this.handleGenericEventHandler}
						value={name}
						className="Common__input"
						autoComplete="off"
						placeholder={t("Hair Color")}
					/>

					<div className="modal__field">{t("Field")}</div>
					<input
						name="field"
						id="custom-field-field"
						type="text"
						onChange={this.handleFieldChange}
						value={field}
						className="Common__input"
						autoComplete="off"
						placeholder={t("hair_color")}
					/>
					<div className="text-danger" style={{ paddingLeft: 5 }}>
						{originalField !== field && fieldExists ? <span>{t("A custom field with this field already exists")}</span> : <span>&nbsp;</span>}
					</div>

					<div className="modal__field">{t("Field")}</div>
					<select id="custom-field-type" className="Common__input" name="type" onChange={this.handleGenericEventHandler} value={type}>
						{types.map(aType => {
							return (
								<option key={aType} value={aType}>
									{CUSTOM_FIELD_TYPES_LABELS[aType]}
								</option>
							);
						})}
					</select>

					<div className="modal__field" data-tip data-for="favorite-rtt">
						{t("Favorite")} <Icon.Info size={12} />{" "}
					</div>
					<Checkbox
						id="custom-field-favorite"
						name="favorite"
						checked={favorite}
						onChange={event => {
							if (this.isFavoriteCheckboxDisabled()) {
								return;
							}
							if (!canFavorite && !event.target.checked) {
								this.update({ canFavorite: true });
							}
							this.update({ favorite: event.target.checked });
						}}
						disabled={this.isFavoriteCheckboxDisabled()}
					/>
					<ReactTooltip id="favorite-rtt" className="mb-react-tooltip mb-react-tooltip--medium" arrowColor="#333" type="info" effect="solid" place="bottom">
						{t("Favorited custom fields will be available in Messenger in the contact details menu.")}
					</ReactTooltip>

					<div className="modal__field">{t("Description")}</div>
					<TextArea
						name="description"
						id="description"
						type="text"
						onChange={this.handleGenericEventHandler}
						value={description}
						height={130}
						rows={5}
						style={{ resize: "none" }}
						placeholder={t("Description of the field")}
						blueBorder={true}
						showFooter={false}
					/>

					<div className="modal__actions">
						<div id={!data ? "create" : "update"} className={`mb-button ${this.isFormValid() ? "" : "mb-button--disabled"}`} onClick={this.handleOnSubmit}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(UpdateCustomFieldsModal);
