import React, { Component } from "react";
import _ from "lodash";
import ContentLoader from "react-content-loader";
import { withTranslation, Trans } from "react-i18next";

import ToastService from "../../../../services/ToastService";
import * as Icon from "react-feather";
import TooltipService from "../../../../services/TooltipService";
import DHSearchInput from "../../../../components/common/DHSearchInput";
import CreateBookingSetting from "../CreateBookingSetting";
import BookingRequestService from "../../../../services/BookingRequestService";
import UserService from "../../../../services/UserService";
import Action from "../../../../components/common/Action";
import Alert from "../../../../components/common/Alert";
import LanguageSelectorMini from "../../../../components/common/LanguageSelectorMini";

import TranslationService from "../../../../services/TranslationService";

import "../../../../styles/css/scenes/widget-config.css";
import "../../../../styles/css/scenes/booking-widget-services.css";
import "../../../../styles/css/scenes/booking-widget-config.css";

class Services extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			searchTerm: "",
			appointmentSettings: [],
			selectedAppointmentSetting: null,
			showModal: false,
			changesMade: false,
			showConfirmDeleteModal: false,
			languageSectionSupportedOptions: [],
			languageSection: "en_CA"
		};

		this.debouncedChanged = _.debounce(
			async () => {
				await this.fetchAppointmentSettings();
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async resetComponent() {
		try {
			await this.update({
				searchTerm: "",
				appointmentSettings: [],
				selectedAppointmentSetting: null,
				showModal: false,
				changesMade: false,
				showConfirmDeleteModal: false
			});
			await this.fetchAppointmentSettings();
			await this.setLanguageSelectionOptions();
		} catch (error) {
			console.log(error);
		}
	}

	fetchAppointmentSettings = async () => {
		let { t } = this.props;

		await this.update({ loading: true });

		try {
			let locationId = UserService.getActiveLocation().id;
			let { searchTerm } = this.state;

			const appointmentSettings = await BookingRequestService.getAppointmentSettings({ locationId, searchTerm });

			if (!appointmentSettings) {
				ToastService.error(t(`Unable to fetch appiontment settings. Please try again.`));
				return;
			}

			await this.update({ appointmentSettings });
		} catch (error) {
			console.log(error);
		}

		await this.update({ loading: false });
	};

	setLanguageSelectionOptions = async () => {
		let locationLanguage = TranslationService.getLocationLanguage();
		let supportedLocationOptions = TranslationService.getSupportedLanguagesOptions();

		await this.update({
			languageSectionSupportedOptions: supportedLocationOptions,
			languageSection: locationLanguage
		});
	};

	async handleOnDelete() {
		try {
			let { selectedAppointmentSetting } = this.state;
			let { t } = this.props;

			if (!selectedAppointmentSetting) {
				return;
			}

			await BookingRequestService.deleteAppointmentSettings(selectedAppointmentSetting.id);
			ToastService.info(t("Appointment Service Deleted!"));
			await this.resetComponent();
		} catch (error) {
			console.log(error);
			ToastService.error(t("Error deleting appointment service!"));
		}
	}

	async onSearchChange(event) {
		await this.update({ searchTerm: event.target.value });

		await this.debouncedChanged();
	}

	async onSearchClear() {
		await this.update({ searchTerm: "" });
		await this.resetComponent();
	}

	onConfirmDeleteModalClose = async confirm => {
		if (confirm) {
			await this.handleOnDelete();
		}

		await this.update({ showConfirmDeleteModal: false, selectedAppointmentSetting: null });
	};

	renderHeader() {
		const { searchTerm } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_widgets;

		return (
			<div className="bwsc__header">
				{/*
				Removed in DH-3635 due to trade off of moving to JSON
				<DHSearchInput
					onChange={event => this.onSearchChange(event)}
					onSearchClear={event => this.onSearchClear(event)}
					value={searchTerm}
					placeholder="Filter services..."
					width={300}
				/>*/}
				<div className="Common__flex">
					{allowCreate && (
						<div style={{ position: "relative" }}>
							<Action
								id="create"
								label={t("Create a service")}
								icon={Icon.Plus}
								onClick={() => {
									this.update({ selectedAppointmentSetting: null, showModal: true });
								}}
							/>
						</div>
					)}

					{TooltipService.info("refresh", "top", "solid", "Refresh")}
					{TooltipService.info("create", "top", "solid", "Create")}
				</div>
			</div>
		);
	}

	renderOptions() {
		const { appointmentSettings, languageSection, languageSectionSupportedOptions } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;
		let allowDelete = user.GroupPermission.delete_widgets;

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		return (
			<div className="bwsc__body__options">
				{showLanguageSelectors && (
					<div className="form-group cbs__create_modal__language_container">
						<LanguageSelectorMini
							language={languageSection}
							languageOptions={languageSectionSupportedOptions}
							onChange={newLanguage => {
								this.update({ languageSection: newLanguage });
							}}
						/>
					</div>
				)}
				{appointmentSettings.map((item, key) => {
					let textFields = null;
					try {
						textFields = JSON.parse(item.text_fields);
					} catch (error) {
						console.log("Booking Config: An error occured reading the text_fields");
					}

					let name = textFields ? textFields.name[languageSection] : "";
					let description = textFields ? textFields.description[languageSection] : "";

					return (
						<div key={key} className="wc__options__field wc__options__field--small">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">
									{name}{" "}
									{item.User && item.User.first_name && (
										<div className="wc__options__field__label__title__small">
											{t("- Offered by {{name}}", { name: UserService.createFullName({ firstName: item.User.first_name, lastName: item.User.last_name }) })}
										</div>
									)}
								</div>
								<div className="wc__options__field__label__description">{description}</div>
								<div className="wc__options__field__label__description">{t("Duration: {{minutes}} minutes", { minutes: item.duration })}</div>
							</div>
							<div className="wc__options__field__actions">
								{allowUpdate && (
									<Action
										id={`edit-service-${name}`}
										label={t("Edit service")}
										icon={Icon.Edit3}
										onClick={() => this.update({ selectedAppointmentSetting: item, showModal: true })}
									/>
								)}
								{allowDelete && (
									<Action
										id={`delete-service-${name}`}
										label={t("Delete service")}
										icon={Icon.Trash2}
										onClick={() => this.setState({ showConfirmDeleteModal: true, selectedAppointmentSetting: item })}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	renderLoading() {
		return (
			<ContentLoader height={480} width={440}>
				<rect x="0" y="0" rx="5" ry="5" width="440" height="45" />
				<rect x="0" y="70" rx="5" ry="5" width="440" height="45" />
				<rect x="0" y="140" rx="5" ry="5" width="440" height="45" />
				<rect x="0" y="210" rx="5" ry="5" width="440" height="45" />
				<rect x="0" y="280" rx="5" ry="5" width="440" height="45" />
			</ContentLoader>
		);
	}

	render() {
		const { selectedAppointmentSetting, showModal, showConfirmDeleteModal, loading } = this.state;
		let { t } = this.props;

		return (
			<div className="bwsc">
				{this.renderHeader()}
				{loading && this.renderLoading()}
				{!loading && <div className="bwsc__body">{this.renderOptions()}</div>}

				{showModal && (
					<CreateBookingSetting
						showModal={showModal}
						appointmentSetting={selectedAppointmentSetting}
						onHide={() => this.update({ showModal: false })}
						onSubmit={() => this.resetComponent()}
					/>
				)}
				<Alert
					type="info"
					show={showConfirmDeleteModal}
					title={t("Delete service?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmDeleteModalClose}
				>
					<div>{t("Are you sure you'd like to delete this service?")}</div>
				</Alert>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(Services);
