const notificationSound = new Audio("/sounds/notification.wav");
const cashRegisterSound = new Audio("/sounds/cashRegister.wav");

import i18n from "../i18n/config";

export const TEAMCHAT_EVENT_NOTIFICATIONS = {
	message: {
		id: "message",
		title: i18n.t(` • Team Chat`),
		sound: notificationSound
	},
	dhCustomerWin: {
		id: "dh_customer_win",
		title: i18n.t(`Cha Ching!`),
		sound: cashRegisterSound
	}
};

export const CUSTOMER_EVENT_NOTIFICATIONS = {
	message: {
		id: "message",
		title: i18n.t(`New Message from `),
		sound: notificationSound
	},
	userAssigned: {
		id: "user_assigned",
		title: i18n.t(`New Message from `),
		sound: notificationSound
	},
	dhCustomerWin: {
		id: "dh_customer_win",
		title: i18n.t(`Cha Ching!`),
		sound: cashRegisterSound
	}
};
