import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import autosize from "autosize";
import Select from "react-select";
import { PoseGroup } from "react-pose";
import { Pin } from "lucide-react";
import { withTranslation, Trans } from "react-i18next";

import ContactService from "../../../../services/ContactService";
import ToastService from "../../../../services/ToastService";
import InboxService from "../../../../services/InboxService";
import UserService from "../../../../services/UserService";
import NotificationService from "../../../../services/NotificationService";
import MessagesService from "../../../../services/MessagesService";
import GAService from "../../../../services/GAService";
import LocationService from "../../../../services/LocationService";

import Alert from "../../../../components/common/Alert";
import Checkbox from "../../../../components/common/Checkbox";

import { GA_CATEGORIES, GA_ACTIONS } from "../../../../constants/GAConstants";
import { ContextMenu, KEYS, CUSTOMER_OPTIONS } from "../../../../constants/Messenger";
import { CUSTOM_FIELD_MAX_FAVORITES, CUSTOM_FIELD_TYPES } from "../../../../constants/CustomFields";

import "./customer-more-options.css";

class CustomerMoreOptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: {
				contactDetails: { id: CUSTOMER_OPTIONS.contactDetails, handler: "onShowContactDetails", icon: <Icon.User /> },
				addNote: { id: CUSTOMER_OPTIONS.addNote, handler: "onAddNote", icon: <Icon.MessageSquare /> }
			},
			customOptions: {},
			showNoteArea: false,
			note: "",
			addingNoteLoading: false,
			leadSource: "",

			showMoveInbox: false,
			inboxes: [],
			inbox: null,

			showSendToCRMModal: false
		};

		this.cMenu = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		NotificationService.subscribeOnce("customFieldsUpdated", "thread_customerMoreOptions", async contactId => {
			await this.resetCustomFields(contactId);
		});

		document.addEventListener("mousedown", this.onMouseDown, false);
		await this.resetComponent();
		let contact = await ContactService.getContact(this.props.contactId);

		if (!contact) {
			return;
		}

		await this.resetCustomFields(this.props.contactId);
		await this.update({ leadSource: contact.lead_source });
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.contactId !== this.props.contactId) {
			let contact = await ContactService.getContact(this.props.contactId);
			await this.resetCustomFields(this.props.contactId);
			this.setState({ leadSource: contact.lead_source });
		}
	}

	async resetComponent() {
		let { options } = this.state;
		let { hasMessagePins } = this.props;

		if (InboxService.canMoveContactToInbox()) {
			options.moveToInbox = {
				id: CUSTOMER_OPTIONS.moveToInbox,
				handler: "onMoveToInbox",
				icon: <Icon.Inbox />
			};
		}

		options[CUSTOMER_OPTIONS.pinnedMessages.id] = {
			id: CUSTOMER_OPTIONS.pinnedMessages.id,
			icon: <Pin color={"#333"} fill={hasMessagePins ? "#333" : "#fff"} />
		};

		options[CUSTOMER_OPTIONS.leadSource.id] = {
			id: CUSTOMER_OPTIONS.leadSource.id,
			icon: <Icon.BarChart2 />
		};

		await this.update({
			showNoteArea: false,
			note: "",
			addingNoteLoading: false,
			showMoveInbox: false,
			options
		});

		this.props.onHide();
	}

	resetCustomFields = async contactId => {
		if (!LocationService.isCustomFieldsEnabled() || !contactId) {
			await this.update({ customOptions: {} });
			return;
		}

		let assignableCustomFields = await LocationService.getCustomFields({
			sortField: "name",
			sortOrder: "asc",
			favorites: true,
			limit: CUSTOM_FIELD_MAX_FAVORITES
		});
		let customFields = await ContactService.getContactCustomFields({ contactId });

		// The options that we'll show in the dropdown
		let options = {};

		for (let i = 0; i < assignableCustomFields.length; i++) {
			const assignableCF = assignableCustomFields[i];
			let customField = customFields.find(cf => cf.custom_field_id === assignableCF.id);

			if (customField) {
				customField.value = customField.value;
			}

			if (customField && assignableCF.type === CUSTOM_FIELD_TYPES.boolean) {
				customField.value = customField.value === "1";
			}

			options[assignableCF.id] = {
				id: assignableCF.id,
				field: assignableCF.field,
				type: assignableCF.type,
				name: assignableCF.name,
				description: assignableCF.description,
				show: false,
				loading: false,
				customField: customField ? customField : null
			};
		}

		await this.update({ customOptions: options });
	};

	onMouseDown = async e => {
		if (this.contextTrigger && this.contextTrigger.contains && this.contextTrigger.contains(e.target)) {
			this.props.onShow();

			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.customer.topMenu.openedTopMenu,
				label: GA_ACTIONS.messenger.thread.customer.topMenu.openedTopMenu
			});

			return;
		}

		if (this.cMenu && this.cMenu.contains && this.cMenu.contains(e.target)) {
			return;
		}

		this.props.onHide();

		this.resetComponent();
	};

	handleGenericEventHandler = event => {
		if (!this.state.addingNoteLoading) {
			this.setState({ [event.target.name]: event.target.value });
		}
	};

	onNoteInputKeyDown = e => {
		if (e.keyCode === KEYS.enter && !e.shiftKey && this.isAddNoteEnabled()) {
			this.onAddNote();
		}
		if (e.keyCode === KEYS.esc && this.state.addingNoteLoading) {
			this.onCloseNoteInput();
		}
	};

	async onSelect(option) {
		const { options } = this.state;
		switch (option.id) {
			case CUSTOMER_OPTIONS.addNote.id:
				this.onToggleShowNoteArea();
				break;
			case CUSTOMER_OPTIONS.moveToInbox.id:
				this.onMoveToInbox();
				break;
			case CUSTOMER_OPTIONS.pinnedMessages.id:
				this.onSeePinnedMessages();
				break;
			default:
				// look for a prop method by default
				let prop = options[option.id];
				let handler = this.props[prop.handler];
				if (handler && typeof handler === "function") {
					handler();
				}
				break;
		}
	}

	onInboxSelected = async selected => {
		let { contactId } = this.props;
		let { t } = this.props;

		let location = UserService.getActiveLocation();

		let updatedContact = await ContactService.updateContact({
			contactId,
			inboxId: selected.value,
			locationId: location.id
		});

		if (!updatedContact) {
			ToastService.error(t("An error occurred trying to move this conversation."));
			return;
		}

		await this.fetchInboxes();

		NotificationService.publish("contactInboxUpdated", updatedContact);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topMenu.movedContactToInbox,
			label: GA_ACTIONS.messenger.thread.customer.topMenu.movedContactToInbox
		});
	};

	fetchInboxes = async () => {
		let locationId = UserService.getActiveLocation().id;
		let userId = UserService.get().id;
		let { contactId } = this.props;
		let { t } = this.props;

		// Get the contact
		let contact = await ContactService.getContact(contactId);

		// Get the currently assigned inbox id
		let currentlyAssignedInbox = contact.inbox_id;

		// Fetch inboxes for this location
		let inboxes = await InboxService.fetchInboxes(locationId, userId);
		if (!inboxes) {
			return;
		}

		// Create options for the inbox select
		let inboxesObject = inboxes.map(inbox => {
			return {
				value: inbox.id,
				label: inbox.name
			};
		});
		inboxesObject.push({
			label: t("Unassigned"),
			value: 0
		});

		// Try to find the currently assigned inbox among the inboxes we are assigned to
		let userIsAssignedToInbox = inboxesObject.find(currentInbox => {
			if (currentInbox.value === currentlyAssignedInbox) {
				return currentInbox;
			} else if (currentInbox.value === 0 && currentlyAssignedInbox === null) {
				// if the inbox id is null, it is considered to be as "Unassigned"
				return currentInbox;
			}
		});

		let inbox = null;
		if (currentlyAssignedInbox) {
			inbox = currentlyAssignedInbox;
		}

		// If the currently assigned inbox is not one that is assigned to us, then atleast add it as an option
		// in the select so the user can see what the currently assigned inbox name is
		if (!userIsAssignedToInbox) {
			// Declare a backup incase this inbox no longer exists (might be deleted)
			let currentlyAssignedInboxName = t("Unknown Inbox");

			// If that inbox exists, then use it's name as the label
			if (currentlyAssignedInbox) {
				// Get the name of the inbox the contact is currently assigned to
				let fetchedInbox = await InboxService.fetchInbox(currentlyAssignedInbox);

				if (fetchedInbox && fetchedInbox.name) {
					currentlyAssignedInboxName = fetchedInbox.name;
				}
			}

			// Add this inbox to the assignable options
			inboxesObject.push({
				label: currentlyAssignedInboxName,
				value: currentlyAssignedInbox
			});
		}

		// Update the list of inboxes we could assign to
		await this.update({
			inboxes: inboxesObject,
			inbox
		});
	};

	async onMoveToInbox() {
		// Update the list of inboxes we could assign to
		await this.fetchInboxes();

		await this.update({ showMoveInbox: true });
	}

	async onToggleShowNoteArea() {
		await this.update({ showNoteArea: !this.state.showNoteArea, note: "" });
		if (this.state.showNoteArea) {
			this.noteInput.focus();
			autosize(this.noteInput);
		}
	}

	onSeePinnedMessages() {
		if (this.props.onHide) {
			this.props.onHide(true);
		}
	}

	onCloseNoteInput() {
		this.setState({ showNoteArea: false, note: "" });
	}

	isNoteInputDisabled() {
		return this.state.addingNoteLoading;
	}

	isAddNoteEnabled() {
		let { note } = this.state;
		return note !== "" && note.length > 2;
	}

	onAddNote = async () => {
		let { t } = this.props;

		if (this.isNoteInputDisabled()) {
			return;
		}

		this.setState({ addingNoteLoading: true });

		let response = await ContactService.addContactNote({ contactId: this.props.contactId, note: this.state.note });
		if (!response) {
			this.setState({ addingNoteLoading: false });
			ToastService.info(t("Error adding note. Please try again."));
			this.onCloseNoteInput();
			return;
		}
		this.setState({ addingNoteLoading: false });

		this.onCloseNoteInput();
		this.props.onHide();

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topMenu.createdNote,
			label: GA_ACTIONS.messenger.thread.customer.topMenu.createdNote
		});
	};

	onSendToCRMSelected = async () => {
		await this.update({
			showSendToCRMModal: true
		});
		this.props.onHide();
	};

	onSendToCRMAlertClosed = async confirm => {
		let { contactId } = this.props;
		let { t } = this.props;

		await this.update({
			showSendToCRMModal: false
		});

		if (!confirm) {
			return;
		}

		let contact = await ContactService.getContact(contactId);
		let success = await MessagesService.sendCRMLead(contact.location_id, contact.id);

		if (!success) {
			ToastService.info(t("CRM Send Error"));
			return;
		}

		ToastService.info(t("Contact Information Sent!"));

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topMenu.sendContactToCRM,
			label: GA_ACTIONS.messenger.thread.customer.topMenu.sendContactToCRM
		});
	};

	onCustomFieldSelected = id => {
		let { customOptions } = this.state;
		customOptions[id].show = true;
		this.update({ customOptions });
	};

	onCustomFieldChange = async (option, event) => {
		let { customOptions } = this.state;

		if (!customOptions[option.id].customField) {
			customOptions[option.id].customField = {
				id: option.id,
				value: option.type === CUSTOM_FIELD_TYPES.string ? "" : false
			};
		}

		if (option.type === CUSTOM_FIELD_TYPES.string) {
			customOptions[option.id].customField.value = event.target.value;
		} else {
			customOptions[option.id].customField.value = !customOptions[option.id].customField.value;
		}

		await this.update({ customOptions });

		if (option.type === CUSTOM_FIELD_TYPES.boolean) {
			await this.onUpdateContactCustomFields();
		}
	};

	onUpdateContactCustomFields = async () => {
		let { customOptions } = this.state;
		let { contactId } = this.props;
		let { t } = this.props;

		let customFields = Object.keys(customOptions)
			.map(key => {
				if (!customOptions[key].customField) {
					return null;
				}
				return { id: key, value: customOptions[key].customField.value };
			})
			.filter(cf => cf !== null);

		let response = await ContactService.updateContactCustomFields({
			contactId: contactId,
			customFields
		});

		if (!response) {
			ToastService.error(t(`Error updating custom field. Please try again`));
			return;
		}

		ToastService.info(t("Custom field saved."));
	};

	onSaveCustomField = async id => {
		let { customOptions } = this.state;
		customOptions[id].loading = true;

		await this.update({ customOptions });

		await this.onUpdateContactCustomFields();

		customOptions[id].loading = false;

		await this.update({ customOptions });
	};

	onHideCustomField = id => {
		let { customOptions } = this.state;
		customOptions[id].show = false;
		this.update({ customOptions });
	};

	renderCustomFields = () => {
		let { customOptions } = this.state;
		let { t } = this.props;

		if (!customOptions) {
			return null;
		}

		return Object.values(customOptions).map(option => (
			<div key={`cf-${option.field}`} className="mb-more-options-item">
				<div className="mb-more-options-item__icon ">{option.type === CUSTOM_FIELD_TYPES.string ? <Icon.Type /> : <Icon.ToggleRight />}</div>
				<div className="mb-more-options-item__text__container">
					<div className="mb-more-options-item__text" onClick={() => this.onCustomFieldSelected(option.id)}>
						<span className="mb-more-options-item__text__name mb-more-options-item__text__name--cf">
							{option.name}

							{option.type === CUSTOM_FIELD_TYPES.boolean && (
								<span>
									<Checkbox
										className={"mb-more-options-item__text__name__checkbox"}
										id={`cf-${option.field}-input`}
										name={option.field}
										checked={option.customField ? option.customField.value : false}
										onChange={() => this.onCustomFieldChange(option)}
									/>
								</span>
							)}
						</span>
						<span className="mb-more-options-item__text__description">{option.description}</span>
					</div>

					{option.show && option.type === CUSTOM_FIELD_TYPES.string && (
						<div className="mb-more-options__add-note">
							<>
								<textarea
									id={`cf-${option.field}-input`}
									name={option.field}
									className="mb-more-options__add-note__input"
									autoComplete="off"
									value={option.customField ? option.customField.value : ""}
									onChange={event => this.onCustomFieldChange(option, event)}
									placeholder={t("Value ...")}
									onKeyDown={event => {
										if (event.keyCode === KEYS.enter && !event.shiftKey) {
											this.onSaveCustomField(option.id);
										}
										if (event.keyCode === KEYS.esc) {
											this.onHideCustomField(option.id);
										}
									}}
								/>

								<div className="mb-more-options__add-note__buttons">
									<div className="mb-more-options__add-note__icon" onClick={() => this.onSaveCustomField(option.id)}>
										{option.loading ? <div className="mb-more-options__add-note__loader" /> : <Icon.Check />}
									</div>
									<div className="mb-more-options__add-note__icon" onClick={() => this.onHideCustomField(option.id)}>
										<Icon.X />
									</div>
								</div>
							</>
						</div>
					)}
				</div>
			</div>
		));
	};

	renderList() {
		let { options, showNoteArea, note, addingNoteLoading, showMoveInbox, inboxes, inbox, leadSource } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowSendToCrm = user.GroupPermission.send_to_crm_integrations;

		// Create the readable label for the currently assigned inbox
		let assignableInboxLabel = inboxes.find(aInbox => {
			if (aInbox.value === inbox) {
				return aInbox.label;
			} else if (aInbox.value === 0 && inbox === null) {
				// if the inbox id is null, it is considered to be as "Unassigned"
				return aInbox.label;
			}
		});

		return (
			<ContextMenu
				key="container"
				className="mb-more-options-list"
				ref={ref => {
					this.cMenu = ref;
				}}
			>
				<div ref={ref => (this.addNoteContext = ref)} key={CUSTOMER_OPTIONS.addNote.id} className="mb-more-options-item">
					<div className="mb-more-options-item__icon">
						<span onClick={() => this.onSelect(CUSTOMER_OPTIONS.addNote)}>{options[CUSTOMER_OPTIONS.addNote.id].icon}</span>
					</div>
					<div className="mb-more-options-item__text__container">
						<div onClick={() => this.onSelect(CUSTOMER_OPTIONS.addNote)} className="mb-more-options-item__text">
							<span className="mb-more-options-item__text__name">{CUSTOMER_OPTIONS.addNote.name}</span>
							<span className="mb-more-options-item__text__description">{CUSTOMER_OPTIONS.addNote.description}</span>
						</div>

						{showNoteArea && (
							<div className="mb-more-options__add-note">
								<>
									<textarea
										id="more-options-note-input"
										name="note"
										ref={ref => (this.noteInput = ref)}
										className={`mb-more-options__add-note__input ${this.isNoteInputDisabled() ? "mb-more-options__add-note__input--disabled" : ""}`}
										disabled={this.isNoteInputDisabled()}
										autoComplete="off"
										value={note}
										onChange={this.handleGenericEventHandler}
										placeholder={t("Note...")}
										onKeyDown={this.onNoteInputKeyDown}
									/>
									<div className="mb-more-options__add-note__buttons">
										<div
											className={`mb-more-options__add-note__icon ${this.isAddNoteEnabled() ? "" : "mb-more-options__add-note__icon--disabled"}`}
											onClick={() => this.onAddNote()}
										>
											{addingNoteLoading ? <div className="mb-more-options__add-note__loader" /> : <Icon.Check />}
										</div>
										<div className="mb-more-options__add-note__icon" onClick={() => this.onCloseNoteInput()}>
											<Icon.X />
										</div>
									</div>
								</>
							</div>
						)}
					</div>
				</div>
				<div key={CUSTOMER_OPTIONS.contactDetails.id} className="mb-more-options-item" onClick={() => this.onSelect(CUSTOMER_OPTIONS.contactDetails)}>
					<div className="mb-more-options-item__icon">{options[CUSTOMER_OPTIONS.contactDetails.id].icon}</div>
					<div className="mb-more-options-item__text__container">
						<div onClick={() => this.onSelect(CUSTOMER_OPTIONS.contactDetails)} className="mb-more-options-item__text">
							<span className="mb-more-options-item__text__name">{CUSTOMER_OPTIONS.contactDetails.name}</span>
							<span className="mb-more-options-item__text__description">{CUSTOMER_OPTIONS.contactDetails.description}</span>
						</div>
					</div>
				</div>
				{options.moveToInbox && (
					<div key={CUSTOMER_OPTIONS.moveToInbox.id} className="mb-more-options-item">
						<div className="mb-more-options-item__icon" onClick={() => this.onSelect(CUSTOMER_OPTIONS.moveToInbox)}>
							{options[CUSTOMER_OPTIONS.moveToInbox.id].icon}
						</div>
						<div className="mb-more-options-item__text__container">
							<div className="mb-more-options-item__text" onClick={() => this.onSelect(CUSTOMER_OPTIONS.moveToInbox)}>
								<span className="mb-more-options-item__text__name">{CUSTOMER_OPTIONS.moveToInbox.name}</span>
								<span className="mb-more-options-item__text__description">{CUSTOMER_OPTIONS.moveToInbox.description}</span>
							</div>
							{showMoveInbox && (
								<div className="mb-more-options__move-inbox">
									<div className="mb-more-options__move-inbox__text">{t("Current Inbox:")}</div>

									<Select
										id="inbox-select"
										options={inboxes}
										value={assignableInboxLabel}
										placeholder={t("Move to ...")}
										onChange={this.onInboxSelected}
										className="mb-more-options__move-inbox__select"
									/>
								</div>
							)}
						</div>
					</div>
				)}
				{options.pinnedMessages && (
					<div key={CUSTOMER_OPTIONS.pinnedMessages.id} className="mb-more-options-item" onClick={() => this.onSelect(CUSTOMER_OPTIONS.pinnedMessages)}>
						<div className="mb-more-options-item__icon mb-more-options-item__icon--not-clickable">{options[CUSTOMER_OPTIONS.pinnedMessages.id].icon}</div>
						<div className="mb-more-options-item__text__container">
							<div className="mb-more-options-item__text mb-more-options-item__text--not-clickable">
								<span className="mb-more-options-item__text__name mb-more-options-item__text__name--not-clickable">{CUSTOMER_OPTIONS.pinnedMessages.name}</span>
								<span className="mb-more-options-item__text__description">{t("See Pinned Messages")}</span>
							</div>
						</div>
					</div>
				)}
				{options.leadSource && (
					<div key={CUSTOMER_OPTIONS.leadSource.id} className="mb-more-options-item">
						<div className="mb-more-options-item__icon mb-more-options-item__icon--not-clickable">{options[CUSTOMER_OPTIONS.leadSource.id].icon}</div>
						<div className="mb-more-options-item__text__container">
							<div className="mb-more-options-item__text mb-more-options-item__text--not-clickable">
								<span className="mb-more-options-item__text__name mb-more-options-item__text__name--not-clickable">{CUSTOMER_OPTIONS.leadSource.name}</span>
								<span className="mb-more-options-item__text__description">{leadSource}</span>
							</div>
						</div>
					</div>
				)}
				{allowSendToCrm && (
					<div key={CUSTOMER_OPTIONS.sendToCrm.id} className="mb-more-options-item" onClick={() => this.onSendToCRMSelected()}>
						<div className="mb-more-options-item__icon ">{<Icon.Share />}</div>
						<div className="mb-more-options-item__text__container">
							<div className="mb-more-options-item__text ">
								<span className="mb-more-options-item__text__name ">{CUSTOMER_OPTIONS.sendToCrm.name}</span>
								<span className="mb-more-options-item__text__description">{CUSTOMER_OPTIONS.sendToCrm.description}</span>
							</div>
						</div>
					</div>
				)}
				{this.renderCustomFields()}
			</ContextMenu>
		);
	}

	render() {
		let { showCustomerMenu } = this.props;
		let { showSendToCRMModal } = this.state;
		let { t } = this.props;

		return (
			<>
				<div className="">
					<div
						id="mb-contact-details"
						data-tip
						data-for="contact-details-tooltip"
						className="mb-thread-header-action "
						ref={ref => (this.contextTrigger = ref)}
					>
						<Icon.MoreVertical size="22" />
					</div>
					<ReactTooltip id="contact-details-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
						{t("Details")}
					</ReactTooltip>
					<PoseGroup>{showCustomerMenu && this.renderList()}</PoseGroup>
				</div>
				<Alert type="warning" show={showSendToCRMModal} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onSendToCRMAlertClosed}>
					<div>{t("Are you sure you would like to send this contact information to your CRM?")}</div>
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(CustomerMoreOptions);
