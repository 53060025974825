import i18n from "../i18n/config";

export const CREATE_LOCATION_STEPS = {
	gmbApi: {
		step: 1,
		headingText: i18n.t("Let's create a new location"),
		subHeadingText: i18n.t("Find your business on Google to make the set up faster."),
		headingIcon: "fa fa-magic fa-2x"
	},
	locationInfo: {
		step: 2,
		headingText: i18n.t("Where is your business located?"),
		subHeadingText: i18n.t("Please fill out all the required fields (marked by a red star)."),
		headingIcon: "fa fa-magic"
	},
	contactInfo: {
		step: 3,
		headingText: i18n.t("Contact information"),
		subHeadingText: i18n.t("Where can people find you online?"),
		headingIcon: "fa fa-magic"
	},
	selectSMSService: {
		step: 4,
		headingText: i18n.t("Select SMS Service Provider"),
		subHeadingText: i18n.t("Please choose between the different sms services you would like to use"),
		headingIcon: "fa fa-magic"
	},
	createSMSNumbers: {
		step: 5,
		headingText: i18n.t("Order SMS Numbers"),
		subHeadingText: i18n.t("Search and order numbers from a provider or Skip to next step to Assign an existing number"),
		headingIcon: "fa fa-magic"
	},
	smsNumbers: {
		step: 6,
		headingText: i18n.t("Set up SMS numbers"),
		subHeadingText: i18n.t(
			"Create or assign numbers that are used for DemandHub Messenger and Webchat. Remember that every location must have at least 1 'primary' number."
		),
		headingIcon: "fa fa-magic"
	},
	goals: {
		step: 7,
		headingText: i18n.t("What are the goals?"),
		subHeadingText: i18n.t("Having goals is an easy way to measure how you are doing!"),
		headingIcon: "fa fa-magic"
	},
	settings: {
		step: 8,
		headingText: i18n.t("Configure settings"),
		subHeadingText: i18n.t("Select any of these that apply."),
		headingIcon: "fa fa-magic"
	},
	logos: {
		step: 9,
		headingText: i18n.t("Logos"),
		subHeadingText: i18n.t("What are the url's of your logos?"),
		headingIcon: "fa fa-magic"
	},
	integrations: {
		step: 10,
		headingText: i18n.t("Google and Facebook integrations"),
		subHeadingText: i18n.t("Connect your DemandHub location to Google and Facebook."),
		headingIcon: "fa fa-magic"
	},
	connections: {
		step: 11,
		headingText: i18n.t("GMB and Facebook Connections"),
		subHeadingText: i18n.t("Connect your location to GMB and Facebook to get latest reviews."),
		headingIcon: "fa fa-magic"
	},
	summary: {
		step: 12,
		headingText: i18n.t("Summary"),
		subHeadingText: i18n.t("Here is everything that you have set for your location. Please review carefully."),
		headingIcon: "fa fa-smile"
	},
	complete: {
		step: 13,
		headingText: i18n.t("Success!"),
		subHeadingText: i18n.t("Congratulations! The location has been set up properly. "),
		headingIcon: "fa fa-smile"
	}
};
