// Libraries
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";

// Constants
import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import RouteList from "../../constants/RouteList.json";

// Services
import AppointmentsService from "../../services/AppointmentsService";
import ContactService from "../../services/ContactService";
import SupportChatService from "../../services/SupportChatService";
import Kichiri from "../../services/KichiriService";
import LocationService from "../../services/LocationService";
import NotificationService from "../../services/NotificationService";
import PaymentService from "../../services/PaymentService";
import ScheduledMessages from "../../scenes/ScheduledMessages/ScheduledMessages";
import TeamChatService from "../../services/TeamChatService";
import UserService from "../../services/UserService";
import WebsocketsConnection from "../../services/WebsocketsConnection";
import WorkflowService from "../../services/WorkflowService";
import DashboardService from "../../services/DashboardService";

// Scene Components
import Dashboard from "../../scenes/Dashboard/Dashboard";
import Dashboards from "../../scenes/Dashboard/Dashboards";

// TODO - Migrate CSS from messages.css to new messenger css
// import Messages from "../../scenes/Messages/Messages";
import Appointments from "../../scenes/Appointments/Appointments";
import BookingRequests from "../../scenes/BookingRequests/BookingRequests";
import CheckLoginInterval from "../../components/common/CheckLoginInterval";
import Companies from "../../scenes/Companies/Companies";
import Contacts from "../../scenes/Contacts/Contacts";
import DHLightboxContainer from "../common/DHLightboxContainer";
import EmojisContainer from "../../scenes/MessengerBeta/Thread/Emojis/EmojisContainer";
import FacebookIntegration from "../../scenes/Locations/FacebookIntegration";
import InvoiceDetails from "../../scenes/Payments/Invoices/InvoiceDetails";
import ManageCompany from "../../scenes/Companies/ManageCompany";
import ManageLocation from "../../scenes/Locations/ManageLocation";
import ManageLocationWizard from "../../scenes/Locations/ManageLocationWizard";
import ManageUserProfile from "../../scenes/Users/ManageUserProfile";
import ManageUsersOverview from "../../scenes/Users/ManageUsersOverview";
import MessengerBeta from "../../scenes/MessengerBeta/MessengerBeta.js";
import NetPromoterScore from "../../scenes/NetPromoterScore/NetPromoterScore";
import PaymentRequestDetails from "../../scenes/Payments/PaymentRequests/PaymentRequestDetails";
import Payments from "../../scenes/Payments/Payments";
import Reviews from "../../scenes/Reviews/Reviews";
import Settings from "../../scenes/Settings/Settings";
import Tasks from "../../scenes/Tasks/Tasks";
import UserInvites from "../../scenes/UserInvites/UserInvites";
import VoicemailInbox from "../../scenes/Voicemail/VoicemailInbox";
import ManageDashboard from "../../scenes/Dashboard/ManageDashboard";
import Calendar from "../../scenes/Calendar/Calendar";
import InvitesPage from "../../scenes/Invites/InvitesPage";
import Analytics from "../../scenes/Analytics/Analytics.js";
import CustomerSuccess from "../../scenes/CustomerSuccess/CustomerSuccess.js";

// Common Components
import Alert from "../../components/common/Alert";
import ForceReset from "../common/ForceReset";
import LegacyPage from "../common/LegacyPage";
import Nav from "../common/Nav";
import NotFound from "../layouts/NotFound";
import Top from "../common/Top";

import "react-toastify/dist/ReactToastify.css";
import "../../styles/css/components/commons/container.css";
import Posts from "../../scenes/Posts/Posts";
class Main extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: UserService.get(),
			newMessageCount: 0,
			newInternalMessageCount: 0,
			showNumberChecking: false,
			showNumberCheckDuplicateMessage: false,
			showNumberCheckOriginalMessage: false,
			showNumberCheckFailMessage: false,
			isTabFocused: true,
			hasMentions: false
		};

		this.unreadTitleFlashInterval = null;
		this.delay = null;
	}

	render() {
		let { t } = this.props;

		if (!UserService.get()) {
			const publicRouteFound = RouteList.public_routes.some(aPublicRoute => this.props.location.pathname === aPublicRoute);
			if (publicRouteFound) {
				return <Redirect to={this.props.location.pathname} />;
			} else {
				return <Redirect to="login" />;
			}
		}

		// Get the location data
		const location = UserService.getActiveLocation();
		let locationBillingProviderUserId = "";

		if (location) {
			locationBillingProviderUserId = location.billing_provider_user_id;
		}

		return (
			<>
				{/* Put components that you would like to sit on top of the app here */}
				{/*<ProfitWell authToken={config.PROFITWELL_TOKEN} userId={locationBillingProviderUserId} doNotLoad={!config.PROFITWELL_TOKEN ? true : false} />*/}
				<ToastContainer style={{ zIndex: 9999999 }} />
				<EmojisContainer />
				<DHLightboxContainer />
				<Alert
					type="warning"
					show={this.state.showNumberCheckDuplicateMessage}
					title={t("Duplicate Number")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ showNumberCheckDuplicateMessage: false });
					}}
				>
					<div>A review was previously sent to this customer.</div>
				</Alert>
				<Alert
					type="success"
					show={this.state.showNumberCheckOriginalMessage}
					title={t("Original Number")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ showNumberCheckOriginalMessage: false });
					}}
				>
					<div>{t("A review was not previously sent to this customer.")}</div>
				</Alert>
				<Alert
					type="error"
					show={this.state.showNumberCheckFailMessage}
					title={t("Number Check Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ showNumberCheckFailMessage: false });
					}}
				>
					<div>{t("The number/email could not be checked. Please try again.")}</div>
				</Alert>
				<CheckLoginInterval />
				{/* Page Container */}

				<div className="dh__container">
					<Nav />
					<div className="dh__container__core">
						<Top teamChatCount={this.state.newInternalMessageCount} />
						<Switch>
							<Route exact path="/" render={() => <Redirect to="/home" />} />
							<Route exact path="/dashboard" render={() => <Redirect to="/home" />} />
							<Route exact path="/home" render={props => <Dashboard {...props} />} />

							{LocationService.isMessengerPermissible() && (
								<Route path="/messenger">
									<Redirect to="/inbox" />
								</Route>
							)}
							{LocationService.isMessengerPermissible() && (
								<Route path="/messenger-beta">
									<Redirect to="/inbox" />
								</Route>
							)}
							{LocationService.isMessengerPermissible() && <Route exact path="/inbox" render={props => <MessengerBeta {...props} />} />}

							{/* Contact Routes */}
							{UserService.get().GroupPermission.view_contacts && <Route exact path="/contacts/:tab?" render={props => <Contacts {...props} />} />}
							{LocationService.isMessengerPermissible() && <Route exact path="/voicemail" render={props => <VoicemailInbox {...props} />} />}
							{LocationService.isMessengerPermissible() && UserService.get().GroupPermission.view_scheduled_messages && (
								<Route exact path="/scheduled-messages/:tab?" render={props => <ScheduledMessages {...props} />} />
							)}

							{/* Reputation Routes */}
							{LocationService.isReviewsEnabled() && <Route exact path="/reviews" render={props => <Reviews {...props} />} />}
							{LocationService.isViewReviewInvitesEnabled() && <Route exact path="/invites/:tab?" render={props => <InvitesPage {...props} />} />}

							<Route exact path="/nps/:tab?" render={props => <NetPromoterScore {...props} />} />

							{/* Scheduling Routes */}
							{LocationService.isMessengerPermissible() && <Route exact path="/calendar" render={props => <Calendar {...props} />} />}

							{AppointmentsService.isPermissible() && <Route exact path="/appointments" render={props => <Appointments {...props} />} />}
							{AppointmentsService.isPermissible() && <Route exact path="/appointment-notifications" render={props => <Appointments {...props} />} />}

							<Route exact path="/booking-requests" render={props => <BookingRequests {...props} />} />

							{/* ... More Routes */}
							{DashboardService.canViewDashboard() && <Route exact path="/dashboards" render={props => <Dashboards {...props} />} />}
							{DashboardService.canViewDashboard() && <Route exact path="/dashboards/:dashboardId" render={props => <ManageDashboard {...props} />} />}

							{true && <Route exact path="/posts" render={props => <Posts />} />}

							{PaymentService.isPaymentsEnabled() && (
								<Route exact path="/payments/request/:paymentRequestId" render={props => <PaymentRequestDetails {...props} />} />
							)}
							{PaymentService.isPaymentsEnabled() && <Route exact path="/payments/invoice/:invoiceId" render={props => <InvoiceDetails {...props} />} />}
							{<Route exact path="/payments/:tab?" render={props => <Payments {...props} />} />}

							{LocationService.isLocationTasksPermissible() && <Route exact path="/tasks" render={props => <Tasks {...props} />} />}

							{UserService.get().GroupPermission.view_settings && <Route path="/analytics" render={props => <Analytics {...props} />} />}

							{/* Global Routes */}

							<Route exact path="/facebook" render={props => <FacebookIntegration {...props} />} />

							<Route
								exact
								path="/invite-users"
								render={props => (
									<LegacyPage>
										<UserInvites {...props} />
									</LegacyPage>
								)}
							/>

							{/* TODO: Migrate to Settings.js once we rejig it to work like Analytics.js  */}
							{UserService.get().GroupPermission.view_team && <Route path="/settings/users/:userId" component={ManageUsersOverview} />}

							{/* TODO: Migrate to Settings.js once we rejig it to work like Analytics.js  */}
							{UserService.isSuperAdminOrCustomerSuccessOrReseller() && (
								<Route path="/settings/location-wizard/:locationId" render={props => <ManageLocationWizard {...props} />} />
							)}

							{/* TODO: Migrate to Settings.js once we rejig it to work like Analytics.js  */}
							{UserService.isSuperAdminOrCustomerSuccessOrReseller() && (
								<Route path="/settings/locations/:locationId" render={props => <ManageLocation {...props} />} />
							)}

							{UserService.get().GroupPermission.view_companies && <Route path="/companies/:companyId" component={ManageCompany} />}
							{UserService.get().GroupPermission.view_companies && <Route exact path="/companies" render={props => <Companies {...props} />} />}

							{UserService.get().GroupPermission.view_settings && <Route path="/settings/:setting" render={props => <Settings {...props} />} />}
							{UserService.get().GroupPermission.view_settings && <Route path="/settings" render={props => <Settings {...props} />} />}

							{/* Customer Success Routes */}

							{UserService.get().GroupPermission.view_cs_reports && <Route path="/customer-success" render={props => <CustomerSuccess {...props} />} />}

							{/* Global Routes */}

							<Route exact path="/user-profile" render={props => <ManageUserProfile {...props} />} />

							{/* Tooling Routes */}

							<Route
								exact
								path="/forcereset"
								render={props => (
									<LegacyPage>
										<ForceReset {...props} />
									</LegacyPage>
								)}
							/>
							<Route component={NotFound} />
						</Switch>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		this.setup();

		window.addEventListener("focus", this.onFocus);
		window.addEventListener("blur", this.onBlur);

		NotificationService.subscribe("locationChanged", () => {
			WebsocketsConnection.connect(this.connectMessenger);
		});
	}

	async setup() {
		SupportChatService.init();
		WebsocketsConnection.connect(this.connectMessenger);
		NotificationService.askNotificationPermission();
		TeamChatService.checkEnabled();
	}

	componentWillUnmount() {
		this.clearTabTitle();

		window.addEventListener("focus", this.onFocus);
		window.addEventListener("blur", this.onBlur);
	}

	onFocus = () => {
		this.setState({
			isTabFocused: true
		});
	};

	onBlur = () => {
		this.setState({
			isTabFocused: false
		});
	};

	connectMessenger = async () => {
		// Set up the websocket event subscription'
		const user = UserService.get();

		if (!user) {
			return;
		}

		const authToken = UserService.getAuthToken();
		const location = UserService.getActiveLocation();

		if (location && location.id) {
			WebsocketsConnection.subscribeToNewMessages(location.id, authToken, async (err, message) => {
				let { isTabFocused } = this.state;

				// Best effort mark desktop notification as received
				try {
					WebsocketsConnection.markDesktopNotificationAsReceived(message.desktopNotificationPublicId);
				} catch (error) {
					console.log(error);
				}

				// If the message does not belong to this location, simply ignore it
				if (message.location_id !== location.id) {
					return;
				}

				let isMessageUserSameAsCurrentUser = user.id === message.sender_user_id;
				let isNotOnMessengerPage = this.props.location && this.props.location.pathname !== "/inbox" && this.props.location.pathname !== "/messenger-classic";
				let isMessageSilent = message.isSilent;

				// If we are not on the messenger page and the message notification is not silent then open the OS notification banner
				if (isNotOnMessengerPage && !isMessageUserSameAsCurrentUser && !isMessageSilent) {
					NotificationService.openMessageNotification(message);
				}
				// If we are on the messenger page, we don't want to show the OS notification, but just publish the payload
				else {
					NotificationService.publish("newMessage", message);
				}

				// If the tab is not focused and the user that sent the message is not the same as the current user, open the OS notification banner
				if (!isTabFocused && !isMessageUserSameAsCurrentUser && !isMessageSilent) {
					NotificationService.openMessageNotification(message);
				}
			});

			WebsocketsConnection.subscribeToContactCount(location.id, user.id, async (err, contactCount) => {
				this.updateContactCountUpdate(contactCount);
				ContactService.cacheContactCount(contactCount);

				// XXX - Kinda gross, but works for now...
				setTimeout(() => {
					NotificationService.publish("contactCountUpdate", contactCount);
				}, 2000);
			});

			if (user.enable_teamchat) {
				WebsocketsConnection.subscribeToInternalMessages(user.id, async (err, message) => {
					NotificationService.publish("newInternalMessage", message);

					let isTabFocused = this.state.isTabFocused;
					let isCurrentPageNewMessenger = this.props.location && this.props.location.pathname === "/inbox";
					let isSender = user.id === message.sender_user_id;
					let hasNotificationPreference = TeamChatService.hasNotificationPreference(message.conversation_id, message.content);
					await TeamChatService.unhideConversation(message.conversation_id, false);

					if ((!isTabFocused || !isCurrentPageNewMessenger) && !isSender && hasNotificationPreference) {
						NotificationService.openMessageNotification(message);
					}

					await this.updateInternalUnreadCount();
				});

				WebsocketsConnection.onInternalConversationRead(async event => {
					await this.updateInternalUnreadCount();

					if (this.props.location.pathname === "/inbox") {
						NotificationService.publish("markInternalMessageRead", event.conversationId);
					}
				});

				WebsocketsConnection.onInternalMessageUpdate(event => {
					NotificationService.publish("internalMessageUpdated", event);
				});

				await this.updateInternalUnreadCount();

				NotificationService.publish("realtimeConnected");
			}
		}
	};

	updateInternalUnreadCount = async () => {
		let count = await TeamChatService.getTotalUnreadCount();
		let hasMentions = TeamChatService.hasMentions();

		// If we have messages coming in less than 2 seconds apart, wait for some breathing room and then update the TopHeader
		if (this.delay) {
			clearTimeout(this.delay);
		}

		NotificationService.publish("teamChatCountUpdate", { count, hasMentions });

		this.delay = setTimeout(() => {
			this.setState({ newInternalMessageCount: count, hasMentions }, () => {
				this.updateTabTitle();
			});
		}, 2000);
	};

	updateContactCountUpdate(contactCount) {
		// The all count is handled by platfrom, depending on permissions of the user
		let count = contactCount.all;
		this.setState({ newInternalContactCount: count }, () => {
			this.updateTabTitle();
		});
	}

	/**
	 * Clears the tab title update
	 *
	 */
	clearTabTitle() {
		clearInterval(this.unreadTitleFlashInterval);
		this.unreadTitleFlashInterval = null;
		document.title = "DemandHub";
		this.setState({
			newInternalContactCount: 0
		});
	}

	/**
	 * Toggles the document title to and from a new messages copy and the regular DemandHub copy
	 *
	 */
	toggleTabTitle() {
		let { newInternalContactCount, newInternalMessageCount } = this.state;
		let { t } = this.props;

		const user = UserService.get();
		let isLoggedInUser = user && user.auth_token;

		let count = newInternalContactCount + newInternalMessageCount;

		if (!isLoggedInUser) {
			document.title = "DemandHub";
			return;
		}

		if (!user.GroupPermission.view_customer_messages) {
			document.title = "DemandHub";
			return;
		}

		let title = document.title;
		if (title === "DemandHub" && count > 0) {
			title = `(` + count + `) ${t("New Messages!")}`;
		} else {
			title = "DemandHub";
		}

		document.title = title;
	}

	/**
	 * Updates the tab title if there are more than 1 new message.
	 *
	 */
	updateTabTitle() {
		let { hasMentions, newInternalContactCount, newInternalMessageCount } = this.state;
		let { t } = this.props;

		let count = newInternalContactCount + newInternalMessageCount;
		let title = `DemandHub`;

		if (hasMentions && newInternalMessageCount > 0) {
			title = `(${count}) @ ${t("Messages!")} | DemandHub`;
		} else if (count > 0 && !hasMentions) {
			title = `(${count}) ${t("New Messages!")} | DemandHub`;
		}

		document.title = title;
	}
}

export default withTranslation(null, { withRef: true })(Main);
