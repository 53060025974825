import React from "react";
import { withRouter } from "react-router-dom";
import ReactSwitch from "react-switch";
import { withTranslation } from "react-i18next";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import LocationService from "../../../services/LocationService";
import ToastService from "../../../services/ToastService";

import { REVIEW_TABS } from "../../../constants/Settings";

import "../../../styles/css/components/commons/dh-details.css";

import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

class ReviewsGeneral extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: REVIEW_TABS.general.id,
			monthlyInviteGoal: 0,
			monthlyReviewGoal: 0,
			shareCommentsShowAllOptionsByDefault: false,
			loading: false,
			showBatchSendModal: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLocation();
	}

	onLocationChanged = () => {
		this.fetchLocation();
	};

	async fetchLocation() {
		let { t } = this.props;

		this.setState({ loading: true });
		try {
			let location = await LocationService.fetchLocation(UserService.getActiveLocation().id);
			let metaData = LocationService.getAndParseMetaData(location);

			this.setState({
				loading: false,
				monthlyInviteGoal: location.monthly_invite_goal,
				monthlyReviewGoal: location.monthly_review_goal,
				shareCommentsShowAllOptionsByDefault: metaData.shareComments && metaData.shareComments.skipToOptions ? metaData.shareComments.skipToOptions : false,
				fetchError: false
			});
		} catch (error) {
			this.setState({ loading: false, fetchError: true });
			ToastService.error(t("Sorry, not able to fetch location data. Please try again."));
			console.log(error);
		}
	}

	handleSaveClick = async () => {
		const { monthlyInviteGoal, monthlyReviewGoal, shareCommentsShowAllOptionsByDefault } = this.state;
		let { t } = this.props;

		const locationData = {};
		let location = UserService.getActiveLocation();
		locationData.locationId = location.id;
		locationData.company = UserService.getActiveCompany().id;
		locationData.monthly_invite_goal = monthlyInviteGoal;
		locationData.monthly_review_goal = monthlyReviewGoal;
		locationData.handle = location.handle;
		locationData.share_comments_skip_to_options = shareCommentsShowAllOptionsByDefault;
		let success = await LocationService.update(locationData);
		if (!success) {
			this.setState({ loading: false });
			ToastService.error(t("Sorry, an error was encountered while saving the settings. Please try again."));
			return;
		}
		this.setState({ loading: false });
		ToastService.info(t("Location saved successfully."));
		this.fetchLocation();
	};

	handleMonthlyInviteGoalOnChange(e) {
		this.setState({ monthlyInviteGoal: parseInt(e.target.value, 10) });
	}

	handleMonthlyReviewGoalOnChange(e) {
		this.setState({ monthlyReviewGoal: parseInt(e.target.value, 10) });
	}

	handleOnBatchSendInvites = () => {
		this.setState({ showBatchSendModal: true });
	};

	batchSendInvites = async () => {
		let { t } = this.props;

		let result = await LocationService.batchSendReviewRequests([UserService.getActiveLocation().id]);
		if (!result) {
			ToastService.error(t("Sorry, an error occurred trying to queue invites."));
			return;
		}
		ToastService.info(t("Successfully queued invites."));
	};

	render() {
		let { monthlyInviteGoal, monthlyReviewGoal } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<div className="Common__flex-grow" style={{ marginLeft: 30 }}>
				<div className="dh-details-info">
					<div className="dh-details-title">{t("Goals")}</div>
					<div className="dh-details-item">
						<label>{t("Monthly Invite Goal")}</label>
						{user.GroupPermission.update_locations ? (
							<input
								name="monthly_invite_goal"
								id="monthly_invite_goal"
								onChange={e => this.handleMonthlyInviteGoalOnChange(e)}
								type="number"
								value={monthlyInviteGoal}
								className="form-control Common__input"
								disabled={!user.GroupPermission.update_locations}
							/>
						) : (
							<div>{monthlyInviteGoal}</div>
						)}
					</div>
					<div className="dh-details-item">
						<label>{t("Monthly Invite Goal")}</label>
						{user.GroupPermission.update_locations ? (
							<input
								name="monthly_review_goal"
								id="monthly_review_goal"
								type="number"
								value={monthlyReviewGoal}
								onChange={e => this.handleMonthlyReviewGoalOnChange(e)}
								className="form-control Common__input"
								disabled={!user.GroupPermission.update_locations}
							/>
						) : (
							<div>{monthlyReviewGoal}</div>
						)}
					</div>
				</div>
				{isSuperOrCs && (
					<div className="dh-details-info">
						<div className="dh-details-title">{t("Send Review Invites")}</div>
						<div className="dh-details-item">
							<div id="sendBatchReviewInvites" className="mb-button mb-button--fit" onClick={this.handleOnBatchSendInvites}>
								{t("Batch Send Review Invites")}
							</div>
						</div>
					</div>
				)}
				<Alert
					type="info"
					show={this.state.showBatchSendModal}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("Cancel")}
					onClose={confirmed => {
						if (confirmed) {
							this.batchSendInvites();
						}
						this.setState({ showBatchSendModal: false });
					}}
				>
					<div>{t("Review invites will be queued based on 30 days of synced appointment data. Are you sure you would like to proceed?")}</div>
				</Alert>
				<div className="dh-details-info">
					<div className="dh-details-title dh-details-title--margin">{t("Review Site Options")}</div>
					<div className="dh-details-item">
						<label>{t("Show All Review Sites Options By Default")}</label>
						{user.GroupPermission.update_locations ? (
							<div>
								<ReactSwitch
									id="showAllReviewSites"
									onChange={() => this.setState({ shareCommentsShowAllOptionsByDefault: !this.state.shareCommentsShowAllOptionsByDefault })}
									checked={this.state.shareCommentsShowAllOptionsByDefault}
									uncheckedIcon={false}
									checkedIcon={false}
									onColor="#4A90E2"
									disabled={false}
								/>
							</div>
						) : (
							<div>{monthlyReviewGoal}</div>
						)}
					</div>
				</div>
				{user.GroupPermission.update_locations && (
					<div className="dh-details__save">
						<div id="save" className="mb-button" onClick={this.handleSaveClick}>
							{t("Save")}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ReviewsGeneral)));
