import UserService from "./UserService";
import Kichiri from "./KichiriService";

/**
 * This service abstracts away all communication with the backend to manipulate the chatbot_faqs table
 */
const ChatbotService = {
	/**
	 * Initiate a chatbot conversation
	 * @param {Integer} contact_id
	 * @param {Integer} chatbot_id
	 */
	async initiateConversation(contact_id, chatbot_id) {
		try {
			let authToken = UserService.getAuthToken();
			let locationId = UserService.getActiveLocation().id;
			let response = await Kichiri.chatbot.initiateConversation(
				{
					contact_id: contact_id,
					chatbot_id: chatbot_id,
					location_id: locationId
				},
				{},
				authToken
			);
			return response.data;
		} catch (err) {
			throw new Error(`Error in initiating chatbot conversation - ${err}`);
		}
	},

	/**
	 * Forcefully take over a conversation from a chatbot
	 * @param {Integer} contactId
	 */
	async killConversation(contactId) {
		try {
			let authToken = UserService.getAuthToken();
			let locationId = UserService.getActiveLocation().id;
			let response = await Kichiri.chatbot.killConversation(
				{
					contact_id: contactId,
					location_id: locationId
				},
				{},
				authToken
			);
			return response.data;
		} catch (err) {
			throw new Error(`Error in ending chatbot conversation - ${err}`);
		}
	},

	/**
	 * Fetches all chatbots and their configured locations
	 */
	async fetchChatbotLocations(params) {
		try {
			let query = {
				locationId: 0,
				sortField: "",
				sortOrder: "",
				limit: 30,
				offset: 0
			};

			query = Object.assign(query, params);
			query.location_id = query.locationId;

			let authToken = UserService.getAuthToken();
			let response = await Kichiri.chatbot.fetchChatbots({}, query, authToken);
			let allChatbots = response.data.chatbots;
			return allChatbots;
		} catch (err) {
			throw new Error(`Error in fetching all chatbots - ${err}`);
		}
	},
	/**
	 * Fetches all active chatbots for current location
	 */
	async fetchActiveChatbots() {
		try {
			let locationId = UserService.getActiveLocation().id;
			let authToken = UserService.getAuthToken();

			let { data } = await Kichiri.chatbot.list({}, { locationId }, authToken);

			return data;
		} catch (err) {
			throw new Error(`Error in fetching all chatbots - ${err}`);
		}
	},
	/**
	 * Updates the given chatbot's permissible locations
	 */
	async updateChatbotLocations(chatbotId, locationId) {
		try {
			let authToken = UserService.getAuthToken();
			let response = await Kichiri.chatbot.updateChatbotLocations({ chatbot_id: chatbotId, location_id: locationId }, {}, authToken);
			return response;
		} catch (err) {
			throw new Error(`Error in updating chatbot locations - ${err}`);
		}
	},
	/**
	 * Fetches all FAQs for the current location
	 */
	async fetchFaqs(params) {
		let locationId = UserService.getActiveLocation().id;
		let authToken = UserService.getAuthToken();
		let query = {
			location_id: locationId,
			sortField: "",
			sortOrder: "",
			limit: 30,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			let response = await Kichiri.chatbot.fetchFaqs({}, query, authToken);
			return response.data.faqs;
		} catch (err) {
			throw new Error(`Error in fetching FAQs for location ${locationId} - ${err.stack}`);
		}
	},
	/**
	 * Deletes the record given by faqId
	 * @param {Integer} faqId
	 */
	async deleteFaq(faqId) {
		let locationId = UserService.getActiveLocation().id;
		let authToken = UserService.getAuthToken();
		try {
			let response = {};
			if (faqId === -1) return;
			// Only make backend call if faq actually exists in backend.
			response = await Kichiri.chatbot.deleteFaq(
				{
					faq_id: faqId,
					location_id: locationId
				},
				{},
				authToken
			);
			return response.data;
		} catch (err) {
			throw new Error(`Error in deleting FAQ with ID ${faqId} - ${err.stack}`);
		}
	},
	/**
	 * Creates a new record with faqRequestParams or updates pre-existing record
	 * @param {Object} faqRequestParams
	 */
	async createOrUpdateFaq(faqRequestParams) {
		let authToken = UserService.getAuthToken();
		let locationId = UserService.getActiveLocation().id;
		try {
			let response = {};
			faqRequestParams.alternativeQuestions = JSON.stringify(faqRequestParams.alternativeQuestions);
			faqRequestParams.locationId = locationId;
			if (faqRequestParams.faqId === -1) {
				response = await Kichiri.chatbot.createFaq(faqRequestParams, {}, authToken);
			} else {
				response = await Kichiri.chatbot.updateFaq(faqRequestParams, {}, authToken);
			}
			return response.data;
		} catch (err) {
			throw new Error(`Encountered error while trying to create or update FAQ entry - ${err.stack}`);
		}
	},
	/**
	 * Converts the raw string array alternativeQuestions into an array of options
	 * compatible with Creatable Multiselect React component
	 * @param {Array[String]} alternativeQuestions
	 */
	convertAlternativeQuestionsToOptions(alternativeQuestions) {
		let alternativeQuestionsOptions = [];
		for (let alternativeQuestion of alternativeQuestions) {
			alternativeQuestionsOptions.push({ label: alternativeQuestion, value: alternativeQuestion });
		}
		return alternativeQuestionsOptions;
	},
	/**
	 * Determines whether all permission criteria are satisfied
	 * for a user to access chatbot functionality
	 */
	async isUserAllowed() {
		let user = UserService.get();
		let locationFeatures = UserService.getActiveLocation().LocationFeature;
		return locationFeatures.chatbots && user.GroupPermission.update_chatbots;
	},
	/**
	 * Updates the default chatbot set for given location
	 * @param {Integer} chatbotId
	 * @param {Integer} locationId
	 * @param {Boolean} clearDefault
	 */
	async updateDefaultChatbot(chatbotId, locationId, clearDefault) {
		let authToken = UserService.getAuthToken();
		try {
			await Kichiri.chatbot.updateDefaultChatbot({ locationId, chatbotId, clearDefault }, {}, authToken);
		} catch (err) {
			console.log(`Encountered error while trying to create or update default chatbot - ${err.stack}`);
		}
	},

	/**
	 * Creates a new chatbot
	 *
	 * @param {String} name
	 * @return {Promise<Object>} The created chatbot data
	 * @throws {Error} If an error occurs while creating the chatbot
	 */
	async createChatbot({ name }) {
		let authToken = UserService.getAuthToken();
		let locationId = UserService.getActiveLocation().id;
		try {
			let response = await Kichiri.chatbot.create({ name, locationId }, {}, authToken);
			return response.data;
		} catch (err) {
			console.log(`Encountered error while trying to create chatbot - ${err.stack}`);
		}
	},

	/**
	 * Fetches a chatbot by its ID
	 *
	 * @param {String} chatbotId
	 * @return {Promise<Object>} The chatbot data
	 * @throws {Error} If an error occurs while fetching the chatbot
	 */
	async fetchChatbot({ chatbotId }) {
		let authToken = UserService.getAuthToken();
		try {
			let response = await Kichiri.chatbot.fetch({ chatbotId }, {}, authToken);
			return response.data;
		} catch (err) {
			console.log(`Encountered error while trying to fetch chatbot - ${err.stack}`);
		}
	},

	/**
	 * Updates a chatbot's configuration
	 *
	 * @param {String} chatbotId
	 * @param {String} name
	 * @param {Object} config
	 * @return {Promise<Object>} The updated chatbot data
	 * @throws {Error} If an error occurs while updating the chatbot
	 */
	async updateChatbot({ chatbotId, name, config }) {
		let authToken = UserService.getAuthToken();
		try {
			let response = await Kichiri.chatbot.update({ chatbotId, name, config }, {}, authToken);
			return response.data;
		} catch (err) {
			console.log(`Encountered error while trying to update chatbot - ${err.stack}`);
		}
	},

	/**
	 * Tests a chatbot with the given messages
	 *
	 * @param {String} chatbotId
	 * @param {Array} messages
	 * @return {Promise<Object>} The chatbot response data
	 * @throws {Error} If an error occurs while testing the chatbot
	 */
	async testChatbot({ chatbotId, messages }) {
		let authToken = UserService.getAuthToken();
		try {
			let response = await Kichiri.chatbot.test({ chatbotId, messages }, {}, authToken);
			return response.data;
		} catch (err) {
			console.log(`Encountered error while trying to test chatbot - ${err.stack}`);
		}
	},

	/**
	 * Fetches all chatbots for the current location
	 *
	 * @return {Promise<Array>} The list of chatbots
	 * @throws {Error} If an error occurs while fetching the chatbots
	 */
	async fetchChatbots() {
		let authToken = UserService.getAuthToken();
		let locationId = UserService.getActiveLocation().id;
		try {
			let response = await Kichiri.chatbot.list({}, { locationId }, authToken);
			return response.data;
		} catch (err) {
			console.log(`Encountered error while trying to fetch chatbots - ${err.stack}`);
		}
	}
};

export default ChatbotService;
