import React, { Component } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import LocationService from "../../services/LocationService";
import MessagesService from "../../services/MessagesService";
import DHDropdown from "./DHDropdown";
import DHDropdownOption from "./DHDropdownOption";
import PaymentService from "../../services/PaymentService";
import UserService from "../../services/UserService";

import SendNpsRequest from "../../scenes/NetPromoterScore/SendNpsRequest";
import SendReviewInvites from "../../scenes/Invites/SendReviewInvites";
import NewConversationModal from "../../scenes/MessengerBeta/List/NewConversationModal/NewConversationModal";
import PaymentRequest from "../../scenes/MessengerBeta/Thread/PaymentRequest/PaymentRequest";

import { PAYMENT_TYPES } from "../../constants/Payments";

import "../../styles/css/components/commons/new-actions.css";

class NewActions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			showNPSModal: false,

			showNewConversationModal: false,
			showPaymentRequestModal: false,
			selectedContactName: "",
			selectedContactId: null
		};

		this.sendReviewInviteModalReference = null;
	}

	async componentDidMount() {}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onSendMessage = () => {
		// Navigate to messenger
		this.props.history.push("/inbox");

		// Trigger showing the "Start new Conversation" modal
		MessagesService.triggerShowSendMessage();

		// Hide NewActions context menu
		this.update({ show: false });
	};

	onSendReviewInvite = () => {
		if (!this.sendReviewInviteModalReference) {
			return null;
		}

		this.sendReviewInviteModalReference.triggerShow();

		// TODO - determine if we need withLocation on the SendReviewInvites component
		// if (!this.sendReviewInviteModalReference?.wrappedComponentRef?.current) {
		// 	return null;
		// }

		// this.sendReviewInviteModalReference.wrappedComponentRef.current.triggerShow();
		this.update({ show: false });
	};

	onSendNPSRequest = () => {
		this.update({ show: false, showNPSModal: true });
	};

	onSendPaymentRequest = async () => {
		// Hide NewActions context menu and show new conversation modal (after a contact is created or selected, it will automatically show the payment request modal)
		this.update({
			showNewConversationModal: true,
			show: false
		});
	};

	onChange = ({ show }) => {
		this.update({ show });
	};

	onCloseNPSModal = () => {
		this.update({ showNPSModal: false });
	};

	onContactSelected = async (contact, createMode) => {
		// If there is no payment method on file, open the PaymentRequest modal
		await this.update({
			selectedContactId: contact.contact_id,
			showNewConversationModal: false,
			showPaymentRequestModal: true
		});
	};

	onPaymentRequestModalClosed = async () => {
		await this.update({
			showPaymentRequestModal: false
		});
	};

	onNewConversationModalClose = () => {
		this.update({
			showNewConversationModal: false
		});
	};

	renderPaymentComponents() {
		let { showNewConversationModal, showPaymentRequestModal, selectedContactId } = this.state;
		let { t } = this.props;

		return (
			<>
				<NewConversationModal
					show={showNewConversationModal}
					title={t("Select a contact ...")}
					actionText={t("Next")}
					showPaymentMethod={true}
					onCreate={this.onContactSelected}
					onClose={this.onNewConversationModalClose}
				/>
				<PaymentRequest
					type={PAYMENT_TYPES.general.id}
					show={showPaymentRequestModal}
					onClose={this.onPaymentRequestModalClosed}
					contactId={selectedContactId}
				/>
			</>
		);
	}

	render() {
		let { show, showNPSModal } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let location = UserService.getActiveLocation();

		// Payments related information
		let paymentsEnabled = PaymentService.isPaymentsEnabled();
		let paymentsConnected = location.stripe_account_id && location.stripe_account_id.length > 0;
		let paymentsAllowed = user.GroupPermission.create_payments;

		let showPaymentsOption = paymentsEnabled && paymentsConnected && paymentsAllowed;

		let isNPSEnabled = LocationService.isNpsPermissible();
		let showNPSOption = isNPSEnabled && user.GroupPermission.create_nps;

		return (
			<>
				<DHDropdown
					show={show}
					onChange={this.onChange}
					trigger={
						<div className="new-actions__trigger" onClick={() => this.update({ showMoreComposeActions: true })}>
							<div className="new-action__trigger__icon">
								<Icon.Send size="18" />
							</div>
							<div className="new-action__trigger__title">{t("Send a message")}</div>
						</div>
					}
					options={
						<>
							{LocationService.isCreateReviewsInvitesEnabled() && (
								<DHDropdownOption
									icon={Icon.MessageSquare}
									title={t("Send a message")}
									action={this.onSendMessage}
									description={t("Initiate a conversation with one of your customers")}
								/>
							)}
							{LocationService.isCreateReviewsInvitesEnabled() && (
								<DHDropdownOption
									icon={Icon.Star}
									title={t("Review Request")}
									action={this.onSendReviewInvite}
									description={t("Invite someone to leave a review for your business")}
								/>
							)}
							{showNPSOption && (
								<DHDropdownOption
									icon={Icon.Activity}
									title={t("Net Promoter Score")}
									action={this.onSendNPSRequest}
									description={t("Send an NPS request to this contact")}
								/>
							)}
							{showPaymentsOption && (
								<DHDropdownOption
									icon={Icon.DollarSign}
									title={t("Payment Request")}
									action={this.onSendPaymentRequest}
									description={t("Request a payment from this contact")}
								/>
							)}
						</>
					}
				/>

				<SendNpsRequest show={showNPSModal} onHide={this.onCloseNPSModal} />
				<SendReviewInvites ref={ref => (this.sendReviewInviteModalReference = ref)} key="invite-button" />

				{this.renderPaymentComponents()}
			</>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(NewActions));
