import React, { Component } from "react";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";

import JSONService from "../../../services/JSONService";
import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CsvService } from "../../../services/CsvService";
import CompanyService from "../../../services/CompanyService";

import "../../../styles/css/scenes/analytics.css";
import DashboardCard from "../../Dashboard/DashboardCard";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

class RatingsByLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			locations: [],
			locationsReviews: [],
			ratingsByLocationCsv: null
		};
	}

	resetComponent() {
		this.setAllLocationsForCompany();
	}

	componentDidMount() {
		this.resetComponent();
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	generateRatingsByLocationCsv() {
		let locRatings = [];
		locRatings.push(["Ratings By Location", ""]);
		JSONService.sortByField(this.state.locationsReviews, "rating", "desc").map(loc => locRatings.push([loc.name, loc.rating]));
		this.setState({
			ratingsByLocationCsv: locRatings
		});
		return locRatings;
	}

	/**
	 * get all the locations for the company
	 */
	async setAllLocationsForCompany() {
		const locations = [];
		try {
			let data = await CompanyService.fetchLocations({ companyId: UserService.getActiveCompany().id });

			if (!data) {
				data = [];
			}

			data.forEach(aLocation => locations.push({ id: aLocation.id, name: aLocation.name }));

			await this.update({ locations });
			await this.setOverallRatingsForAllLocations();
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	}

	/**
	 * Get total_reviews and avg_rating for all the locations
	 */
	async setOverallRatingsForAllLocations() {
		const { locations } = this.state;

		await this.update({ loading: true });

		try {
			const locationReviewPromises = [];

			locations.forEach(aLocation => {
				locationReviewPromises.push(
					AnalyticsService.getOverallRatingAndReviews({ locationId: aLocation.id }).catch(error => {
						return { failed: true, error };
					})
				);
			});

			const locationReviewPromisesResolve = await Promise.all(locationReviewPromises);

			var locationsReviews = [];

			for (let i = 0; i < locationReviewPromisesResolve.length; i++) {
				if (locationReviewPromisesResolve[i].failed) {
					console.log(locationReviewPromisesResolve[i].error);
					continue;
				}

				locationsReviews.push({
					id: locationReviewPromisesResolve[i].location_id,
					name: locationReviewPromisesResolve[i].location_name,
					reviews: locationReviewPromisesResolve[i].total_reviews,
					rating: locationReviewPromisesResolve[i].avg_rating.toFixed(1)
				});
			}

			await this.update({ locationsReviews });
			this.calculateRatioForReviewCounts();
			this.generateRatingsByLocationCsv();
		} catch (error) {
			console.log(error);
		}

		await this.update({ loading: false });
	}

	/**
	 * calculate ratio of location's review counts w.r.t. each other
	 */
	calculateRatioForReviewCounts() {
		const { locationsReviews } = this.state;
		const sorted = JSONService.sortByField(locationsReviews, "reviews", "desc");
		sorted.forEach(() => {
			sorted[0].reviews_ratio = 100;
			const baseReviewCount = sorted[0].reviews;
			for (let i = 1; i < locationsReviews.length; i++) {
				sorted[i].reviews_ratio = parseInt((sorted[i].reviews * 100) / baseReviewCount, 10);
			}
		});
		this.setState({ locationsReviews: sorted });
	}

	renderHeader = () => {
		const { locationsReviews, loading, ratingsByLocationCsv } = this.state;
		const { isCard, cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		const locationName = CsvService.filterActiveLocationName();
		return (
			<div className="dashboard__card__header dashboard__card__header--full-width">
				<div className="dashboard__card__header__name">{cardName ? cardName : t("Ratings By Location")}</div>
				<div className="dashboard__card__header__actions">
					{ratingsByLocationCsv && (
						<CSVLink data={ratingsByLocationCsv} filename={`${locationName}-ratings_by_location.csv`} target="_self">
							<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
								<Icon.Download />
							</Action>
						</CSVLink>
					)}
				</div>
			</div>
		);
	};

	renderBody = () => {
		const { locationsReviews, loading } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Reviews is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Review data is restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div className="analytics__ratings-by-location">
				{JSONService.sortByField(locationsReviews, "reviews", "desc").map(
					aLocationReview =>
						aLocationReview.id && (
							<div key={aLocationReview.id}>
								<div>
									<small className="pull-right Analytics__counts">
										{aLocationReview.rating} <i className="fa fa-star" />
									</small>
									<h3>{aLocationReview.name}</h3>
								</div>
								<div className="progress progress-large">
									<div style={{ width: `${aLocationReview.rating * 20}%` }} className="progress-bar progress-bar-primary" />
								</div>
							</div>
						)
				)}
			</div>
		);
	};

	render = () => {
		return (
			<DashboardCard className={DASHBOARD_CARDS.ratingsByLocation.className} height={DASHBOARD_CARDS.ratingsByLocation.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	};
}

export default withTranslation(null, { forwardRef: true })(withLocation(RatingsByLocation));
