import React from "react";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import wavEncoder from "wav-encoder";

import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";

import Action from "./Action";
import Media from "../../scenes/MessengerBeta/Thread/Media/Media";

import "../../styles/css/components/commons/record-audio.css";

const MAX_RECORD_TIME = 30; // Maximum recording time in seconds

class RecordAudio extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isRecording: false,
			audioMedia: null,
			recordTime: 0
		};

		this.wavesurfer = null;
		this.record = null;
		this.timeInterval = null;
	}

	componentDidMount() {
		this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = () => {};

	onRecordAudio = async () => {
		let devices = await navigator.mediaDevices.enumerateDevices();

		let deviceId = devices[0].deviceId;

		// Avoid click sound
		await UtilityService.timeout(250);

		let { t, recordingId, recordText } = this.props;

		this.wavesurfer = WaveSurfer.create({
			barWidth: 3,
			barRadius: 3,
			barGap: 2,

			cursorWidth: 0,

			height: 48,
			barHeight: 3,

			container: `#waveform-recording-${recordingId}`,
			waveColor: "#C4C4C4",
			progressColor: "#60A9FF"
		});

		this.record = this.wavesurfer.registerPlugin(
			RecordPlugin.create({
				renderRecordedAudio: false,
				scrollingWaveform: true,
				scrollingWaveformWindow: 10
				// continuousWaveform: false,
				// continuousWaveformDuration: 30 // optional
			})
		);

		this.record.on("record-end", this.onRecordingFinished);

		// Should be working but doesn't
		// https://wavesurfer.xyz/docs/types/plugins_record.RecordPluginEvents
		// this.record.on("record-progress", time => {
		// 	console.log(`time: `, time);
		// });

		// await UtilityService.timeout(1000);
		await this.record.startRecording();

		await this.update({
			recordTime: 0,
			isRecording: true,
			audioMedia: false
		});

		this.timeInterval = setInterval(() => {
			if (this.state.recordTime >= MAX_RECORD_TIME) {
				this.onStopRecordAudio();
			}

			this.update({
				recordTime: this.state.recordTime + 1
			});
		}, 1000);
	};

	onStopRecordAudio = async () => {
		this.record.stopRecording();
		this.wavesurfer.destroy();
		clearInterval(this.timeInterval);
		this.timeInterval = null;

		this.update({
			isRecording: false,
			recordTime: 0
		});
	};

	onRecordingFinished = async audioBlob => {
		let { recordingId } = this.props;

		audioBlob = await this.convertWebMToWav(audioBlob);

		let file = new File([audioBlob], `${recordingId}_recording.wav`, { type: audioBlob.type });

		const url = URL.createObjectURL(audioBlob);

		let localMedia = MessagesService.storeLocalMedia({
			file: file
		});

		this.update({
			audioMedia: localMedia
		});

		if (this.props.onRecordingFinished) {
			this.props.onRecordingFinished(localMedia);
		}
	};

	convertWebMToWav = async blob => {
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const arrayBuffer = await blob.arrayBuffer();
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

		const wavData = await wavEncoder.encode({
			sampleRate: audioBuffer.sampleRate,
			channelData: [audioBuffer.getChannelData(0)] // Convert single channel (mono)
		});

		return new Blob([wavData], { type: "audio/wav" });
	};

	formatTime = time => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	};

	render() {
		let { t, recordingId, recordText } = this.props;
		let { isRecording, audioMedia, recordTime } = this.state;

		if (!recordText) {
			recordText = "Record Audio";
		}

		return (
			<div className="record-audio">
				{isRecording && (
					<div className="record-audio__recording">
						<Action id={`stop-recording`} label={"Stop Recording Message"} icon={Icon.Square} onClick={this.onStopRecordAudio} />
						<div data-tip data-for={`${recordingId}_time_tooltip`}>
							{this.formatTime(recordTime)}
							<ReactTooltip id={`${recordingId}_time_tooltip`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
								{/* <div className="tasks__info__tooltip-text">{t(`Maximum of ${MAX_RECORD_TIME} seconds.`)}</div> */}
							</ReactTooltip>
						</div>
					</div>
				)}
				{!isRecording && <Action id={`start-recording`} label={"Start Recording Message"} icon={Icon.Mic} onClick={this.onRecordAudio} />}

				{audioMedia && (
					<Media media={audioMedia} idPrefix={`recording-${recordingId}`} nMediaClicked={url => {}} soundWaveHeight={48} playButtonSize={28}></Media>
				)}
				{!isRecording && !audioMedia && (
					<div className="record-audio__text">
						{recordText} <span className="input__label__required">*</span>
					</div>
				)}
				<div id={`waveform-recording-${recordingId}`} className={`record-audio__wave ${!isRecording ? "record-audio__wave--hide" : ""}`} />
			</div>
		);
	}
}

export default RecordAudio;
// export default withTranslation(null, { withRef: true })(RecordAudio);
