import React, { PureComponent } from "react";
import * as Icon from "react-feather";
import moment from "moment";

import { MEDIA_TYPES } from "../../constants/Messenger";

import Media from "../../scenes/MessengerBeta/Thread/Media/Media";

import "../../styles/css/components/commons/media-grid-item.css";

class MediaGridItem extends PureComponent {
	constructor(props) {
		super(props);

		this.threadMedia = null;
	}

	onMediaClicked = url => {
		if (this.props.onMediaClicked) {
			this.props.onMediaClicked(url);
		}
	};

	renderImage = () => {
		let { mediaItem, referrerPolicy } = this.props;

		let id = mediaItem.id;
		let filename = mediaItem.file_name;
		let date = moment(mediaItem.created_at).format("MMMM Do YYYY, h:mm A");

		return (
			<div className="media-grid-item">
				<div className="media-grid-item__content">
					<Media key={id} media={mediaItem} maxHeightOrWidth={250} onMediaClicked={url => this.onMediaClicked(url)} referrerPolicy={referrerPolicy} />
				</div>
				<div className="media-grid-item__caption">
					{filename && <div className="media-grid-item__caption__title">{filename}</div>}
					{date && <div className="media-grid-item__caption__date">{date}</div>}
				</div>
			</div>
		);
	};

	renderVideo = () => {
		let { mediaItem, referrerPolicy } = this.props;

		let id = mediaItem.id;
		let filename = mediaItem.file_name;
		let date = moment(mediaItem.created_at).format("MMMM Do YYYY, h:mm A");

		return (
			<div className="media-grid-item">
				<div className="media-grid-item__content">
					<Media key={id} media={mediaItem} maxHeightOrWidth={250} referrerPolicy={referrerPolicy} />
				</div>
				<div className="media-grid-item__caption">
					{filename && <div className="media-grid-item__caption__title">{filename}</div>}
					{date && <div className="media-grid-item__caption__date">{date}</div>}
				</div>
			</div>
		);
	};

	renderFile = () => {
		let { mediaItem, readOnly } = this.props;

		let id = mediaItem.id;
		let filename = mediaItem.file_name;
		let date = moment(mediaItem.created_at).format("MMMM Do YYYY, h:mm A");

		return (
			<div className="media-grid-item">
				<div className="media-grid-item__content">
					<Media key={id} media={mediaItem} maxHeightOrWidth={250} readOnly={readOnly} />
				</div>
				<div className="media-grid-item__caption">
					{filename && <div className="media-grid-item__caption__title">{filename}</div>}
					{date && <div className="media-grid-item__caption__date">{date}</div>}
				</div>
			</div>
		);
	};

	renderAudio = () => {
		let { mediaItem, readOnly } = this.props;

		let id = mediaItem.id;
		let filename = mediaItem.file_name;
		let date = moment(mediaItem.created_at).format("MMMM Do YYYY, h:mm A");

		return (
			<div className="media-grid-item">
				<div className="media-grid-item__content">
					<Media key={id} ref={ref => (this.threadMedia = ref)} media={mediaItem} maxHeightOrWidth={250} idPrefix="grid" readOnly={readOnly} />
				</div>
				<div className="media-grid-item__caption">
					<div className="thread-media__audio__filename">
						{filename && <div className="thread-media__audio__filename__text">{filename}</div>}
						<div className="thread-media__audio__filename__download">
							<Icon.Download size={18} onClick={() => (this.threadMedia ? this.threadMedia.startDownload() : null)} />
						</div>
					</div>
					{date && <div className="media-grid-item__caption__date">{date}</div>}
				</div>
			</div>
		);
	};

	render = () => {
		let { mediaItem } = this.props;

		if (mediaItem.type === MEDIA_TYPES.image) {
			return this.renderImage();
		} else if (mediaItem.type === MEDIA_TYPES.video) {
			return this.renderVideo();
		} else if (mediaItem.type === MEDIA_TYPES.file) {
			return this.renderFile();
		} else if (mediaItem.type === MEDIA_TYPES.audio) {
			return this.renderAudio();
		} else {
			return null;
		}
	};
}

export default MediaGridItem;
