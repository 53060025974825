import UserService from "./UserService";
import Kichiri from "./KichiriService";
import UtilityService from "./UtilityService";
import moment from "moment";
import _ from "lodash";

import { DATE_FORMAT } from "../constants/CommonConstants";

export const AnalyticsService = {
	/**
	 * Gets last x error events for widget
	 * @param {string} appName
	 * @param {string} eventName
	 * @param {string} locationId
	 * @param {integer} limit
	 */
	async getLastXEvents(appName, eventName, locationId, limit) {
		try {
			const authToken = UserService.get().auth_token;
			const lastXResponse = await Kichiri.stats.getLastXStatus({}, { appName, eventName, locationId, limit }, authToken);
			return lastXResponse.data;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Get total review count across all review sites
	 * @param {number} locationId
	 * @returns {number} review count
	 */
	async getReviewCountForLocation({ locationId }) {
		try {
			const authToken = UserService.getAuthToken();
			const reviewAndRatingsResponse = await Kichiri.location.getReviewAndRatings({ locationId }, {}, authToken);
			let reviewCount = 0;
			reviewAndRatingsResponse.data.forEach(aReviewResponse => {
				reviewCount += aReviewResponse.count;
			});
			return reviewCount;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Get average ratings across all review sites
	 * @param {number} locationId
	 * @returns {number} weighted average
	 */
	async getAvgRatingsForLocation({ locationId }) {
		try {
			const authToken = UserService.getAuthToken();
			const reviewAndRatingsResponse = await Kichiri.location.getReviewAndRatings({ locationId }, {}, authToken);

			//calculate the weighted average

			let reviewCount = 0,
				weight = 0;
			reviewAndRatingsResponse.data.forEach(aReviewResponse => {
				reviewCount += aReviewResponse.count;
				weight += aReviewResponse.count * parseFloat(aReviewResponse.avg_rating);
			});
			const avgRating = weight / reviewCount;

			return isNaN(avgRating) ? 0 : avgRating.toFixed(1);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get inbound lead count for the last x days
	 * @param {Integer} locationId
	 * @param {Integer} days
	 * @param {Boolean} allTimeStats If all time stats should be fetched. Overrides days param
	 *
	 * @returns {Number} Number of inbound conversations
	 */
	async getInboundConversationsForLastXDays({ locationId, days, allTimeStats }) {
		try {
			const inboundConversationsResponse = await Kichiri.stats.getInboundConversationsForLastXDays(
				{ locationId },
				{ days: days, allTimeStats: allTimeStats ? allTimeStats : false },
				UserService.getAuthToken()
			);
			return inboundConversationsResponse.data.total;
		} catch (error) {
			console.log(error);
		}
		return 0;
	},
	/**
	 * Get summary total ratings, reviews for a location
	 * @param {integer} locationId
	 * @returns {object}
	 */
	async getReviewsSummary(locationId) {
		try {
			const authToken = UserService.getAuthToken();
			const reviewsSummaryResponse = await Kichiri.stats.getReviewsSummary({ locationId }, {}, authToken);
			return reviewsSummaryResponse.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},
	/**
	 * Get overall total ratings, reviews for a location
	 * Response Object: {avg_rating: 4.6, reviews: [{avg_rating: 4.8, date: "2018-05-12", name: "RateMDs", review_count: 34, review_count_ratio: 100, site_id: 5}], total_reviews: 95}
	 * @param {integer} locationId
	 * @returns {object}
	 */
	async getOverallRatingAndReviews({ locationId }) {
		try {
			const authToken = UserService.get().auth_token;
			const overAllRatingsResponse = await Kichiri.location.overallRatings({ locationId }, {}, authToken);
			return overAllRatingsResponse.data;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Get the total replies, the total positive/negative replies, and get the positive/negative review counts
	 * @param {integer} locationId The id of the location that we want to get data for
	 * @returns {object}
	 */
	async getOverallReplies({ locationId }) {
		try {
			const authToken = UserService.get().auth_token;

			let response = await Kichiri.location.getOverallReplies(
				{
					locationId: locationId
				},
				null,
				authToken
			);

			return {
				data: {
					repliesCount: response.data.repliesCount,
					positiveReviewCount: response.data.positiveReviewCount,
					negativeReviewCount: response.data.negativeReviewCount,
					positiveReviewReplyCount: response.data.positiveReviewReplyCount,
					negativeReviewReplyCount: response.data.negativeReviewReplyCount
				}
			};
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch NPS requests
	 * @param {integer} locationId The location that we want to fetch NPS stats for
	 * @param {string} startRange The first date in the range that we want to get stats for
	 * @param {string} endRange The last date in the range that we want to get stats for
	 */
	async fetchNps(locationId, startRange, endRange, minScore, maxScore, hasSuggestion) {
		try {
			const authToken = UserService.get().auth_token;

			let response = await Kichiri.location.fetchNps(
				{
					locationId: locationId
				},
				{
					startRange: startRange,
					endRange: endRange,
					minScore: minScore,
					maxScore: maxScore,
					hasSuggestion: hasSuggestion
				},
				authToken
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Get company email report
	 * @param {number} companyId
	 * @param {string} reportType
	 * @param {string} startDate
	 * @param {string} endDate
	 */
	async getCompanyEmailReportData(companyId, reportType, startDate, endDate) {
		try {
			const emailReportStatsResponse = await Kichiri.company.getEmailReportAnalytics(
				{ companyId },
				{ start: moment(startDate).format("YYYY-MM-DD"), end: moment(endDate).format("YYYY-MM-DD"), type: reportType },
				UserService.getAuthToken()
			);
			return emailReportStatsResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get Location email report
	 * @param {number} companyId
	 * @param {string} startDate
	 * @param {string} endDate
	 * @param {string} reportType
	 */
	async getLocationEmailReportAnalytics({ locationId, start, end, reportType }) {
		try {
			const response = await Kichiri.location.getEmailReportAnalytics(
				{ locationId: locationId },
				{ start: moment(start).format("YYYY-MM-DD"), end: moment(end).format("YYYY-MM-DD"), type: reportType },
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch team chat stats
	 * @param {Boolean} favoritesOnly
	 * @param {Moment} startDate
	 * @param {Moment} endDate
	 * @param {Boolean} favoritesOnly
	 */
	async fetchTeamChatCSAnalytics({ favoritesOnly, startDate, endDate, clearCache }) {
		try {
			const authToken = UserService.get().auth_token;
			const response = await Kichiri.customer_success.fetchTeamChatCSAnalytics({}, { favoritesOnly, startDate, endDate, clearCache }, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch lagging locations
	 * @param {Boolean} favoritesOnly
	 * @param {String} searchTerm
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @param {Boolean} clearCache
	 *
	 * @returns {Object}
	 */
	async fetchCSLaggingLocations({ favoritesOnly, searchTerm, sortField, sortOrder, limit, clearCache }) {
		try {
			const response = await Kichiri.customer_success.fetchLaggingLocations(
				{},
				{ favoritesOnly, searchTerm, sortField, sortOrder, clearCache, limit },
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch CS Messenger Analytics Data
	 * @param {Boolean} favoritesOnly
	 * @param {Boolean} clearCache
	 *
	 * @returns {Object}
	 */
	async fetchCSMessengerData({ favoritesOnly, clearCache }) {
		try {
			const response = await Kichiri.stats.getMessengerStats({}, { favoritesOnly, clearCache }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch message delivery stats
	 * @param {Number} locationId
	 * @param {Moment} startDate
	 * @param {Moment} endDate
	 */
	async getMessageDeliveryAnalytics(locationId, start, end) {
		try {
			const response = await Kichiri.location.getMessageDeliveryAnalytics({ locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get any messages that have failed to send or had an error
	 * @param {Number} locationId
	 * @param {Moment} start
	 * @param {Moment} end
	 */
	async getFailedMessages(locationId, start, end) {
		let failedMessages = [];
		try {
			const response = await Kichiri.location.getFailedMessages({ locationId }, { start, end }, UserService.getAuthToken());
			failedMessages = response.data;
		} catch (error) {
			console.log(error);
		}
		return failedMessages;
	},

	/**
	 * Get the amount of messages that have failed to send, and the total message sent count for a given location and date range
	 * @param {Number} locationId
	 * @param {Moment} start
	 * @param {Moment} end
	 */
	async getMessageDeliveryRate(locationId, start, end) {
		try {
			const response = await Kichiri.location.getMessageDeliveryRate({ locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get messenger analytics
	 * @param {Number} locationId
	 * @param {Moment} start
	 * @param {Moment} end
	 */
	async getMessagesAnalytics(locationId, start, end) {
		try {
			const response = await Kichiri.location.getMessagesAnalytics({ locationId: locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get contact lead source data
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getLeadSourceAnalytics(locationId, start, end) {
		try {
			const response = await Kichiri.location.getLeadSourceAnalytics({ locationId: locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get contact lead source data summed up for the given range
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getLeadSourceTotalAnalytics(locationId, start, end) {
		try {
			const response = await Kichiri.location.getLeadSourceTotalAnalytics({ locationId: locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the number of new lead sources by the hour that they were created
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getLeadSourceTimeOfDayAnalytics({ locationId, start, end }) {
		try {
			const response = await Kichiri.location.getLeadSourceTimeOfDayAnalytics({ locationId: locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get contact lead source data
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getKeywordAnalytics(locationId, start, end) {
		try {
			const response = await Kichiri.location.getKeywordAnalytics({ locationId: locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get webchat widget stats
	 * @param {Number} locationId
	 * @param {String} widget
	 * @param {String} start
	 * @param {String} end
	 */
	async getWidgetAnalytics(locationId, widget, start, end) {
		try {
			const response = await Kichiri.location.getWidgetAnalytics({ locationId: locationId }, { widget, start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get contact subscriber stats
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getSubscriberAnalytics({ locationId, start, end }) {
		try {
			const response = await Kichiri.location.getSubscriberAnalytics({ locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch the CS Feature Summary
	 * @returns {Array/Object} The feature summary data that we need
	 */
	async fetchCSFeatureSummary() {
		try {
			const response = await Kichiri.customer_success.fetchFeatureSummary({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch the CS Feature Matrix config data
	 * @returns {Array/Object} The feature matrix config data
	 */
	async fetchFeatureMatrixConfig() {
		try {
			const response = await Kichiri.customer_success.fetchFeatureMatrixConfig({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a list of locations, along with feature data
	 * @param {String} searchTerm
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Array/String} enabledFeaturesFilter
	 * @param {Array/String} disabledFeaturesFilter
	 * @returns {Array/Object}
	 */
	async fetchCSLocationFeatureList({ searchTerm, sortField, sortOrder, enabledFeaturesFilter, disabledFeaturesFilter }) {
		try {
			const response = await Kichiri.customer_success.fetchLocationFeaturesList(
				{},
				{ searchTerm, sortField, sortOrder, enabledFeaturesFilter, disabledFeaturesFilter },
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get conversion stats
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 * @param {Array} leadSources
	 */
	async getConversionsAnalytics({ locationId, start, end, leadSources }) {
		try {
			const response = await Kichiri.location.getConversionsAnalytics({ locationId }, { start, end, leadSources }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get lifetime conversion data
	 * @param {Number} locationId
	 */
	async getTotalConversionsCount({ locationId }) {
		try {
			let key = `AnalyticsService:getTotalConversionsCount:${locationId}`;

			let cachedData = await UtilityService.getCachedData({
				key
			});

			// If the cached data was not found
			if (cachedData) {
				return cachedData;
			}

			const response = await Kichiri.location.getTotalConversionsCount({ locationId }, {}, UserService.getAuthToken());

			let data = response.data;

			UtilityService.cacheData({ key, data });

			return data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get lifetime reengagements data
	 * @param {Number} locationId
	 */
	async getLifetimeReengagementConversions({ locationId }) {
		try {
			const response = await Kichiri.location.getLifetimeReengagementConversions({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get conversion appointments
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 * @param {Array} leadSources
	 */
	async getConversionsAppointments({ locationId, start, end, leadSources }) {
		try {
			const response = await Kichiri.location.getConversionsAppointments({ locationId }, { start, end, leadSources }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get reengagement attribution stats
	 * @param {Number} locationId
	 */
	async getReengagementAttributionAnalytics({ locationId }) {
		try {
			const response = await Kichiri.location.getReengagementAttributionAnalytics({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the number of reengagement messages sent
	 * @param {Number} locationId
	 */
	async getReengagementsSentCount({ locationId }) {
		try {
			const response = await Kichiri.location.getReengagementsSentCount({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get scheduled message stats
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 */
	async getScheduledMessageAnalytics({ locationId, start, end }) {
		try {
			const response = await Kichiri.location.getScheduledMessageAnalytics({ locationId }, { start, end }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get lifetime payment data
	 * @param {Number} locationId
	 */
	async getTotalPaymentsCount({ locationId }) {
		try {
			const response = await Kichiri.location.getTotalPaymentsCount({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch CS Lagging Location Details
	 *
	 * @param {Integer} locationId
	 * @param {Integer} lastReviewdInXDays
	 * @param {Integer} invitesSentInXDays
	 * @param {Integer} avgInvitesPerDayOverXDays
	 * @param {Integer} avgInvitesPerWeekOverXDays
	 * @param {Integer} avgMessagesPerDayOverXDays
	 *
	 * @returns {Array}
	 */
	async fetchCSLaggingLocationDetails({
		locationId,
		lastReviewdInXDays,
		invitesSentInXDays,
		avgInvitesPerDayOverXDays,
		avgInvitesPerWeekOverXDays,
		avgMessagesPerDayOverXDays
	}) {
		try {
			const response = await Kichiri.customer_success.fetchLaggingLocationDetails(
				{},
				{
					locationId,
					lastReviewdInXDays,
					invitesSentInXDays,
					avgInvitesPerDayOverXDays,
					avgInvitesPerWeekOverXDays,
					avgMessagesPerDayOverXDays
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get Review Insights
	 * @param {Number} locationId
	 * @param {String} startDate
	 * @param {String} endDate
	 * @param {Integer} siteId
	 */
	async fetchReviewInsights({ locationId, startDate, endDate, siteId }) {
		try {
			const response = await Kichiri.location.insights(
				{
					locationId: locationId
				},
				{
					start: moment(startDate).format(DATE_FORMAT),
					end: moment(endDate).format(DATE_FORMAT),
					siteId
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get Monthly Review Insights
	 * @param {Number} locationId
	 * @param {String} startDate
	 * @param {String} endDate
	 * @param {Integer} siteId
	 */
	async fetchMonthlyReviewInsites({ locationId, startDate, endDate, siteId }) {
		try {
			const response = await Kichiri.location.getMonthlyInsights(
				{
					locationId: locationId
				},
				{
					startDate: moment(startDate).format(DATE_FORMAT),
					endDate: moment(endDate).format(DATE_FORMAT),
					siteId
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch Insights Related to Reviews
	 * @param {Integer} siteId
	 * @param {String} sentiment
	 * @param {String} reportType
	 * @returns {Array}
	 */
	async fetchReviewInsightData({ location, sentiment, reportType }) {
		try {
			let response = await Kichiri.review_insight.fetch(
				{},
				{
					location,
					sentiment,
					reportType
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get an overall snapshot for all locations
	 * @param {Integer} locationId
	 * @returns {Array}
	 */
	async getOverallSnapshot({ locationId }) {
		try {
			let response = await Kichiri.location.getOverallSnapshot({ locationId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	},

	/**
	 * Get review counts per dates
	 * @param {Integer} locationId
	 * @returns {Array}
	 */
	async getReviewCountsForDates({ locationId, dates }) {
		try {
			let response = await Kichiri.location.getReviewCountsForDates({ dates, locationId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	},

	/**
	 * Fetch lagging automations
	 * @param {Boolean} favoritesOnly
	 * @param {Boolean} clearCache
	 * @returns {Array}
	 */
	async fetchLaggingAutomations({ favoritesOnly, clearCache }) {
		try {
			const response = await Kichiri.customer_success.fetchLaggingAutomations({}, { favoritesOnly, clearCache }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch  Messenger Channel Stats
	 * @param {Boolean} favoritesOnly
	 * @returns {Array}
	 */
	async getMessengerChannelStats({ favoritesOnly }) {
		try {
			const response = await Kichiri.stats.getMessengerChannelStats({}, { favoritesOnly }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch Review Stats
	 * @param {Boolean} favoritesOnly
	 * @returns {Array}
	 */
	async getReviewStats({ favoritesOnly }) {
		try {
			const response = await Kichiri.stats.getReviewStats({}, { favoritesOnly: favoritesOnly }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch Webchat Stats
	 * @param {Boolean} favoritesOnly
	 * @returns {Array}
	 */
	async getWebchatStats({ favoritesOnly }) {
		try {
			const response = await Kichiri.stats.getWebchatStats({}, { favoritesOnly }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch Message count data
	 * @param {Integer} locationId
	 * @returns {Array}
	 */
	async getMessagesCount({ locationId }) {
		try {
			let response = await Kichiri.stats.getMessagesCount({ locationId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};
