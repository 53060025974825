import i18n from "../i18n/config";

export const INVITE_STATUS = {
	creating: "creating",
	pending: "pending",
	sending: "sending",
	sent: "sent",
	failed: "failed",
	cancelled: "cancelled",
	deleted: "deleted"
};

export const INVITE_STATUS_CLASS = {
	creating: "invites__label--creating",
	pending: "invites__label--pending",
	sending: "invites__label--sending",
	sent: "invites__label--sent",
	failed: "invites__label--failed",
	cancelled: "invites__label--cancelled",
	deleted: "invites__label--deleted"
};

export const INVITES_DEADLINE_ACTION = {
	send: "send",
	expire: "expire",
	label: {
		send: i18n.t("Send"),
		expire: i18n.t("Expire")
	}
};

export const SEND_REVIEW_INVITES_MODAL = {
	type: {
		invite: {
			id: "invite",
			name: i18n.t("Invite")
		},
		reviewReminder: {
			id: "reviewReminder",
			name: i18n.t("Review Reminder")
		}
	}
};

export const CREATE_REVIEW_INVITE_ERRORS = {
	receiveFeedbackSmsDisabled: {
		id: "Silent: !contact.receive_feedback_sms contact",
		message: i18n.t("Sending SMS to this contact is disabled. They have the receive feedback flag disabled.")
	},
	receiveFeedbackEmailDisabled: {
		id: "Silent: !contact.receive_feedback_emails contact",
		message: i18n.t("Sending email to this contact is disabled. They have the receive feedback flag disabled.")
	},
	failedToSend: {
		id: "failedToSend",
		message: i18n.t("The review request could not be sent. Please check the phone number or email and try again.")
	}
};

export const INVITES_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	remindable: {
		id: "remindable",
		display: i18n.t("Remindable Only"),
		order: 2
	}
};

export const INVITES_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1,
		widthMd: 1
	},
	phoneOrEmail: {
		id: "phoneOrEmail",
		value: i18n.t("Phone Or Email"),
		sortable: false,
		sortField: "phoneOrEmail",
		width: 2,
		widthMd: 0
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 1
	},
	sendAfter: {
		id: "sendAfter",
		value: i18n.t("Send After"),
		sortable: true,
		sortField: "send_After",
		width: 2
	},
	leadSource: {
		id: "leadSource",
		value: i18n.t("Lead Source"),
		sortable: true,
		sortField: "lead_source",
		width: 1,
		widthMd: 0
	},
	clicked: {
		id: "clicked",
		value: i18n.t("Clicked"),
		sortable: true,
		sortField: "clicked_at",
		width: 1,
		widthMd: 0
	},
	sentBy: {
		id: "sentBy",
		value: i18n.t("Sent By"),
		sortable: false,
		sortField: "sentBy",
		width: 1,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const INVITES_TAB = {
	invites: {
		id: "invites",
		value: i18n.t("Review Invites"),
		route: "/invites/review-invites"
	},
	reminder: {
		id: "reminder",
		value: i18n.t("Reminders"),
		route: "/invites/reminders"
	}
};

export const INVITES_STATUS_OPTIONS = {
	all: {
		value: "all",
		label: i18n.t("All")
	},
	sent: {
		value: "sent",
		label: i18n.t("Sent")
	},
	pending: {
		value: "pending",
		label: i18n.t("Pending")
	},
	failed: {
		value: "failed",
		label: i18n.t("Failed")
	},
	cancelled: {
		value: "cancelled",
		label: i18n.t("Cancelled")
	}
};

export const INVITES_REMINDABLE_OPTIONS = {
	all: {
		value: "all",
		label: i18n.t("All")
	},
	remindable: {
		value: "remindable",
		label: i18n.t("Remindable Only")
	}
};

export const UNKNOWN_NAMES_OPTIONS = {
	all: {
		value: "all",
		label: i18n.t("All Names")
	},
	unknown: {
		value: "unknown",
		label: i18n.t("Unknown Names")
	}
};
