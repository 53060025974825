import LocationService from "../services/LocationService";
import InboxService from "../services/InboxService";

import { DATE_RANGES } from "./CommonConstants";
import { LOCATION_FEATURES } from "./LocationConstants";

import i18n from "../i18n/config";

export const LEADERBOARD_COLUMNS = {
	rank: {
		id: "rank",
		value: i18n.t("Rank"),
		sortable: true,
		sortField: "rank",
		width: 1
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2
	},
	number_of_conversations: {
		id: "number_of_conversations",
		value: i18n.t("Conversations"),
		sortable: true,
		sortField: "number_of_conversations",
		width: 2,
		tooltip: i18n.t("The number of conversations that a user has been involved in. At least 1 message sent in the conversation.")
	},
	messages_sent: {
		id: "messages_sent",
		value: i18n.t("Messages Sent"),
		sortable: true,
		sortField: "messages_sent",
		width: 2,
		tooltip: i18n.t("The number of messages sent out within the specified date range.")
	},
	median_response_time: {
		id: "median_response_time",
		value: i18n.t("Median Response Time"),
		sortable: true,
		sortField: "actual_median_response_time_minutes",
		width: 2,
		tooltip: i18n.t("Median first response time to respond to new inbound conversations, accounting for regular business hours.")
	},
	average_response_time: {
		id: "average_response_time",
		value: i18n.t("Average Response Time"),
		sortable: true,
		sortField: "actual_average_response_time_minutes",
		width: 2,
		tooltip: i18n.t("Average first response time to respond to new inbound conversations, accounting for regular business hours.")
	},
	invites_sent: {
		id: "invites_sent",
		value: i18n.t("Invites Sent"),
		sortable: true,
		sortField: "invites_sent",
		width: () => (LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.reviews_invites.id) ? 2 : 0)
	},
	invites_clicked: {
		id: "invites_clicked",
		value: i18n.t("Invites Clicked"),
		sortable: true,
		sortField: "invites_clicked",
		width: () => (LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.reviews_invites.id) ? 2 : 0)
	},
	invite_click_rate: {
		id: "invite_click_rate",
		value: i18n.t("Invite Click %"),
		sortable: true,
		sortField: "invite_click_rate",
		width: () => (LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.reviews_invites.id) ? 2 : 0)
	},
	reminders_sent: {
		id: "reminders_sent",
		value: i18n.t("Reminders Sent"),
		sortable: true,
		sortField: "reminders_sent",
		width: () => (LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.reviews_invites.id) ? 2 : 0)
	},
	attributed_reviews: {
		id: "attributed_reviews",
		value: i18n.t("Reviews"),
		sortable: true,
		sortField: "attributed_reviews",
		width: () => (LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.reviews_invites.id) ? 2 : 0)
	},
	auto_assigned_contact_count: {
		id: "auto_assigned_contact_count",
		value: "RR " + i18n.t("Assigned"),
		sortable: true,
		sortField: "auto_assigned_contact_count",
		width: () => (InboxService.isAutoAssignmentLocationFeatureEnabled() ? 2 : 0),
		tooltip: i18n.t("Number of times the user has been assigned a lead in round robin.")
	},
	auto_assigned_responses: {
		id: "auto_assigned_responses",
		value: "RR " + i18n.t("Responses"),
		sortable: true,
		sortField: "auto_assigned_responses",
		width: () => (InboxService.isAutoAssignmentLocationFeatureEnabled() ? 2 : 0),
		tooltip: i18n.t("Number of times the user has responded to a lead in round robin.")
	}
};

export const LEADERBOARD_FILTERS = {
	thisWeek: {
		id: DATE_RANGES.thisWeek,
		display: i18n.t("This Week"),
		value: DATE_RANGES.thisWeek,
		order: 1
	},
	thisMonth: {
		id: DATE_RANGES.thisMonth,
		display: i18n.t("This Month"),
		value: DATE_RANGES.thisMonth,
		order: 2
	},
	lastMonth: {
		id: DATE_RANGES.lastMonth,
		display: i18n.t("Last Month"),
		value: DATE_RANGES.lastMonth,
		order: 3
	},
	allTime: {
		id: DATE_RANGES.allTime,
		display: i18n.t("All Time"),
		value: DATE_RANGES.allTime,
		order: 4
	}
};
