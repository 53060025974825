import React from "react";
import * as Icon from "react-feather";
import Dropzone from "react-dropzone";
import { withTranslation, Trans } from "react-i18next";

import ToastService from "../../../services/ToastService";

import { MEDIA_EXTENSIONS } from "../../../constants/Messenger";

import "../../../styles/css/components/commons/drag-and-drop-overlay.css";

class DragAndDropOverlay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDraggingFile: false,
			isMultiple: false
		};

		this.dropZone = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onClickOpen = () => {
		if (this.dropZone && this.dropZone.current) {
			this.dropZone.current.open();
		}
	};

	onDrop = async files => {
		await this.update({ isDraggingFile: false });

		if (this.props.onFileAdded) {
			this.props.onFileAdded(files);
		}
	};

	onDragEnter = async () => {
		await this.update({ isDraggingFile: true });
	};

	onDragLeave = async () => {
		await this.update({ isDraggingFile: false });
	};

	onDropRejected = async event => {
		let { accept } = this.props;
		let { t } = this.props;

		if (!accept) {
			ToastService.error(t("Unexpected file format."));
			return;
		}

		let extensions = {};

		for (const mimeType in accept) {
			let ext = MEDIA_EXTENSIONS.mimeType[mimeType];
			if (ext) {
				extensions[ext] = ext;
			}
		}

		extensions = Object.values(extensions);

		if (extensions.length < 1) {
			ToastService.error(t("Unexpected file format."));
			return;
		}

		ToastService.error(t("Expecting {{fileExtensions}} file type", { fileExtensions: extensions }));
	};

	render = () => {
		let { isDraggingFile } = this.state;
		let { accept, className } = this.props;
		let hasChildren = this.props.children;
		let { t } = this.props;

		return (
			<Dropzone
				ref={this.dropZone}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDropRejected={this.onDropRejected}
				noClick={true}
				accept={accept}
			>
				{({ getRootProps, getInputProps, acceptedFiles }) => (
					<div
						{...getRootProps({
							className: `dnd ${className || ""} ${isDraggingFile ? "dnd--is-dragging" : ""}`,
							onDrop: event => event.preventDefault(),
							onClick: event => event.preventDefault()
						})}
					>
						{isDraggingFile && (
							<div className="dnd__overlay">
								<div className="dnd__overlay__text">
									<span className="dnd__overlay__text__icon">
										<Icon.Paperclip size={14} />
									</span>
									{t("Add File Attachment")}
								</div>
							</div>
						)}
						<input {...getInputProps({ className: "dnd__overlay__file-input" })}></input>
						{hasChildren && this.props.children}
						{!hasChildren && (
							<div className="dnd__drop-area" onClick={this.onClickOpen}>
								{!isDraggingFile && (
									<div>
										<Trans
											i18nKey="Drag & drop a file or <1>click here</1>."
											components={{
												1: <span className="dnd__drop-area__click-here" />
											}}
										>
											Drag & drop a file or <span className="dnd__drop-area__click-here">click here</span>.
										</Trans>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</Dropzone>
		);
	};
}

export default withTranslation(null, { withRef: true })(DragAndDropOverlay);
