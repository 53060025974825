import moment from "moment";
import { getServices } from "service-fetch";
import i18n from "../i18n/config";

import UserService from "./UserService";
import MessagesService from "./MessagesService";
import UtilityService from "./UtilityService";
import LocationService from "./LocationService";

import { CALENDAR_EVENT_TYPES } from "../constants/Calendar";
import { LOCATION_FEATURES } from "../constants/LocationConstants";

const ical2json = require("ical2json");

// Pulls our backend CalendarService from service-fetch
const { CalendarService } = getServices();

const LocalCalendarService = {
	/**
	 * Parses our DemandHub Calendar Events into something readable for the FullCalendar Library
	 *
	 * @param {Array<CalendarEvent>} events
	 * @returns {Array<FullCalendarEvent>}
	 */
	parseEventsForFullCalendar({ events }) {
		let parsedEvents = events.map(event => {
			let parsedEvent = {
				type: CALENDAR_EVENT_TYPES.calendarEvents.value,
				internalId: event.id,
				id: UtilityService.uuid(),
				groupId: event.public_id,
				allDay: event.is_all_day,
				title: event.title,
				start: moment(event.start_at).toDate(),
				end: moment(event.end_at).toDate(),
				startStr: event.start_at,
				endStr: event.end_at,

				editable: true,
				eventStartEditable: true,
				eventResizableFromStart: true,

				color: event.color
			};

			// FullCalendar uses something called RRule and RRule.js to achieve recurring events
			// But there seems to be an issue with version rrule v2.6.3 and @fullcalendar/rrule v6.1.4,
			// so right now we are checking all recurring events on the backend
			// if (event.is_repeated) {
			// 	parsedEvent.rrule = event.repetition_pattern;
			// }

			return parsedEvent;
		});

		return parsedEvents;
	},

	/**
	 * Parses our DemandHub Booking Requests into something readable for the FullCalendar Library
	 *
	 * @param {Array<BookingRequest>} bookingRequests
	 * @returns {Array<FullCalendarEvent>}
	 */
	parseBookingRequestsForFullCalendar({ bookingRequests }) {
		let parsedEvents = bookingRequests.map(bookingRequest => {
			let startDate = moment(bookingRequest.date);
			let endDate = moment(bookingRequest.date);
			let startTime = moment(bookingRequest.start_time, "HH:mm");
			let endTime = moment(bookingRequest.end_time, "HH:mm");

			startDate.set({
				hour: startTime.get("hour"),
				minute: startTime.get("minute")
			});

			endDate.set({
				hour: endTime.get("hour"),
				minute: endTime.get("minute")
			});

			let parsedEvent = {
				type: CALENDAR_EVENT_TYPES.bookingRequests.value,
				internalId: bookingRequest.id,
				id: UtilityService.uuid(),
				groupId: bookingRequest.public_id,
				allDay: false,
				title: bookingRequest.reason,
				start: startDate.toDate(),
				end: endDate.toDate(),

				editable: false,
				eventStartEditable: false,
				eventResizableFromStart: false,

				color: "#60A9FF"
			};

			return parsedEvent;
		});

		return parsedEvents;
	},

	/**
	 * Parses our DemandHub Synced Appointments into something readable for the FullCalendar Library
	 *
	 * @param {Array<SyncedAppointment>} syncedAppointments
	 * @returns {Array<FullCalendarEvent>}
	 */
	parseSyncedAppointmentsForFullCalendar({ syncedAppointments }) {
		let parsedEvents = [];

		parsedEvents = syncedAppointments.map(appointment => {
			let startDate = moment(appointment.booking_at);
			let endDate = moment(appointment.booking_at).add(30, "minutes");

			let parsedEvent = {
				type: CALENDAR_EVENT_TYPES.syncedAppointments.value,
				internalId: appointment.id,
				id: UtilityService.uuid(),
				// groupId: appointment.public_id,
				allDay: false,
				title: `${appointment.first_name} ${appointment.last_name}`,
				start: startDate.toDate(),
				end: endDate.toDate(),

				editable: false,
				eventStartEditable: false,
				eventResizableFromStart: false,

				color: "#60A9FF"
			};

			return parsedEvent;
		});

		return parsedEvents;
	},

	/**
	 * Retrieves a list of Calendar event froms a iCal Link
	 *
	 * @param {String} url
	 * @returns {Array<CalendarEvent>}
	 */
	async getICalendarData({ url }) {
		let response = await fetch(url);
		let data = await response.text();
		let output = ical2json.convert(data);

		let events = output.VCALENDAR[0].VEVENT;
		let parsedEvents = [];

		parsedEvents = events.map(event => {
			let parsedEvent = {
				type: CALENDAR_EVENT_TYPES.external.value,
				internalId: event.UID,
				id: UtilityService.uuid(),
				// groupId: appointment.public_id,
				allDay: false,
				title: event.SUMMARY,
				start: event.DTSTART,
				end: event.DTEND,

				editable: false,
				eventStartEditable: false,
				eventResizableFromStart: false,

				color: "#60A9FF"
			};

			return parsedEvent;
		});

		return parsedEvents;
	},

	/**
	 * Checks to see if the current user is a guest of the provided calendarEvent, and if they are return the guest information
	 *
	 *
	 * @param {CalendarEvent} calendarEvent
	 * @returns {CalendarEventInvitee}
	 */
	getCurrentUserGuestSettings({ calendarEvent }) {
		let user = UserService.get();
		let guests = calendarEvent.Users;

		let userInvitee = guests.find(guest => guest.id === user.id);

		if (!userInvitee) {
			return null;
		}

		return userInvitee.CalendarEventInvitee;
	},

	/**
	 * Parses all the invitees for a calendarEvent to make the data consumable for AsyncSelect from the react-select library
	 *
	 * @param {CalendarEvent} calendarEvent
	 * @returns {Object}
	 */
	parseInvitees({ calendarEvent }) {
		let { Users: users, Contacts: contacts } = calendarEvent;

		let userInvitees = users.map(user => {
			return {
				label: `${i18n.t("User")}: ${user.first_name} ${user.last_name}`,
				value: user.id,
				type: "user"
			};
		});

		let contactInvitees = contacts.map(contact => {
			let mediumData = MessagesService.getMediumData(contact.preferred_medium, contact.phone, contact.email);

			return {
				label: `${i18n.t("Contact")}: ${contact.name} - ${mediumData}`,
				value: contact.id,
				type: "user"
			};
		});

		return { userInvitees, contactInvitees };
	},

	/**
	 * Indicates whether or not the current logged in user can author changes to a Calendar Event
	 *
	 * @param {Number} authorUserId
	 * @param {Array<CalendarEventInvitee>} userInvitees
	 * @returns {Boolean}
	 */
	canAuthor({ authorUserId, userInvitees }) {
		let currentUser = UserService.get();

		// If the user does not have the update_calendar_events permission enabled, then they should not be able to make any changes
		if (!currentUser.GroupPermission.update_calendar_events) {
			return false;
		}

		// If the user has update_all_calendar_events enabled, then they can should be allowed to make any changes
		if (currentUser.GroupPermission.update_all_calendar_events) {
			return true;
		}

		// This means that the current user is the original author of the event, they should be allowed to make changes to the event
		if (currentUser.id == authorUserId) {
			return true;
		}

		// If the current user is an invitee and they are an admin (organiser) for the current event they they should be allowed to make changes
		if (this.isCurrentUserInviteeAndAdmin({ userInvitees })) {
			return true;
		}

		return false;
	},

	/**
	 * Determines whether or not current user is an admin (organiser) for a list of invitees for a given calendar event
	 *
	 * @param {Array<CalendarEventInvitee>} userInvitees
	 * @returns {Boolean}
	 */
	isCurrentUserInviteeAndAdmin({ userInvitees }) {
		let currentUser = UserService.get();

		let user = userInvitees.find(invitee => {
			return invitee.value === currentUser.id && invitee.is_admin;
		});

		if (user) {
			return true;
		}

		return false;
	},

	/**
	 * Check if Calendar is enabled
	 * @returns {Boolean}
	 */
	isCalendarEnabled() {
		try {
			return LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.calendar.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	}
};

export default { ...LocalCalendarService, ...CalendarService };
