import React from "react";
import { withTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import { Redirect, withRouter } from "react-router-dom";
import * as Icon from "react-feather";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import LocationService from "../../services/LocationService";
import ToastService from "../../services/ToastService";
import MessagesService from "../../services/MessagesService";
import TemplateService from "../../services/TemplateService";

import DHCard from "../../components/common/DHCard";
import DHCardHeader from "../../components/common/DHCardHeader";
import Select from "../../components/common/DHSelect";
import UnsavedChanges from "../../components/common/UnsavedChanges";
import Media from "../MessengerBeta/Thread/Media/Media";
import withLocation from "../../components/common/WithLocation";
import TextArea from "../../components/common/TextArea";
import Action from "../../components/common/Action";
import RecordAudio from "../../components/common/RecordAudio";
import DHCardSubheader from "../../components/common/DHCardSubheader";

import { TEMPLATE_TYPE } from "../../constants/TemplateConstants";
import { MEDIUM, MODE } from "../../constants/Messenger";

import "../../scenes/Voicemail/voicemail.css";

const VOICE_TYPES = {
	standard: "Standard",
	enhanced: "Enhanced"
};

class Voice extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			changesMade: false,
			voiceConfig: null,
			voiceOptions: [],
			selectedVoice: null,
			voiceMedia: null,

			templateVariables: [],

			// Voice & Voicemail related message templates
			defaultMessage: null,
			leaveVoicemailMessage: null,
			voicemailResponse: null,
			missedCallResponse: null,

			madeVoicemailLocationChanges: false,
			madeMessageTemplateChanges: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchTemplateVariables();
		this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	resetComponent = async () => {
		this.fetchData();
	};

	fetchTemplateVariables = async () => {
		let templateVariables = await TemplateService.getMessageVariablesForType(TEMPLATE_TYPE.voiceDefaultMessage);
		this.update({ templateVariables });
	};

	fetchData = async () => {
		let { t } = this.props;

		this.update({
			loading: true
		});

		let config = await MessagesService.fetchVoicemailConfig();

		if (!config) {
			ToastService.error(t("Error fetching settings. Please try again."));
			this.update({
				loading: false
			});
			return;
		}

		let enhancedVoiceEnabled = LocationService.isVoiceEnhancedEnabled();

		let location = await LocationService.fetchLocation(UserService.getActiveLocation().id);
		let metaData = LocationService.getAndParseMetaData(location);

		let voiceSettings = metaData.voice_settings;
		let selectedVoice = null;
		let voices = config.voices;

		// Only show standard voices if the enhanced voice feature is not enabled
		if (!enhancedVoiceEnabled) {
			voices = voices.filter(vo => vo.voice_type === VOICE_TYPES.standard);
		}
		// Generate the voice options
		let voiceOptions = voices.map(v => {
			let voiceName = v.voice.replace("_enh", "");
			return {
				value: v.voice,
				label: `${voiceName}${v.voice_type === VOICE_TYPES.standard ? "" : t(" *Enhanced*")} (${v.gender}) - ${v.localeLabel}`,
				url: v.url
			};
		});

		if (voiceSettings) {
			selectedVoice = voiceSettings.voice;
		}

		await this.fetchTemplates();

		await this.update({
			loading: false,
			changesMade: false,
			selectedVoice,
			voiceConfig: config,
			voiceOptions,
			madeVoicemailLocationChanges: false,
			madeMessageTemplateChanges: false
		});

		await this.fetchSampleAudio();
	};

	fetchTemplates = async () => {
		let locationId = UserService.getActiveLocation().id;

		let templates = await TemplateService.fetchTemplates({
			locationId,
			types: [
				TEMPLATE_TYPE.voiceDefaultMessage,
				TEMPLATE_TYPE.voiceLeaveVoicemail,
				TEMPLATE_TYPE.voiceVoicemailResponse,
				TEMPLATE_TYPE.voiceMissedCallResponse
			],
			includeMedia: true
		});

		let defaultMessage = templates.find(t => t.type === TEMPLATE_TYPE.voiceDefaultMessage);
		let leaveVoicemailMessage = templates.find(t => t.type === TEMPLATE_TYPE.voiceLeaveVoicemail);
		let voicemailResponse = templates.find(t => t.type === TEMPLATE_TYPE.voiceVoicemailResponse);
		let missedCallResponse = templates.find(t => t.type === TEMPLATE_TYPE.voiceMissedCallResponse);

		await this.update({
			defaultMessage,
			leaveVoicemailMessage,
			voicemailResponse,
			missedCallResponse
		});
	};

	fetchSampleAudio = async () => {
		try {
			let { selectedVoice } = this.state;

			let localMedia = MessagesService.storeLocalMedia({
				file: {
					name: "sample.mp3",
					type: "audio/mpeg"
				},
				downloadUrl: selectedVoice.url
			});

			this.update({
				voiceMedia: localMedia
			});
		} catch (error) {
			console.log(error);
			ToastService.error(t("Failed to load voice sample."));
		}
	};

	onSelectedVoiceChange = async selectedVoice => {
		await this.update({ voiceMedia: null });
		await this.update({ selectedVoice: selectedVoice, changesMade: true });
		await this.fetchSampleAudio();
	};

	onCancelChanges = () => {
		this.resetComponent();
	};

	onRecordingFinished = ({ name, template, media }) => {
		template.Media = [media];

		this.update({
			[name]: template
		});
	};

	isTemplateValid(template, name) {
		let { t } = this.props;

		if (template.Media && template.Media.length > 0) {
			let media = template.Media[0];
			if (!media) {
				ToastService.error(t(`"${name}" needs a recording.`));
				return false;
			}
		} else if (!template.msg_text) {
			ToastService.error(t(`"${name}" requires text.`));
			return false;
		}

		return true;
	}

	areTemplatesValid = () => {
		let { leaveVoicemailMessage, voicemailResponse, missedCallResponse } = this.state;
		let { t } = this.props;

		let isValid = this.isTemplateValid(leaveVoicemailMessage, t("Leave a Voicemail Message"));

		if (!isValid) {
			return false;
		}

		isValid = this.isTemplateValid(voicemailResponse, t("Voicemail Left Response"));

		if (!isValid) {
			return false;
		}

		isValid = this.isTemplateValid(missedCallResponse, t("Missed Call Response"));

		if (!isValid) {
			return false;
		}

		return true;
	};

	uploadMedia = async ({ name, template }) => {
		let { t } = this.props;

		if (!template.Media || (template.Media.length < 1 && !template.Media[0])) {
			return;
		}

		let media = template.Media[0];

		let localMedia = MessagesService.getLocalMedia(media.id);

		// If the template media is not local media (already has been uploaded)
		if (!localMedia) {
			return;
		}

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let uploadedMedia = await MessagesService.uploadLocalMedia({
			mediaIds: [localMedia.id],
			mode: MODE.customer,
			medium: MEDIUM.sms.key,
			limit: 1,
			companyId,
			locationId
		});

		if (!uploadedMedia) {
			ToastService.error(t("Error uploading media."));
			return;
		}

		template.Media = uploadedMedia;

		await this.update({ [name]: template });
	};

	uploadTemplateMedia = async () => {
		let { defaultMessage, leaveVoicemailMessage, voicemailResponse } = this.state;

		if (defaultMessage) {
			await this.uploadMedia({ name: "defaultMessage", template: defaultMessage });
		}
		if (leaveVoicemailMessage) {
			await this.uploadMedia({ name: "leaveVoicemailMessage", template: leaveVoicemailMessage });
		}
		if (voicemailResponse) {
			await this.uploadMedia({ name: "voicemailResponse", template: voicemailResponse });
		}
	};

	saveTemplates = async () => {
		let { leaveVoicemailMessage, voicemailResponse, defaultMessage, missedCallResponse } = this.state;

		let saveSuccess = false;

		if (voicemailResponse) {
			saveSuccess = await TemplateService.update({
				templateId: voicemailResponse.id,
				text: voicemailResponse.msg_text,
				mediaIds: voicemailResponse.Media.map(m => m.id)
			});
		}

		if (leaveVoicemailMessage) {
			saveSuccess = await TemplateService.update({
				templateId: leaveVoicemailMessage.id,
				text: leaveVoicemailMessage.msg_text,
				mediaIds: leaveVoicemailMessage.Media.map(m => m.id)
			});
		}

		if (defaultMessage) {
			saveSuccess = await TemplateService.update({
				templateId: defaultMessage.id,
				text: defaultMessage.msg_text,
				mediaIds: defaultMessage.Media.map(m => m.id)
			});
		}

		if (missedCallResponse) {
			saveSuccess = await TemplateService.update({
				templateId: missedCallResponse.id,
				text: missedCallResponse.msg_text
			});
		}
	};

	onSave = async () => {
		let { selectedVoice, madeVoicemailLocationChanges, madeMessageTemplateChanges } = this.state;
		let { t } = this.props;

		let isValid = this.areTemplatesValid();

		if (!isValid) {
			return;
		}

		this.update({
			loading: true
		});

		if (madeMessageTemplateChanges) {
			// Upload local media if needed
			await this.uploadTemplateMedia();

			// Save the message templates
			await this.saveTemplates();
		}

		let success = true;
		if (madeVoicemailLocationChanges) {
			const locationData = {};
			let location = UserService.getActiveLocation();
			locationData.locationId = location.id;
			locationData.company = UserService.getActiveCompany().id;
			locationData.handle = location.handle;
			locationData.voice_settings = {
				voice: selectedVoice
			};

			success = await LocationService.update(locationData);
		}

		this.update({
			loading: false,
			changesMade: false
		});

		if (!success) {
			ToastService.error(t("Error updating voice settings. Please try again."));
			return;
		}

		ToastService.info(t("Saved voice settings."));
		MessagesService.clearLocalMedia();

		this.resetComponent();
	};

	onTemplateTextChange = (template, event) => {
		let name = event.target.name;
		let value = event.target.value;

		template.msg_text = value;

		this.update({
			[name]: template,
			madeMessageTemplateChanges: true,
			changesMade: true
		});
	};

	onToggleUseCustomText = ({ name, template, hasMedia }) => {
		let media = template.Media && template.Media.length > 0 ? template.Media[0] : false;

		// If we want to create a recording
		if (media === false) {
			template.Media = [];
			template.Media.push(null);
			// If we want use a text response
		} else {
			template.Media = [];
		}
		this.update({
			[name]: template,
			madeMessageTemplateChanges: true,
			changesMade: true
		});
	};

	hasChangeVoicePermission = () => {
		const user = UserService.get();
		let updateLocation = user.GroupPermission.update_locations;
		return updateLocation;
	};

	hasChangeTemplatesPermission = () => {
		const user = UserService.get();
		let updateVoicemail = user.GroupPermission.update_voicemail;
		let updateTemplates = user.GroupPermission.update_templates;
		return updateVoicemail && updateTemplates;
	};

	renderRecording = ({ template, name }) => {
		let { t } = this.props;

		return (
			<RecordAudio
				recordText={t("Record your own response for a customer.")}
				recordingId={template.type}
				onRecordingFinished={media => this.onRecordingFinished({ name, template, media })}
			/>
		);
	};

	renderTemplateMessage = ({ template, name, header, subHeader, canRecord = true }) => {
		let { loading, templateVariables } = this.state;
		let { t } = this.props;

		if (loading || !template) {
			return (
				<DHCard>
					<DHCardHeader>
						<div className="voicemail-settings__cards__card__header">
							<div>{header}</div>
						</div>
					</DHCardHeader>
					{subHeader && (
						<DHCardSubheader>
							<div>{subHeader}</div>
						</DHCardSubheader>
					)}

					<ContentLoader height={"120"} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="120" />
					</ContentLoader>
				</DHCard>
			);
		}

		const user = UserService.get();
		let isReadOnly = !this.hasChangeTemplatesPermission();
		let hasMedia = template.Media && template.Media.length > 0;
		let media = hasMedia ? template.Media[0] : null;

		return (
			<DHCard>
				<DHCardHeader>
					<div className="voicemail-settings__cards__card__header">
						<div>{header}</div>
						{canRecord && (
							<div className="dh-card__actions">
								{hasMedia && (
									<Action
										id={`custom-${template.id}`}
										label={t("Custom Text Response")}
										icon={Icon.Type}
										onClick={() => this.onToggleUseCustomText({ name, template, hasMedia })}
										disabled={isReadOnly}
									/>
								)}
								{!hasMedia && (
									<Action
										id={`custom-${template.id}`}
										label={t("Custom Audio Response")}
										icon={Icon.Volume2}
										onClick={() => this.onToggleUseCustomText({ name, template, hasMedia })}
										disabled={isReadOnly}
									/>
								)}
								{hasMedia && media !== null && (
									<Action
										id={`delete-audio-${template.id}`}
										label={t("Remove audio")}
										icon={Icon.Trash}
										onClick={() => {
											template.Media = [];
											template.Media.push(null);
											this.update({
												[name]: template,
												changesMade: true
											});
										}}
										disabled={isReadOnly}
									/>
								)}
							</div>
						)}
					</div>
				</DHCardHeader>
				{subHeader && (
					<DHCardSubheader>
						<div>{subHeader}</div>
					</DHCardSubheader>
				)}
				{hasMedia ? (
					media === null ? (
						this.renderRecording({ template, name })
					) : (
						<div className="voicemail-settings__cards__audio">
							<Media media={media} idPrefix={`${template.type}-recording`} onMediaClicked={url => {}} soundWaveHeight={48} barHeight={3} playButtonSize={28} />
						</div>
					)
				) : (
					<TextArea
						id={name}
						name={name}
						type="text"
						blueBorder={true}
						onChange={event => this.onTemplateTextChange(template, event)}
						value={template.msg_text}
						required
						height={130}
						rows={5}
						style={{ resize: "none" }}
						showFeedbackFaces={true}
						showFeedbackLength={true}
						showScrollbar={false}
						placeholder={t("Message text...")}
						showVariables={templateVariables.length > 0 ? true : false}
						showCustomFields={true}
						varDropdownVisibleY={-15}
						varDropdownVisibleX={-20}
						varDropdownVisibleZ={0}
						variables={templateVariables}
						showMessageTemplates={false}
						disabled={isReadOnly}
					/>
				)}
			</DHCard>
		);
	};

	renderVoiceSettings = () => {
		let { loading, voiceOptions, selectedVoice, voiceMedia, changesMade } = this.state;
		let { t } = this.props;

		const user = UserService.get();
		let isReadOnly = !this.hasChangeVoicePermission();

		if (loading) {
			return (
				<DHCard>
					<DHCardHeader>{t("Phone Voice")}</DHCardHeader>
					<DHCardSubheader>
						<div>{t("Voice to use for a text based response.")}</div>
					</DHCardSubheader>

					<ContentLoader height={"160"} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="80" rx="5" ry="5" width="100%" height="80" />
					</ContentLoader>
				</DHCard>
			);
		}

		return (
			<DHCard>
				<DHCardHeader>{t("Phone Voice")}</DHCardHeader>
				<DHCardSubheader>
					<div>{t("Voice to use for a text based response.")}</div>
				</DHCardSubheader>
				<Select
					label={t("Voice")}
					className={"voicemail-settings__cards__select"}
					value={selectedVoice}
					placeholder={t("Select Voice")}
					options={voiceOptions}
					onChange={this.onSelectedVoiceChange}
					required={true}
					disabled={isReadOnly}
				/>

				{voiceMedia && (
					<>
						<div className="input__label">{t("Audio Sample")}</div>
						<div className="voicemail-settings__cards__audio">
							<Media media={voiceMedia} idPrefix={`${selectedVoice.value}-voice-sample`} onMediaClicked={url => {}} soundWaveHeight={40} playButtonSize={28} />
						</div>
					</>
				)}
			</DHCard>
		);
	};

	render() {
		let { loading, changesMade, defaultMessage, leaveVoicemailMessage, voicemailResponse, missedCallResponse } = this.state;
		let { t } = this.props;

		if (!LocationService.isVoiceEnabled()) {
			return <Redirect to="/settings" />;
		}

		let isVoicemailEnabled = LocationService.isVoicemailEnabled();
		let isCallForwardingEnabled = LocationService.isVoiceCallForwardingEnabled();
		let isMissedCallEnabled = LocationService.isVoiceMissedCallResponseEnabled();

		return (
			<Page>
				<Header title={t("Voice Settings")} />

				<div className="voicemail-settings">
					<div className="voicemail-settings__cards">
						{this.renderVoiceSettings()}
						{!isVoicemailEnabled &&
							!isCallForwardingEnabled &&
							this.renderTemplateMessage({
								name: "defaultMessage",
								template: defaultMessage,
								header: t("Call Response")
							})}
						{isVoicemailEnabled &&
							this.renderTemplateMessage({
								name: "leaveVoicemailMessage",
								template: leaveVoicemailMessage,
								header: t("Leave a Voicemail Message"),
								subHeader: t("The message before a contact leaves a voicemail.")
							})}
						{isVoicemailEnabled &&
							this.renderTemplateMessage({
								name: "voicemailResponse",
								template: voicemailResponse,
								header: t("Voicemail Left Response"),
								subHeader: t("The response after a contact has left a voicemail.")
							})}
						{/* Missed call reponse is text only */}
						{isMissedCallEnabled &&
							this.renderTemplateMessage({
								name: "missedCallResponse",
								template: missedCallResponse,
								header: t("Missed Call Text Response"),
								subHeader: t("Send a text message after a contact calls."),
								canRecord: false
							})}
					</div>
				</div>
				{changesMade && !loading && (
					<UnsavedChanges>
						<div className="unsaved-changes__buttons">
							<div id="save-location-features" className="mb-button" onClick={this.onSave}>
								{t("Save")}
							</div>
							<div className="mb-button mb-button--cancel" onClick={this.onCancelChanges}>
								{t("Cancel")}
							</div>
						</div>
					</UnsavedChanges>
				)}
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Voice)));
