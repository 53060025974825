import React, { Component } from "react";

import ChatbotService from "../../../services/ChatbotService";

import { KEYS } from "../../../constants/Messenger";

import "./ai-agents-playground.css";

class AIAgentPlayground extends Component {
	constructor(props) {
		super(props);

		this.state = {
			messages: [],
			input: "",
			typing: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onSendMessage = async () => {
		let { chatbotId } = this.props;
		let { input, messages } = this.state;

		if (!input.trim()) {
			return;
		}

		let newMessages = [...messages, { content: input, role: "user" }, { content: "", role: "assistant", typing: true }];

		await this.update({ messages: newMessages, input: "", typing: true });

		let response = await ChatbotService.testChatbot({ chatbotId, messages: newMessages });

		newMessages = newMessages.map(msg => {
			if (msg.typing) {
				return { content: response, role: "assistant" };
			}
			return msg;
		});

		await this.update({ messages: newMessages, typing: false });
	};

	onChangeInput = event => {
		this.update({
			input: event.target.value
		});
	};

	onKeyDown = event => {
		if (event.keyCode === KEYS.enter) {
			this.onSendMessage();
		}
	};

	renderMessage = (message, messageIndex) => {
		let blurbableContent = message.content;

		return blurbableContent.split("\n").map((blurb, index) => {
			let key = parseInt(`${messageIndex}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			return (
				<p className="chatbot__bubble__blurb" key={key}>
					{blurb}
				</p>
			);
		});
	};

	renderMessages = () => {
		let { messages } = this.state;

		return (
			<div className="chatbot__messages">
				{messages.map((message, index) => (
					<div key={index} className={`chatbot__message chatbot__message--${message.role}`}>
						<div className={`chatbot__bubble chatbot__bubble--${message.role}`}>
							{message.typing && (
								<div className="chatbot__typing-dots">
									<span></span>
									<span></span>
									<span></span>
								</div>
							)}
							{this.renderMessage(message, index)}
						</div>
					</div>
				))}
			</div>
		);
	};

	render() {
		let { input } = this.state;

		return (
			<div className="chatbot__container">
				{this.renderMessages()}
				<div className="chatbot__compose">
					<input value={input} onChange={this.onChangeInput} onKeyDown={this.onKeyDown} placeholder="Type a message..." className="chatbot__compose__input" />
					<button onClick={this.onSendMessage} className="chatbot__compose__button">
						Send
					</button>
				</div>
			</div>
		);
	}
}

export default AIAgentPlayground;
