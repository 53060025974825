import i18n from "../i18n/config";

export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const STATUS = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export const STATUS_FILTER = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	active: {
		id: "active",
		display: i18n.t("On"),
		order: 2
	},
	inactive: {
		id: "inactive",
		display: i18n.t("Off"),
		order: 3
	}
};

export const KEYWORDS_COLUMNS = {
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: false,
		sortField: "status",
		width: 1
	},
	words: {
		id: "words",
		value: i18n.t("Words"),
		sortable: false,
		sortField: "words",
		width: 2
	},
	response: {
		id: "response",
		value: i18n.t("Response"),
		sortable: false,
		sortField: "response",
		width: 5
	},
	action: {
		id: "action",
		value: i18n.t("Action"),
		sortable: false,
		sortField: "action",
		width: 1,
		notClickable: true
	}
};
