import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";
import ReactSwitch from "react-switch";
import AppConfig from "../../../../config/app/web-app.config.js";
import { withTranslation, Trans } from "react-i18next";

import { INBOX_RULE_ATTRIBUTES, INBOX_RULE_METADATA, INBOX_TYPES_OPTIONS, INBOX_USERS_HEADERS, INBOX_TABS } from "../../../../constants/InboxConstants";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../../constants/GAConstants";

import GAService from "../../../../services/GAService";
import InboxService from "../../../../services/InboxService";
import UserService from "../../../../services/UserService";
import MessagesService from "../../../../services/MessagesService";
import ToastService from "../../../../services/ToastService";
import SupportChatService from "../../../../services/SupportChatService";

import Modal from "../../../../components/common/DHModal";
import Tabs from "../../../../components/common/Tabs";
import Tab from "../../../../components/common/Tab";
import List from "../../../../components/common/List";
import Action from "../../../../components/common/Action";
import Alert from "../../../../components/common/Alert";

import DeleteInboxModal from "./DeleteInboxModal";

import "./edit-inbox-modal.css";

class EditInboxModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			loading: true,
			selectedTab: INBOX_TABS.details,
			scheduledMessageId: null,
			showConfirmAlert: false,
			showBlockedAlert: false,
			showConfirmDeleteAlert: false,

			inboxId: null,
			name: "",
			type: null,
			isPublic: false,
			isScheduleAware: false,
			autoAssignment: false,
			url: "",
			selectedUsers: [],
			rules: [],

			users: [],
			inboxes: [],
			metadata: {}
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchUsers();
		await this.fetchInbox();
		await this.fetchInboxes();
		await this.fetchMetadata();
	};

	async componentDidUpdate(prevProps) {
		let { show, inboxId } = this.props;

		if (prevProps.show !== show) {
			await this.update({
				show,
				inboxId
			});
			await this.resetComponent();
		}
	}

	fetchInbox = async () => {
		let { inboxId } = this.state;

		let inbox = await InboxService.fetchInbox(inboxId);

		if (!inbox) {
			return;
		}

		// Only show active users
		let selectedUsers = inbox.Users.map(u => {
			let fullName = UserService.createFullName({ firstName: u.first_name, lastName: u.last_name });
			return {
				id: u.id,
				user_id: u.id,
				name: `${fullName}`,
				email: u.email,
				auto_assignable: u.InboxUser.auto_assignable
			};
		});

		await this.update({
			name: inbox.name,
			type: inbox.type,
			rules: inbox.rules || [],
			isPublic: inbox.is_public,
			isScheduleAware: inbox.is_schedule_aware,
			autoAssignment: inbox.auto_assignment_enabled,
			url: inbox.url || "",
			selectedUsers
		});
	};

	fetchUsers = async () => {
		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let users = await MessagesService.fetchAssignableUsers(location.id, user.id);

		users = users.map(u => {
			let fullName = UserService.createFullName({ firstName: u.first_name, lastName: u.last_name });
			return {
				id: u.id,
				label: `${fullName}`,
				value: u.id,
				user_id: u.id,
				auto_assignable: false,
				email: u.email,
				name: `${fullName}`
			};
		});

		await this.update({
			users
		});
	};

	fetchInboxes = async () => {
		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let inboxes = await InboxService.fetchInboxes(location.id, user.id);

		inboxes = inboxes.map(inbox => {
			return {
				value: inbox.id,
				label: inbox.name
			};
		});

		await this.update({
			inboxes
		});
	};

	fetchMetadata = async () => {
		let metadata = await InboxService.fetchMetadata();

		await this.update({
			metadata
		});
	};

	onConfirmSave = () => {
		this.update({
			showConfirmAlert: true
		});
	};

	onConfirmDelete = () => {
		this.update({
			showConfirmDeleteAlert: true
		});
	};

	onConfirmDeleteClosed = deleted => {
		this.setState({ showConfirmDeleteAlert: false });
		if (this.props.onClose) {
			this.props.onClose(deleted);
		}
	};

	onConfirmAlertClose = confirm => {
		this.update({
			showConfirmAlert: false
		});

		if (confirm) {
			this.onSave();
		}
	};

	onSave = async () => {
		let { inboxId, name, autoAssignment, isPublic, isScheduleAware, type, selectedUsers, rules, url } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		if (!user.GroupPermission.update_inboxes) {
			ToastService.info(t("Invalid Permissions."));
			return;
		}

		let areRulesValid = await this.areRulesValid();

		if (!areRulesValid) {
			ToastService.info(t("Failed to update inbox."));
			return;
		}

		let inboxDetails = {
			inboxId: inboxId,
			inboxUsers: selectedUsers,
			name,
			type,
			isPublic,
			isScheduleAware,
			autoAssignment,
			rules,
			url
		};

		let success = await InboxService.updateInbox(inboxDetails);

		if (!success) {
			ToastService.info(t("Failed to update inbox."));
			return;
		}

		ToastService.info(t("Inbox Updated!"));

		// Set the Modal back to the details view before closing
		await this.update({
			selectedTab: INBOX_TABS.details
		});

		if (this.props.onClose) {
			this.props.onClose(true);
		}
	};

	onTabSelect = tab => {
		this.update({ selectedTab: tab.id });

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.sections.editInbox,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
	};

	isValid = () => {
		return true;
	};

	areRulesValid = async () => {
		let { rules, metadata } = this.state;
		let { t } = this.props;

		let isValid = true;

		rules = JSON.parse(JSON.stringify(rules));

		for (let rule of rules) {
			// Check to make sure the rule has a proper delay set
			if (rule.delay <= 0 || rule.delay > 120) {
				isValid = false;
				rule.error = t("Invalid time set for rule.");
				break;
			}

			// Check to make sure an action is present on the rule
			if (!rule.action) {
				rule.error = t("This rule is missing an action.");
				isValid = false;
				break;
			}

			let mActionData = metadata.actions[rule.action];

			// Check to make sure that if the action for the rule requires additional data, that it is present on the rule
			if (mActionData.needs.length > 0 && rule.metadata === null) {
				rule.error = t("Some data is missing for this rule.");
				isValid = false;
				break;
			}
		}

		await this.update({
			rules
		});

		return isValid;
	};

	onAddNewRule = () => {
		let { rules } = this.state;

		rules.push({
			category: null,
			action: null,
			delay: 0,
			is_repeated: false,
			metadata: null,
			error: false
		});

		this.update({
			rules
		});
	};

	updateRule = async (index, key, value) => {
		let { rules } = this.state;

		// Clone the array
		rules = rules.slice();

		// Clone the rule at the index
		let rule = Object.assign({}, rules[index]);

		// XXX - Very gunda
		if (key === "category" || key === "action") {
			rule.action = null;
			rule.metadata = null;
			rule.is_repeated = false;
		}

		// Update the newly cloned rule
		rule[key] = value;

		// Update the index with the newly cloned object at the respective index
		rules[index] = rule;

		// Update the component with the newly modified rule
		await this.update({
			rules
		});
	};

	deleteRule = async index => {
		let { rules } = this.state;

		// Clone the array
		rules = rules.slice();

		// Remove item from the array
		rules.splice(index, 1);

		// Update the component with the newly modified rule
		await this.update({
			rules
		});
	};

	onChange = async (key, value) => {
		await this.update({
			[key]: value
		});
	};

	renderDetails() {
		let { inboxId, name, type, isPublic, isScheduleAware, url } = this.state;
		let { t } = this.props;

		let location = UserService.getActiveLocation();

		let selectedType = INBOX_TYPES_OPTIONS.find(t => {
			return type === t.value;
		});

		return (
			<div className="uim__details">
				{UserService.isSuperAdminOrCustomerSuccess() && (
					<>
						<div className="uim__label">{t("ID")}</div>
						<input className="uim__input uim__input--wide fnctst-inbox-id" value={inboxId} disabled={true} />
					</>
				)}
				<div className="uim__label">{t("Name")}</div>
				<input className="uim__input uim__input--wide fnctst-inbox-name" value={name} onChange={event => this.update({ name: event.target.value })} />
				<div className="uim__label">{t("Type")}</div>
				<Select
					className="uim__input--wide fnctst-inbox-type"
					value={selectedType}
					placeholder={t("Select a type ...")}
					isSearchable
					options={INBOX_TYPES_OPTIONS}
					onChange={nType => this.update({ type: nType.value })}
				/>
				<div className="uim__label ">{t("Expose this Inbox")}</div>
				<ReactSwitch
					className="fnctst-inbox-expose"
					height={22}
					width={38}
					checked={isPublic}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#60A9FF"
					offColor="#c5c5c5"
					onChange={checked => this.update({ isPublic: checked })}
				/>
				<div className="uim__label">URL</div>
				<input
					className="uim__input uim__input--wide fnctst-inbox-url"
					placeholder={`eg. ${location.website}`}
					value={url}
					onChange={event => this.update({ url: event.target.value })}
				/>
				<div className="uim__label ">{t("Disable Round Robin After Business Hours")}</div>
				<ReactSwitch
					className="fnctst-inbox-is-schedule-aware"
					height={22}
					width={38}
					checked={isScheduleAware}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#60A9FF"
					offColor="#c5c5c5"
					onChange={checked => this.update({ isScheduleAware: checked })}
				/>
			</div>
		);
	}

	onUserAvailableClicked = async user => {
		let { selectedUsers } = this.state;

		// Find index of the user that is being updated
		let index = selectedUsers.findIndex(su => {
			return su.user_id === user.user_id;
		});

		// Clone the array
		selectedUsers = selectedUsers.slice();

		// Clone the rule at the index
		let updatedUser = Object.assign({}, selectedUsers[index]);

		updatedUser.auto_assignable = !updatedUser.auto_assignable;

		// Update the index with the newly cloned object at the respective index
		selectedUsers[index] = updatedUser;

		// Update the component with the newly modified rule
		await this.update({
			selectedUsers
		});
	};

	onUserRemoved = async user => {
		let { selectedUsers } = this.state;

		// Find index of the user that is being updated
		let index = selectedUsers.findIndex(su => {
			return su.user_id === user.user_id;
		});

		// Clone the array
		selectedUsers = selectedUsers.slice();

		selectedUsers.splice(index, 1);

		// Update the component with the newly modified rule
		await this.update({
			selectedUsers
		});
	};

	onUserAdded = async item => {
		let { selectedUsers } = this.state;

		let exists = selectedUsers.find(u => {
			return u.user_id === item.user_id;
		});

		if (exists) {
			return;
		}

		selectedUsers.unshift(item);

		await this.update({
			selectedUsers
		});
	};

	onRoundRobinToggle = async checked => {
		let isRoundRobinEnabled = InboxService.isAutoAssignmentLocationFeatureEnabled();

		if (!isRoundRobinEnabled) {
			await this.update({
				showBlockedAlert: true
			});
			return;
		}

		await this.update({
			autoAssignment: checked
		});
	};

	onBlockedFeatureAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showBlockedAlert: false
		});
	};

	onClose = async () => {
		// Set the modal back to the details view before closing
		await this.update({
			selectedTab: INBOX_TABS.details
		});

		if (this.props.onClose) {
			this.props.onClose(false);
		}
	};

	renderUserRecord = user => {
		let { t } = this.props;

		return [
			user.name,
			user.email,
			<div className="uim__user__checkbox">
				<ReactSwitch
					className="fnctst-view-only-switch"
					height={22}
					width={38}
					checked={!user.auto_assignable}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#60A9FF"
					offColor="#c5c5c5"
					onChange={() => this.onUserAvailableClicked(user)}
				/>
			</div>,
			<Action label={t("Remove User")} icon={Icon.MinusCircle} transparent onClick={() => this.onUserRemoved(user)} />
		];
	};

	renderUsers() {
		let { users, selectedUsers } = this.state;
		let { t } = this.props;

		return (
			<div className="uim__users">
				<Select
					className="uim__input--wide fnctst-user-selection"
					placeholder={t("Search for users to add to this inbox ...")}
					isSearchable
					options={users}
					onChange={this.onUserAdded}
				/>

				<div className="uim__rules__spacer" />
				{selectedUsers.length > 0 && (
					<List
						items={selectedUsers}
						loading={false}
						loadedAll={true}
						headers={{ items: INBOX_USERS_HEADERS }}
						renderRecord={this.renderUserRecord}
						noDataTitle={t("Looks like there are no users assigned to this inbox yet...")}
						noDataIcon={<Icon.AlertCircle />}
					/>
				)}
				{selectedUsers.length === 0 && (
					<div className="uim__users__empty">
						<img alt="No Users" className="uim__users__empty__img" src={`${AppConfig.CDN_URL}web-app/assets/no_users.svg`} />
						<div>{t("You don't have any users assigned to this inbox.")}</div>
					</div>
				)}
			</div>
		);
	}

	renderRules() {
		let { rules, autoAssignment } = this.state;
		let { t } = this.props;

		return (
			<div className="uim__rules">
				<div className="uim__rules__top">
					<div className="uim__rules__top__text">
						<div className="uim__rules__top__title">{t("Round Robin")}</div>
						<div className="uim__rules__top__subtitle">
							{t("Assign new inbound messages to team members in a round robin pool to distribute conversations evenly.")}
						</div>
					</div>
					<div className="uim__rules__top__toggle">
						<ReactSwitch
							className="fnctst-round-robin-switch"
							height={22}
							width={38}
							checked={autoAssignment}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={checked => this.onRoundRobinToggle(checked)}
						/>
					</div>
				</div>
				<div className="uim__rules__spacer" />
				<div className="uim__rules__actions">
					{t("Choose how to handle unanswered messages (first-time only).")}
					<div className="mb-button uim__rules__add-button" onClick={this.onAddNewRule}>
						{t("Add new rule")}
					</div>
				</div>

				{rules.length === 0 && (
					<div className="uim__rules__no-rules">
						<img alt="No inbox rules" className="uim__rules__no-rules__img" src={`${AppConfig.CDN_URL}web-app/assets/no_inbox_rules.svg`} />
						<div>{t("You don't have any round robin rules setup for this inbox.")}</div>
					</div>
				)}

				{rules.map((r, i) => {
					return this.renderRule(r, i);
				})}
			</div>
		);
	}

	renderRule(rule, index) {
		let { t } = this.props;

		return (
			<div className="uim__rule" key={index}>
				<div className="uim__rule__top">
					<div className="uim__rule__top__text">{t("If message hasn't been answered after")} </div>
					<input
						className="uim__input uim__input--small fnctst-rule-mins"
						value={rule.delay}
						onChange={event => this.updateRule(index, INBOX_RULE_ATTRIBUTES.delay, event.target.value)}
					/>
					<div className="uim__rule__top__text">{t("minutes.")}</div>
					<div className="uim__rule__top__delete" onClick={() => this.deleteRule(index)}>
						<Icon.Trash2 size={16} />
					</div>
				</div>
				{rule.error && <div className="uim__rule__error">{rule.error}</div>}
				{rule.category === null && this.renderCategories(rule, index)}
				{rule.category && this.renderSelectedCategory(rule.category, index)}
			</div>
		);
	}

	renderSelectedCategory(mCategory, index) {
		let { metadata } = this.state;

		let mCategoryData = metadata.categories[mCategory];
		let mActions = mCategoryData.actions;

		return (
			<div className="uim__rule__category__detail">
				<div className="uim__rule__category__detail__top">
					{mCategoryData.description}
					<div className="uim__rule__category__detail__top__close" onClick={() => this.updateRule(index, INBOX_RULE_ATTRIBUTES.category, null)}>
						<Icon.XCircle size={16} />
					</div>
				</div>
				<div className="uim__rule__category__detail__body">
					{mActions.map(mA => {
						return this.renderAction(mA, index);
					})}
				</div>
			</div>
		);
	}

	renderAction(mAction, index) {
		let { metadata, rules } = this.state;
		let { t } = this.props;

		let mActionData = metadata.actions[mAction];

		let rule = rules[index];

		let isSelected = rule.action === mActionData.id;
		let requiresMetadata = mActionData.needs.length > 0;

		// Currently we only support 1 metdata item
		let mNeed = null;
		let needValue = null;

		try {
			mNeed = mActionData.needs[0];
			needValue = rule.metadata[mNeed];
		} catch (error) {}

		return (
			<div key={`${mAction}-${index}`} className="uim__rule__action">
				<div className="uim__rule__action__top">
					<div className="uim__rule__action__top__radio" onClick={() => this.updateRule(index, INBOX_RULE_ATTRIBUTES.action, mAction)}>
						<div className={`uim__rule__action__top__radio__button ${rule.action === mActionData.id ? "uim__rule__action__top__radio__button--active" : ""}`} />
						{mActionData.description}
					</div>

					{isSelected && requiresMetadata && this.renderMetadataSelector(mNeed, index, needValue)}
				</div>
				{isSelected && mActionData.isRepeatable && (
					<div className="uim__rule__action__repeat">
						<div className="uim__rule__action__repeat__text">
							<div className="uim__rule__action__repeat__text__title">{t("Should this rule repeat?")}</div>
							<div className="uim__rule__action__repeat__text__subtitle">
								{t("This rule will continue to repeat until the message has been responded to or it meets the criteria of another rule.")}
							</div>
						</div>

						<div className="uim__rule__action__repeat__toggle">
							<ReactSwitch
								className="fnctst-repeat-rule-switch"
								height={22}
								width={38}
								checked={rule.is_repeated}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#60A9FF"
								offColor="#c5c5c5"
								onChange={checked => this.updateRule(index, INBOX_RULE_ATTRIBUTES.isRepeated, checked)}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}

	renderCategories(rule, index) {
		let { metadata } = this.state;

		return Object.keys(metadata.categories).map((k, i) => {
			let c = metadata.categories[k];
			return (
				<div key={i} className="uim__rule__category" onClick={() => this.updateRule(index, INBOX_RULE_ATTRIBUTES.category, k)}>
					{c.description}
				</div>
			);
		});
	}

	renderMetadataSelector(mNeed, index, defaultValue = null) {
		let { users, inboxes } = this.state;
		let { t } = this.props;

		if (mNeed === INBOX_RULE_METADATA.user) {
			let selected = users.find(u => {
				return defaultValue === u.value;
			});

			return (
				<Select
					className="uim__rule__action__top__select fnctst-rule-user-selection"
					placeholder={t("Select a user ...")}
					value={selected}
					isSearchable
					options={users}
					onChange={user => this.updateRule(index, INBOX_RULE_ATTRIBUTES.metadata, { userId: user.value })}
				/>
			);
		}
		// TODO - Need to build message forwarding from customer to team first
		// else if (mNeed === "conversationId") {
		// 	return (
		// 		<Select
		// 			className="uim__rule__action__top__select"
		// 			placeholder="Select a Teamchat ..."
		// 			value={defaultValue}
		// 			isSearchable
		// 			options={users}
		// 			onChange={user => this.updateRule(index, "metadata", { userId: user.value })}
		// 		/>
		// 	);
		// }
		else if (mNeed === INBOX_RULE_METADATA.inbox) {
			let selected = inboxes.find(i => {
				return defaultValue === i.value;
			});

			return (
				<Select
					className="uim__rule__action__top__select fnctst-rule-inbox-selection"
					placeholder={t("Select an inbox ...")}
					value={selected}
					isSearchable
					options={inboxes}
					onChange={inbox => this.updateRule(index, INBOX_RULE_ATTRIBUTES.metadata, { inboxId: inbox.value })}
				/>
			);
		}
	}

	render() {
		let { show, name, selectedTab, showConfirmAlert, showBlockedAlert, inboxId, showConfirmDeleteAlert } = this.state;
		let { t } = this.props;

		let title = t("{{inboxName}} Inbox", { inboxName: name });
		let user = UserService.get();

		return (
			<>
				<Modal show={show} onHide={this.onClose} title={title}>
					<div className="uim">
						<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
							<Tab id={INBOX_TABS.details} value={t("Details")} />
							<Tab id={INBOX_TABS.users} value={t("Users")} />
							<Tab id={INBOX_TABS.rules} value={t("Rules")} />
						</Tabs>
						{selectedTab === INBOX_TABS.details && this.renderDetails()}
						{selectedTab === INBOX_TABS.users && this.renderUsers()}

						{selectedTab === INBOX_TABS.rules && this.renderRules()}
						<div className="uim__actions">
							{user.GroupPermission.delete_inboxes && (
								<div className={`mb-button mb-button--cancel ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onConfirmDelete}>
									{t("Delete")}
								</div>
							)}

							<div className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onConfirmSave}>
								{t("Save")}
							</div>
						</div>
					</div>
				</Modal>
				<Alert type="warning" show={showConfirmAlert} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onConfirmAlertClose}>
					{t("Are you sure you would like to save changes for this inbox?")}
				</Alert>
				<Alert type="info" show={showBlockedAlert} title={t("Upgrade Subscription")} confirm={t("Chat with us")} onClose={this.onBlockedFeatureAlertClose}>
					{t("To enable Lead Management, please contact support@demandhub.co or chat with us.")}
				</Alert>
				<DeleteInboxModal show={showConfirmDeleteAlert} title={`Delete the Inbox?`} inboxId={inboxId} name={name} onHide={this.onConfirmDeleteClosed} />
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditInboxModal);
