import React from "react";
import * as Icon from "react-feather";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import ReengagementService from "../../../services/ReengagementService";
import UserService from "../../../services/UserService";

import Header from "../../../components/common/Header";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import ConversionSettingsModal from "./ConversionSettingsModal";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import ReengagementsList from "./ReengagementsList";
import ScheduleHours from "../ScheduleHours";

import { SCHEDULE } from "../../../constants/LocationConstants";

const TABS = {
	reengagements: "reengagements",
	schedule: "schedule"
};

class Reengagements extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConversionSettingsModal: false,
			selectedReengagementId: null,
			selectedTab: TABS.reengagements
		};

		this.reengagementList = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onShowManageModal = async () => {
		if (this.reengagementList && this.reengagementList.wrappedComponentRef) {
			this.reengagementList.wrappedComponentRef.current.onShowManageModal();
		}
	};

	onOpenConversionSettingsModal = async id => {
		await this.update({ selectedReengagementId: id, showConversionSettingsModal: true });
	};

	onHideConversionSettingsModal = async () => {
		await this.update({ selectedReengagementId: null, showConversionSettingsModal: false });
	};

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
	};

	renderHeader = () => {
		let { selectedTab } = this.state;
		let user = UserService.get();
		let { t } = this.props;

		if (selectedTab === TABS.reengagements) {
			return (
				<Header title="Reengagements">
					{user.GroupPermission.update_locations && (
						<Action id="settings" label={t("Reengagement Settings")} icon={Icon.Settings} onClick={() => this.onOpenConversionSettingsModal()} />
					)}
					{user.GroupPermission.create_reengagements && (
						<Action id="create" label={t("Create Reengagement")} icon={Icon.Plus} onClick={() => this.onShowManageModal()} />
					)}
				</Header>
			);
		}

		return (
			<Header title="Reengagements">
				{user.GroupPermission.update_locations && (
					<Action id="settings" label={t("Reengagement Settings")} icon={Icon.Settings} onClick={() => this.onOpenReengagementSettings()} />
				)}
			</Header>
		);
	};

	render = () => {
		const { selectedTab, showConversionSettingsModal, selectedReengagementId } = this.state;
		let { t } = this.props;

		if (!ReengagementService.canViewReengagements()) {
			return <Redirect to="/settings" />;
		}

		return (
			<Page>
				{this.renderHeader()}

				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={TABS.reengagements} value={t("Reengagements")} />
					<Tab id={TABS.schedule} value={t("Schedule")} />
				</Tabs>

				{selectedTab === TABS.reengagements && <ReengagementsList ref={ref => (this.reengagementList = ref)} />}
				{selectedTab === TABS.schedule && <ScheduleHours type={SCHEDULE.types.reengagementSendTime} />}

				<ConversionSettingsModal show={showConversionSettingsModal} onHide={this.onHideConversionSettingsModal} id={selectedReengagementId} />
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { withRef: true })(Reengagements));
