import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";
import Action from "../../components/common/Action";
import DashboardCard from "./DashboardCard";
import EditContactModal from "../../components/common/EditContactModal";
import List from "../../components/common/List";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";
import { SORT_ORDER, STATUS } from "../../constants/CommonConstants";
import { CONVERSION_APPOINTMENTS_COLUMNS } from "../../constants/Appointments";

import "../../App.css";
import "../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class ConversionAppointments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			appointments: [],
			appointmentsCsv: [],
			conversions: [],
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.add(45, "days")
				.endOf("day")
				.toDate()
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	onLocationChanged = async location => {
		await this.update({
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate()
		});
		this.resetComponent();
	};

	resetComponent = async () => {
		await this.fetchData();
	};

	fetchData = async () => {
		let { start, end } = this.state;
		await this.update({ loading: true });
		let result = await AnalyticsService.getConversionsAppointments({
			locationId: UserService.getActiveLocation().id,
			start,
			end
		});

		if (!result) {
			await this.update({ loading: false });
			return;
		}

		let appointmentsCsv = result.map(aData => {
			return {
				Name: UserService.createFullName({ firstName: aData.first_name, lastName: aData.last_name }),
				Phone: aData.phone,
				Email: aData.email,
				"Booked At": aData.booking_at,
				State: STATUS[aData.state] ? STATUS[aData.state].display : aData.state,
				Lead: aData.lead_source
			};
		});

		await this.update({ appointments: result, appointmentsCsv, loading: false });
	};

	onContactDetailsClosed = async contact => {
		await this.update({
			showEditContactModal: false
		});
	};

	onContactDetailsSaved = async contact => {
		await this.update({
			showEditContactModal: false
		});
		await this.fetchData();
	};

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};
	handleToChange = end => {
		this.update({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.resetComponent();
	}

	renderHeader = () => {
		const { start, end, appointmentsCsv } = this.state;
		const { cardName } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">
						{cardName ? cardName : "Conversion Appointments"} <Icon.Info size={20} data-tip data-for="dashboard-widget-conversion-appointments" />
					</div>

					<ReactTooltip
						id="dashboard-widget-conversion-appointments"
						className="mb-react-tooltip mb-react-tooltip--medium"
						arrowColor="#333"
						type="info"
						effect="solid"
						place="right"
					>
						Shows data for the specified date range. An appointment scheduled within 45 days of a reengagement message is considered a reengagement conversion.
					</ReactTooltip>

					<div className="dashboard__card__header__actions">
						{appointmentsCsv && (
							<CSVLink
								data={appointmentsCsv}
								filename={`${locationName}-conversions-${moment(start).format("YYYY-MM-DD")}-${moment(end).format("YYYY-MM-DD")}_analytics.csv`}
								target="_self"
							>
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<div className="dashboard__card___datepicker">
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</button>
				</div>
			</>
		);
	};

	getHeaders() {
		let headers = {
			items: CONVERSION_APPOINTMENTS_COLUMNS,
			sortBy: () => {}
		};

		return headers;
	}

	onRecordClicked = () => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			if (!recordData) {
				return null;
			}
			var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

			return [
				fullName,
				STATUS[recordData.state] ? STATUS[recordData.state].display : recordData.state,
				recordData.booking_at ? moment(recordData.booking_at).format("MMM Do YYYY hh:mm a") : "",
				recordData.lead_source,
				recordData.contact_id ? (
					<Icon.MoreVertical
						size="22"
						style={{ cursor: "pointer", color: "hsla(230,8%,70%,1)" }}
						onClick={() => {
							this.update({
								showEditContactModal: true,
								showContactDetailsContactId: recordData.contact_id
							});
						}}
					/>
				) : null
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		let { appointments, appointmentsCsv, appointmentsLoading, start, end } = this.state;

		const locationName = CsvService.filterActiveLocationName();

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Conversions is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Widget data is restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<List
				items={appointments}
				loading={appointmentsLoading}
				loadedAll={true}
				sortField={"booking_at"}
				sortOrder={SORT_ORDER.desc}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={this.onRecordClicked}
				noDataTitle={"No appointments found..."}
				noDataIcon={<Icon.AlertCircle />}
			/>
		);
	};

	render() {
		let { showEditContactModal, showContactDetailsContactId } = this.state;

		return (
			<DashboardCard className={DASHBOARD_CARDS.conversionsBarChart.className} height={DASHBOARD_CARDS.conversionsBarChart.h}>
				{this.renderHeader()}

				{this.renderBody()}

				<EditContactModal
					show={showEditContactModal}
					contactId={showContactDetailsContactId}
					onClose={this.onContactDetailsClosed}
					onSave={this.onContactDetailsSaved}
				/>
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ConversionAppointments));
