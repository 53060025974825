import React from "react";
import * as Icon from "react-feather";
import "../../styles/css/components/commons/dh-input.css";

const Input = React.forwardRef(
	(
		{
			id,
			name,
			value,
			onChange,
			onFocus,
			onKeyDown,
			type = "text",
			disabled = false,
			// XXX Works better than "off" https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
			autoComplete = "new-password",
			required = false,
			placeholder,
			label,
			description,
			maxLength,
			invalid,
			accept,
			fileLabel,
			autoFocus,
			fullWidth = false,
			className,
			rows = 10
		},
		ref
	) => {
		return (
			<div className={`input ${fullWidth ? "input--full-width" : ""}`}>
				{label && (
					<>
						<div className="input__label">
							{label} {required && <span className="input__label__required">*</span>}
						</div>
					</>
				)}
				{description && <div className="input__description">{description}</div>}
				{type === "file" && (
					<label className={`input__file-input-btn ${disabled ? "input__file-input-btn--disabled" : ""}`}>
						<Icon.UploadCloud size={16} /> {fileLabel ? fileLabel : "Upload"}
						<input
							id={id}
							ref={ref}
							name={name}
							onChange={onChange}
							onFocus={onFocus}
							onKeyDown={onKeyDown}
							type={type}
							value={value}
							className={`input__input input__input--file ${invalid ? "input__input--invalid" : ""}`}
							disabled={disabled}
							autoComplete={autoComplete}
							required={required}
							placeholder={placeholder}
							maxLength={maxLength}
							accept={accept}
							autoFocus={autoFocus}
						/>
					</label>
				)}
				{type !== "textarea" && type !== "file" && (
					<input
						id={id}
						ref={ref}
						name={name}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={onKeyDown}
						type={type}
						value={value}
						className={`input__input ${invalid ? "input__input--invalid" : ""}`}
						disabled={disabled}
						autoComplete={autoComplete}
						required={required}
						placeholder={placeholder}
						maxLength={maxLength}
						autoFocus={autoFocus}
					/>
				)}

				{type === "textarea" && (
					<textarea
						id={id}
						ref={ref}
						name={name}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={onKeyDown}
						type={type}
						value={value}
						className={`input__input input__input--textarea ${invalid ? "input__input--invalid" : ""} ${className ? className : ""}`}
						disabled={disabled}
						autoComplete={autoComplete}
						required={required}
						placeholder={placeholder}
						style={{ resize: "none" }}
						rows={rows}
						maxLength={maxLength}
						autoFocus={autoFocus}
					/>
				)}
			</div>
		);
	}
);

export default Input;
