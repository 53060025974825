import React, { Component } from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import { withTranslation, Trans } from "react-i18next";

import MessagesService from "../../../../services/MessagesService";
import UserService from "../../../../services/UserService";
import ToastService from "../../../../services/ToastService";
import ContactService from "../../../../services/ContactService";

import Menu from "../../../../components/common/Menu";
import MenuOption from "../../../../components/common/MenuOption";
import Alert from "../../../../components/common/Alert";

import { DIRECTION, MEDIUM, MESSAGE_STATES, PROVIDER_ERRORS } from "../../../../constants/Messenger";
import NUMBERS from "../../../../constants/Numbers";

import "./message-error.css";

class MessageError extends Component {
	constructor(props) {
		super(props);

		this.state = {
			medium: null,
			showDetailsModal: false,
			showRetryViaEmailModal: false,
			contact: null
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onRetry = async viaEmail => {
		this.retryDebounce(viaEmail);
	};

	retryDebounce = _.debounce(
		viaEmail => {
			this.retrySend(viaEmail);
		},
		3000,
		{
			leading: true,
			trailing: false
		}
	);

	onRetryViaEmail = async () => {
		let { message } = this.props;

		let contact = await ContactService.getContact(message.contact_id);

		this.setState({ showRetryViaEmailModal: true, contact });
	};

	onCloseRetryViaEmailModal = async confirm => {
		let { contact } = this.state;

		if (!contact || !contact.email) {
			this.setState({ showRetryViaEmailModal: false, contact: null });
			return;
		}

		if (confirm) {
			await this.onRetry(true);
		}
		this.setState({ showRetryViaEmailModal: false, contact: null });
	};

	showRetryViaEmailOption = () => {
		let { message } = this.props;

		// Get the sms api provider type of the location
		let provider = message.sms_api;

		let providerErrorIndex = "";
		if (provider === NUMBERS.api.bandwidthV2) {
			providerErrorIndex = "BANDWIDTH_V2_ERRORS";
		} else if (provider === NUMBERS.api.bandwidth) {
			providerErrorIndex = "BANDWIDTH_ERRORS";
		} else if (provider === NUMBERS.api.twilio) {
			providerErrorIndex = "TWILIO_ERRORS";
		} else if (provider === NUMBERS.api.unifonic) {
			providerErrorIndex = "UNIFONIC_ERRORS";
		}

		// Get the object holding all error codes for all providers
		let errorsObject = MessagesService.getProviderMetaData().errors;
		// Get the metadata object holding the sms errors for this location
		let providerErrors = errorsObject[providerErrorIndex];

		let showOption = false;
		// Only for landline errors
		if (provider === NUMBERS.api.bandwidthV2 && providerErrors && PROVIDER_ERRORS.bandwidth.landline.includes(message.delivery_code)) {
			showOption = true;
		} else if (provider === NUMBERS.api.twilio && providerErrors && PROVIDER_ERRORS.twilio.sms_unsupported.includes(message.delivery_code)) {
			showOption = true;
		}

		// Only if the message is an sms message
		return showOption && message.medium === MEDIUM.sms.key;
	};

	retrySend = async viaEmail => {
		let { t } = this.props;

		let messageId = this.props.message.id;
		let toast = ToastService.info(t("Retrying message ..."));

		let updatedMessage = await MessagesService.retryMessageSend({
			messageId,
			viaEmail
		});

		if (!updatedMessage) {
			ToastService.update(toast, t("Retry failed."));
		} else {
			ToastService.update(toast, t("Message queued!"));
		}
	};

	renderEmailError() {
		let { message } = this.props;
		let { status_events: statusEvents } = message;
		let { t } = this.props;

		// Render nothing if there are no status events
		if (!statusEvents) {
			return null;
		}

		/**
		 * Anatomy of status_events:
		 * [
		 * 		{
		 * 			sesEvent: { ... AWS SES raw event information here ... }
		 * 			event: { state: "sent", timestamp: "2021-12-13 10:00:00", description: "Message was sent at 10:00 am" }
		 * 		},
		 * 		...
		 * ]
		 */

		try {
			statusEvents = JSON.parse(statusEvents);
		} catch (error) {
			statusEvents = null;
		}

		// Render nothing in the case that the status events are corrupted
		if (!Array.isArray(statusEvents)) {
			return null;
		}

		// Render nothing if there are no status events in the events array
		if (statusEvents.length === 0) {
			return null;
		}

		// Grab the latest event
		let latestEvent = statusEvents[statusEvents.length - 1];

		return (
			<>
				{t("There was an error trying to send this message.")}
				<br />
				<br />
				{latestEvent.event.description}
			</>
		);
	}

	renderSMSError() {
		let { message } = this.props;
		let { t } = this.props;

		// Get the object holding all error codes for all providers
		let errorsObject = MessagesService.getProviderMetaData().errors;

		// Get the sms api provider type of the location
		let provider = message.sms_api;

		// Check which section of the provider errors we will use
		let providerErrorIndex = "";
		if (provider === NUMBERS.api.bandwidthV2) {
			providerErrorIndex = "BANDWIDTH_V2_ERRORS";
		} else if (provider === NUMBERS.api.bandwidth) {
			providerErrorIndex = "BANDWIDTH_ERRORS";
		} else if (provider === NUMBERS.api.twilio) {
			providerErrorIndex = "TWILIO_ERRORS";
		} else if (provider === NUMBERS.api.unifonic) {
			providerErrorIndex = "UNIFONIC_ERRORS";
		}

		// Get the metadata object holding the sms errors for this location
		let providerErrors = errorsObject[providerErrorIndex];
		let demandhubErrors = errorsObject["DEMANDHUB_ERRORS"];

		// Get the error code which will be the key in the error object
		let errorCode = message.delivery_code;

		// Declare the detailed information we will find in the errors object
		let errorDescription = "";
		let errorFriendlyDescription = "";
		let errorExplanation = "";

		// Make sure the error code exist in the object
		if (providerErrors && providerErrors[errorCode]) {
			errorDescription = providerErrors[errorCode].description;
			errorFriendlyDescription = providerErrors[errorCode].friendlyDescription;
			errorExplanation = providerErrors[errorCode].explanationOfError;
		} else if (demandhubErrors && demandhubErrors[errorCode]) {
			errorDescription = demandhubErrors[errorCode].description;
			errorFriendlyDescription = demandhubErrors[errorCode].friendlyDescription;
			errorExplanation = demandhubErrors[errorCode].explanationOfError;
		} else {
			errorDescription = t("The message is in an error state without a recognised error code.");
			errorFriendlyDescription = t("Please contact a DemandHub Representative for more information.");
			errorExplanation = t("The error code associated with the message error is unknown.");
		}

		return (
			<>
				{t("There was an error trying to send this message.")}
				<>
					<br />
					<br />
					{t("DemandHub Information:")}
					<br />
					{t("Message State:")} {message.message_state}
					<br />
					{t("Delivery State:")} {message.delivery_state}
					<br />
					{t("Code:")} {message.delivery_code}
					<br />
					{t("Description:")} {message.delivery_description}
					<br />
					<br />
					{t("Detailed Provider Information:")}
					<br />
					{t("Provider Description:")} {errorDescription}
					<br />
					{t("Friendly Description:")} {errorFriendlyDescription}
					<br />
					{t("Explanation:")} {errorExplanation}
				</>
			</>
		);
	}

	renderMenuTrigger = () => {
		let { message } = this.props;

		return (
			<div id={`message-error-${message.id}`} className="mb-message-customer-error">
				<div className="mb-message-customer-error__info">
					<Icon.AlertCircle size="20" color="#ec4758" />
				</div>
			</div>
		);
	};

	renderRetryViaEmail = () => {
		let { contact } = this.state;
		let { t } = this.props;

		if (!contact.email || contact.email === "") {
			return <div>{t("This contact does not have an email. Please set one to send via email.")}</div>;
		}

		return (
			<div>
				<Trans
					i18nKey="Would you like to send this message via email to <1>{{email}}</1>?"
					values={{ email: contact.email }}
					components={{
						1: <b />
					}}
				>
					Would you like to send this message via email to <b>{contact.email}</b>?
				</Trans>
				<br />
				<br /> {t("This contact's preferred medium will be changed to email.")}
			</div>
		);
	};

	renderRetryViaEmailModal = () => {
		let { showRetryViaEmailModal, contact } = this.state;
		let { t } = this.props;

		if (!showRetryViaEmailModal || !contact) {
			return null;
		}

		return (
			<>
				{/* If the contact has an email */}
				{contact.email && (
					<Alert
						type="info"
						show={showRetryViaEmailModal}
						title={t("Retry Via Email")}
						confirm={t("Yes")}
						cancel={t("No")}
						onClose={this.onCloseRetryViaEmailModal}
					>
						{this.renderRetryViaEmail()}
					</Alert>
				)}
				{/* If the contact does not have an email */}
				{!contact.email && (
					<Alert type="info" show={showRetryViaEmailModal} title={t("Retry Via Email")} confirm={t("Okay")} onClose={this.onCloseRetryViaEmailModal}>
						{this.renderRetryViaEmail()}
					</Alert>
				)}
			</>
		);
	};

	render() {
		let { showDetailsModal } = this.state;
		let { message } = this.props;
		let { t } = this.props;

		let isError = message.message_state === MESSAGE_STATES.error;

		if (!isError) {
			return null;
		}

		let isInbound = message.direction === DIRECTION.in;
		let showRetryViaEmailOption = this.showRetryViaEmailOption();

		return (
			<div className="mb-message-customer-error-container">
				<Menu top={-47} left={-5} width={185} triggerComponent={this.renderMenuTrigger()}>
					<MenuOption icon={Icon.Info} onSelect={() => this.setState({ showDetailsModal: true })} title={t("Details")} />
					{!isInbound && <MenuOption icon={Icon.MessageCircle} onSelect={this.onRetry} title={t("Retry")} />}
					{showRetryViaEmailOption && <MenuOption icon={Icon.Mail} onSelect={this.onRetryViaEmail} title={t("Retry Via Email")} />}
				</Menu>

				{showDetailsModal && (
					<Alert
						type="info"
						show={showDetailsModal}
						title={t("Error Information")}
						confirm={t("Okay")}
						onClose={() => this.setState({ showDetailsModal: false })}
					>
						<div>
							{message && message.medium === MEDIUM.sms.key && this.renderSMSError()}
							{message && message.medium === MEDIUM.email.key && this.renderEmailError()}
						</div>
					</Alert>
				)}
				{this.renderRetryViaEmailModal()}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(MessageError);
