import i18n from "../i18n/config";

export const API_KEY_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	username: {
		id: "username",
		value: i18n.t("Username"),
		sortable: true,
		sortField: "username",
		width: 1
	},
	token: {
		id: "token",
		value: i18n.t("Token"),
		sortable: true,
		sortField: "token",
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 1
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};
