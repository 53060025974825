import React from "react";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";
import { getServices } from "service-fetch";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Action from "../../../components/common/Action";
import WebhookManageModal from "./WebhookManageModal";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";

import { SORT_ORDER, STATUS } from "../../../constants/CommonConstants";
import { WEBHOOKS_COLUMNS } from "../../../constants/Settings";
import withLocation from "../../../components/common/WithLocation";

const { WebhooksService } = getServices();

class Webhooks extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			showManageModal: false,
			selectedWebhookId: null,

			loading: false,
			loadedAll: false,
			sortField: WEBHOOKS_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.asc,
			limit: 50
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		this.fetchData();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	fetchData = async () => {
		await this.update({ loading: true });

		let { sortField, sortOrder, limit } = this.state;

		let locationId = await UserService.getActiveLocation().id;

		let { data } = await WebhooksService.fetch({
			params: {
				locationId
			},
			query: {
				sortField,
				sortOrder,
				limit
			}
		});

		await this.update({ loading: false });

		if (!data) {
			ToastService.error("Error getting webhooks");
			return;
		}

		this.update({ data, loadedAll: data.length < limit });
	};

	onShowManageModal = async id => {
		if (id) {
			await this.update({ selectedWebhookId: id, showManageModal: true });
			return;
		}
		await this.update({ showManageModal: true, selectedWebhookId: null });
	};

	onHideModal = async resetComponent => {
		await this.update({ showManageModal: false, selectedWebhookId: null });

		if (resetComponent) {
			this.resetComponent();
		}
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ sortField, sortOrder });
		await this.fetchData();
	};

	getHeaders() {
		let headers = {
			items: WEBHOOKS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let canUpdate = LocationService.canUpdateWebhooks();

		try {
			return [
				recordData.name,
				<div>{recordData.long_url}</div>,
				<div>{recordData.last_ran_at}</div>,
				<div className="dh-list__actions">
					{canUpdate && (
						<Action transparent id={`edit-${recordData.id}`} label={"Edit"} icon={Icon.Edit} onClick={() => this.onShowManageModal(recordData.id)} />
					)}
					{canUpdate && (
						<Action
							transparent
							id={`status-${recordData.id}`}
							label={recordData.status === STATUS.active ? "Deactivate" : "Activate"}
							icon={recordData.status === STATUS.active ? Icon.Power : Icon.Zap}
							onClick={() => this.openToggleStatusAlert(recordData.id, recordData.status)}
						/>
					)}
				</div>
			];
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	render() {
		let { data, loading, loadedAll, sortField, sortOrder, showManageModal, selectedWebhookId } = this.state;

		let canCreate = LocationService.canCreateWebhooks();

		return (
			<Page>
				<Header title={"Webhooks"}>
					{canCreate && <Action id="create" label={"Create Webhook"} icon={Icon.Plus} onClick={() => this.onShowManageModal()} />}
				</Header>

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={"No Webhooks found..."}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<WebhookManageModal show={showManageModal} id={selectedWebhookId} onHide={this.onHideModal} />
			</Page>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(Webhooks));
