import i18n from "../i18n/config";

export const BULK_TYPES = {
	reviewRequest: {
		id: "reviewRequest",
		name: "review_request",
		friendlyName: i18n.t("review invites")
	},
	sendMessage: {
		id: "sendMessage",
		name: "send_message",
		friendlyName: i18n.t("messages")
	},
	contactUpload: {
		id: "contactUpload",
		name: "contact_upload",
		friendlyName: i18n.t("contact upload")
	},
	appointmentUpload: {
		id: "appointmentUpload",
		name: "appointment_upload",
		friendlyName: i18n.t("appointment upload")
	}
};

export const BULK_SCHEDULE_TYPES = {
	sendImmediately: {
		id: "sendImmediately"
	},
	sendAfter: {
		id: "sendAfter"
	},
	options: [
		{
			label: i18n.t("Send Now"),
			value: "sendImmediately"
		},
		{
			label: i18n.t("Send later"),
			value: "sendAfter"
		}
	]
};

export const COLUMN_HEADING_TEXT = {
	name: i18n.t("Name"),
	phone: i18n.t("Cell Phone"),
	email: i18n.t("Email")
};

export const COLUMN_HEADING_OPTION = {
	name: { label: i18n.t("Name"), value: "name" },
	firstName: { label: i18n.t("First Name"), value: "dh_first_name" },
	lastName: { label: i18n.t("Last Name"), value: "dh_last_name" },
	phone: { label: i18n.t("Cell Phone"), value: "phone" },
	email: { label: i18n.t("Email"), value: "email" },
	homePhone: { label: i18n.t("Home Phone"), value: "home_phone" },
	workPhone: { label: i18n.t("Work Phone"), value: "work_phone" },
	address1: { label: i18n.t("Address 1"), value: "address_1" },
	address2: { label: i18n.t("Address 2"), value: "address_2" },
	city: { label: i18n.t("City"), value: "city" },
	stateProv: { label: i18n.t("State or Province"), value: "state_prov" },
	zipPostal: { label: i18n.t("Zip or Postal Code"), value: "zip_postal" },
	country: { label: i18n.t("Country"), value: "country" },
	notes: { label: i18n.t("Notes"), value: "notes" }
};

export const COLUMN_HEADING_APPOINTMENTS_OPTION = {
	name: { label: i18n.t("Name*"), value: "name" },
	firstName: { label: i18n.t("First Name"), value: "dh_first_name" },
	lastName: { label: i18n.t("Last Name"), value: "dh_last_name" },
	phone: { label: i18n.t("Cell Phone*"), value: "phone" },
	email: { label: i18n.t("Email"), value: "email" },
	homePhone: { label: i18n.t("Home Phone"), value: "home_phone" },
	workPhone: { label: i18n.t("Work Phone"), value: "work_phone" },
	address1: { label: i18n.t("Address 1"), value: "address_1" },
	address2: { label: i18n.t("Address 2"), value: "address_2" },
	city: { label: i18n.t("City"), value: "city" },
	stateProv: { label: i18n.t("State or Province"), value: "state_prov" },
	zipPostal: { label: i18n.t("Zip or Postal Code"), value: "zip_postal" },
	country: { label: i18n.t("Country"), value: "country" },

	// Appointment related
	apptState: { label: i18n.t("Appointment State*"), value: "appt_state" },
	apptDate: { label: i18n.t("Appointment Date* (yyyy-mm-dd)"), value: "appt_date" },
	apptStartTime: { label: i18n.t("Appointment Start Time* (hh:mm)"), value: "appt_start_time" },
	apptEndTime: { label: i18n.t("Appointment End Time (hh:mm)"), value: "appt_end_time" },
	apptDuration: { label: i18n.t("Appointment Duration (Minutes)"), value: "appt_duration" },
	crmContactId: { label: i18n.t("Patient ID*"), value: "crm_contact_id" },
	crmAppointmentId: { label: i18n.t("Appointment ID*"), value: "crm_appointment_id" },
	assignedRep: { label: i18n.t("Assigned Rep"), value: "assigned_rep" },

	// Patient related
	dob: { label: i18n.t("Date of Birth (yyyy-mm-dd)"), value: "dob" },
	gender: { label: i18n.t("Gender"), value: "gender" }
};

export const COLUMN_HEADING_OPTION_MAP = {
	name: "name",
	dh_first_name: "firstName",
	dh_last_name: "lastName",
	phone: "phone",
	email: "email"
};

/**
 * Supported CSV mime types
 */
export const CSV_FILE_TYPES = [
	"text/comma-separated-values",
	"text/comma-separated-values",
	"text/csv",
	"application/csv",
	"application/vnd.ms-excel",
	"application/vnd.msexcel"
];
/**
 * Data for sample CSV file to be downloaded
 */
export const SAMPLE_CSV_DATA_CONTACT = [
	["Name", "Phone", "Email"],
	["John Doe", 6476713142, "jdoe@example.com"],
	["Bob Johnson", 6476713142, "bjohnson@example.com"]
];

export const SAMPLE_CSV_DATA_APPOINTMENT = [
	["Name", "Phone", "Email", "Patient ID", "Appointment ID", "Appointment State", "Appointment Date", "Appointment Start Time"],
	["John Doe", 6476713142, "jdoe@example.com", 1, 1, "arrived", "2024-08-02", "11:20"],
	["Bob Johnson", 6476713142, "bjohnson@example.com", 2, 2, "unconfirmed", "2025-10-02", "17:39"]
];

/**
 * Threshold for invalid emails
 */
export const INVALID_EMAIL_THRESHOLD = 0.4;
