import React from "react";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import GroupPermissionService from "../../../services/GroupPermissionService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";

import withLocation from "../../../components/common/WithLocation";
import Checkbox from "../../../components/common/Checkbox";

import "../../../styles/css/scenes/all-permissions.css";

class AllPermissions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			groupPermissionFields: [], // All the group permission fields
			groups: [], // The different groups
			permissionFieldGroupings: [] // The different permission groups/categories (eg. messenger permission, review permissions etc... )
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = async location => {
		await this.fetchData();
	};

	fetchData = async () => {
		let groupPermissionFields = await GroupPermissionService.fetchGroupPermissionInfo();
		let groups = await GroupPermissionService.fetchGroupPermissions();

		if (!UserService.isSuperAdmin()) {
			groups = groups.filter(gp => gp.type !== "super_administrator" && gp.type !== "customer_success");
		}
		await this.update({
			permissionFieldGroupings: this.sortGroups(groupPermissionFields),
			groupPermissionFields: groupPermissionFields.fields,
			groups
		});
	};

	sortGroups = groupPermissionFields => {
		return Object.values(groupPermissionFields.fieldGroups).sort((a, b) => {
			let aName = a.name.toLowerCase();
			let bName = b.name.toLowerCase();
			if (aName < bName) {
				return -1;
			}
			if (aName > bName) {
				return 1;
			}
			return 0;
		});
	};

	onChange = async (groupPermissionId, fieldId) => {
		let { groups } = this.state;
		let { t } = this.props;

		let index = -1;
		if (groups) {
			index = groups.map(gp => gp.id).indexOf(groupPermissionId);
		}

		if (index < 0) {
			return;
		}

		let groupPermission = { ...groups[index] };
		groupPermission[fieldId] = !groupPermission[fieldId];
		groups[index] = groupPermission;
		await this.update({ groups });

		let result = await GroupPermissionService.updateGroupPermission(groupPermission);
		if (!result) {
			// Revert change
			groupPermission[fieldId] = !groupPermission[fieldId];
			groups[index] = groupPermission;
			await this.update({ groups });

			ToastService.error(t("An error occurred trying to update permission. Please try again."));
			return;
		}

		ToastService.info(t("Updated Group Permission"));
	};

	renderHeader = group => {
		let { groups } = this.state;

		if (!groups || !group) {
			return null;
		}

		return (
			<div key="header-row" className="all-permissions__table__row">
				<div className="all-permissions__table__row__left-column all-permissions__table__row__column--header">{group.name}</div>
				{groups.map(permission => (
					<div key={permission.id} className="all-permissions__table__row__column all-permissions__table__row__column--header">
						{permission.name}
					</div>
				))}
			</div>
		);
	};

	renderFieldsForGroup = group => {
		let { groupPermissionFields, groups } = this.state;

		if (!group || !group.fields) {
			return null;
		}

		return group.fields.map(fieldId => {
			let field = groupPermissionFields[fieldId];

			if (!field) {
				console.log(`field ${fieldId} not found in permissions meta data`);
				return null;
			}

			return (
				<div key={field.id} className="all-permissions__table__row">
					<div className="all-permissions__table__row__left-column">
						<div data-tip data-for={`rtt-${field.id}`}>
							{field.name}
						</div>
						<ReactTooltip id={`rtt-${field.id}`} className="mb-react-tooltip waf__tooltip" type="info" effect="solid" place="top" arrowColor="#333">
							{field.description}
						</ReactTooltip>
					</div>
					{groups.map(permission => (
						<div key={permission.id} className="all-permissions__table__row__column">
							<Checkbox
								id={`checkbox-${field.id}-${permission.id}`}
								name={`${field.id}-${permission.id}`}
								checked={permission[field.id] || false}
								onChange={() => this.onChange(permission.id, field.id)}
							/>
						</div>
					))}
				</div>
			);
		});
	};

	render() {
		let { permissionFieldGroupings } = this.state;

		return (
			<div className="all-permissions">
				{permissionFieldGroupings &&
					permissionFieldGroupings.map(group => (
						<div key={group.id} className="all-permissions__table">
							{this.renderHeader(group)}
							{this.renderFieldsForGroup(group)}
						</div>
					))}
			</div>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(AllPermissions));
