import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";

// Moment imports
import "moment/locale/es";
import "moment/locale/fr";

let IS_I18N_INITIALIZED = false;

// Check if i18n is already initialized
if (!IS_I18N_INITIALIZED) {
	let config = {
		fallbackLng: "en_US",
		lng: "en_US",
		resources: {
			en_CA: {
				translations: require("./locales/en_CA/translations.json"),
			},
			en_US: {
				translations: require("./locales/en_US/translations.json"),
			},
			en_GB: {
				translations: require("./locales/en_GB/translations.json"),
			},
			es_US: {
				translations: require("./locales/es_US/translations.json"),
			},
			fr_CA: {
				translations: require("./locales/fr_CA/translations.json"),
			},
		},
		ns: ["translations"],
		defaultNS: "translations",
		debug: false,
		saveMissing: true,
		missingKeyHandler: (lng, ns, key, fallbackValue) => {
			// console.log(`i18n: Missing translation: ${key} [lng: ${lng}, ns: ${ns}, fallbackValue: ${fallbackValue}]`);
		},
	};

	// Initialize i18n
	i18n.use(initReactI18next).init(config);
	i18n.languages = Object.keys(config.resources);

	// Try to find the language set in local storage
	try {
		let localStorageLanguage = localStorage.getItem("dh_language");

		if (localStorageLanguage !== "undefined" && localStorageLanguage !== null) {
		
			// Set language
			i18n.changeLanguage(localStorageLanguage);

			// Set moment locale
			let momentLanguage = localStorageLanguage.split("_")[0];
			moment.locale(momentLanguage);
		} else {
			
			// Set default moment locale
			let defaultLanguageForMoment = config.lng.split("_")[0];
			moment.locale(defaultLanguageForMoment);
		}
	} catch (error) {
		console.log("Error getting language from local storage: ", error);
	}

	IS_I18N_INITIALIZED = true;
}

export default i18n;
