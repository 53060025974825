import i18n from "../i18n/config";

export const CONFIRM_BLOCK_COLUMNS_CONTACT = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: false,
		width: 1
	},
	created: {
		id: "created",
		value: i18n.t("Created"),
		sortable: false,
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: false,
		width: 1
	}
};

export const CONFIRM_BLOCK_COLUMNS_APPOINTMENTS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: false,
		width: 1
	},
	state: {
		id: "state",
		value: i18n.t("State"),
		sortable: false,
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: false,
		width: 1
	}
};
