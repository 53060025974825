export default `swagger: "2.0"
info:
  version: "0.0.1"
  title: DemandHub - platform
# during dev, should point to your local machine
host: localhost:4000
# basePath prefixes all resource paths
basePath: /api
#
schemes:
  # tip: remove http to make production-grade
  - http
# format of bodies a client can send (Content-Type)
consumes:
  - application/json
# format of the responses to the client (Accepts)
produces:
  - application/json

securityDefinitions:
  authorization:
    x-authorize: security/auth.js
    scopes:
      default: Default Scope
      audit_log: Internal audit log to track critical DH activities
  widget_auth:
    x-authorize: security/widget_auth.js
    scopes:
      webchat: Webchat scope
      reviews_widget_fetch: Get all the reviews for a location
      create_analytics_event: Create analytics event
      nucleus_config: Get Nucleus config
      booking_widget: Booking Widget Scope
  third_party_auth:
    x-authorize: security/third_party_auth.js
    scopes:
      third_party_validate_api_key: Let a third party service validate an API key
      third_party_create_review_request: Let a third party service create review requests
      third_party_create_contact_request: Let a third party service create a contact
      third_party_create_message_request: Let a third party service create a message
      third_party_create_synced_appointment: Let a third party service create a synced appointment
      third_party_fetch_templates: Let a third party service fetch templates
  secure_chat_auth:
    x-authorize: security/secure_chat_auth.js
    scopes:
      secure_chat: Secure Chat Authorization Scope

security:
  - authorization: [ default ]
  - widget_auth: [ reviews_widget_fetch, create_analytics_event, webchat, booking_widget ]
  - third_party_auth: [ third_party_validate_api_key, third_party_create_review_request, third_party_create_contact_request, third_party_create_message_request, third_party_create_synced_appointment, third_party_fetch_templates ]

tags:
  - name: user
    description: User namespace.
  - name: review_request
    description: Review_request namespace.
  - name: nps_request
    description: Nps_request namespace.
  - name: review
    description: Review namespace.
  - name: review_insight
    description: Review insight namespace.
  - name: message
    description: Message namespace.
  - name: voice
    description: Voice namespace.
  - name: location
    description: Location namespace.
  - name: company
    description: Company namespace.
  - name: message_template
    description: Message Template Namespace
  - name: email
    description: Email Management Namespace
  - name: review_sites
    description: Review Site Namespace
  - name: timezones
    description: Timezone Namespace
  - name: password
    description: Password Namespace
  - name: contacts
    description: Contact Namespace
  - name: recommendations
    description: Recommendations Namespace
  - name: synced_appointment
    description: Synced Appointment Namespace
  - name: crm
    description: CRM Namespace
  - name: admin
    description: Admin Namespace
  - name: audit_log
    description: Audit Log Namespace
  - name: push
    description: Push Namespace
  - name: reviews_widget
    description: Reviews Widget Namespace
  - name: stats
    description: Statistics Namespace
  - name: api_keys
    description: API Keys Namespace
  - name: customer_success
    description: Customer Success Namespace
  - name: number
    description: Number Namespace
  - name: nucleus
    description: Nucleus Namespace
  - name: nps
    description: Net Promoter Score Namespace
  - name: crm_integrations
    description: CRM Integrations Namespace
  - name: payment
    description: Payments Namespace
  - name: inbox
    description: Inboxes Namespace
  - name: bulk
    description: Bulk Actions Namespace
  - name: trackers
    description: Trackers Namespace
  - name: tag
    description: Tags Namespace
  - name: appointment
    description: Appointments Namespace
  - name: booking_widget
    description: Booking Widget Namespace
  - name: chatbot
    description: Chatbot Namespace
  - name: workflow
    description: Workflow Namespace
  - name: video_chat
    description: Video Chat Namespace
  - name: media
    description: Media Namespace
  - name: scheduled_message
    description: Scheduled Message Namespace
  - name: log
    description: Logs
  - name: zapier
    description: Zapier Namespace
  - name: secure_chat
    description: Secure Chat Namespace
  - name: tasks
    description: Tasks
  - name: short_links
    description: Short Links
  - name: calendar
    description: Calendar and Calendar Event System
  - name: a2p
    description: A2P / The Campaign Registry (TCR) Namespace
  - name: test
    description: Functional Testing Namespace
  - name: reengagements
    description: Reengagements
  - name: sign_ups
    description: sign_ups
  - name: webhooks
    description: webhooks

paths:

  /number/fetch_available_numbers:
    get:
      description: Get numbers
      tags: [ number ]
      operationId: fetchAvailableNumbers

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: areaCode
        type: integer
        required: true

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/order_a_number:
    post:
      description: Order a number
      tags: [ number ]
      operationId: orderANumber

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: body
        name: post body
        required: true
        description: Phone number object
        schema:
          type: object
          required:
            - phone
            - provider
          properties:
            phone:
              oneOf:
                - type: integer
                - type: string
            provider:
              type: "string"

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/check_importability:
    post:
      description: Check if a phone number can be imported
      tags: [ number ]
      operationId: checkImportability

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: body
        name: post body
        required: true
        description: Phone number object
        schema:
          type: object
          required:
            - phone
          properties:
            phone:
              oneOf:
                - type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /number/import_number:
    post:
      description: Import a landline number 
      tags: [ number ]
      operationId: importNumber

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: body
        name: post body
        required: true
        description: Phone number object
        schema:
          type: object
          required:
            - phoneNumber
            - name
            - houseNumber
            - streetName
            - city
            - stateCode
            - zip
            - authorizingPerson

          properties:
              phoneNumber:
                type: "string"
              name:
                type: "string"
              houseNumber:
                type: "string"
              streetName:
                type: "string"
              city:
                type: "string"
              stateCode:
                type: "string"
              zip:
                type: "string"
              authorizingPerson:
                type: "string"

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/check_import_order_status:
    get:
      description: Check the status of an import order
      tags: [ number ]
      operationId: checkImportOrderStatus

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: phone
        type: string
        required: true

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/count_conversations:
    get:
      description: Count the number of conversations that a number is being used in (as the 'out_number')
      tags: [ number ]
      operationId: countConversations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/is_valid:
    get:
      description: Check if a phone number is valid
      tags: [ number ]
      operationId: isValid

      security: [
        widget_auth: [ webchat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/messenger/provider/metadata:
    get:
      description: Get metadata for all providers used for messenger
      tags: [ admin ]
      operationId: getMessengerProviderMetadata

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/messenger/stats:
    get:
      description: Get messenger stats.
      tags: [ admin ]
      operationId: stats

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/cron_tasks/fetch:
    get:
      description: Get Cron Tasks.
      tags: [ admin ]
      operationId: fetchCronTasks

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/cron_tasks/run:
    post:
      description: Run a specific cronTask
      tags: [ admin ]
      operationId: runCronTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/locations:
    get:
      description: Fetch all locations
      tags: [ admin ]
      operationId: locations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/companies:
    get:
      description: Fetch all companies
      tags: [ admin ]
      operationId: companies

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/support:
    get:
      description: Fetch Support HTML Chat for Mobile
      tags: [ admin ]
      operationId: getSupportChat

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/ping:
    post:
      description: Public endpoint to take in a ping checkin from a CRM automation
      tags: [ crm_integrations ]
      operationId: crmPing

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/fetch_all:
    get:
      description: Get CRM Integrations
      tags: [ crm_integrations ]
      operationId: fetchAll

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{locationId}/fetch:
    get:
      description: Get CRM Integrations by location id
      tags: [ crm_integrations ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: location id

        - in: query
          name: type
          oneOf: 
            - type: string
            - type: array
          items:
            type: string
          required: false
          description: crm type
      
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{integrationId}/fetch_rest_integration:
    get:
      description: Get CRM integrations details for a REST api
      tags: [ crm_integrations ]
      operationId: fetchRestIntegration

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: integrationId
          type: integer
          required: true
          description: integration id
      
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{integrationId}/test_rest_integration:
    post:
      description: Test the credentials for a REST API integration
      tags: [ crm_integrations ]
      operationId: testRestIntegration

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: integrationId
          type: integer
          required: true

        - in: body
          name: credentials
          required: true
          description: CRM credential data
          schema:
            type: object
            required:
              - integrationId
            properties:
              integrationId:
                type: integer
              tenantId:
                type: string
              login:
                type: string
              password:
                type: string
              code:
                type: string
              accessToken:
                type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{integrationId}/fetch_rest_locations:
    post:
      description: Get the locations for a REST API integration
      tags: [ crm_integrations ]
      operationId: fetchRestLocations

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: integrationId
          type: integer
          required: true

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{integrationId}/update_rest_location:
    post:
      description: Update the metadata field for the location in a CRM integration
      tags: [ crm_integrations ]
      operationId: updateRestLocation

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: integrationId
          type: integer
          required: true

        - in: body
          name: locationData
          required: true
          description: CRM location data
          schema:
            type: object
            required:
              - integrationId
              - crmLocationId
            properties:
              integrationId:
                type: integer
              locationId:
                type: string
              crmLocationId:
                oneOf:
                  - type: integer
                  - type: string
              locationName:
                type: string
              crmLocationName:
                type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{locationId}/create:
    post:
      description: Create CRM Integration for location
      tags: [ crm_integrations ]
      operationId: createCrmIntegration

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer

        - in: body
          name: crmData
          required: true
          description: CRM data object
          schema:
            type: object
            required:
              - locationId
              - crmType
              - crmVersion
              - integrationType
              - direction
              - metadata
            properties:
              locationId:
                type: integer
              crmType:
                type: string
                enum: ["demandhub", "abeldent", "opendental", "dentrix", "tracker", "paradigm","eaglesoft", "liveddm", "dealervault", "cdk", "cdk_180", "cdk_180_rest", "adf_email", "dealertrack", "dealersocket", "asa_tiremaster", "bayiq", "omnique", "shopboss", "cliniko", "pbs_systems", "zapier", "opes", "juvonno", "janeapp", "clinicmaster", "mindbody", "fresha", "webpt", "pteverywhere", "simplepractice", "practicefusion", "dr_chrono", "dolphin", "schedulista", "nuemd", "orthotrac", "therapynotes", "odofficepro", "milano", "athena_health", "daysmart", "salesforce", "eclinicalworks", "resurva", "booker", "practicebetter", "clio", "noterro", "tebra", "shift_digital"]
              crmVersion:
                type: "string"
              integrationType:
                type: string
                enum: ["chat_lead_generation", "sync_appointments", "sync_contacts", "sync_invoices", "appointment_confirmation_status", "invoice_payment_status", "report"]
              direction:
                type: string
                enum: ["to_crm", "from_crm"]
              syncType:
                type: string
                enum: ["rest_api", "sync_app", "ftp"]
              metadata:
                type: string
              runType:
                type: string
                enum: ["one_time", "interval", "manual"]
              name:
                type: string
              inboxId:
                type: string
              schedules:
                type: array
                items:
                  type: object

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{integrationPublicId}/upload_data:
    post:
      description: Uploads data from a CRM integration
      tags: [ crm_integrations ]
      operationId: uploadData

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      parameters:
        - in: path
          name: integrationPublicId
          required: true
          type: string
        - in: body
          name: dataEntry
          required: true
          description: CRM data object
          schema:
            type: object
            required:
              - type
              - data
            properties:
              type:
                type: string
              data:
                type: object
              upload_after:
                type: string

  /crm_integrations/{integrationPublicId}/download_data:
    get:
      description: Downloads data from a CRM integration
      tags: [ crm_integrations ]
      operationId: downloadData

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      parameters:
        - in: path
          name: integrationPublicId
          required: true
          type: string
        
  /crm_integrations/{integrationPublicId}/download_ack:
    post:
      description: Send the download acknowledgement report after a CRM data download
      tags: [ crm_integrations ]
      operationId: downloadAck

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      parameters:
        - in: path
          name: integrationPublicId
          required: true
          type: integer
        - in: body
          name: ackData
          required: true
          description: CRM ack data object
          schema:
            type: object
            items:
                type: object
          
  /crm_integrations/update:
    post:
      description: Update CRM record
      tags: [ crm_integrations ]
      operationId: updateCrmIntegration

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: body
        name: crmData
        required: true
        description: CRM data object
        schema:
          type: object
          required:
            - crmId
            - crmType
            - crmVersion
            - integrationType
            - direction
            - metadata
            - status
          properties:
            crmId:
              type: integer
            crmType:
              type: string
              enum: ["demandhub", "abeldent", "opendental", "dentrix", "tracker", "paradigm","eaglesoft", "liveddm", "dealervault", "cdk", "cdk_180", "cdk_180_rest", "adf_email", "dealertrack", "dealersocket", "asa_tiremaster", "bayiq", "omnique", "shopboss", "cliniko", "pbs_systems", "zapier", "opes", "juvonno", "janeapp", "clinicmaster", "mindbody", "fresha", "webpt", "pteverywhere", "simplepractice", "practicefusion", "dr_chrono", "dolphin", "schedulista", "nuemd", "orthotrac", "therapynotes", "odofficepro", "milano", "athena_health", "daysmart", "salesforce", "eclinicalworks", "resurva", "booker", "practicebetter", "clio", "noterro", "tebra", "shift_digital"]
            crmVersion:
              type: "string"
            integrationType:
              type: string
              enum: ["chat_lead_generation", "sync_appointments", "sync_contacts", "sync_invoices", "appointment_confirmation_status", "invoice_payment_status", "report"]
            direction:
              type: string
              enum: ["to_crm", "from_crm"]
            syncType:
              type: string
              enum: ["rest_api", "sync_app", "ftp"]
            metadata:
              type: string
            runType:
              type: string
              enum: ["one_time", "interval", "manual"]
            status:
              type: string
              enum: ["active", "inactive", "deleted"]
            name:
              type: string
            inboxId:
              type: string
            schedules:
              type: array
              items:
                type: object

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/fetch_enum:
    get:
      description: Get CRM Integrations
      tags: [ crm_integrations ]
      operationId: fetchEnum

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/messenger:
    get:
      description: Get overall Stats for the messenger
      tags: [ stats ]
      operationId: getMessengerStats

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/webchat:
    get:
      description: Get overall Stats for the webchat
      tags: [ stats ]
      operationId: getWebchatStats

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/last_x_status:
    get:
      description: Get last x status for a event
      tags: [ stats ]
      operationId: getLastXStatus

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: query
          name: appName
          type: string
          required: true
          description: Name of the app/widget
        - in: query
          name: locationId
          type: integer
          required: true
          description: Location id
        - in: query
          name: eventName
          type: string
          required: true
          description: Name of the event
        - in: query
          name: limit
          type: integer
          required: true
          description: Limit of the results

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/{locationId}/reviews_summary:
    get:
      description: Get Reviews Summary for top 3 configured review sites
      tags: [ stats ]
      operationId: getReviewsSummary

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: Location id to get reviews summary for

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/{locationId}/total_messages_counts:
    get:
      description: Get total inbound and outbound messages count
      tags: [ stats ]
      operationId: getMessagesCount

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: Location id to get total messages counts for

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/{locationId}/inbound_conversations:
    get:
      description: Get inbound conversations for last X days
      tags: [ stats ]
      operationId: getInboundConversationsForLastXDays

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: Location id to get inbound conversations for

        - in: query
          name: days
          type: integer
          required: true

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/{locationId}/avg_messages_per_day:
    get:
      description: Get Avg Messages Per Day for X days
      tags: [ stats ]
      operationId: getAvgMessagesPerDayForLastXDays

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: Location id to get messages for

        - in: query
          name: days
          type: integer
          required: true

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/review:
    get:
      description: Get overall Stats for the Reviews
      tags: [ stats ]
      operationId: getReviewStats

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/create:
    post:
      description: Create an analytics event
      tags: [ stats ]
      operationId: createAnalyticsEvent

      parameters:
        - in: body
          name: analytics
          description: Analytics object
          schema:
            type: object
            required:
              - appName
              - eventName
            properties:
              appName:
                type: string
                enum: [webapp, platform, share_comments, webchat, reviews_widget, booking_widget, nucleus]
              eventName:
                type: string
                enum: [webchat_opened, webchat_loaded, webchat_not_loaded, webchat_message_sent, reviews_widget_loaded, reviews_widget_not_loaded, reviews_widget_opened, reviews_widget_closed, reviews_widget_add_review_clicked, booking_widget_opened, booking_widget_closed, booking_widget_submitted, nucleus_loaded]
              eventDescription:
                type: string
              locationId:
                type: integer
              locationPublicId:
                type: string
              userId:
                type: integer
              companyId:
                type: integer

      security: [
        widget_auth: [ reviews_widget_fetch ]
      ]

      responses:
        "201":
          description: Created
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /stats/messenger_channel:
    get:
      description: Get overall stats for the different messenger channels
      tags: [ stats ]
      operationId: getMessengerChannelStats

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /nucleus/config:
    get:
      description: Get Nucleus Config
      tags: [ nucleus ]
      operationId: getNucleusConfig

      security:
        - widget_auth: [ nucleus_config ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /nucleus/recaptcha_assessment:
    post:
      description: Get a recaptcha assessment score
      tags: [ nucleus ]
      operationId: getRecaptchaScore

      security: [
        widget_auth: [ webchat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /version/latest:
    get:
      description: Retrieves latest version number for the web platform.
      tags: [ version ]
      operationId: latest

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /version/whats_new:
    get:
      description: Get latest version notes
      tags: [ version ]
      operationId: getWhatsNew

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /auth/login:
    get:
      description: Check for a valid login
      tags: [ user ]
      operationId: authLogin

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /auth/verify:
    post:
      description: Check 2FA token exists 
      tags: [ user ]
      operationId: verifyToken

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /auth/confirm:
    post:
      description: Confirm the 2FA pin
      tags: [ user ]
      operationId: confirmPin

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/login:
    post:
      description: Attempt to log the user in
      tags: [ user ]
      operationId: login

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/logout:
    post:
      description: Attempt to logout the user
      tags: [ user ]
      operationId: logout

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/check_auth_token:
    get:
      description: Check if auth token is valid
      tags: [ user ]
      operationId: checkAuthToken

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/create:
    post:
      description: Create a user
      tags: [ user ]
      operationId: createUser

      security: [
        authorization: [ default, audit_log ]
      ]

      parameters:
        - in: body
          name: user
          description: User Data
          schema:
            type: object
            required:
              - company
              - group
              - assigned_user_locations
              - password
              - email
            properties:
              first_name:
                type: string
              last_name:
                type: string
              company:
                type: integer
              group:
                type: integer
              assigned_user_locations:
                type: array
                items:
                  type: object
                minItems: 1
              password:
                type: string
                minLength: 6
              email:
                type: string
                minLength: 3
              phone:
                type: string
              notes:
                type: string
              messenger_signature:
                type: string
              messenger_html_signature:
                type: string
              enable_messenger_signature:
                type: boolean
              messenger_click_to_send:
                type: boolean
              messenger_show_reply_suggestions:
                type: boolean
              messenger_conversation_nudging:
                type: boolean
              receive_company_alerts:
                type: boolean
              receive_company_reports:
                type: boolean
              language:
                type: string
              enable_teamchat:
                type: boolean
              enable_tours:
                type: boolean
              is_two_factor_enabled:
                type: boolean
              teamchatChannels:
                items:
                    type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/update:
    post:
      description: Update a user
      tags: [ user ]
      operationId: updateUser

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/delete:
    post:
      description: Delete a user
      tags: [ user ]
      operationId: deleteUser

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/fetch_assignable:
    get:
      description: Fetches a list of assignable users.
      tags: [ user ]
      operationId: fetchAssignable

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/fetch:
    get:
      description: Fetches a list of users.
      tags: [ user ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/details:
    get:
      description: Retrieves a users detailed data
      tags: [ user ]
      operationId: getUserDetails

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/subordinate_accounts:
    get:
      description: Gets all the subordinate accounts to current user group
      tags: [ user ]
      operationId: getSubordinateAccounts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}:
    get:
      description: Fetches a single user.
      tags: [ user ]
      operationId: getUser

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/locations:
    get:
      description: Retrieves assigned locations for a user
      tags: [ user ]
      operationId: getUserLocations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/profile_picture/update:
    post:
      description: Update profile picture for a user
      tags: [ user ]
      operationId: updateProfilePicture
      consumes:
        - 'multipart/form-data'

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/profile_picture/remove:
    post:
      description: Update profile picture for a user
      tags: [ user ]
      operationId: removeProfilePicture

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/sessions/fetch:
    get:
      description: Fetch session tokens used by a user
      tags: [ user ]
      operationId: fetchSessions

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/user_location/update:
    post:
      description: Update a User Location for a user
      tags: [ user ]
      operationId: updateUserLocation

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /password/reset/send_token:
    post:
      description: Generates and stores password reset token in redis cache
      tags: [ password ]
      operationId: sendToken

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /password/reset/verify_token:
    post:
      description: Verifies if the token is valid
      tags: [ password ]
      operationId: verifyToken

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /password/reset/users:
    post:
      description: Reset the password of multiple users
      tags: [ password ]
      operationId: resetUsers

      security: [
          authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /password/reset:
    post:
      description: Update password in the database
      tage: [ password ]
      operationId: reset

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/count:
    get:
      description: Get count review requests for a location
      tags: [ review_request ]
      operationId: count

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/bulk_invite_count:
    get:
      description: Get number of remaining bulk review requests for a location
      tags: [ review_request ]
      operationId: getBulkInviteCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/create:
    post:
      description: Create a review request
      tags: [ review_request ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/create_bulk:
    post:
      description: Create multiple review requests
      tags: [ review_request ]
      operationId: createBulk

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/create_third_party:
    post:
      description: Create a review request from a third party API
      tags: [ review_request ]
      operationId: createThirdParty

      parameters:
        - in: body
          name: requestData
          description: Review Request Data
          schema:
            type: object
            required:
              - name
            properties:
              name:
                type: string
              phone:
                type: string
              email:
                type: string
              templateId:
                type: integer
              doNotSend:
                type: boolean

      security: [
        third_party_auth: [ third_party_create_review_request ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/cancel:
    post:
      description: Cancels a review request
      tags: [ review_request ]
      operationId: cancel

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/uncancel:
    post:
      description: Uncancels a review request
      tags: [ review_request ]
      operationId: uncancel

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: body
          name: reviewRequestId
          description: Review Request Id
          schema:
            type: object
            required:
              - id
            properties:
              id:
                type: integer

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/followup:
    post:
      description: Followup review request creation
      tags: [ review_request ]
      operationId: followup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/last_sent:
    get:
      description: Get last sent review request for a phone number from a location
      tags: [ review_request ]
      operationId: lastSent

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/fetch:
    get:
      description: Fetch review requests from a location
      tags: [ review_request ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/{reviewRequestId}/update_last_event:
    post:
      description: Update review requests with last event
      tags: [ review_request ]
      operationId: updateLastEvent

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/{reviewGuid}/submit_review:
    post:
      description: Review submission
      tags: [ review_request ]
      operationId: submitReview

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/{reviewGuid}:
    get:
      description: Review request
      tags: [ review_request ]
      operationId: review

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/send:
    post:
      description: Send a review request that requires manual action
      tags: [ review_request ]
      operationId: send

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/send_batch:
    post:
      description: Send a batch review request from older synced appointments
      tags: [ review_request ]
      operationId: batchSend

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_request/{reviewRequestId}/update_notes:
    post:
      description: Update review request notes
      tags: [ review_request ]
      operationId: updateNotes

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /nps_request/create:
    post:
      description: Create a nps request
      tags: [ nps_request ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /nps_request/last_sent:
    get:
      description: Check if a nps request has been sent before
      tags: [ nps_request ]
      operationId: lastSent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/count:
    get:
      description: Count reviews for a location
      tags: [ review ]
      operationId: count

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/fetch:
    get:
      description: Fetch reviews for a location
      tags: [ review ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/fetch_by_ids:
    get:
      description: Get all reviews by ids
      tags: [ review ]
      operationId: fetchReviewsByIds

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/update:
    post:
      description: Update a review
      tags: [ review ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/set_requester:
    post:
      description: Set requester tag
      tags: [ review ]
      operationId: setRequester

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/reset_requester:
    post:
      description: Reset requester tag
      tags: [ review ]
      operationId: resetRequester

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/set_response:
    post:
      description: Sets a response to the existing review
      tags: [ review ]
      operationId: setResponse

      parameters:
        - in: body
          name: review
          description: Review Object
          schema:
            type: object
            required:
              - reply
              - repliedBy
              - reviewId
              - locationId
            properties:
              reply:
                type: string
              repliedBy:
                type: string
              reviewId:
                type: integer
              locationId:
                type: integer
              subject:
                type: string

      security: [
        authorization: [ default ]
      ]

      responses:
        "201":
          description: Created
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/delete_response:
    post:
      description: Deletes a response for an existing review
      tags: [ review ]
      operationId: deleteResponse

      parameters:
        - in: body
          name: review
          description: Review Object
          schema:
            type: object
            required:
              - reviewId
              - locationId
            properties:
              reviewId:
                type: integer
              locationId:
                type: integer

      security: [
        authorization: [ default ]
      ]

      responses:
        "201":
          description: Deleted
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/generate_ai_reply:
    get:
      description: Generates an AI reply to a review
      tags: [ review ]
      operationId: generateAiReplySuggestion

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review/{reviewId}/propagate_review:
    post:
      description: Propagates a review to a different website
      tags: [ review ]
      operationId: propagateReview

      parameters:
        - in: body
          name: review
          description: Review Object
          schema:
            type: object
            required:
              - reviewId
              - locationId
              - shareToSiteId
              - review
            properties:
              reviewId:
                type: integer
              locationId:
                type: integer
              shareToSiteId:
                type: integer
              review:
                type: string

      security: [
        authorization: [ default ]
      ]

      responses:
        "201":
          description: Created
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_insight/fetch:
    get:
      description: Fetch review insights
      tags: [review_insight]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/twilio/video/create_token:
    get:
      description: Get Twilio Video Chat token
      tags: [ message ]
      operationId: getToken

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/search/messages:
    post:
      description: Search message content for a specific location
      tags: [ message ]
      operationId: searchMessages

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: body
          name: searchCriteria
          description: Search Criteria Request
          schema:
            type: object
            required:
              - searchTerm
              - locationId
            properties:
              searchTerm:
                type: string
              locationId:
                type: integer
              contactId:
                type: integer
              page:
                type: integer
              pageSize:
                type: integer

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/search/conversations:
    post:
      description: Search conversations
      tags: [ message ]
      operationId: searchConversations

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: body
          name: searchCriteria
          description: Search Criteria Request
          schema:
            type: object
            required:
              - searchTerm
            properties:
              searchTerm:
                type: string
              locationId:
                type: integer
              contactId:
                type: integer
              page:
                type: integer
              pageSize:
                type: integer
              allowRestrictedViewAll: 
                type: boolean
              hasPaymentMethod:
                type: boolean
              receiveTransactionalSms:
                type: boolean
              receiveTransactionalEmail:
                type: boolean

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/receive_twilio_sms:
    post:
      description: Receive messages from Twilio
      tags: [ message ]
      operationId: receiveTwilioSms

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/receive_bandwidth_sms:
    get:
      description: Receive messages from Bandwidth
      tags: [ message ]
      operationId: receiveBandwidthSmsGet

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

    post:
      description: Receive messages from Bandwidth
      tags: [ message ]
      operationId: receiveBandwidthSms

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/receive_bandwidth_voice:
    get:
      description: Receive phone calls from Bandwidth
      tags: [ message ]
      operationId: receiveBandwidthVoice

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/metadata/{provider}:
    get:
      description: Get the metadata and error codes for provider and demandhub
      tags: [ message ]
      operationId: fetchProviderMetaData

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/send_message:
    post:
      description: Send a message
      tags: [ message ]
      operationId: sendMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/retry_send:
    post:
      description: Retry sending a message
      tags: [ message ]
      operationId: retryMessageSend

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/queue/messages:
    get:
      description: Fetch the customer messages that have queued messages
      tags: [ message ]
      operationId: fetchCustomerQueuedMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/queue/count:
    get:
      description: Count the number of customer queued messages that are within a conversation
      tags: [ message ]
      operationId: countCustomerScheduledMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/replace_message:
    get:
      description: Replace message variables in a message
      tags: [ message ]
      operationId: getReplacedMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/send_draft:
    post:
      description: Send a draft message
      tags: [ message ]
      operationId: sendDraft

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/count_conversations:
    get:
      description: Count conversations for a locations
      tags: [ message ]
      operationId: countConversations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/fetch_conversations:
    get:
      description: Fetch conversations for a location
      tags: [ message ]
      operationId: getConversations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/start_conversation:
    post:
      description: Start a conversation for a location
      tags: [ message ]
      operationId: startConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/mark_conversation_as_read:
    post:
      description: Mark a conversation as read for a location
      tags: [ message ]
      operationId: markConversationAsRead

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/mark_conversation_as_unread:
    post:
      description: Marks all messages after a certain message as unread
      tags: [ message ]
      operationId: markConversationAsUnread

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/count_messages:
    get:
      description: Count messages for a location
      tags: [ message ]
      operationId: countMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/fetch_messages:
    get:
      description: Fetch messages for a location
      tags: [ message ]
      operationId: fetchMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/fetch_reply_suggestions:
    get:
      description: Fetch reply suggestions for a conversation
      tags: [ message ]
      operationId: getReplySuggestions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/{messageId}:
    get:
      description: Fetch a message
      tags: [ message ]
      operationId: getMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/start_web_chat:
    post:
      description: Creates a message via a web-chat widget
      tags: [ message ]
      operationId: startWebChat

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/web/receive_message:
    post:
      description: Webhook event for when TalkJS message comes in
      tags: [ message ]
      operationId: receiveWebMessage

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/ses/receive_event:
    post:
      description: Webhook event for SES events
      tags: [ message ]
      operationId: receiveSESEvent

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/fetch_conversations:
    get:
      description: Fetch all the internal conversations for a user
      tags: [ message ]
      operationId: fetchInternalConversations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/fetch_messages:
    get:
      description: Fetch messages for internal conversation
      tags: [ message ]
      operationId: fetchInternalMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/start_conversation:
    post:
      description: Creates an internal conversation
      tags: [ message ]
      operationId: startInternalConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/unread_count:
    get:
      description: Fetches a list of unread counts for internal conversations
      tags: [ message ]
      operationId: fetchInternalUnreadCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/count_messages:
    get:
      description: Count the number of internal messages that are within a conversation
      tags: [ message ]
      operationId: countInternalMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/fetch_members:
    get:
      description: Fetches all the members in a conversation
      tags: [ message ]
      operationId: fetchMembers

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"


  /message/internal/{conversationId}:
    get:
      description: Fetches a conversation and all of it's contextual data
      tags: [ message ]
      operationId: fetchInternalConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/update_user:
    post:
      description: Updates user preferences for a specific conversation
      tags: [ message ]
      operationId: updateInternalConversationUserPreferences

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/media/fetch:
    get:
      description: Fetches media for a Team Chat conversation
      tags: [ message ]
      operationId: fetchTeamChatMedia

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/search:
    get:
      description: Searches a team chat conversation for messages that match the provided search term
      tags: [ message ]
      operationId: searchInternalConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/update:
    post:
      description: Updates an internal conversation
      tags: [ message ]
      operationId: updateInternalConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/add_members:
    post:
      description: Add members from an internal conversation
      tags: [ message ]
      operationId: addInternalConversationMember

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/remove_members:
    post:
      description: Removes members from an internal conversation
      tags: [ message ]
      operationId: removeInternalConversationMember

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/toggle_hide:
    post:
      description: Hide or unhides a conversation for a user
      tags: [ message ]
      operationId: toggleHide

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/update_message:
    post:
      description: Updates a team chat message
      tags: [ message ]
      operationId: updateMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/mark_as_unread:
    post:
      description: Marks a team chat message as unread
      tags: [ message ]
      operationId: markAsUnread

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/purge_messages:
    post:
      description: Purges team chat messages within a certain range
      tags: [ message ]
      operationId: purgeTeamchatMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/internal/{conversationId}/archive_conversation:
    post:
      description: Archive team chat conversation
      tags: [ message ]
      operationId: archiveTeamchatConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/queue/fetch:
    get:
      description: Fetch the teamchat queued messages that are within a conversation
      tags: [ message ]
      operationId: fetchTeamChatScheduledMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/queue/conversations:
    get:
      description: Fetch the teamchat conversations that have queued messages
      tags: [ message ]
      operationId: fetchConversationsWithQueuedMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/queue/count:
    get:
      description: Count the number of teamchat queued messages that are within a conversation
      tags: [ message ]
      operationId: countTeamChatScheduledMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/queue/create:
    post:
      description: Create a teamchat queued message for a conversation
      tags: [ message ]
      operationId: createTeamChatScheduledMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/queue/{id}/update:
    post:
      description: Update a teamchat queued message
      tags: [ message ]
      operationId: updateTeamChatScheduledMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/conversation_group/fetch:
    get:
      description: Fetch the teamchat conversation groups
      tags: [ message ]
      operationId: fetchTeamChatConversationGroups

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/teamchat/conversation_group/create:
    post:
      description: Create a teamchat conversation group for conversations
      tags: [ message ]
      operationId: createTeamChatConversationGroup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /message/teamchat/conversation_group/{id}/update:
    post:
      description: Update a teamchat conversation group message
      tags: [ message ]
      operationId: updateTeamChatConversationGroup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/facebook/receive_message:
    get:
      description: Verifies the Facebook Webhook
      tags: [ message ]
      operationId: verifyFacebookWebhook

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

    post:
      description: Webhook event for when Facebook Messages are received
      tags: [ message ]
      operationId: receiveFacebookMessage

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/google/receive_message:
    post:
      description: Webhook event when Google Business Messages are received
      tags: [ message ]
      operationId: receiveGoogleBusinessMessage

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/media/upload:
    post:
      description: Uploads a media item to the demandhub media S3 bucket
      tags: [ message ]
      operationId: uploadMedia
      consumes:
        - 'multipart/form-data'

      security:
        - authorization: [ default ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/media/{mediaId}:
    get:
      description: Retrieves a media item from S3 and serves it as an image
      tags: [ message ]
      operationId: getMedia

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/{messageId}/spam:
    post:
      description: Marks a message as spam
      tags: [ message ]
      operationId: markMessageAsSpam

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/{messageId}/update:
    post:
      description: Updates a message
      tags: [ message ]
      operationId: updateCustomerMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/{messageId}/delete:
    post:
      description: Marks a message as deleted
      tags: [ message ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_pin/fetch:
    get:
      description: Fetch message pins
      tags: [ message ]
      operationId: fetchMessagePins

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_pin/{messageId}/pin:
    post:
      description: Pin or unpin a message
      tags: [ message ]
      operationId: pinMessage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/bandwidth/callback:
    post:
      description: Webhook used by Bandwidth to update the status of a message
      tags: [ message ]
      operationId: bandwidthCallback

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/bandwidth/receive_message:
    post:
      description: Webhook used by Bandwidth v2 to receive events
      tags: [ message ]
      operationId: bandwidthV2Webhook

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/bandwidth/subscriptions:
    post:
      description: Webhook used by Bandwidth v2 to receive Account subscription events
      tags: [ message ]
      operationId: bandwidthV2SubscriptionWebhook
      consumes:
        - 'application/xml'

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/deep_chat/receive_message:
    post:
      description: receive message from deep chat
      tags: [ message ]
      operationId: receiveDeepMessage

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Message received
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message/create_third_party:
    post:
      description: Create a message from a third party API
      tags: [ message ]
      operationId: createThirdParty

      parameters:
        - in: body
          name: requestData
          description: Message Data
          schema:
            type: object
            required:
              - name
              - medium
              - message
            properties:
              name:
                type: string
              phone:
                type: string
              email:
                type: string
              medium:
                type: string
              subject:
                type: string
              content:
                type: string
              content_html:
                type: string
              send_after:
                type: string
              force:
                type: boolean

      security: [
        third_party_auth: [ third_party_create_message_request ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /voice/bandwidth/event:
    post:
      description: Webhook used by Bandwidth v2 to receive voice events
      tags: [ voice ]
      operationId: bandwidthV2Webhook

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /voice/voicemail/fetch:
    get:
      description: Fetch voicemail for a location
      tags: [ voice ]
      operationId: fetchVoicemail

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /voice/voicemail/config:
    get:
      description: Fetch voicemail for a location
      tags: [ voice ]
      operationId: fetchVoicemailConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /voice/voicemail/{voicemailId}/update:
    post:
      description: Update a voicemail for a location
      tags: [ voice ]
      operationId: updateVoicemail

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/webchat/{locationPublicId}/nucleus_config:
    get:
      description: Fetch the nucleus config
      tags: [ location ]
      operationId: getWidgetConfigs

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/webchat/{companyPublicId}:
    get:
      description: Fetch the Location web-chat specific info for a company via public id
      tags: [ location ]
      operationId: getWebChatLocationInformation

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/requester_list:
    get:
      description: List of requesters for location
      tags: [ location ]
      operationId: requesterList

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/delete:
    post:
      description: Delete a location
      tags: [ location ]
      operationId: deleteLocation

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/business_hours_for_day:
    get:
      description: Fetch business hours for a day
      tags: [ location ]
      operationId: fetchBusinessHoursForDay

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer

        - in: query
          name: day
          type: string
          required: true
          description: day of the week

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/websites:
    get:
      description: Fetch location websites
      tags: [ location ]
      operationId: fetchWebsites

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/widget_config:
    get:
      description: Get Widget Config
      tags: [ location ]
      operationId: getWidgetConfig

      security:
        - authorization: [ default ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/widget_status:
    post:
      description: Update Widget Status
      tags: [ location ]
      operationId: updateWidgetStatus

      security:
        - authorization: [ default, audit_log ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/request_count:
    get:
      description: Review request count for location
      tags: [ location ]
      operationId: requestCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/avg_rating:
    get:
      description: Average rating for a location
      tags: [ location ]
      operationId: avgRating

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/click_rate:
    get:
      description: Click rate for a location
      tags: [ location ]
      operationId: clickRate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/monthly_request_count:
    get:
      description: Invites this month for location
      tags: [ location ]
      operationId: monthlyRequestCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/review_goal:
    get:
      description: Fetch review goal data for a location
      tags: [ location ]
      operationId: fetchReviewGoal

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/invite_goal:
    get:
      description: Fetch invite goal data for a location
      tags: [ location ]
      operationId: fetchInviteGoal

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/review_and_ratings:
    get:
      description: Review counts and ratingd for a location by review site
      tags: [ location ]
      operationId: getReviewAndRatings

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/monthly_review_count:
    get:
      description: Review counts by month for location
      tags: [ location ]
      operationId: monthlyReviewCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/monthly_review_replies_count:
    get:
      description: Review replies this month for location
      tags: [ location ]
      operationId: monthlyReviewRepliesCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/request_count_by_month:
    get:
      description: Review counts by month for location
      tags: [ location ]
      operationId: requestCountByMonth

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/review_count_by_month:
    get:
      description: Review counts by month for location
      tags: [ location ]
      operationId: reviewCountByMonth

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/insights:
    get:
      description: Insights by the day for a location
      tags: [ location ]
      operationId: insights

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/monthly_insights:
    get:
      description: Insights by the month for a location
      tags: [ location ]
      operationId: getMonthlyInsights

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer

        - in: query
          name: startDate
          type: string
          required: true
          description: start date

        - in: query
          name: endDate
          type: string
          required: true
          description: end date

        - in: query
          name: siteId
          type: integer
          required: true
          description: site id

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/leaderboard:
    get:
      description: Leaderboard for a location
      tags: [ location ]
      operationId: leaderboard

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/add_user:
    post:
      description: Add a user for a location
      tags: [ location ]
      operationId: addUser

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/remove_user:
    post:
      description: Remove a user for a location
      tags: [ location ]
      operationId: removeUser

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/{locationId}/trigger:
    post:
      description: Manually trigger an endpoint
      tags: [ crm_integrations ]
      operationId: integrationTrigger

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer

        - in: body
          name: crmData
          required: true
          description: CRM data object
          schema:
            type: object
            required:
              - locationId
              - crmType
            parameters:
              crmType:
                type: string
                enum: ["cdk"]
              locationId:
                type: integer
              metadata:
                type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm_integrations/fetch_apps:
    get:
      description: Get the list of CRM apps
      tags: [ crm_integrations ]
      operationId: fetchApps

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/schedules/fetch:
    get:
      description: Get schedules for a location
      tags: [ location ]
      operationId: getSchedulesForLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/schedules/update:
    post:
      description: Update a schedule
      tags: [ location ]
      operationId: updateSchedules

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/reengagements/{reengagementId}/update:
    post:
      description: Update a reengagement for a location
      tags: [ reengagements ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/reengagements/{reengagementId}/fetch:
    get:
      description: Get a single reengagement for a location
      tags: [ reengagements ]
      operationId: fetchReengagement

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/reengagements/config:
    get:
      description: Get reengagements configuration data
      tags: [ reengagements ]
      operationId: fetchConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/reengagements/fetch:
    get:
      description: Get reengagements for a location
      tags: [ reengagements ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/reengagements/create:
    post:
      description: Get reengagements for a location
      tags: [ reengagements ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/webhooks/{webhookId}/fetch:
    get:
      description: Get a webhook
      tags: [ webhooks ]
      operationId: fetchWebhook

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/webhooks/{webhookId}/update:
    post:
      description: Update webhooks for a location
      tags: [ webhooks ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/webhooks/fetch:
    get:
      description: Get webhooks for a location
      tags: [ webhooks ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/webhooks/create:
    post:
      description: Create webhooks for a location
      tags: [ webhooks ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/firewall/fetch:
    get:
      description: Fetch a list of firewall rules
      tags: [ admin ]
      operationId: fetchFirewallRules

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/firewall/create:
    post:
      description: Create a single firewall rule
      tags: [ admin ]
      operationId: createFirewallRule

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/firewall/{firewallId}:
    get:
      description: Fetch a single firewall rule
      tags: [ admin ]
      operationId: fetchFirewallRule

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/firewall/{firewallId}/update:
    post:
      description: Update a single firewall rule
      tags: [ admin ]
      operationId: updateFirewallRule

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/has_integrations:
    get:
      description: Getting a list of of Messenger Integration by location id
      tags: [ location ]
      operationId: hasIntegrations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/create:
    post:
      description: Create a location
      tags: [ location ]
      operationId: create

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/messenger_integration/update:
    post:
      description: Update a messenger integration
      tags: [ location ]
      operationId: updateMessengerIntegration

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/update:
    post:
      description: Update a location
      tags: [ location ]
      operationId: update

      security: [
          authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/fetch:
    get:
      description: Fetch locations
      tags: [ location ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/verticals:
    get:
      description: Fetch all assignabled verticals
      tags: [ location ]
      operationId: getVerticals

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/location_features_config:
    get:
      description: Get a location feature config
      tags: [ location ]
      operationId: getLocationFeaturesConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}:
    get:
      description: Get location
      tags: [ location ]
      operationId: getLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/contacts:
    get:
      description: Get all contacts for location
      tags: [ location ]
      operationId: getContacts

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: status
        type: string
        description: status of a contact

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationHandle}/check_in:
    get:
      description: Fetches data specific to a location, only for public viewing
      tags: [ location ]
      operationId: fetchCheckInData

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

    post:
      description: Allows customers to check into a location
      tags: [ location ]
      operationId: checkIn

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationHandle}/micro_site:
    get:
      description: Fetches all the data required to render a micro site for a user
      tags: [ location ]
      operationId: fetchMicroSiteData

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationHandle}/micro_site/reviews:
    get:
      description: Fetch review data to be shown on a micro site
      tags: [ location ]
      operationId: fetchMicroSiteReviews

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationHandle}/micro_site/banner_image:
    post:
      description: Upload the banner image
      tags: [ location ]
      operationId: uploadMicroSiteBanner
      consumes:
        - 'multipart/form-data'

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/facebook/fetch_pages:
    post:
      description: Fetches the Facebook pages that the user owns
      tags: [ location ]
      operationId: fetchPages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/facebook/subscribe_to_page:
    post:
      description: Subscribe to a facebook page
      tags: [ location ]
      operationId: subscribeToPage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/facebook/unsubscribe_from_page:
    post:
      description: Unsubscribe from a facebook page
      tags: [ location ]
      operationId: unsubscribeFromPage

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/create_agent:
    post:
      description: Create a GBM Agent
      tags: [ location ]
      operationId: gbmCreateAgent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/update_agent:
    post:
      description: Update a GBM Agent
      tags: [ location ]
      operationId: gbmUpdateAgent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/create_integration:
    post:
      description: Create a GBM Messenger Integration
      tags: [ location ]
      operationId: gbmCreateIntegration

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/sync_integration:
    post:
      description: Synchronize a GBM Messenger Integration
      tags: [ location ]
      operationId: gbmSyncIntegration

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/fetch_integration:
    get:
      description: Fetch a GBM Messenger Integration
      tags: [ location ]
      operationId: gbmFetchIntegration

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/agent_logo:
    post:
      description: Upload an image to be used for the GBM Agent Logo
      tags: [ location ]
      operationId: gbmSetAgentLogo
      consumes:
        - 'multipart/form-data'

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/add_location:
    post:
      description: Add a GMB location to a GBM Agent
      tags: [ location ]
      operationId: gbmAddLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/verify_agent:
    post:
      description: Verify an GBM Agent
      tags: [ location ]
      operationId: gbmVerifyAgent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/verify_location:
    post:
      description: Verify locations for GBM
      tags: [ location ]
      operationId: gbmVerifyLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/launch_agent:
    post:
      description: Launch a GBM Agent
      tags: [ location ]
      operationId: gbmLaunchAgent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/launch_location:
    post:
      description: Launch a GBM Location
      tags: [ location ]
      operationId: gbmLaunchLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/gbm/check_status:
    get:
      description: Check the statuses of the agent and location for a GBM Messenger Integration 
      tags: [ location ]
      operationId: gbmCheckStatus

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/overall_ratings:
    get:
      description: Get overall ratings
      tags: [ location ]
      operationId: overallRatings

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/messages:
    get:
      description: Get monthly/daily messages analytics
      tags: [ location ]
      operationId: getMessagesAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/keywords:
    get:
      description: Get monthly/daily keyword analytics
      tags: [ location ]
      operationId: getKeywordAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/lead_source:
    get:
      description: Get monthly/daily lead source analytics
      tags: [ location ]
      operationId: getLeadSourceAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/lead_source_total:
    get:
      description: Get monthly/daily lead source analytics
      tags: [ location ]
      operationId: getLeadSourceTotalAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/lead_source_time_of_day_analytics:
    get:
      description: Get monthly/daily lead source analytics
      tags: [ location ]
      operationId: getLeadSourceTimeOfDayAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/message_delivery:
    get:
      description: Get monthly/daily messages delivery stats
      tags: [ location ]
      operationId: getMessageDeliveryAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/failed_messages:
    get:
      description: Get messages that have failed to send
      tags: [ location ]
      operationId: getFailedMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/message_delivery_rate:
    get:
      description: Get the amount of failed and successfull messages
      tags: [ location ]
      operationId: getMessageDeliveryRate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/gmb_stats:
    get:
      description: Get GMB Location Stats for a location
      tags: [ location ]
      operationId: getGmbLocationStats

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/gmb_stats_analysis:
    get:
      description: Get GMB Location Stats for a location
      tags: [ location ]
      operationId: getGmbLocationStatsAnalysis

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/email_reports:
    get:
      description: Get monthly/daily email reports analytics
      tags: [ location ]
      operationId: getEmailReportAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/review_counts_for_dates:
    post:
      description: Get rating count for date array
      tags: [ location ]
      operationId: getReviewCountsForDates

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/overall_snapshot:
    get:
      description: Get overall snapshot from join date to today's date
      tags: [ location ]
      operationId: getOverallSnapshot

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/fetch_nps:
    get:
      description: Get the Net Promoter Score for a location
      tags: [ location ]
      operationId: fetchNps

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/overall_replies:
    get:
      description: Get overall replies stats from join date to today's date
      tags: [ location ]
      operationId: getOverallReplies

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/widget:
    get:
      description: Get monthly/daily widget analytics
      tags: [ location ]
      operationId: getWidgetAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/contact_subscribers:
    get:
      description: Get daily/monthly contact subscriber data
      tags: [ location ]
      operationId: getSubscriberAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/conversions:
    get:
      description: Get daily/monthly contact conversion data
      tags: [ location ]
      operationId: getConversionsAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/reengagement_attribution:
    get:
      description: Get Reengagement attribution data
      tags: [ location ]
      operationId: getReengagementAttributionAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/total_conversions_count:
    get:
      description: Get total conversion numbers
      tags: [ location ]
      operationId: getTotalConversionsCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/lifetime_reengagement_conversions:
    get:
      description: Get lifetime reengagement conversions data
      tags: [ location ]
      operationId: getLifetimeReengagementConversions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/reengagements_sent_count:
    get:
      description: Get the total number of reengagements sent
      tags: [ location ]
      operationId: getReengagementsSentCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /location/{locationId}/analytics/total_payments_count:
    get:
      description: Get total payment numbers
      tags: [ location ]
      operationId: getTotalPaymentsCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/scheduled_messages:
    get:
      description: Get daily/monthly scheduled message data
      tags: [ location ]
      operationId: getScheduledMessageAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/conversion_appointments:
    get:
      description: Get all the appointments related to conversions
      tags: [ location ]
      operationId: getConversionsAppointments

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/analytics/location_widgets_installed:
    get:
      description: Check if a location's widgets have been loaded recently
      tags: [ location ]
      operationId: getLocationWidgetsInstalled

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/invite_queue_details:
    get:
      description: Get invite queue details such as the deadline time and the count
      tags: [ location ]
      operationId: getInviteQueueDetails

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/location_features:
    get:
      description: Get a location feature
      tags: [ location ]
      operationId: getLocationFeatures

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/location_features/update:
    post:
      description: Update location features
      tags: [ location ]
      operationId: updateLocationFeatures

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/create:
    post:
      description: Create a company
      tags: [ company ]
      operationId: createCompany

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/fetch:
    get:
      description: Fetch all companies
      tags: [ company ]
      operationId: fetchCompanies

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/performance:
    get:
      description: Get performance for all companies for all locations
      tags: [ company ]
      operationId: performance

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/group_permissions:
    get:
      description: Get group permissions information
      tags: [ company ]
      operationId: getGroupPermissionInfo

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/group_permissions/{groupPermissionId}:
    post:
      description: Update a group permission
      tags: [ company ]
      operationId: updateGroupPermission

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/purge_test_data:
    post:
      description: Purges all test company data that was generated from automated testing
      tags: [ company ]
      operationId: purgeTestData

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/add_test_review:
    post:
      description: Added test review data for testing company
      tags: [ company ]
      operationId: addTestReview

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/update:
    post:
      description: Update a company
      tags: [ company ]
      operationId: updateCompany

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}:
    get:
      description: Get a company
      tags: [ company ]
      operationId: getCompany

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/leaderboard:
    get:
      description: Get a leaderboard for a company
      tags: [ company ]
      operationId: getLeaderboard

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/analytics/email_reports:
    get:
      description: Get monthly/daily email reports analytics
      tags: [ company ]
      operationId: getEmailReportAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/mark_favorite:
    post:
      description: Mark a company as a favorite for the calling user
      tags: [ company ]
      operationId: markFavorite

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/unmark_favorite:
    post:
      description: Unmark a company as a favorite for the calling user
      tags: [ company ]
      operationId: unmarkFavorite

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/invite_users:
    post:
      description: Invite users to sign up for DemandHub
      tags: [ company ]
      operationId: inviteUsers

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/resend_user_invite:
    post:
      description: Resend a user invite to sign up for DemandHub
      tags: [ company ]
      operationId: resendUserInvite

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/cancel_user_invite:
    post:
      description: Cancel a user invite to sign up for DemandHub
      tags: [ company ]
      operationId: cancelUserInvite

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/group_permissions:
    get:
      description: Get the group permissions for a company
      tags: [ company ]
      operationId: getGroupPermissions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/user_invites/fetch:
    get:
      description: Fetch all user invites
      tags: [ company ]
      operationId: fetchUserInvites

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/user_invites/{guid}:
    get:
      description: Get a user invite
      tags: [ company ]
      operationId: getUserInvite

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/user_invites/{guid}/create:
    post:
      description: Create a user from the 
      tags: [ company ]
      operationId: createUserFromInvite

      security: []

      parameters:
        - in: body
          name: requestData
          required: true
          description: User sign up data
          schema:
            type: object
            required:
              - guid
              - companyId
              - groupId
              - email
              - firstName
              - lastName
              - loginMethod
            properties:
              guid:
                type: string
              companyId:
                type: integer
              groupId:
                type: integer
              email:
                type: string
              phone:
                type: string
              firstName:
                type: string
              lastName:
                type: string
              password:
                type: string
              loginMethod:
                type: string
                enum: ["email", "google"]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/{locationId}/templates:
    get:
      description: Gets all message templates/provided message templates for a particular location
      tags: [ message_template ]
      operationId: fetchAll

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: includeMedia
        type: boolean
        required: false
        description: include media in templates

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/{templateId}/delete:
    post:
      description: Delete Template by ID
      tags: [ message_template ]
      operationId: deleteTemplateById

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/{templateId}/create:
    post:
      description: Create new template for a location
      tags: [ message_template ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/{templateId}/update:
    post:
      description: Update template
      tags: [ message_template ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/{templateId}/fetch:
    get:
      description: Get a template by ID
      tags: [ message_template ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: replaceMessageVariables
        type: boolean
        required: false
        description: If the message template should have it's message variables replaced with values

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/types:
    get:
      description: Get all possible types of a template
      tags: [ message_template ]
      operationId: getTypes

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/message_variables:
    get:
      description: Get all message variable replacements
      tags: [ message_template ]
      operationId: getMessageVariables

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /message_template/fetch_third_party:
    get:
      description: Get all message templates of a certain type for third party
      tags: [ message_template ]
      operationId: getMessageTemplatesThirdParty

      security: [
        third_party_auth: [ third_party_fetch_templates ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/fetch_not_in_location:
    get:
      description: Get all review sites not present in a location
      tags: [ review_sites ]
      operationId: getReviewSitesNotInLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/fetch:
    get:
      description: Get all review sites
      tags: [ review_sites ]
      operationId: getAllReviewSites

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/check_gmb_fb_status:
    get:
      description: Get gmb and fb status
      tags: [ review_sites ]
      operationId: checkGmbAndFbStatus

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /timezones/fetch:
    get:
      description: Get all timezones
      tags: [ timezones ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/update:
    post:
      description: Update contact
      tags: [ contacts ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/delete:
    post:
      description: Delete contact
      tags: [ contacts ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/merge:
    post:
      description: Merge contact with another contact
      tags: [ contacts ]
      operationId: mergeContact

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/fetch_assigned_tags:
    get:
      description: Fetch the tags that are assigned to a contact
      tags: [ contacts ]
      operationId: fetchAssignedTags

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/find:
    get:
      description: Fetch contact by id, phone or email
      tags: [ contacts ]
      operationId: findContact

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}:
    get:
      description: Get contact by id
      tags: [ contacts ]
      operationId: getContactById

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/media/fetch:
    get:
      description: Fetches all the media for this particular contact
      tags: [ contacts ]
      operationId: fetchMedia

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/notes/create:
    post:
      description: Add a note to a contact (Conversation)
      tags: [ contacts ]
      operationId: addContactNote

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/notes/fetch:
    get:
      description: Fetch the notes for a contact
      tags: [ contacts ]
      operationId: fetchNotes

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/notes/{noteId}/fetch:
    get:
      description: Fetch a note for a contact
      tags: [ contacts ]
      operationId: fetchNote

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/notes/{noteId}/update:
    post:
      description: Updates the content of a note for a contact
      tags: [ contacts ]
      operationId: updateNote

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/notes/{noteId}/delete:
    post:
      description: Deletes a note for a contact
      tags: [ contacts ]
      operationId: deleteNote

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/download_conversation:
    get:
      description: Get a PDF of a conversation with this contact
      tags: [ contacts ]
      operationId: downloadConversation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/custom_fields:
    get:
      description: Fetch a contacts custom fields
      tags: [ contacts ]
      operationId: fetchCustomFields

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/{contactId}/custom_fields/update:
    post:
      description: Update a contacts custom fields
      tags: [ contacts ]
      operationId: updateCustomFields

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/bulk_update:
    post:
      description: Update bulk things for many contacts
      tags: [ contacts ]
      operationId: bulkUpdateContacts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contacts/create_third_party:
    post:
      description: Create a contact from a third party API
      tags: [ contacts ]
      operationId: createThirdParty

      parameters:
        - in: body
          name: requestData
          description: Contact Data
          schema:
            type: object
            required:
              - name
            properties:
              name:
                type: string
              phone:
                type: string
              email:
                type: string
              tags: 
                type: array
                items:
                  type: string

      security: [
        third_party_auth: [ third_party_create_contact_request ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /recommendations/google/place/autocomplete:
    get:
      description: Get suggestions from google place api
      tags: [ recommendations ]
      operationId: getPlacesSuggestions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /recommendations/google/place/{placeId}:
    get:
      description: Get place details for provided placeid
      tags: [ recommendations ]
      operationId: getPlacesDetails

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /synced_appointment/create:
    post:
      description: Creates a synced appointment from a CRM for a location
      tags: [ synced_appointment ]
      operationId: create

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /synced_appointment/create_third_party:
    post:
      description: Creates a synced appointment from a CRM for a location using third party auth
      tags: [ synced_appointment ]
      operationId: createThirdParty

      security: [
        third_party_auth: [ third_party_create_synced_appointment ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /synced_appointment/download_calendar_event:
    get:
      description: Downloads an ics calendar event
      tags: [ synced_appointment ]
      operationId: downloadCalendarEvent

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /synced_appointment/fetch:
    get:
      description: Fetch synced appointments
      tags: [ synced_appointment ]
      operationId: fetchAppointments

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /synced_appointment/{appointmentId}/fetch:
    get:
      description: Fetch a synced appointment
      tags: [ synced_appointment ]
      operationId: getAppointment

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: appointmentId
          type: integer
          required: true
          description: appointment id

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /synced_appointment/{appointmentId}/update:
    post:
      description: Fetch a synced appointment
      tags: [ synced_appointment ]
      operationId: updateAppointment

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: appointmentId
          type: integer
          required: true
          description: appointment id

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/update/:
    post:
      description: Update Review Sites for a location
      tags: [ review_sites ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/create:
    post:
      description: Creates a new review site for a location
      tags: [ review_sites ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer
          minimum: 1
        - in: body
          name: reviewSite
          description: Review site to be created
          schema:
            type: object
            required:
              - locationId
              - name
              - companyId
            properties:
              locationId:
                type: integer
                minimum: 1
              name:
                type: string
              company_id:
                type: integer
                minimum: 1
              place_id:
                type: string
              is_custom:
                type: boolean
              post_reviews_url:
                type: string
              reply_reviews_url:
                type: string
              view_reviews_url:
                type: string
              monitored_site:
                type: boolean
              page_color:
                type: string
              page_bcolor:
                type: string
              footer_color:
                type: string
              footer_bcolor:
                type: string
              button_color:
                type: string
              button_bcolor:
                type: string
              domain:
                type: string
              review_site_only:
                type: boolean
              icon_url:
                type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/reorder/:
    post:
      description: Reorder Review Sites for a location
      tags: [ review_sites ]
      operationId: reorder

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /review_sites/{locationId}/delete/:
    post:
      description: Delete Review Sites for a location
      tags: [ review_sites ]
      operationId: remove

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/create_referral:
    post:
      description: Create new referral from webapp
      tags: [ crm ]
      operationId: createReferral

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/fetch:
    get:
      description: Fetch leads
      tags: [ crm ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/send_reputation_report:
    post:
      description: Generate reputation report
      tags: [ crm ]
      operationId: generateReputationReport

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/create_lead:
    post:
      description: Create new lead
      tags: [ crm ]
      operationId: createLead

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/compare_tool/create:
    post:
      description: Create a new lead from the compare tool
      tags: [ crm ]
      operationId: createCompareToolLead

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/compare_tool/fetch:
    get:
      description: Create a new lead from the compare tool
      tags: [ crm ]
      operationId: fetchCompareToolLead

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/compare_tool/fetch_all:
    get:
      description: Fetch all of the compare tool leads
      tags: [ crm ]
      operationId: fetchAllCompareToolLeads

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/compare_tool/send_report:
    post:
      description: Send the compare tool report
      tags: [ crm ]
      operationId: sendCompareToolLeadReport

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /crm/send_lead:
    post:
      description: Send a lead to the location's CRM
      tags: [ crm ]
      operationId: sendLead

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /audit_logs/purge:
    post:
      description: Purge Audit Logs
      tags: [ audit_log ]
      operationId: purgeAuditLogs

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /push/device/register:
    post:
      description: Registers a new device token
      tags: [ push ]
      operationId: registerDevice

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /push/{pushNotificationId}/mark_received:
    get:
      description: Marks a push notification as received
      tags: [ push ]
      operationId: markReceived

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /reviews_widget/fetch:
    get:
      description: Gets all reviews data
      tags: [ reviews_widget ]
      operationId: fetch

      security: [
        widget_auth: [ reviews_widget_fetch ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /reviews_widget/configuration:
    get:
      description: Gets configuration data for reviews widget
      tags: [ reviews_widget ]
      operationId: getReviewsWidgetConfiguration

      security: [
        widget_auth: [ reviews_widget_fetch ]
      ]

      parameters:
      - in: query
        name: name
        type: string
        enum: ['webchat','reviews_widget','embedded_reviews_widget']
        required: true
        description: app name

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /reviews_widget/nucleus_config:
    get:
      description: Gets configuration data for nucleus
      tags: [ reviews_widget ]
      operationId: getWidgetConfigsForBacksideCompatibility

      security: [
        widget_auth: [ reviews_widget_fetch ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /webchat/fetch_config:
    get:
      description: Gets configuration data for webchat
      tags: [ location ]
      operationId: fetchConfig

      security: [
        widget_auth: [ webchat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/webchat/{locationId}/fetch_profile_pictures:
    get:
      description: Get profile picture for all users in a location
      tags: [ location ]
      operationId: getProfilePictures

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/webchat/{locationId}/update_config:
    post:
      description: Update Wevbchat config
      tags: [ location ]
      operationId: updateWebchatConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/connect_gmb:
    post:
      description: Connect a location to DH
      tags: [ location ]
      operationId: connectGMB

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_locations:
    get:
      description: Connect a location to DH
      tags: [ location ]
      operationId: getGMBLocations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/add_dh_to_gmb_location:
    post:
      description: Connect a location to DH
      tags: [ location ]
      operationId: addDHToGMBLocation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/custom_fields:
    get:
      description: Fetch custom fields for a location
      tags: [ location ]
      operationId: getCustomFields

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/custom_fields/create:
    post:
      description: Create a custom field for a location
      tags: [ location ]
      operationId: createCustomField

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/custom_fields/update:
    post:
      description: Update custom field for a location
      tags: [ location ]
      operationId: updateCustomField

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/custom_fields/{fieldName}:
    get:
      description: Fetch a single custom field
      tags: [ location ]
      operationId: getCustomField

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/dashboards/fetch:
    get:
      description: Fetch dashboards for a location
      tags: [ location ]
      operationId: getDashboards

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: isDefault
        type: boolean
        description: is the default dashboard

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/dashboards/create:
    post:
      description: Create a dashboard for a location
      tags: [ location ]
      operationId: createDashboard

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/dashboards/{dashboardId}/update:
    post:
      description: Update dashboard for a location
      tags: [ location ]
      operationId: updateDashboard

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/dashboards/{dashboardId}/fetch:
    get:
      description: Fetch a single dashboard
      tags: [ location ]
      operationId: getDashboard

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: isDefault
        type: boolean
        description: is the default dashboard

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/fetch:
    get:
      description: Fetch GMB Local Posts for a location
      tags: [ location ]
      operationId: getGmbLocalPosts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/media/upload:
    post:
      description: Upload media for a GMB Local Post for a location
      tags: [ location ]
      operationId: uploadGmbLocalPostMedia
      consumes:
        - 'multipart/form-data'

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/media/delete:
    post:
      description: Upload media for a GMB Local Post for a location
      tags: [ location ]
      operationId: deleteGmbLocalPostMedia

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/create:
    post:
      description: Create a GMB Local Post for a location
      tags: [ location ]
      operationId: createGmbLocalPost

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/update:
    post:
      description: Update a GMB Local Post for a location
      tags: [ location ]
      operationId: updateGmbLocalPost

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/gmb_local_posts/delete:
    post:
      description: Delete a GMB Local Post for a location
      tags: [ location ]
      operationId: deleteGmbLocalPost

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/a2p/submit:
    post:
      description: Submit data to the a2p registery using via a sms provider
      tags: [ location ]
      operationId: submitA2P

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/a2p/metadata:
    get:
      description: Get meta data related to a2p submissions
      tags: [ location ]
      operationId: getA2PMetaData

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /location/{locationId}/a2p/reset:
    post:
      description: Restart and the state of a2p and remove existing campaigns
      tags: [ location ]
      operationId: resetA2p

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /email/send_bulk_invite_email:
    post:
      description: Send an email
      tags: [ email ]
      operationId: sendBulkInviteEmail

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/fetch:
    get:
      description: Get a list of sign ups
      tags: [ sign_ups ]
      operationId: fetchSignUps

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/chargebee_details:
    get:
      description: Chargebee sign in details
      tags: [ sign_ups ]
      operationId: fetchChargebeeDetails

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/create:
    post:
      description: Create a single of sign up
      tags: [ sign_ups ]
      operationId: createSignUp

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/{signUpId}:
    get:
      description: Get a single of sign up
      tags: [ sign_ups ]
      operationId: fetchSignUp

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/{signUpId}/update:
    post:
      description: Update a single of sign up
      tags: [ sign_ups ]
      operationId: updateSignUp

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/public/{signUpGuid}:
    get:
      description: Get a Sign Up for public viewing
      tags: [ sign_ups ]
      operationId: fetchPublicSignUp

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/public/{signUpGuid}/download:
    get:
      description: Download Sign Up Contract Pdf
      tags: [ sign_ups ]
      operationId: downloadPdf

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/public/{signUpGuid}/confirm_payment:
    post:
      description: Complete Sign Up
      tags: [ sign_ups ]
      operationId: confirmPayment

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/public/{signUpGuid}/complete:
    post:
      description: Complete Sign Up
      tags: [ sign_ups ]
      operationId: completeSignUp

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/sign_ups/webhooks/docuseal:
    post:
      description: Handles DocuSeal webhooks
      tags: [ sign_ups ]
      operationId: handleDocuSealWebhook

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/lagging_locations:
    get:
      description: Get all details of lagging locations
      tags: [ customer_success ]
      operationId: fetchLaggingLocations

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/lagging_location_details:
    get:
      description: Get all details of lagging locations
      tags: [ customer_success ]
      operationId: fetchLaggingLocationDetails

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: locationId
        type: integer
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/lagging_automations:
    get:
      description: Get all details of lagging crm automations
      tags: [ customer_success ]
      operationId: fetchLaggingAutomations

      security: [
        authorization: [ default ]
      ]

      parameters:
      - in: query
        name: favoritesOnly
        type: boolean
        required: true
        description: favorite only toggle

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/team_chat:
    get:
      description: Get team chat Customer Success analytics
      tags: [ customer_success ]
      operationId: fetchTeamChatCSAnalytics

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/feature_summary:
    get:
      description: Get feature summary for customer success
      tags: [ customer_success ]
      operationId: fetchFeatureSummary

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/feature_matrix_config:
    get:
      description: Get Feature matrix meta data
      tags: [ customer_success ]
      operationId: fetchFeatureMatrixConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer_success/location_features_list:
    get:
      description: Get a list of locations with details about which features are enabled
      tags: [ customer_success ]
      operationId: fetchLocationFeaturesList

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/token:
    get:
      description: Gets a token from api_keys table
      tags: [ api_keys ]
      operationId: getToken

      parameters:
        - in: query
          name: name
          type: string
          required: true
          description: Name of the app for which the token is required
        - in: query
          name: locationId
          type: integer
          required: true
          description: Location for which the token is required

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/{locationId}/fetch:
    get:
      description: Gets all api keys for company
      tags: [ api_keys ]
      operationId: fetch

      parameters:
        - in: path
          name: locationId
          type: integer
          required: true
          description: location id

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/fetch_names:
    get:
      description: Gets all api key names configured
      tags: [ api_keys ]
      operationId: getAllKeyNames

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/{apiKeyId}/update:
    post:
      description: Update existing api key
      tags: [ api_keys ]
      operationId: update

      security: [
        authorization: [ default, audit_log ]
      ]

      parameters:
        - in: body
          name: apiKeys
          required: true
          description: API Keys object
          schema:
            type: object
            required:
              - apiKeyId
            properties:
                name:
                  type: string
                  enum: ['webchat','reviews_widget','platform', 'webapp' ,'master_key', 'nucleus', 'third_party', '']
                username:
                  type: string
                password:
                  type: string
                token:
                  type: string
                status:
                  type: string
                  enum: ['active', 'inactive', 'deleted']
                known_hosts:
                  type: string
                apiKeyId:
                  type: integer

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/validate:
    get:
      description: Validates a key exists in the api_keys table
      tags: [ api_keys ]
      operationId: validate

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chargebee/notify:
    post:
      description: Notify when a Chargebee event occurs
      tags: [ api_keys ]
      operationId: notify

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /api_keys/create:
    post:
      description: Creates a new entry in api_keys table
      tags: [ api_keys ]
      operationId: create

      parameters:
        - in: body
          name: apiKeys
          description: API Keys object
          schema:
            type: object
            required:
              - name
              - locationId
            properties:
              name:
                type: string
                enum: ['webchat','reviews_widget','platform', 'webapp' ,'master_key', 'nucleus', 'third_party', '']
              locationId:
                type: integer
              username:
                type: string
              password:
                type: string
              known_hosts:
                type: string

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "201":
          description: Created
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/fetch_assignable:
    get:
      description: Fetch all of the assignable numbers
      tags: [ number ]
      operationId: fetchAssignable

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Message received
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /number/fetch:
    get:
      description: Fetch all of the numbers
      tags: [ number ]
      operationId: fetchNumbers

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Message received
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /net_promoter_score/fetch:
    get:
      description: Fetch NPS suggestions
      tags: [ nps ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /net_promoter_score/count:
    get:
      description: Count the number of NPS suggestions
      tags: [ nps ]
      operationId: count

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /net_promoter_score/{npsRequestGuid}:
    get:
      description: Get a single NPS request
      tags: [ nps ]
      operationId: read

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /net_promoter_score/{npsRequestGuid}/update_request:
    post:
      description: Update the score or suggestion of a nps request
      tags: [ nps ]
      operationId: updateRequest

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/fetch:
    get:
      description: Get a list of payment requests
      tags: [ payment ]
      operationId: fetchPaymentRequests
            
      security: [
        authorization: [ default ]
      ]
      
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/account/confirm:
    post:
      description: Confirms a new Stripe Account with Strip
      tags: [ payment ]
      operationId: confirmAccount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/create:
    post:
      description: Creates and sends a new payment request to a contact
      tags: [ payment ]
      operationId: createPaymentRequest

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestGuid}:
    get:
      description: Fetches a new payment request object
      tags: [ payment ]
      operationId: fetchPaymentRequest

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/confirm:
    post:
      description: Attempt to confirm a payment
      tags: [ payment ]
      operationId: confirmPayment
            
      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/charge:
    post:
      description: Attempt to charge a payment against Stripe
      tags: [ payment ]
      operationId: chargePayment
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/fetch:
    get:
      description: Fetch a payment request by id 
      tags: [ payment ]
      operationId: fetchPaymentRequestId
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/cancel:
    post:
      description: Cancels a Payment Request
      tags: [ payment ]
      operationId: cancelPaymentRequest
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/refund:
    post:
      description: Refunds a Payment Request
      tags: [ payment ]
      operationId: refundPaymentRequest
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/event/create:
    post:
      description: Creates a Payment Event for a Payment Request
      tags: [ payment ]
      operationId: createEvent
            
      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/request/{paymentRequestId}/invoice/download:
    get:
      description: Creates and downloads a PDF version of the Invoice attached to a PaymentRequest 
      tags: [ payment ]
      operationId: downloadInvoice
            
      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
 
  /payment/invoice/fetch:
    get:
      description: Get a list of invoices
      tags: [ payment ]
      operationId: fetchInvoices
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/invoice/create:
    post:
      description: Creates and sends a new invoice to a contact
      tags: [ payment ]
      operationId: createInvoice

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /payment/invoice/{invoiceId}/fetch:
    get:
      description: Fetch a invoice by id 
      tags: [ payment ]
      operationId: fetchInvoiceId
            
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/fetch:
    get:
      description: Gets a list of inboxes for a location and user
      tags: [ inbox ]
      operationId: fetchInboxes

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/fetch_public:
    get:
      description: Gets a a list of public inboxes for widgets
      tags: [ inbox ]
      operationId: fetchPublicInboxes

      security: [
        widget_auth: [ webchat ]
      ]

      parameters:
        - in: query
          name: locationId
          required: true
          type: integer
          
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/metadata:
    get:
      description: Gets all the inbox metadata 
      tags: [ inbox ]
      operationId: fetchMetadata

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/{inboxId}:
    get:
      description: Retrieves a specific Inbox
      tags: [ inbox ]
      operationId: fetchInbox

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/{inboxId}/update:
    post:
      description: Updates an Inbox
      tags: [ inbox ]
      operationId: update

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /inbox/create:
    post:
      description: Create an Inbox
      tags: [ inbox ]
      operationId: create

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /bulk/bulk_action_limit_count:
    get:
      description: Get number of remaining bulk message counts for a location
      tags: [ bulk ]
      operationId: getBulkActionLimitCount

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /bulk/send_messages:
    post:
      description: Create multiple messages
      tags: [ bulk ]
      operationId: sendMessages

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /bulk/send_bulk_status_email:
    post:
      description: Send a bulk stats email
      tags: [ bulk ]
      operationId: sendBulkStatusEmail

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /bulk/bulk_action:
    post:
      description: Run a bulk action
      tags: [ bulk ]
      operationId: action

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /trackers/email/click:
    get:
      description: Track click from an email
      tags: [ trackers ]
      operationId: emailClick

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /trackers/email/open:
    get:
      description: Track open  from an email
      tags: [ trackers ]
      operationId: emailOpened

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/fetch:
    get:
      description: Fetch tags for a location
      tags: [ tag ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/create:
    post:
      description: Create a tag for a location
      tags: [ tag ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/tag_exists:
    get:
      description: Check if a tag/group already exists
      tags: [ tag ]
      operationId: tagExists

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
            
  /tag/{tagId}:
    get:
      description: Get a tag for a location
      tags: [ tag ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/{tagId}/update:
    post:
      description: Update a tag for a location
      tags: [ tag ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/{tagId}/delete:
    post:
      description: Update a tag for a location
      tags: [ tag ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/{tagId}/usage_data:
    get:
      description: Fetch usage data associated to a tag
      tags: [ tag ]
      operationId: getTagUsageData

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/assign_tags:
    post:
      description: Assign tags to a contact
      tags: [ tag ]
      operationId: assignTags

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/add_tags_for_contacts:
    post:
      description: Add tags for many contacts
      tags: [ tag ]
      operationId: addTagsForContacts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_settings/{locationId}/create:
    post:
      description: Create Appointment Settings for location
      tags: [ appointments ]
      operationId: createAppointmentSettings

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: locationId
          required: true
          type: integer

        - in: body
          name: data
          required: true
          description: Appointments data object
          schema:
            type: object
            required:
              - textFields
              - duration
              - user_id
            properties:
              locationId:
                type: integer
              textFields:
                type: string
              duration:
                type: integer
              user_id:
                type: integer
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_settings/update:
    post:
      description: Update Appointment Settings for location
      tags: [ appointments ]
      operationId: updateAppointmentSettings

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: body
          name: data
          required: true
          description: Appointments data object
          schema:
            type: object
            required:
              - id
              - textFields
              - duration
              - user_id
            properties:
              id:
                type: integer
              textFields:
                type: string
              duration:
                type: integer
              user_id:
                type: integer

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_settings/{locationId}/fetch:
    get:
      description: Fetch Appointment Settings for location
      tags: [ appointments ]
      operationId: getAppointmentSettings

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_notifications/{locationId}/fetch:
    get:
      description: Fetch Appointment Notifications for location
      tags: [ appointments ]
      operationId: getAppointmentNotifications

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_notifications/{locationId}/{id}/update:
    post:
      description: Update an Appointment Notification
      tags: [ appointments ]
      operationId: updateAppointmentNotification

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tag/overwrite_tags_for_contacts:
    post:
      description: Overwrite tags for many contacts
      tags: [ tag ]
      operationId: overwriteTagsForContacts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /appointment_settings/delete:
    post:
      description: Delete Appointment Settings for location
      tags: [ appointments ]
      operationId: deleteAppointmentSettings

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: body
          name: data
          required: true
          description: Appointments data object
          schema:
            type: object
            required:
              - id
            properties:
              id:
                type: integer
      
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /booking_widget/fetch:
    get:
      description: Gets all bookings data
      tags: [ booking_widget ]
      operationId: fetch

      security: [
        widget_auth: [ booking_widget ]
      ]

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/create:
    post:
      description: Create a new appointment
      tags: [ booking_widget ]
      operationId: create

      security: [
        widget_auth: [ booking_widget ]
      ]

      parameters:
        - in: body
          name: data
          required: true
          description: Appointment object
          schema:
            type: object
            required:
              - locationId
              - date
              - start
              - end
              - name
              - email
              - new_customer
            properties:
              serviceId:
                type: integer
                nullable: true
              locationId:
                type: integer
              date:
                type: string
              start:
                type: string
              end: 
                type: string
              name:
                type: string
              email:
                type: string
              phone:
                type: string
              new_customer:
                type: boolean
              reason:
                type: string
              note:
                type: string
              scheduleId:
                type: integer
                nullable: true
              sessionTrackingArray:
                type: array
                items:
                  type: object
                required: false
              stripePaymentMethodId:
                type: string

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/send:
    post:
      description: Send a request for a contact to complete a booking request
      tags: [ booking_widget ]
      operationId: sendBookingRequest

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /booking_request/count:
    get:
      description: Count all booking requests
      tags: [ booking_widget ]
      operationId: countBookingRequests

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: query
          name: locationId
          required: true
          type: integer

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/last_created:
    get:
      description: Fetch the last created booking request for a specific contact
      tags: [ booking_widget ]
      operationId: getLastCreated

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: query
          name: locationId
          required: true
          type: integer
        - in: query
          name: contactId
          required: true
          type: integer

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/has_request_url:
    get:
      description: Check to see if a location has a booking request url that can be used
      tags: [ booking_widget ]
      operationId: hasRequestUrl

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: query
          name: contactId
          required: true
          type: integer
        - in: query
          name: locationId
          required: true
          type: integer

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/fetch:
    get:
      description: Get all booking requests
      tags: [ booking_widget ]
      operationId: fetchBookingRequests

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: query
          name: locationId
          required: true
          type: integer

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/{bookingRequestId}:
    get:
      description: Get a single booking request
      tags: [ booking_widget ]
      operationId: fetchBookingRequest

      security: [
        authorization: [ default ]
      ]

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_widget/fetch_slots:
    get:
      description: Get time slots
      tags: [ booking_widget ]
      operationId: fetchSlots

      security: [
        widget_auth: [ booking_widget ]
      ]

      parameters:
        - in: query
          name: locationId
          required: true
          type: integer
        - in: query
          name: startDate
          required: true
          type: string

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /booking_request/{bookingRequestId}/update:
    post:
      description: Update a booking request/appointment
      tags: [ booking_widget ]
      operationId: updateBookingRequest

      security: [
        authorization: [ default ]
      ]

      parameters:
        - in: path
          name: bookingRequestId
          required: true
          type: integer

        - in: body
          name: data
          required: true
          description: Booking object
          schema:
            type: object
            required:
              - bookingId
              - status
            properties:
              bookingRequestId:
                type: integer
              date:
                type: string
              start_time:
                type: string
              end_time:
                type: string
              status:
                type: string
                enum: ["awaiting", "confirmed", "cancelled", "deleted", "completed"]
              locationId:
                type: integer
              sendNotification:
                type: boolean

      responses:
        '200':
          description: Success
          schema:
            $ref: '#/definitions/Response'
        default:
          description: Error
          schema:
            $ref: '#/definitions/Response'

  /tag/remove_tags_for_contacts:
    post:
      description: Remove tags for many contacts
      tags: [ tag ]
      operationId: removeTagsForContacts

      security: [
        authorization: [ default ]
      ]
      
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/faq/fetch_all:
    get:
      description: Fetch all Chatbot FAQs for a location
      tags: [ chatbot ]
      operationId: fetchFaqs

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/faq/update:
    post:
      description: Update the given FAQ with Q/A values
      tags: [ chatbot ]
      operationId: updateFaq

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/faq/create:
    post:
      description: Create a new FAQ entry with given Q/A values
      tags: [ chatbot ]
      operationId: createFaq

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /chatbot/faq/delete:
    post:
      description: Delete FAQ entry with given faq_id
      tags: [ chatbot ]
      operationId: deleteFaq

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/fetch:
    get:
      description: Get all the workflows for a location
      tags: [ workflow ]
      operationId: fetchWorkflows

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/config:
    get:
      description: Get workflows config (constant) data
      tags: [ workflow ]
      operationId: fetchWorkflowsConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /workflow/create:
    post:
      description: Create a workflow
      tags: [ workflow ]
      operationId: create

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/auto_replies:
    get:
      description: Fetch workflow auto replies
      tags: [ workflow ]
      operationId: fetchAutoReplies
      
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/auto_replies/create:
    post:
      description: Create an "auto reply" workflow
      tags: [ workflow ]
      operationId: createAutoReply

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/auto_replies/{workflowId}/update:
    post:
      description: Update an "auto reply" workflow
      tags: [ workflow ]
      operationId: updateAutoReply

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/auto_replies/{workflowId}/delete:
    post:
      description: Delete an "auto reply" workflow
      tags: [ workflow ]
      operationId: deleteAutoReply

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/keywords:
    get:
      description: Fetch keyword workflows
      tags: [ workflow ]
      operationId: fetchKeywords

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/{workflowId}:
    get:
      description: Fetch a single workflow by id 
      tags: [ workflow ]
      operationId: fetchWorkflow
      
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/keywords/create:
    post:
      description: Create a "keyword" workflow
      tags: [ workflow ]
      operationId: createKeyword

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/keywords/{workflowId}:
    get:
      description: Fetch a keyword workflow
      tags: [ workflow ]
      operationId: fetchKeyword
      
      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/keywords/{workflowId}/update:
    post:
      description: Update a "keyword" workflow
      tags: [ workflow ]
      operationId: updateKeyword

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/keywords/{workflowId}/delete:
    post:
      description: Delete a "keyword" workflow
      tags: [ workflow ]
      operationId: deleteKeyword

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/{workflowId}/update:
    post:
      description: Update a workflow
      tags: [ workflow ]
      operationId: update

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/{workflowId}/delete:
    post:
      description: Delete a workflow
      tags: [ workflow ]
      operationId: delete

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/runs/fetch:
    get:
      description: Fetch workflow runs
      tags: [ workflow ]
      operationId: fetchWorkflowRuns

      security: [
        authorization: [ default, audit_log ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /workflow/recipes/fetch:
    get:
      description: Fetch workflow recipes
      tags: [ workflow ]
      operationId: fetchWorkflowRecipes

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/create:
    post:
      description: Create chatbot 
      tags: [ chatbot ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/list:
    get:
      description: Fetch all chatbots from database
      tags: [ chatbot ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/{chatbotId}/update:
    post:
      description: Update chatbot 
      tags: [ chatbot ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/{chatbotId}/test:
    post:
      description: Test chatbot 
      tags: [ chatbot ]
      operationId: test

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /chatbot/{chatbotId}:
    get:
      description: Fetch a specified chatbot
      tags: [ chatbot ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /video_chat/{videoChatGuid}:
    get:
      description: Fetches a video chat object
      tags: [ video_chat ]
      operationId: fetchVideoChat

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /video_chat/create:
    post:
      description: Creates a video chat object
      tags: [ video_chat ]
      operationId: createVideoChat

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/create:
    post:
      description: Creates a secure chat object
      tags: [ secure_chat ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/message/media/upload:
    post:
      description: Uploads a media item to the demandhub media S3 bucket, specifically for Secure Chat
      tags: [ secure_chat ]
      operationId: uploadMedia
      consumes:
        - 'multipart/form-data'

      security:
        - secure_chat_auth: [ secure_chat ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/message/media/{mediaId}:
    get:
      description: Streams a media object for consumption, specifically for Secure Chat
      tags: [ secure_chat ]
      operationId: getSecureChatMedia

      security: [
        secure_chat_auth: [ secure_chat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/message/receive:
    post:
      description: Receive a new message from a secure chat contact
      tags: [ secure_chat ]
      operationId: receiveMessage

      security: [
        secure_chat_auth: [ secure_chat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/message/fetch:
    get:
      description: Fetch all the messages for this secure chat
      tags: [ secure_chat ]
      operationId: fetchMessages

      security: [
        secure_chat_auth: [ secure_chat ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/update:
    post:
      description: Updates a secure chat object
      tags: [ secure_chat ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/auth:
    post:
      description: Determines if a secure chat session is still authenticated and valid
      tags: [ secure_chat ]
      operationId: authenticate

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/send_pin:
    post:
      description: Sends a secure pin to the contact that wants to start a secure chat 
      tags: [ secure_chat ]
      operationId: sendPin

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatPublicId}/confirm_pin:
    post:
      description: Confirms the secure chat pin
      tags: [ secure_chat ]
      operationId: confirmPin

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /secure_chat/{secureChatGuid}:
    get:
      description: Fetches a video chat object
      tags: [ secure_chat ]
      operationId: fetch

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /media/{mediaGuid}:
    get:
      description: Fetches a media object
      tags: [ media ]
      operationId: fetchMedia

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /scheduled_message/fetch:
    get:
      description: Fetch scheduled messages for a location
      tags: [ scheduled_message ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /scheduled_message/create:
    post:
      description: Create a scheduled message for a location
      tags: [ scheduled_message ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /scheduled_message/{scheduledMessageId}:
    get:
      description: Get a scheduled message for a location
      tags: [ scheduled_message ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /scheduled_message/{scheduledMessageId}/update:
    post:
      description: Update a scheduled message for a location
      tags: [ scheduled_message ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /scheduled_message/{scheduledMessageId}/delete:
    post:
      description: Delete a scheduled message for a location
      tags: [ scheduled_message ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/connection/fetch:
    get:
      description: Fetch all the realtime connections active for the current system
      tags: [ admin ]
      operationId: fetchConnections

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/log:
    post:
      description: Create a log for rapid7 
      tags: [ admin ]
      operationId: log

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /admin/{medium}/providers:
    get:
      description: Fetch all providers of a certain medium
      tags: [ admin ]
      operationId: providers

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /widget/update:
    post:
      description: Update a widget
      tags: [ widget ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /widget/list:
    get:
      description: Fetch all widgets for a location
      tags: [ widget ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /widget/{widgetId}/fetch:
    get:
      description: Fetch a widget using it's id
      tags: [ widget ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /widget/fetch:
    get:
      description: Find a Widget given some query parameters
      tags: [ widget ]
      operationId: find

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /zapier/sample:
    get:
      description: Get sample trigger data
      tags: [ zapier ]
      operationId: fetchSample

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /zapier/subscribe:
    post:
      description: Subscribe to a zapier url
      tags: [ zapier ]
      operationId: subscribe

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /zapier/unsubscribe/{hookId}:
    post:
      description: Unsubscribe to a zapier url
      tags: [ zapier ]
      operationId: unsubscribe

      security: [
        third_party_auth: [ third_party_validate_api_key ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/fetch:
    get:
      description: Fetches all tasks for a location
      tags: [ tasks ]
      operationId: fetchTasks

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/{taskId}:
    get:
      description: Fetch a task for a location
      tags: [ tasks ]
      operationId: fetchTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/create:
    post:
      description: Create a task for a location
      tags: [ tasks ]
      operationId: createTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/{taskId}/update:
    post:
      description: Updates a task for a location
      tags: [ tasks ]
      operationId: updateTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/{taskId}/duplicate:
    post:
      description: Duplicates a task for a location
      tags: [ tasks ]
      operationId: duplicateTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tasks/{taskId}/delete:
    post:
      description: Deletes a task for a location
      tags: [ tasks ]
      operationId: deleteTask

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /links/public/{encodedId}:
    get:
      description: Fetch a short link
      tags: [ short_links ]
      operationId: publicFetchShortLink

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /links/update:
    post:
      description: Update a short link
      tags: [ short_links ]
      operationId: updateShortLink

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /links/fetch:
    get:
      description: Fetch short links
      tags: [ short_links ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /links/create:
    post:
      description: Create a short link
      tags: [ short_links ]
      operationId: createShortLink

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/calendar_event/create:
    post:
      description: Create a calendar event
      tags: [ calendar ]
      operationId: createCalendarEvent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/calendar_event/fetch:
    get:
      description: Fetches a list calendar events
      tags: [ calendar ]
      operationId: fetchCalendarEvents

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/calendar_event/{calendarEventId}/update:
    post:
      description: Update a calendar event
      tags: [ calendar ]
      operationId: updateCalendarEvent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/calendar_event/{calendarEventId}:
    get:
      description: Fetche a single calendar event
      tags: [ calendar ]
      operationId: fetchCalendarEvent

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/{calendarId}/update:
    post:
      description: Update a calendar
      tags: [ calendar ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/create:
    post:
      description: Create a calendar
      tags: [ calendar ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/{calendarEventId}:
    get:
      description: Fetches a single calendar
      tags: [ calendar ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /calendar/fetch:
    get:
      description: Fetches a list calendars for a user
      tags: [ calendar ]
      operationId: fetch

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /links/{shortLinkId}:
    get:
      description: Fetch a short link
      tags: [ short_links ]
      operationId: fetchShortLink

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/tcr_receive_event:
    post:
      description: Receive event from the campaign registry
      tags: [ a2p ]
      operationId: tcrReceiveEvent

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/brand/fetch:
    get:
      description: Get all brands for a2p
      tags: [ a2p ]
      operationId: fetchBrands

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/brand/create:
    post:
      description: Create brand for a2p
      tags: [ a2p ]
      operationId: createBrand

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/brand/{brandId}:
    get:
      description: Get all brands for a2p
      tags: [ a2p ]
      operationId: fetchBrand

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/brand/{brandId}/update:
    post:
      description: Update a brand for a2p
      tags: [ a2p ]
      operationId: updateBrand

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/campaign/fetch:
    get:
      description: Get all campaigns for a2p
      tags: [ a2p ]
      operationId: fetchCampaigns

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/campaign/create:
    post:
      description: Create campaign for a2p
      tags: [ a2p ]
      operationId: createCampaign

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/campaign/{campaignId}:
    get:
      description: Get campaign for a2p
      tags: [ a2p ]
      operationId: fetchCampaign

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/campaign/{campaignId}/update:
    post:
      description: Update campaign for a2p
      tags: [ a2p ]
      operationId: updateCampaign

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/submission/fetch:
    get:
      description: Get all submissions for a2p
      tags: [ a2p ]
      operationId: fetchSubmissions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/submission/create:
    post:
      description: Create submission for a2p
      tags: [ a2p ]
      operationId: createSubmission

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/submission/{submissionId}:
    get:
      description: Get submission for a2p
      tags: [ a2p ]
      operationId: fetchSubmission

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/submission/{submissionId}/update:
    post:
      description: Update submission for a2p
      tags: [ a2p ]
      operationId: updateSubmission

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/submission/{submissionId}/submit:
    post:
      description: Submit submission for a2p
      tags: [ a2p ]
      operationId: submitSubmission

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/fetch:
    get:
      description: Get all number associations for a2p
      tags: [ a2p ]
      operationId: fetchNumberAssociations

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/create:
    post:
      description: Create a number association for a2p
      tags: [ a2p ]
      operationId: createNumberAssociation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/{numberAssociationId}:
    get:
      description: Get a number association for a2p
      tags: [ a2p ]
      operationId: fetchNumberAssociation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/{numberAssociationId}/update:
    post:
      description: Update a number association for a2p
      tags: [ a2p ]
      operationId: updateNumberAssociation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/{numberAssociationId}/submit:
    post:
      description: Submit a number association for a2p
      tags: [ a2p ]
      operationId: submitNumberAssociation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/number_association/{numberAssociationId}/remove:
    post:
      description: Remove a number association for a2p
      tags: [ a2p ]
      operationId: removeNumberAssociation

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/webhook_events/fetch:
    get:
      description: Get all webhook events for a2p
      tags: [ a2p ]
      operationId: fetchWebhookEvents

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /a2p/fetch_config:
    get:
      description: Get all enums data for a2p
      tags: [ a2p ]
      operationId: fetchConfig

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /test/create_inbox:
    post:
      description: Create a test inbox
      tags: [ test ]
      operationId: createTestInbox

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /test/create_review:
    post:
      description: Create a test review
      tags: [ test ]
      operationId: createTestReview

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /test/create_template:
    post:
      description: Create a test template
      tags: [ test ]
      operationId: createTestTemplate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /test/create_user:
    post:
      description: Create a test user
      tags: [ test ]
      operationId: createTestUser

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

definitions:

  Request:
    description: Basic Request

  Response:
    description: A standard error response for all API methods.`;
