import i18n from "../i18n/config";

export const CsvMappingConstants = {
	FORMAT: {
		NAMES: {
			auto: {
				id: "auto",
				value: "auto",
				label: i18n.t("Auto")
			},
			firstOnly: {
				id: "firstOnly",
				value: "firstOnly",
				label: i18n.t("FirstName Only")
			},
			lastOnly: {
				id: "lastOnly",
				value: "lastOnly",
				label: i18n.t("LastName Only")
			},
			firstCommaLast: {
				id: "firstCommaLast",
				value: "firstCommaLast",
				label: i18n.t("FirstName, LastName")
			},
			firstSpaceLast: {
				id: "firstSpaceLast",
				value: "firstSpaceLast",
				label: i18n.t("FirstName LastName")
			},
			lastCommaFirst: {
				id: "lastCommaFirst",
				value: "lastCommaFirst",
				label: i18n.t("LastName, FirstName")
			},
			lastSpaceFirst: {
				id: "lastSpaceFirst",
				value: "lastSpaceFirst",
				label: i18n.t("LastName FirstName")
			}
		}
	}
};
