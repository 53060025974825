import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CsvService } from "../../../services/CsvService";
import UtilityService from "../../../services/UtilityService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import DashboardCard from "../../Dashboard/DashboardCard";

import { CHART_COLORS } from "../../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadsPieChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			totalData: [],
			totalLeadSourceCsv: null
		};
	}

	componentDidMount() {
		this.refreshData();
	}

	onLocationChanged = location => {
		this.refreshData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.refreshData();
	}

	async refreshData() {
		const { start, end } = this.state;

		await this.update({ loading: true });
		let totalResult = await AnalyticsService.getLeadSourceTotalAnalytics(UserService.getActiveLocation().id, start, end);

		await this.update({ loading: false, totalData: totalResult });
		this.updateCharts();
	}

	async updateCharts() {
		let { totalData } = this.state;
		let { t } = this.props;

		if (!totalData) {
			return;
		}

		// Massage data for lead source breakdown charts (rotated bar chat and pie chart)
		let xTotalColumns = totalData.map(i => i.lead_source);
		xTotalColumns.unshift("x");
		let valuesTotalColumns = totalData.map(i => i.total);
		let totalLeads = valuesTotalColumns.reduce((a, b) => a + b, 0);
		valuesTotalColumns.unshift(t("Leads"));

		// Generate a color pattern for other charts
		// if there are more lead sources than colors, generate some random colors!
		let colors = [...CHART_COLORS];
		let length = totalData.length > colors.length ? totalData.length - colors.length : 0;
		colors = UtilityService.generateColorsArray({ colors, length });

		this.generateLeadsPieChart(totalLeads, colors);
	}

	generateLeadsPieChart = (totalLeads, colors) => {
		let { totalData } = this.state;
		let { t } = this.props;

		let totalColumns = totalData.map(i => {
			return [i.lead_source, i.total];
		});

		let totalLeadSourceCsv = [];
		totalColumns.map(t => {
			let d = [...t];
			let percent = (t[1] / totalLeads) * 100;
			d.push(Math.round(percent * 10) / 10);
			totalLeadSourceCsv.push(d);
			return null;
		});
		totalLeadSourceCsv.unshift([t("Lead Source"), t("Total"), t("Percentage")]);

		this.setState({
			totalLeadSourceCsv
		});

		this.generatePieChart("#lead-source-analytics-pie-chart", totalColumns, {
			pattern: colors
		});
	};

	generatePieChart(id, columns, color) {
		c3.generate({
			bindto: id,
			data: {
				columns: columns,
				type: "pie"
			},
			size: {
				height: 260
			},
			color
		});
	}

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	renderDatePicker = () => {
		const { start, end } = this.state;
		const modifiers = { start, end };
		let { t } = this.props;

		return (
			<div className="dashboard__card___datepicker">
				<label>{t("Date Range")}&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder={t(" From")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder={t(" To")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<div id="date-update" className="mb-button " onClick={e => this.handleUpdateDate(e)}>
					{t("Update Date")}
				</div>
			</div>
		);
	};

	renderHeader = () => {
		const { totalLeadSourceCsv } = this.state;
		const { cardName } = this.props;
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : t("Lead Break Down")}</div>
					<div className="dashboard__card__header__actions">
						{totalLeadSourceCsv && (
							<CSVLink data={totalLeadSourceCsv} filename={`${locationName}-contact_total_lead_sources.csv`} target="_self">
								<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				{this.renderDatePicker()}
			</>
		);
	};

	renderBody = () => {
		const { loading, totalData } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Contacts is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Contact data restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		if (!totalData || totalData.length < 1) {
			return <div className="text-center">{t("No data found.")}</div>;
		}

		return (
			<div className="lead-source-analytics__chart-section">
				<div id="lead-source-analytics-pie-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.leadsPieChart.className} height={DASHBOARD_CARDS.leadsPieChart.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(LeadsPieChart)));
