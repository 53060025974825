import i18n from "../i18n/config";

export const TEMPLATE_TYPE = {
	reviewRequest: "review_request",
	general: "general",
	reviewReminder: "review_reminder",
	referralRequest: "referral_request",
	appointmentReminder: "appointment_reminder",
	appointmentChanged: "appointment_changed",
	appointmentCancelled: "appointment_cancelled",
	webChatResponse: "web_chat_response",
	autoResponse: "auto_response",
	unreadMessageReminder: "unread_message_reminder",
	genericAutoResponse: "generic_auto_response",
	liveChatResponse: "live_chat_response",
	liveChatBusinessHoursResponse: "live_chat_business_hours_response",
	reviewResponse: "review_response",
	oneStarResponse: "1_star_response",
	twoStarResponse: "2_star_response",
	threeStarResponse: "3_star_response",
	fourStarResponse: "4_star_response",
	fiveStarResponse: "5_star_response",
	autoReviewResponse: "auto_review_response",
	autoReviewRequest: "auto_review_request",
	keywordResponse: "keyword_response",
	startVideoChat: "start_video_chat",
	bookingRequestResponse: "booking_request_response",
	chatbotResponse: "chatbot_response",
	teamChat: "team_chat",
	creditCardRequest: "credit_card_request",
	reengagementRequest: "reengagement_request",
	voiceDefaultMessage: "voice_default_message",
	voiceLeaveVoicemail: "voice_leave_voicemail",
	voiceVoicemailResponse: "voice_voicemail_response",
	voiceMissedCallResponse: "voice_missed_call_response",
	voiceCallForwarding: "messenger_voice_call_forwarding"
};

export const MESSAGE_VARIABLE_PATTERNS = {
	reviewUrl: ":review_url:"
};

export const ADMIN_TEMPLATE_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	msg_text: {
		id: "msg_text",
		value: i18n.t("Text"),
		sortable: true,
		sortField: "msg_text",
		width: 1
	},
	type: {
		id: "type",
		value: i18n.t("Type"),
		sortable: true,
		sortField: "type",
		width: 1
	},
	default_for_type: {
		id: "default_for_type",
		value: i18n.t("Default"),
		sortable: true,
		sortField: "default_for_type",
		width: 1
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const ADMIN_TEMPLATE_FILTERS = {
	other: {
		id: "other",
		display: i18n.t("Other"),
		order: 1
	},
	autoResponses: {
		id: "autoResponses",
		display: i18n.t("Auto Review Responses"),
		order: 2
	}
};

export const EDIT_TEMPLATE_TABS = {
	general: {
		id: "general",
		value: i18n.t("General")
	},
	email: {
		id: "email",
		value: i18n.t("Email")
	},
	backup: {
		id: "backup",
		value: i18n.t("Backup")
	}
};

export const MAX_RECENT_TEMPLATES_TO_STORE = 2;
