import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { withTranslation, Trans } from "react-i18next";

import UtilityService from "../../../../services/UtilityService";
import MessagesService from "../../../../services/MessagesService";

import { MODE, STATE } from "../../../../constants/Messenger";

import Modal from "../../../../components/common/DHModal";
import TextBubble from "../../../../components/common/TextBubble";
import TeamChatMessage from "../TeamChatMessageList/TeamChatMessage";
import ThreadMedia from "../Media/ThreadMedia";

class SendLaterModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes(),
			media: [],
			showMessage: false
		};
	}

	componentDidMount() {
		this.updateMedia();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.updateMedia();
			this.updateSendAfter();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	updateMedia = async () => {
		await this.update({ showMessage: false });

		if (!this.props.mediaIds) {
			this.update({ media: [], showMessage: true });
			return;
		}

		let media = [];

		this.props.mediaIds.map(id => {
			if (MessagesService.getLocalMedia(id)) {
				media.push(MessagesService.getLocalMedia(id));
			}
		});
		await this.update({ media: media, showMessage: true });
	};

	updateSendAfter = async () => {
		await this.update({
			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes()
		});
	};

	onDateChange = async value => {
		await this.update({
			sendAfter: value
		});
	};

	render = () => {
		let { show, onHide, onSendLaterAndClose, onSendLater, messageContent, messageHtml, messageJSON, mode, disabled, conversationStatus, mediaIds } = this.props;
		let { t } = this.props;

		let { sendAfter, minSendAfter, media, showMessage } = this.state;

		let sendAfterFormatted = moment(sendAfter).format("ddd, MMMM Do YYYY @ h:mm a");
		let { localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		let minTime = moment()
			.startOf("day")
			.toDate();

		if (moment().format("YYYY-MM-DD") === moment(sendAfter).format("YYYY-MM-DD")) {
			minTime = moment(minSendAfter).toDate();
		}

		return (
			<Modal onHide={onHide} show={show} title={t("Schedule Message")}>
				<div className="mb-send-later-modal-content">
					{mode === MODE.customer && (
						<>
							{media && media.length > 0 ? (
								<div className="mb-send__contains-media">
									{media.map(m => (
										<ThreadMedia id={m.id} media={m} readOnly={true} maxHeightOrWidth={200} />
									))}
								</div>
							) : null}
							{messageContent && <TextBubble text={messageContent} blue={true} />}
						</>
					)}
					{mode === MODE.team && showMessage && (
						<div className="mb-send-later-modal-content__message">
							<TeamChatMessage
								message={{
									id: "send-later-modal",
									event_type: "message",
									content: messageContent,
									content_html: messageHtml,
									content_json: messageJSON,
									media: media,
									reactions: null
								}}
								hideHoverOptions={true}
								hideDate={true}
								mediaMaxHeightOrWidth={150}
								mediaReadOnly={true}
							/>
						</div>
					)}

					<div className="mb-send__picker">
						<DatePicker
							disabled={disabled}
							minDate={minSendAfter}
							placeholderText={t("Send Date")}
							selected={sendAfter}
							onChange={this.onDateChange}
							shouldCloseOnSelect={true}
							dateFormat="MMMM dd, yyyy"
							className="Common__input mb-send__picker__date"
						/>
						<DatePicker
							disabled={disabled}
							minDate={minSendAfter}
							minTime={minTime}
							maxTime={setHours(setMinutes(new Date(), 45), 23)}
							placeholderText={t("Send Time")}
							selected={sendAfter}
							onChange={this.onDateChange}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							timeCaption="Time"
							shouldCloseOnSelect={true}
							dateFormat="h:mm aa"
							className="Common__input mb-send__picker__time"
						/>
					</div>
					<div className="mb-send__description">
						{t("Scheduled send for")} {sendAfterFormatted} ({localTimeZoneShortHand}).
					</div>
					<div className="mb-send-later-actions">
						{onHide && (
							<div className="mb-button mb-button--cancel" onClick={onHide}>
								{t("Cancel")}
							</div>
						)}
						{mode === MODE.customer && conversationStatus === STATE.open && (
							<div className="mb-button" onClick={() => onSendLaterAndClose({ sendAfter })}>
								{t("Send Later & Close")}
							</div>
						)}
						<div className="mb-button" onClick={() => onSendLater({ sendAfter })}>
							{t("Send Later")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(SendLaterModal);
