import i18n from "../i18n/config";

export const APPOINTMENT_TABS = {
	appointments: {
		id: "appointments",
		display: i18n.t("Appointments"),
		value: "appointments",
		label: i18n.t("Appointments"),
		route: "/appointments"
	},
	appointmentNotifications: {
		id: "appointmentNotifications",
		display: i18n.t("Appointment Notifications"),
		value: "appointmentNotifications",
		label: i18n.t("Appointment Notifications"),
		route: "/appointment-notifications"
	}
};

export const APPOINTMENT_DETAILS_TABS = {
	appointmentDetails: {
		id: "appointmentDetails",
		display: i18n.t("Appointment Details"),
		value: "appointmentDetails",
		label: i18n.t("Appointment Details")
	},
	appointmentNotifications: {
		id: "appointmentNotifications",
		display: i18n.t("Appointment Notifications"),
		value: "appointmentNotifications",
		label: i18n.t("Appointment Notifications")
	}
};

export const APPOINTMENT_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2,
		widthMd: 3
	},
	state: {
		id: "state",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "state",
		width: 2,
		widthMd: 2
	},
	confirmed: {
		id: "confirmed",
		value: i18n.t("Confirmed"),
		sortable: true,
		sortField: "confirmed",
		width: 1,
		widthMd: 1
	},
	dh_confirmed: {
		id: "dh_confirmed",
		value: "DH Confirmed",
		sortable: true,
		sortField: "dh_confirmed",
		width: 1,
		widthMd: 1
	},
	type: {
		id: "type",
		value: i18n.t("Type"),
		sortable: false,
		sortField: "type",
		width: 1,
		widthMd: 0
	},
	provider_name: {
		id: "provider_name",
		value: i18n.t("Provider"),
		sortable: false,
		sortField: "provider_name",
		width: 1,
		widthMd: 1
	},
	pendingNotifications: {
		id: "pendingNotifications",
		value: i18n.t("Pending Notifications"),
		sortable: false,
		width: 1,
		widthMd: 0
	},
	booking_at: {
		id: "booking_at",
		value: i18n.t("Booking At"),
		sortable: true,
		sortField: "booking_at",
		width: 2,
		widthMd: 3
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Created At"),
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const STATUS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	unconfirmed: {
		id: "unconfirmed",
		display: i18n.t("Scheduled"),
		order: 10
	},
	preconfirmed: {
		id: "preconfirmed",
		display: i18n.t("Prebooked"),
		order: 7
	},
	confirmed: {
		id: "confirmed",
		display: i18n.t("Booked"),
		order: 5
	},
	arrived: {
		id: "arrived",
		display: i18n.t("Arrived"),
		order: 2
	},
	waiting: {
		id: "waiting",
		display: i18n.t("Waiting"),
		order: 11
	},
	seated: {
		id: "seated",
		display: i18n.t("Seated"),
		order: 8
	},
	billed: {
		id: "billed",
		display: i18n.t("Billed"),
		order: 3
	},
	cancelled: {
		id: "cancelled",
		display: i18n.t("Cancelled"),
		order: 4
	},
	departed: {
		id: "departed",
		display: i18n.t("Completed"),
		order: 6
	},
	missed: {
		id: "missed",
		display: i18n.t("Missed"),
		order: 12
	},
	rescheduled: {
		id: "rescheduled",
		display: "Rescheduled",
		order: 14
	},
	unknown: {
		id: "unknown",
		display: i18n.t("Unknown"),
		order: 13
	}
};

export const CONFIRM_STATES = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	confirmed: {
		id: "1",
		display: i18n.t("Confirmed"),
		order: 2
	},
	unconfirmed: {
		id: "0",
		display: i18n.t("Unconfirmed"),
		order: 3
	}
};

export const APPOINTMENT_NOTIFICATION_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	type: {
		id: "type",
		value: i18n.t("Type"),
		sortable: true,
		sortField: "type",
		width: 1,
		hideMd: true
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 1,
		hideXs: true
	},
	send_after: {
		id: "send_after",
		value: i18n.t("Send After"),
		sortable: true,
		sortField: "send_after",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Created At"),
		sortable: true,
		sortField: "created_at",
		width: 1,
		hideMd: true
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const NOTIFICATION_STATUS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	pending: {
		id: "pending",
		display: i18n.t("Pending"),
		order: 5
	},
	sent: {
		id: "sent",
		display: i18n.t("Sent"),
		order: 6
	},
	cancelled: {
		id: "cancelled",
		display: i18n.t("Cancelled"),
		order: 2
	},

	deleted: {
		id: "deleted",
		display: i18n.t("Deleted"),
		order: 3
	},
	error: {
		id: "error",
		display: i18n.t("Error"),
		order: 4
	}
};

export const NOTIFICATION_TYPES = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	reminder: {
		id: "reminder",
		display: i18n.t("Reminder"),
		order: 3
	},
	confirmation_request: {
		id: "confirmation_request",
		display: i18n.t("Confirmation Request"),
		order: 2
	}
};

export const CONVERSION_APPOINTMENTS_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: false,
		sortField: "name",
		width: 2
	},
	state: {
		id: "state",
		value: i18n.t("State"),
		sortable: false,
		sortField: "state",
		width: 1
	},
	booking_at: {
		id: "booking_at",
		value: i18n.t("Booking At"),
		sortable: false,
		sortField: "booking_at",
		width: 2
	},
	lead_source: {
		id: "lead_source",
		value: i18n.t("Source"),
		sortable: false,
		sortField: "lead_source",
		width: 1
	},
	more: {
		id: "more",
		value: i18n.t("More"),
		sortable: false,
		sortField: "more",
		width: 1
	}
};
