import React from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CreatableSelect from "react-select/lib/Creatable";
import Select from "react-select";
import { withTranslation, Trans } from "react-i18next";

import withLocation from "../../../components/common/WithLocation";

import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";
import ToastService from "../../../services/ToastService";

import { INVITES_DEADLINE_ACTION } from "../../../constants/Invites";
import Times from "../../../constants/Times.json";

class InviteQueue extends React.Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			deadlineAction: { value: INVITES_DEADLINE_ACTION.send, label: INVITES_DEADLINE_ACTION.label.send },
			deadlineActions: [
				{ value: INVITES_DEADLINE_ACTION.expire, label: INVITES_DEADLINE_ACTION.label.expire },
				{ value: INVITES_DEADLINE_ACTION.send, label: INVITES_DEADLINE_ACTION.label.send }
			],
			weekdays: [
				{ value: "Sunday", label: t("Sunday") },
				{ value: "Monday", label: t("Monday") },
				{ value: "Tuesday", label: t("Tuesday") },
				{ value: "Wednesday", label: t("Wednesday") },
				{ value: "Thursday", label: t("Thursday") },
				{ value: "Friday", label: t("Friday") },
				{ value: "Saturday", label: t("Saturday") }
			],
			deadlineWeekday: { value: "Friday", label: t("Friday") },
			deadlineTime: { value: "09:00", label: t("09:00") },
			times: Times
		};
	}

	componentDidMount = () => {
		this.fetchLocation();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchLocation = async () => {
		let location = await LocationService.fetchLocation(UserService.getActiveLocation().id);

		// 00:00 to 23:30
		let times = Times;

		await this.update({
			deadlineAction: {
				value: location.invite_queue_deadline_action,
				label:
					location.invite_queue_deadline_action.toLowerCase() === INVITES_DEADLINE_ACTION.send
						? INVITES_DEADLINE_ACTION.label.send
						: INVITES_DEADLINE_ACTION.label.expire
			},
			deadlineWeekday: { value: location.invite_queue_deadline_day, label: location.invite_queue_deadline_day },
			deadlineTime: { value: location.invite_queue_deadline_time, label: location.invite_queue_deadline_time },
			messageStartTime: location.message_start_time,
			messageEndTime: location.message_end_time,
			times
		});
	};

	onSave = async () => {
		try {
			let { deadlineAction, deadlineWeekday, deadlineTime } = this.state;
			let { t } = this.props;

			let location = UserService.getActiveLocation();
			const locationData = {};
			locationData.locationId = location.id;
			locationData.company = UserService.getActiveCompany().id;
			locationData.handle = location.handle;
			locationData.invite_queue_deadline_action = deadlineAction.value;
			locationData.invite_queue_deadline_day = deadlineWeekday.value;
			locationData.invite_queue_deadline_time = deadlineTime.value;

			let success = await LocationService.update(locationData);
			if (!success) {
				this.setState({ loading: false });
				ToastService.error(t("Sorry, an error was encountered while saving the settings. Please try again."));
				return;
			}

			this.setState({ loading: false });
			ToastService.info(t("Location saved successfully."));
			this.fetchLocation();
		} catch (error) {
			console.log(error);
		}
	};

	handleDeadlineAction(newValue) {
		this.update({ deadlineAction: newValue });
	}

	handleDeadlineDay = newValue => {
		this.update({ deadlineWeekday: newValue });
	};

	handleDeadlineTime = newValue => {
		this.update({ deadlineTime: { label: newValue.value, value: newValue.value } });
	};

	render() {
		let { deadlineAction, deadlineActions, deadlineWeekday, weekdays, deadlineTime, times } = this.state;
		let { t } = this.props;
		let user = UserService.get();

		return (
			<div className="Common__flex-grow" style={{ marginLeft: 30 }}>
				<div className="dh-details-info">
					<div className="dh-details-item">
						<div>
							<span data-tip data-for="invite-deadline-action-tooltip">
								{t("Invite Queue Deadline Action")} <i className="fa fa-info-circle" />
							</span>
							<ReactTooltip id="invite-deadline-action-tooltip" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
								{t("What will happen once the deadline is reached.")} <br />
								{t("Expire review invites (don't send any).")}
								<br /> {t("Or send review invites.")}
							</ReactTooltip>
						</div>
						<CreatableSelect
							id="deadlineAction"
							name="deadlineAction"
							disabled={false}
							value={deadlineAction}
							onChange={newValue => this.handleDeadlineAction(newValue)}
							options={deadlineActions}
							placeholder={t("Select an Action")}
							clearable={false}
						/>
					</div>
				</div>

				<div className="dh-details-info">
					<div className="dh-details-item">
						<div>
							<span data-tip data-for="invite-deadline-day-tooltip">
								{t("Invite Queue Deadline Day")} <i className="fa fa-info-circle" />
							</span>
							<ReactTooltip id="invite-deadline-day-tooltip" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
								<Trans i18nKey="The week day that invites will <0></0> be sent on if no action is taken." components={{ 0: <br /> }}>
									The week day that invites will <br /> be sent on if no action is taken.
								</Trans>
							</ReactTooltip>
						</div>
						<CreatableSelect
							id="deadlineWeekday"
							name="deadlineWeekday"
							disabled={false}
							value={deadlineWeekday}
							onChange={newValue => this.handleDeadlineDay(newValue)}
							options={weekdays}
							placeholder={t("Select a Day")}
							clearable={false}
						/>
					</div>
				</div>

				<div className="dh-details-info">
					<div className="dh-details-item">
						<div>
							<span data-tip data-for="invite-deadline-time-tooltip">
								{t("Invite Queue Deadline Time")} <i className="fa fa-info-circle" />
							</span>
							<ReactTooltip id="invite-deadline-time-tooltip" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
								{deadlineAction.value === INVITES_DEADLINE_ACTION.send
									? t("The time when invites will start being sent out")
									: t("The time when invites will start expiring")}{" "}
								<br /> {t("if no action is taken.")}
							</ReactTooltip>
						</div>
						<Select
							id={"deadlineTime"}
							name={"deadlineTime"}
							checked={false}
							isDisabled={false}
							onChange={newValue => this.handleDeadlineTime(newValue)}
							value={deadlineTime}
							options={times}
						/>
					</div>
				</div>

				{user.GroupPermission.update_locations && (
					<div className="dh-details__save">
						<div id="save" className="mb-button" onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(InviteQueue)));
