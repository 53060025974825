import React from "react";

import "../../styles/css/components/commons/action.css";

const Action = props => {
	let { id, label, icon, text, index, onClick, lightOutline, transparent, active, darkMode, className, color, tooltipLeft, disabled } = props;
	let DynamicIcon = icon;
	return (
		<div
			id={id}
			className={`dh-tip dh-action ${transparent ? "dh-action--transparent" : ""} ${lightOutline ? "dh-action--light-outline" : ""} ${
				active ? "dh-action--active" : ""
			} ${darkMode ? "dh-action--dark" : ""} ${text ? "dh-action--text" : ""} ${tooltipLeft ? "dh-tip--left" : ""} ${className ? className : ""} ${
				disabled ? "dh-action--disabled" : ""
			}`}
			tip={label}
			style={{ color: color }}
			onClick={event => {
				if (onClick) {
					onClick({ id, label, index, event });
				}
			}}
		>
			{DynamicIcon ? <DynamicIcon size="18" /> : null}
			{text ? text : null}
		</div>
	);
};

export default Action;
