import React, { Component } from "react";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class TotalReplies extends Component {
	state = { totalReviews: 0, totalReplies: 0 };

	componentDidMount() {
		this.resetComponent();
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	resetComponent = async () => {
		this.fetchTotalReviews();
		this.fetchTotalReplies();
	};

	async fetchTotalReviews() {
		try {
			const locationId = UserService.getActiveLocation().id;
			const totalReviews = await AnalyticsService.getReviewCountForLocation({ locationId });
			this.setState({ totalReviews });
		} catch (error) {
			console.log(error);
			this.setState({ totalReviews: 0 });
		}
	}

	async fetchTotalReplies() {
		try {
			const locationId = UserService.getActiveLocation().id;
			const repliesData = await AnalyticsService.getOverallReplies({ locationId });

			this.setState({ totalReplies: repliesData.data.repliesCount });
		} catch (error) {
			console.log(error);
			this.setState({ totalReplies: 0 });
		}
	}

	render() {
		const { totalReviews, totalReplies } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.totalReplies.className} height={DASHBOARD_CARDS.totalReplies.h}>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.CornerUpLeft size={42} />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
						{cardName ? cardName : t("Total Replies")} <Icon.Info size={20} data-tip data-for="reply-rate-tooltip" />
						<ReactTooltip id="reply-rate-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
							<Trans
								i18nKey="The reply rate is calculated as the total <0 /> replies divided by total reviews multiplied <1 /> by 100. Shows cumulative data for all time."
								components={{ 0: <br />, 1: <br /> }}
							>
								The reply rate is calculated as the total <br /> replies divided by total reviews multiplied <br /> by 100. Shows cumulative data for all time.
							</Trans>
						</ReactTooltip>
					</div>
				</div>
				<div className="dashboard__card__stat">
					{isEnabled && isEnabled() && isPermissible && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{totalReplies}</div>
							<small>
								{t("Reply rate:")} {((totalReplies / totalReviews) * 100).toFixed(2)}%
							</small>
						</>
					) : (
						`${isEnabled && isEnabled() ? t("Reviews are not enabled. Contact support to get started.") : t("Review Data Restricted.")}`
					)}
				</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(TotalReplies));
