import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";

import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";
import UserService from "../../services/UserService";

import DHModal from "./DHModal";

import "../../styles/css/components/commons/edit-image-modal.css";
import "react-image-crop/dist/ReactCrop.css";

class AttachmentItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			media: null,
			crop: {},
			cropCount: 0,
			aspect: undefined
		};

		this.uploadInput = null;
		this.imageRef = null;
	}

	componentDidMount() {
		this.update({ cropCount: 0 });
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.mediaId !== this.props.mediaId) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { mediaId } = this.props;

		if (!mediaId) {
			this.setState({ media: null, crop: {} });
			return;
		}

		let media = await MessagesService.getMedia({ mediaId });

		if (!media) {
			this.setState({ media: null, crop: {} });
			return;
		}

		media.download_url = UtilityService.appendQueryAuthToken(media.download_url, UserService.getActiveLocation().id, UserService.getAuthToken());

		this.setState({ media, crop: {} });
	};

	hide = () => {
		if (this.props.disabled) {
			return;
		}
		if (this.props.hide) {
			this.props.hide();
		}
	};

	onHide = () => {
		let { onHide } = this.props;

		if (onHide) {
			onHide();
		}
	};

	onCropImage = async () => {
		if (!this.imageRef) {
			return;
		}

		if (this.isCropDisabled()) {
			return;
		}

		let { mediaId } = this.props;
		let { crop } = this.state;

		let localMedia = MessagesService.getLocalMedia(mediaId);

		const canvas = document.createElement("canvas");
		const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
		const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
		canvas.width = crop.width;
		canvas.height = crop.height;

		const ctx = canvas.getContext("2d");

		ctx.drawImage(this.imageRef, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

		canvas.toBlob(blob => {
			let file = new File([blob], localMedia.file.name, { type: blob.type });

			MessagesService.storeLocalMedia({ file, mediaId });

			let { cropCount } = this.state;
			cropCount++;
			this.update({
				cropCount: cropCount
			});

			this.onHide();
		});
	};

	isCropDisabled = () => {
		return this.hasCropped() || Object.keys(this.state.crop).length < 1;
	};

	hasCropped = () => {
		return this.state.cropCount > 0;
	};

	render() {
		let { crop, aspect, media } = this.state;
		const { show, referrerPolicy } = this.props;

		return (
			<DHModal title="Edit Image" show={show} onHide={() => this.onHide()}>
				{media && (
					<ReactCrop crop={crop} aspect={aspect} onChange={crop => this.update({ crop })} disabled={this.hasCropped()} minWidth={400} minHeight={300}>
						<div className="eim__crop">
							<img
								ref={ref => (this.imageRef = ref)}
								crossorigin="anonymous"
								className="eim__crop__img"
								src={media.download_url}
								alt="An attachment"
								referrerPolicy={referrerPolicy}
							/>
						</div>
					</ReactCrop>
				)}

				<div className="modal__actions">
					<div className={`mb-button ${this.isCropDisabled() ? "mb-button--disabled" : ""}`} onClick={this.onCropImage}>
						<Icon.Crop size={14} /> Crop Image
					</div>
				</div>
			</DHModal>
		);
	}
}

export default withTranslation(null, { withRef: true })(AttachmentItem);
