import React from "react";
import * as Icon from "react-feather";
import c3 from "c3";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import PaymentService from "../../services/PaymentService";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import SupportChatService from "../../services/SupportChatService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class PaymentsCount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentRequests: 0,
			paymentEstimates: 0,
			paymentsReceived: 0,
			chart: null
		};
	}

	componentDidMount() {
		this.resetComponent();
		window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	resetComponent = async () => {
		await this.fetchData();
		this.refreshChart();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	fetchData = async () => {
		let data = await AnalyticsService.getTotalPaymentsCount({ locationId: UserService.getActiveLocation().id });

		if (!data) {
			return;
		}

		await this.update({ paymentRequests: data.paymentRequests, paymentEstimates: data.paymentEstimates, paymentsReceived: data.paymentsReceived });
	};

	updateDimensions = () => {
		let { chart } = this.state;

		if (!chart) {
			return;
		}

		this.refreshChart();
	};

	refreshChart = () => {
		let { paymentRequests, paymentEstimates, paymentsReceived } = this.state;
		let { t } = this.props;

		let isPaymentsEnabled = PaymentService.isPaymentsEnabled();

		if (!isPaymentsEnabled) {
			return;
		}

		let chart = c3.generate({
			bindto: "#dashboard__total--payments",
			data: {
				columns: [
					["paymentRequestsText", paymentRequests],
					["paymentsReceivedText", paymentEstimates],
					["paymentEstimatesText", paymentsReceived]
				],
				type: "bar",
				colors: {
					paymentRequestsText: "#6761DB",
					paymentsReceivedText: "#6198DB",
					paymentEstimatesText: "#61D5DB"
				}
			},
			bar: {
				width: {
					ration: 0.5
				},
				title: t("Payments")
			},
			axis: {
				rotated: true
			},
			size: {
				height: 90
			}
		});

		this.update({ chart });
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onGetStarted = () => {
		let { t } = this.props;
		SupportChatService.showNewMessage(t("Hi, I would like to enable Payments. Would you be able to help?"));
	};

	renderHeader = () => {
		const { cardName } = this.props;
		let { t } = this.props;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.DollarSign size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : t("Payments ")}
					<Icon.Info size={20} data-tip data-for="dashboard-widget-payments-count" />{" "}
				</div>

				<ReactTooltip
					id="dashboard-widget-payments-count"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					{t("Shows cumulative data for all time.")}
				</ReactTooltip>
			</div>
		);
	};

	renderBody = () => {
		let { paymentRequests, paymentEstimates, paymentsReceived } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<div className="dashboard__card--payments">
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">
							{t("Send payment requests, receive credit card information, and send estimates via text message.")}
						</div>
						<div className="mb-button mb-button--fit" onClick={this.onGetStarted}>
							{t("Get Started")}
						</div>
					</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className="dashboard__card--payments">
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">{t("Payment data restricted.")}</div>
					</div>
				</div>
			);
		}

		if (paymentRequests === 0 && paymentEstimates === 0 && paymentsReceived === 0) {
			return (
				<div className="dashboard__card--payments">
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">{t("Not applicable. No payment requests sent.")}</div>
					</div>
				</div>
			);
		}

		return (
			<div className="dashboard__widget__payments">
				<div id="dashboard__total--payments" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.paymentsCount.className} height={DASHBOARD_CARDS.paymentsCount.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(PaymentsCount));
