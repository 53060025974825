import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import EditNumberAssociationModal from "./EditNumberAssociationModal";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import A2PService from "../../../services/A2PService";

import { A2P_NUMBER_ASSOCIATIONS_COLUMNS } from "../../../constants/A2P";

class A2PNumberAssociations extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			numberAssociations: [],
			loading: false,
			config: null,

			selectedNumberAssociationId: null,
			showCreateModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			numberAssociations: [],
			loading: false,
			config: null,

			selectedNumberAssociationId: null,
			showCreateModal: false
		});

		await this.fetchConfig();
		await this.fetchNumberAssociations();
	};

	fetchConfig = async () => {
		let config = await A2PService.fetchConfig();
		await this.update({ config });
	};

	fetchNumberAssociations = async () => {
		let companyId = UserService.getActiveCompany().id;

		await this.update({ loading: true });

		let numberAssociations = await A2PService.fetchNumberAssociations({ companyId });

		await this.update({ numberAssociations, loading: false });
	};

	getHeaders() {
		let headers = {
			items: A2P_NUMBER_ASSOCIATIONS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let { config } = this.state;
		let { t } = this.props;

		if (!recordData) {
			return null;
		}

		let showEditAction = recordData.submission_status === config.dhEnums.tcrNumberAssociationSubmissionStatus.unsubmitted;
		let showSubmitAction =
			recordData.submission_status === config.dhEnums.tcrNumberAssociationSubmissionStatus.unsubmitted &&
			recordData.association_status === config.dhEnums.numberAssociationStatuses.unassociated;
		let showRemoveAction =
			recordData.submission_status === config.dhEnums.tcrNumberAssociationSubmissionStatus.submitted &&
			recordData.association_status === config.dhEnums.numberAssociationStatuses.associated;

		return [
			recordData.id,
			recordData.phone_number,
			recordData.tcr_campaign_id,
			recordData.cnp_id,
			recordData.submission_status,
			recordData.association_status,
			moment(recordData.created_at).format("MMM Do YYYY hh:mm a"),
			<div className="dh-list__actions">
				{showEditAction && <Action id={`edit-${recordData.id}`} label={t("Edit")} icon={Icon.Edit} onClick={() => this.onShowCreateModal(recordData.id)} />}
				{showSubmitAction && (
					<Action id={`submit-${recordData.id}`} label={t("Submit")} icon={Icon.Cloud} onClick={() => this.onSubmitAssociationSubmission(recordData.id)} />
				)}
				{showRemoveAction && (
					<Action id={`submit-${recordData.id}`} label={t("Unassociated")} icon={Icon.Minus} onClick={() => this.onSubmitAssociationRemoval(recordData.id)} />
				)}
			</div>
		];
	};

	onSubmitAssociationSubmission = async numberAssociationId => {
		let { t } = this.props;

		try {
			let response = await A2PService.submitNumberAssociation({ numberAssociationId });

			if (response) {
				ToastService.info(t("A2P Request Submitted!"));
			} else {
				ToastService.error(t("Failed to submit a2p number association request"));
			}

			await this.fetchNumberAssociations();
		} catch (error) {
			ToastService.error(t("Failed to submit a2p number association request"));
			console.log(error);
		}
	};

	onSubmitAssociationRemoval = async numberAssociationId => {
		let { t } = this.props;

		try {
			let response = await A2PService.removeNumberAssociation({ numberAssociationId });

			if (response) {
				ToastService.info(t("A2P Request Submitted!"));
			} else {
				ToastService.error(t("Failed to submit a2p number association removal"));
			}

			await this.fetchNumberAssociations();
		} catch (error) {
			ToastService.error(t("Failed to submit a2p number association removal"));
			console.log(error);
		}
	};

	onShowCreateModal = async dhNumberAssociationId => {
		await this.update({
			selectedNumberAssociationId: dhNumberAssociationId,
			showCreateModal: true
		});
	};

	onHideCreateModal = async () => {
		await this.update({
			selectedNumberAssociationId: null,
			showCreateModal: false
		});
	};

	onSave = async () => {
		let { t } = this.props;

		ToastService.info(t("A2P Ready To Submit Number Association!"));
		await this.update({
			selectedNumberAssociationId: null,
			showCreateModal: false
		});
		await this.fetchNumberAssociations();
	};

	render() {
		let { numberAssociations, loading, showCreateModal, selectedNumberAssociationId } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header>
					<Action id="create" label={t("Create Number Association")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
				</Header>

				<List
					items={numberAssociations}
					loading={loading}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No number associations found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				{!loading && (
					<EditNumberAssociationModal
						show={showCreateModal}
						numberAssociationId={selectedNumberAssociationId}
						onHide={this.onHideCreateModal}
						onSave={this.onSave}
					/>
				)}
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(A2PNumberAssociations)));
