import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { getServices } from "service-fetch";
import Select from "react-select";
import axios from "axios";
import moment from "moment";

import UserService from "../../services/UserService";
// import LocationService from "../../services/LocationService";
import GAService from "../../services/GAService";
import DashboardService from "../../services/DashboardService";
import ToastService from "../../services/ToastService";
import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";

import withLocation from "../../components/common/WithLocation";
import DHModal from "../../components/common/DHModal";
import Input from "../../components/common/Input";
import DragAndDropOverlay from "../../components/common/DragAndDropOverlay/DragAndDropOverlay";
import AttachmentItem from "../../components/common/AttachmentItem";
import TimeDropdown from "../../components/common/TimeDropdown";

import {
	LOCAL_POSTS_ACTIONS,
	LOCAL_POSTS_TOPIC_TYPES_OPTIONS,
	LOCAL_POSTS_ACTIONS_OPTIONS,
	LOCAL_POSTS_TOPIC_TYPES,
	LOCAL_POSTS_MEDIA_FORMATS,
	LOCAL_POSTS_PHOTO_LIMITS
} from "../../constants/PostsConstants";
import { MEDIA_EXTENSIONS, MEDIA_TYPES } from "../../constants/Messenger";

import AppConfig from "../../config/app/web-app.config.js";
import "../../App.css";
import "./posts.css";

const { LocationService } = getServices();

class Posts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			saving: false,

			// Local post fields
			summary: "",
			topicType: LOCAL_POSTS_TOPIC_TYPES_OPTIONS[0],
			// Media related fields
			mediaIds: [],
			mediaChangesMade: false,
			// Button fields
			action: LOCAL_POSTS_ACTIONS_OPTIONS[0],
			actionUrl: "",

			// Offer feilds
			couponCode: "",
			redeemOnlineUrl: "",
			termsConditions: "",

			// Event
			title: "",
			startDate: null,
			startTime: null,
			endDate: null,
			endTime: null
		};

		this.accountSetup = null;
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { post, media } = this.props;

		if (!post) {
			this.update({
				loading: false,
				saving: false,
				summary: "",
				topicType: LOCAL_POSTS_TOPIC_TYPES_OPTIONS[0],
				mediaIds: [],
				mediaChangesMade: false,
				action: LOCAL_POSTS_ACTIONS_OPTIONS[0],
				actionUrl: "",

				// Offer feilds
				couponCode: "",
				redeemOnlineUrl: "",
				termsConditions: "",

				// Event
				title: "",
				startDate: null,
				startTime: null,
				endDate: null,
				endTime: null
			});

			return;
		}

		let mediaIds = [];
		let action = LOCAL_POSTS_ACTIONS_OPTIONS[0];
		let actionUrl = "";

		let couponCode = "";
		let redeemOnlineUrl = "";
		let termsConditions = "";

		let title = "";
		let startDate = null;
		let startTime = null;
		let endDate = null;
		let endTime = null;

		if (post.callToAction) {
			action = LOCAL_POSTS_ACTIONS_OPTIONS.find(op => op.value === post.callToAction.actionType);
			actionUrl = post.callToAction.url || "";
		}

		if (media && media.length > 0) {
			for (let i = 0; i < media.length; i++) {
				const aMedia = media[i];

				mediaIds.push(aMedia.id);
			}
		}

		if (post.event) {
			let schedule = post.event.schedule;
			let scheduleStartDate = schedule.startDate;
			let scheduleStartTime = schedule.startTime;
			let scheduleEndDate = schedule.endDate;
			let scheduleEndTime = schedule.endTime;

			title = post.event.title;
			startDate = moment()
				.year(scheduleStartDate.year)
				.month(scheduleStartDate.month - 1)
				.date(scheduleStartDate.day);
			endDate = moment()
				.year(scheduleEndDate.year)
				.month(scheduleEndDate.month - 1)
				.date(scheduleEndDate.day);

			if (scheduleStartTime && typeof scheduleStartTime.hours !== "undefined") {
				let minutes = 0;
				if (typeof scheduleStartTime.minutes !== "undefined") {
					minutes = scheduleStartTime.minutes;
				}
				startTime = `${UtilityService.padStart(scheduleStartTime.hours)}:${UtilityService.padStart(minutes)}`;
			}
			if (scheduleEndTime && typeof scheduleEndTime.hours !== "undefined") {
				let minutes = 0;
				if (typeof scheduleEndTime.minutes !== "undefined") {
					minutes = scheduleEndTime.minutes;
				}
				endTime = `${UtilityService.padStart(scheduleEndTime.hours)}:${UtilityService.padStart(minutes)}`;
			}
		}
		if (post.offer) {
			couponCode = post.offer.couponCode;
			redeemOnlineUrl = post.offer.redeemOnlineUrl;
			termsConditions = post.offer.termsConditions;
		}

		await this.update({
			loading: false,
			saving: false,
			summary: post.summary,
			topicType: LOCAL_POSTS_TOPIC_TYPES_OPTIONS.find(op => op.value === post.topicType),
			mediaIds,
			action,
			actionUrl,

			couponCode,
			redeemOnlineUrl,
			termsConditions,

			title,
			startDate,
			startTime,
			endDate,
			endTime
		});
	};

	onInputChange = async event => {
		let name = event.target.name;
		let value = event.target.value;

		if (name === "startDate" || name === "endDate") {
			value = moment(value);
		}

		await this.update({ [name]: value });
	};

	onTopicTypeChange = option => {
		this.update({
			topicType: option
		});
	};

	onFileAdded = async newFiles => {
		try {
			let { mediaIds } = this.state;
			let { t } = this.props;

			let file = newFiles[0];

			if (file.size > LOCAL_POSTS_PHOTO_LIMITS.sizeLimit) {
				ToastService.error(t("File size limit of 5MB"));
				return;
			}

			let media = MessagesService.storeLocalMedia({ file });

			await UtilityService.timeout(500);

			if (media.height < LOCAL_POSTS_PHOTO_LIMITS.minPixelHeight || media.width < LOCAL_POSTS_PHOTO_LIMITS.minPixelWidth) {
				ToastService.error(t("Image size must be a minimum of 400 x 300."));
				MessagesService.removeLocalMedia(media.id);
				return;
			}

			if (!media) {
				console.log(`Error adding files to local media.`);
				return;
			}

			mediaIds.push(media.id);

			this.update({
				mediaIds,
				mediaChangesMade: true
			});
		} catch (error) {
			console.error(`Thread.js - File Drag/Drop Error - ${error}`);
		}
	};

	onHideAttachment = async mediaId => {
		let { mediaIds } = this.state;
		let { t } = this.props;

		let localMedia = MessagesService.getLocalMedia(mediaId);

		// Remove media on google
		if (localMedia.googleMedia) {
			ToastService.info(t("Media deleted from local post."));
		}

		// Remove locally
		MessagesService.removeLocalMedia(mediaId);

		let mediaIned = mediaIds.indexOf(mediaId);

		if (mediaIned >= 0) {
			mediaIds.splice(mediaIned, 1);
		}

		await this.update({
			mediaIds,
			mediaChangesMade: true
		});
	};

	onActionChange = option => {
		this.update({
			action: option
		});
	};

	handleStartTimeChange = newValue => {
		this.update({
			startTime: newValue.target.value
		});
	};

	handleEndTimeChange = newValue => {
		this.update({
			endTime: newValue.target.value
		});
	};

	isInputDisabled = () => {
		let { saving } = this.state;

		return saving;
	};

	isActionUrlValid = () => {
		let { action, actionUrl } = this.state;

		if (action && action.value !== LOCAL_POSTS_ACTIONS.ACTION_TYPE_UNSPECIFIED && action.value !== LOCAL_POSTS_ACTIONS.CALL) {
			if (!UtilityService.isValidHttpsUrl(actionUrl)) {
				return false;
			}
		}

		return true;
	};

	isRedeemUrlValid = () => {
		let { topicType, redeemOnlineUrl } = this.state;

		if (topicType.value === LOCAL_POSTS_TOPIC_TYPES.OFFER) {
			if (!UtilityService.isValidHttpsUrl(redeemOnlineUrl)) {
				return false;
			}
		}

		return true;
	};

	isFormValid = () => {
		let {
			saving,
			summary,
			topicType,
			mediaIds,

			action,
			actionUrl,
			title,
			startDate,
			startTime,
			endDate,
			endTime,
			couponCode,
			redeemOnlineUrl,
			termsConditions
		} = this.state;

		if (saving) {
			return false;
		}

		if (!summary) {
			return false;
		}

		if (topicType.value === LOCAL_POSTS_TOPIC_TYPES.EVENT) {
			if (!title) {
				return false;
			}

			if (!startDate) {
				return false;
			}

			if (!endDate) {
				return false;
			}
		}

		if (mediaIds && mediaIds.length > 1) {
			return false;
		}

		if (!this.isActionUrlValid()) {
			return false;
		}

		if (!this.isRedeemUrlValid()) {
			return false;
		}

		return true;
	};

	handleOnSave = async () => {
		if (!this.isFormValid()) {
			return;
		}
		try {
			this.update({
				saving: true
			});
			let { post } = this.props;
			let {
				summary,
				topicType,
				action,
				actionUrl,
				mediaIds,
				mediaChangesMade,
				title,
				startDate,
				startTime,
				endDate,
				endTime,
				couponCode,
				redeemOnlineUrl,
				termsConditions
			} = this.state;
			let postData = {
				summary,
				topicType: topicType.value
			};
			let removeMediaIds = [];
			let { t } = this.props;

			if (mediaChangesMade) {
				postData.media = [];
			}

			if (mediaIds && mediaIds.length > 0) {
				for (let i = 0; i < mediaIds.length; i++) {
					let mediaId = mediaIds[i];
					let media = MessagesService.getLocalMedia(mediaId);

					if (media.file.googleMedia) {
						continue;
						// Passing in media using the media dataRef method does not seem to work
						// https://developers.google.com/my-business/reference/rest/v4/accounts.locations.media#MediaItem.MediaItemDataRef
					}

					const localMedia = MessagesService.getLocalMedia(mediaId);

					let data = new FormData();

					if (localMedia.file) {
						data.append("file", localMedia.file);
					}

					let locationId = UserService.getActiveLocation().id;
					let endpoint = `${AppConfig.API_SERVER}/api/location/${locationId}/gmb_local_posts/media/upload`;

					let response = await axios.post(endpoint, data, {
						method: "POST",
						headers: {
							Authorization: UserService.getAuthToken()
						}
					});

					if (!postData.media) {
						postData.media = [];
					}

					removeMediaIds.push(response.data.public_id);

					postData.media.push({
						mediaFormat: response.data.type === MEDIA_TYPES.video ? LOCAL_POSTS_MEDIA_FORMATS.VIDEO : LOCAL_POSTS_MEDIA_FORMATS.PHOTO,
						sourceUrl: response.data.sourceUrl
					});
				}
			}

			if (action) {
				postData.callToAction = {
					actionType: action.value
				};
				if (action.value !== LOCAL_POSTS_ACTIONS.CALL) {
					postData.callToAction.url = actionUrl;
				}
			}

			if (topicType.value === LOCAL_POSTS_TOPIC_TYPES.EVENT || topicType.value === LOCAL_POSTS_TOPIC_TYPES.OFFER) {
				postData.event = {
					title,
					schedule: {
						startDate: {
							year: moment(startDate).year(),
							month: moment(startDate).month() + 1,
							day: moment(startDate).date()
						},
						startTime: {
							hours: 0,
							minutes: 0
						},
						endDate: {
							year: moment(endDate).year(),
							month: moment(endDate).month() + 1,
							day: moment(endDate).date()
						},
						endTime: {
							hours: 0,
							minutes: 0
						}
					}
				};
				if (startTime) {
					let time = startTime.split(":");
					let hours = parseInt(time[0]);
					let minutes = parseInt(time[1]);
					postData.event.schedule.startTime = {
						hours,
						minutes
					};
				}
				if (endTime) {
					let time = endTime.split(":");
					let hours = parseInt(time[0]);
					let minutes = parseInt(time[1]);
					postData.event.schedule.endTime = {
						hours,
						minutes
					};
				}
			}

			if (topicType.value === LOCAL_POSTS_TOPIC_TYPES.OFFER) {
				postData.offer = {
					couponCode,
					redeemOnlineUrl,
					termsConditions
				};
			}

			// If we are updating an existing local post
			if (post) {
				postData.name = post.name;
				let response = await LocationService.updateGmbLocalPost({
					params: { locationId: UserService.getActiveLocation().id },
					body: {
						localPost: postData
					}
				});
				this.update({
					saving: false
				});
				await this.deleteMedia(removeMediaIds);

				ToastService.info(t("Post updated."));

				this.onHide(true);
				return;
			}

			// Create a new local post
			let response = await LocationService.createGmbLocalPost({
				params: { locationId: UserService.getActiveLocation().id },
				body: {
					localPost: postData
				}
			});

			this.update({
				saving: false
			});
			await this.deleteMedia(removeMediaIds);

			this.onHide(true);
			ToastService.info(t("Post saved."));
		} catch (error) {
			console.log(`error: `, error);
		}
		MessagesService.clearLocalMedia();
	};

	deleteMedia = async mediaIds => {
		for (let i = 0; i < mediaIds.length; i++) {
			const mediaId = mediaIds[i];
			try {
				await LocationService.deleteGmbLocalPostMedia({
					params: { locationId: UserService.getActiveLocation().id },
					body: {
						mediaId
					}
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	onHide = changesMade => {
		if (this.props.onClose) {
			this.props.onClose(changesMade);
		}

		this.update({
			loading: false,
			saving: false,
			summary: "",
			topicType: LOCAL_POSTS_TOPIC_TYPES_OPTIONS[0],
			mediaIds: [],
			mediaChangesMade: false,
			action: LOCAL_POSTS_ACTIONS_OPTIONS[0],
			actionUrl: "",

			// Offer feilds (Offer is a special type of event)
			couponCode: "",
			redeemOnlineUrl: "",
			termsConditions: "",

			// Event
			title: "",
			startDate: null,
			startTime: null,
			endDate: null,
			endTime: null
		});
	};

	renderLoading = () => {
		return (
			<div className="modal__body">
				<ContentLoader viewBox="0 0 100% 560" height={560} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="120" rx="5" ry="5" width="100%" height="200" />
					<rect x="0" y="340" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="460" rx="5" ry="5" width="100%" height="100" />
				</ContentLoader>
			</div>
		);
	};

	renderBody = () => {
		let {
			saving,
			summary,
			topicType,
			action,
			actionUrl,
			mediaIds,
			title,
			couponCode,
			redeemOnlineUrl,
			termsConditions,
			startDate,
			startTime,
			endDate,
			endTime
		} = this.state;
		let { t, post } = this.props;

		let isEditingPost = post;

		return (
			<>
				<div className="modal__body">
					<div className="input">
						<div className="input__label">
							{t("Post Type")} <span className="input__label__required">*</span>
						</div>
						<Select
							id={"topic-type"}
							className="mb-tcsm__back__select__select"
							options={LOCAL_POSTS_TOPIC_TYPES_OPTIONS}
							value={topicType}
							placeholder={t("Topic Type")}
							maxMenuHeight={250}
							onChange={this.onTopicTypeChange}
							isDisabled={isEditingPost || this.isInputDisabled()}
						/>
					</div>

					{mediaIds && mediaIds.length < 1 && (
						<div className="input">
							<div className="input__label">{t("Media")}</div>
							<DragAndDropOverlay onFileAdded={this.onFileAdded} accept={{ "image/jpeg": [], "image/png": [], "video/mp4": [], "image/webp": [] }} />
						</div>
					)}

					{mediaIds && mediaIds.length > 0 && (
						<div className="input">
							<div className="posts__post__media">
								{mediaIds.map(mediaId => (
									<AttachmentItem
										key={mediaId}
										mediaId={mediaId}
										hide={() => this.onHideAttachment(mediaId)}
										editAllowed={true}
										referrerPolicy={"no-referrer"}
									/>
								))}
							</div>
						</div>
					)}

					{topicType.value === LOCAL_POSTS_TOPIC_TYPES.EVENT || topicType.value === LOCAL_POSTS_TOPIC_TYPES.OFFER ? (
						<>
							<Input
								label={t("Title")}
								name="title"
								id="title"
								type="text"
								onChange={this.onInputChange}
								value={title}
								disabled={this.isInputDisabled()}
								required
							/>
							<Input
								label={t("Description")}
								name="summary"
								id="summary"
								type="textarea"
								onChange={this.onInputChange}
								value={summary}
								disabled={this.isInputDisabled()}
								required
							/>

							<Input
								label={t("Start Date")}
								name="startDate"
								id="startDate"
								type="date"
								onChange={this.onInputChange}
								value={startDate ? startDate.format("YYYY-MM-DD") : null}
								disabled={this.isInputDisabled()}
								required
							/>
							{topicType.value === LOCAL_POSTS_TOPIC_TYPES.EVENT && (
								<div className="input">
									<div className="input__label">{t("Start Time")}</div>
									<TimeDropdown id={"startTime"} checked={false} isDisabled={false} handleChange={this.handleStartTimeChange} value={startTime} />
								</div>
							)}

							<Input
								label={t("End Date")}
								name="endDate"
								id="endDate"
								type="date"
								onChange={this.onInputChange}
								value={endDate ? endDate.format("YYYY-MM-DD") : null}
								disabled={this.isInputDisabled()}
								required
							/>
							{topicType.value === LOCAL_POSTS_TOPIC_TYPES.EVENT && (
								<div className="input">
									<div className="input__label">{t("End Time")}</div>
									<TimeDropdown id={"endTime"} checked={false} isDisabled={false} handleChange={this.handleEndTimeChange} value={endTime} />
								</div>
							)}

							{topicType.value === LOCAL_POSTS_TOPIC_TYPES.OFFER && (
								<>
									<Input
										label={t("Coupon Code")}
										name="couponCode"
										id="couponCode"
										type="text"
										onChange={this.onInputChange}
										value={couponCode}
										disabled={this.isInputDisabled()}
									/>
									<Input
										label={t("Redeem Online Url")}
										name="redeemOnlineUrl"
										id="redeemOnlineUrl"
										type="text"
										onChange={this.onInputChange}
										disabled={this.isInputDisabled()}
										invalid={!this.isRedeemUrlValid()}
										value={redeemOnlineUrl}
									/>
									<Input
										label={t("Terms and Condition")}
										name="termsConditions"
										id="termsConditions"
										type="textarea"
										onChange={this.onInputChange}
										disabled={this.isInputDisabled()}
										value={termsConditions}
									/>
								</>
							)}
						</>
					) : (
						<Input label={t("Description")} name="summary" id="summary" type="textarea" onChange={this.onInputChange} value={summary} required />
					)}

					{topicType.value !== LOCAL_POSTS_TOPIC_TYPES.OFFER ? (
						<>
							<div className="input">
								<div className="input__label">{t("Action Button")}</div>
								<Select
									id={"action-button"}
									className="mb-tcsm__back__select__select"
									options={LOCAL_POSTS_ACTIONS_OPTIONS}
									value={action}
									placeholder={t("Action Button")}
									maxMenuHeight={250}
									onChange={this.onActionChange}
								/>
							</div>
							{action && action.value !== LOCAL_POSTS_ACTIONS.ACTION_TYPE_UNSPECIFIED && action.value !== LOCAL_POSTS_ACTIONS.CALL && (
								<Input
									label={t("Link for your button")}
									name="actionUrl"
									id="actionUrl"
									type="text"
									onChange={this.onInputChange}
									value={actionUrl}
									disabled={this.isInputDisabled()}
									invalid={!this.isActionUrlValid()}
									required
								/>
							)}
						</>
					) : null}

					<div className="modal__actions">
						<div id={"save"} className={`mb-button ${this.isFormValid() ? "" : "mb-button--disabled"}`} onClick={this.handleOnSave}>
							{saving ? t("Saving...") : t("Save")}
						</div>
					</div>
				</div>
			</>
		);
	};

	render() {
		let { loading } = this.state;
		let { show, post } = this.props;
		let { t } = this.props;

		let isEditingPost = post;

		return (
			<DHModal title={isEditingPost ? t("Edit Post") : t("Create Post")} show={show} onHide={() => this.onHide()}>
				{loading && this.renderLoading()}
				{!loading && this.renderBody()}
			</DHModal>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Posts)));
