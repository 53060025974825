import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import GAService from "../../services/GAService";

import withLocation from "../../components/common/WithLocation";
import Header from "../../components/common/Header";
import Page from "../../components/common/Page";
import ScheduledMessageAnalytics from "./ScheduledMessageAnalytics";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class CampaignAnalytics extends React.Component {
	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	render = () => {
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Campaign Analytics")} />
				<div className="dh-page__cards">
					<ScheduledMessageAnalytics />
				</div>
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(CampaignAnalytics)));
