import React, { PureComponent } from "react";
import moment from "moment";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Pin } from "lucide-react";
import { withTranslation, Trans } from "react-i18next";

import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";

import { EMOJI_POSITIONS } from "../../../../constants/Emojis";
import { DIRECTION, KEYS, MEDIA_TYPES, STATUS_EVENTS, MEDIUM, MODE } from "../../../../constants/Messenger";

import MessagesService from "../../../../services/MessagesService";
import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import TeamChatService from "../../../../services/TeamChatService";
import RealtimeService from "../../../../services/WebsocketsConnection";
import ToastService from "../../../../services/ToastService";
import EmojiService from "../../../../services/EmojiService";

import Attachments from "../Attachments/Attachments";

import { ATTACHMENT_MEDIA } from "../../../../constants/Attachments";

import Reactions from "./Reactions";
import MessageEvent from "../MessageEvent/MessageEvent";
import ThreadMedia from "../Media/ThreadMedia";
import Linkify from "../../../../components/common/Linkify";
import DHLexical from "../DHLexical/DHLexical";

import "./team-chat-message.css";

class TeamChatMessage extends PureComponent {
	constructor(props) {
		super(props);

		let { message, previousMessage, searchTerm } = props;

		this.context = null;

		this.state = {
			message: message,
			previousMessage: previousMessage,
			isMedia: false,
			media: [],
			originalMediaIds: [],
			editedMediaIds: [],
			isContext: false,
			deleted: false,

			isEdit: false,

			editedMessageText: "",
			editedMessageHtml: "",
			editedMessageJSON: "",

			originalMessageText: "",
			editSaveLoading: false,

			retryingSend: false,
			searchTerm: searchTerm
		};

		this.messageInputRichtext = React.createRef();

		this.reactionButton = null;
		this.reactionsComponent = null;
		this.reactionsComponentContainer = null;
		this.attachmentComponent = null;

		this.messageRef = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.forceUpdate !== this.props.forceUpdate) {
			this.buildMediaList();
		}
	}

	async componentDidMount() {
		document.addEventListener("mousedown", this.onClick, false);

		await this.buildMediaList();

		this.scrollIntoView();
	}

	scrollIntoView(options = { behavior: "smooth", block: "center", inline: "end" }) {
		let { isReference } = this.props;
		if (isReference && this.messageRef) {
			this.messageRef.scrollIntoView(options);
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onClick, false);
	}

	buildMediaList = async () => {
		let { message } = this.state;

		let isMedia = message && message.media && message.media.length > 0;

		await this.update({
			media: message.media || [],
			isMedia
		});
	};

	onClick = e => {
		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		if (this.emojiSelector && this.emojiSelector.contains && this.emojiSelector.contains(e.target)) {
			return;
		}

		this.update({
			isContext: false,
			showEmojiSelector: false
		});
	};

	onContextClick = () => {
		let { isContext } = this.state;

		this.update({
			isContext: !isContext
		});
	};

	onCreateTaskMessageClick = () => {
		if (this.props.onCreateTaskMessageClick) {
			this.props.onCreateTaskMessageClick();
		}

		this.update({
			isContext: false
		});
	};

	onDeleteMessageClick = () => {
		let { message } = this.state;
		let { id } = message;

		let content = (
			<div className="mb-toast-undo">
				<div>Message deleted!</div>
				<div className="mb-toast-undo-button" onClick={() => (toastInstance = null)}>
					{" "}
					Undo{" "}
				</div>
			</div>
		);

		let toastInstance = toast.info(content, {
			position: "bottom-center",
			autoClose: 5000,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast",
			onClose: async () => {
				if (toastInstance) {
					await MessagesService.deleteMessage(id);
					await RealtimeService.sendMessageUpdated(id);
				} else {
					this.update({
						deleted: false
					});
				}
			}
		});

		this.update({
			deleted: true,
			isContext: false
		});
	};

	onAttachmentChanged = async mediaIds => {
		await this.update({
			editedMediaIds: mediaIds
		});
	};

	onEditMessage = async () => {
		let { message, media } = this.state;

		await this.update({
			isContext: false,
			isEdit: true,
			originalMessageText: message.content,
			editedMessageText: message.content,
			editedMessageHtml: message.content_html,
			editedMessageJSON: message.content_json
		});

		if (this.messageInputRichtext && this.messageInputRichtext.current) {
			if (message.content_json) {
				this.messageInputRichtext.current.loadJsonState(message.content_json);
			} else if (message.content) {
				this.messageInputRichtext.current.createStateFromText(message.content);
			}
			this.messageInputRichtext.current.focus();
		}

		if (this.attachmentComponent && this.attachmentComponent.importMediaIds) {
			let mediaIds = media.map(m => m.id);

			// Store the original media ids
			await this.update({ originalMediaIds: mediaIds });

			this.attachmentComponent.importMediaIds(mediaIds);
		}
	};

	onStopEditMessage = async () => {
		let { message } = this.state;

		if (this.messageInputRichtext && this.messageInputRichtext.current) {
			this.messageInputRichtext.current.clearInput();
		}

		await this.update({
			isContext: false,
			isEdit: false
		});
	};

	onEditInputScroll = event => {
		// Prevent the scroll event from bubbling up to the thread. That can cause issues with scrolling at times
		event.stopPropagation();
	};

	onReplyToMessage = async () => {
		let { message } = this.state;
		if (this.props.onReplyToMessageClicked) {
			await this.props.onReplyToMessageClicked(message.id);
		}

		await this.update({
			isContext: false
		});
	};

	onKeyDown = e => {
		let user = UserService.get();
		if (!user.messenger_click_to_send && e.keyCode === KEYS.enter && e.shiftKey === false) {
			this.onEditSave();
		}
	};

	onEditSave = async () => {
		let { message, editedMessageText, editedMessageHtml, editedMessageJSON, editedMediaIds, originalMediaIds } = this.state;
		let companyId = UserService.getActiveCompany().id;
		let locationId = UserService.getActiveLocation().id;
		let { t } = this.props;

		if (this.isDisabled()) {
			return;
		}

		await this.update({
			editSaveLoading: true
		});

		// Figure out what media needs to be uploaded first
		let mediaIds = [];

		// If we have media that may need to be uploaded
		if (editedMediaIds.length > 0) {
			let mediaToUpload = [];

			// If we find mediaIdToCheck in originalMediaIds, then we don't need to upload it
			for (let mediaIdToCheck of editedMediaIds) {
				// If we didn't originally have this ID, upload it (it's a UUID)
				if (originalMediaIds.indexOf(mediaIdToCheck) === -1) {
					mediaToUpload.push(mediaIdToCheck);
				} else {
					// We already have this media on the backend (it's a backend id)
					mediaIds.push(mediaIdToCheck);
				}
			}

			// If there is media to upload
			if (mediaToUpload.length > 0) {
				let uploadedMediaList = await MessagesService.uploadLocalMedia({
					mediaIds: mediaToUpload,
					mode: MODE.team,
					medium: MEDIUM.demandhub,
					optimize: true,
					companyId,
					locationId
				});

				if (!uploadedMediaList) {
					ToastService.error(`Error uploading attachment${editedMediaIds.length > 1 ? "s" : ""}. Please try again.`);
					throw new Error(`Error uploading attachment${editedMediaIds.length > 1 ? "s" : ""}.`);
				}

				// Push the uploadedMediaList array to the mediaIds array
				mediaIds.push(...uploadedMediaList.map(m => m.id));

				MessagesService.clearLocalMedia();
			}
		}

		let newMessage = await TeamChatService.updateMessage({
			messageId: message.id,
			content: editedMessageText,
			contentHtml: editedMessageHtml,
			contentJSON: editedMessageJSON,
			mediaIds
		});

		if (!newMessage) {
			await this.update({
				editSaveLoading: false
			});

			toast.info(t("Unable to update message."), {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				className: "mb-toast"
			});

			return;
		}

		RealtimeService.sendMessageUpdated(newMessage.id);

		await this.update({
			editSaveLoading: false,
			editedMediaIds: [],
			originalMediaIds: [],
			isEdit: false,
			message: newMessage
		});

		// Rebuild media list
		await this.buildMediaList();

		toast.info(t("Message updated!"), {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast"
		});
	};

	onEditCancel = async () => {
		let { editSaveLoading } = this.state;

		if (editSaveLoading) {
			return;
		}

		await this.update({
			isEdit: false
		});
	};

	onRichTextMessageInput = async (messageInput, messageInputJSON, messageInputHTML) => {
		await this.update({
			editedMessageText: messageInput,
			editedMessageJSON: messageInputJSON,
			editedMessageHtml: messageInputHTML
		});

		if (this.props.onContentChange) {
			this.props.onContentChange(messageInput, messageInputJSON, messageInputHTML);
		}
	};

	onReactionClick = () => {
		EmojiService.openEmojiSelector({
			positionBeside: this.reactionButton,
			position: EMOJI_POSITIONS.left,
			onEmojiSelect: this.onEmojiSelected
		});
	};

	onEmojiSelected = emoji => {
		if (this.reactionsComponent) {
			this.reactionsComponent.onAddOrRemoveReaction(emoji.native);
		}
	};

	onMarkAsUnread = async () => {
		let { message } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		let success = await TeamChatService.markAsUnread(message.id, message.conversation_id, user.id);

		this.update({
			isContext: false
		});

		if (!success) {
			ToastService.error(t("An error occurred trying to mark the message as unread."));
			return;
		}

		if (this.props.onMarkAsUnread) {
			this.props.onMarkAsUnread(message);
		}
	};

	onPinMessage = async () => {
		let { message } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		let success = await MessagesService.pinMessage({
			locationId: null,
			userId: user.id,
			conversationId: message.conversation_id,
			messageId: message.id
		});

		this.update({
			isContext: false
		});

		if (!success) {
			ToastService.error(t("An error occurred trying to pin the message."));
			return;
		}

		if (this.props.onPinMessage) {
			this.props.onPinMessage(message);
		}
	};

	onEnter = e => {
		if (e.keyCode === KEYS.enter && e.shiftKey === false) {
			e.preventDefault();
			this.onReplySave();
		}
	};

	onGoToCustomerChat = async messageId => {
		let { t } = this.props;

		let message = await MessagesService.getMessage(messageId);

		if (!message) {
			ToastService.error(t("Error trying to go to Customer Chat"));
			return;
		}

		let url = `${window.location.origin}/inbox?contactId=${message.Contact.public_id}&locationId=${message.Location.public_id}`;
		window.location.href = url;
	};

	renderDate(message, previousMessage) {
		if (previousMessage && !MessagesService.shouldAppendDate(previousMessage.created_at, message.created_at)) {
			return null;
		}

		let date = MessagesService.getRelativeDate(message.created_at);

		return (
			<div key={`${message.id}-date`} className="mb-message-list-divider">
				<div className="mb-message-list-divider-line" />
				<div className="mb-message-list-divider-date">{date}</div>
				<div className="mb-message-list-divider-line" />
			</div>
		);
	}

	renderUnreadDivider(message, previousMessage) {
		let { t } = this.props;

		if (!TeamChatService.shouldAppendUnreadIndicator(message, previousMessage)) {
			return null;
		}

		return (
			<div key={`${message.id}-date`} className="mb-message-list-divider" ref={ref => (this.newLine = ref)}>
				<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
				<div className="mb-message-list-divider-date mb-message-list-divider-date--danger">{t("NEW")}</div>
				<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
			</div>
		);
	}

	renderName(message, previousMessage) {
		// If there is no previous message, then we should not render the name header
		if (!previousMessage) {
			return null;
		}

		let isPreviousEvent = previousMessage && previousMessage.event_type !== "message";
		let isCurrentEvent = message && message.event_type !== "message";

		// If the current and previous messages are both events, we should not render the name header
		if (isCurrentEvent) {
			return null;
		}

		// If the previous message is not an event, and the sender_user_ids on both the current and previous message are the same,
		// it can be assumed that the sender is the same and we should not preppend the name
		if (
			!isPreviousEvent &&
			previousMessage &&
			previousMessage.sender_user_id === message.sender_user_id &&
			moment(message.created_at).diff(moment(previousMessage.created_at), "minutes") < 5
		) {
			return null;
		}

		let date = moment(message.created_at).format("h:mm a");

		return (
			<div key={`${message.id}-name`} className="mb-message-list-name">
				{message.sender_user_name}
				<span className="mb-message-list-date">{date}</span>
			</div>
		);
	}

	placeholderMessage = () => {
		return "Edit your message ...";
	};

	isDisabled = () => {
		let { editSaveLoading, editedMessageText, editedMediaIds } = this.state;

		if (editSaveLoading) {
			return true;
		}

		if (!editedMessageText && editedMediaIds.length === 0) {
			return true;
		}

		return false;
	};

	renderEdited(message) {
		let { t } = this.props;

		if (!message.status_events) {
			return null;
		}

		let edited = false;
		let editedTime = null;
		try {
			let events = JSON.parse(message.status_events);
			events.reverse();

			let event = events.find(item => item.event === STATUS_EVENTS.messageEdited.id);
			if (event) {
				edited = true;
				editedTime = moment(event.created_at).format("MMMM Do YYYY, h:mm A");
			}
		} catch (error) {
			console.log(`Error rendering edited `, error);
		}

		if (!edited) {
			return null;
		}

		return (
			<>
				<div className="mb-message-date mb-message-date--edited" data-tip data-for={`rtt-tc-message-edited-${message.id}`}>
					{STATUS_EVENTS.messageEdited.label}
				</div>
				<ReactTooltip id={`rtt-tc-message-edited-${message.id}`} className="mb-react-tooltip" type="info" effect="solid" place="top" arrowColor="#333">
					{t("Edited on:")} {editedTime}
				</ReactTooltip>
			</>
		);
	}

	onReplyClicked = async messageReplyId => {
		if (this.props.onReplyClicked) {
			await this.props.onReplyClicked(messageReplyId);
		}
	};

	renderReply() {
		let { message } = this.state;
		if (!message.reply_id) {
			return null;
		}

		let isReplyMedia = message.reply_media_id ? true : false;

		return (
			<div className="mb-message-reply" onClick={() => this.onReplyClicked(message.reply_id)}>
				<div className="mb-message-reply-name">{message.reply_name}</div>
				{message.reply_content && <div className="mb-message-reply-content">{this.renderContent(message.reply_id, message.reply_content)}</div>}
				{isReplyMedia && <div className="mb-message-media-content">{this.renderReplyMedia()}</div>}
			</div>
		);
	}

	renderReplyMedia() {
		let { message } = this.state;

		let replyMedia = message.reply_media_object;

		if (!replyMedia) {
			return null;
		}

		return <ThreadMedia key={message.reply_media_id} media={replyMedia} maxHeightOrWidth={50} idPrefix={"tc-reply"} readOnly={true} />;
	}

	renderMedia() {
		let { media } = this.state;
		let { onMediaClicked, mediaMaxHeightOrWidth, mediaReadOnly, horizontalMedia } = this.props;

		if (!media || media.length === 0) {
			return null;
		}

		if (horizontalMedia) {
			return (
				<div className="mb-message-team-set__media--horizontal">
					{media.map(m => {
						let maxHeightOrWidth = mediaMaxHeightOrWidth;
						// Make files twice the width
						if (maxHeightOrWidth && m.type !== MEDIA_TYPES.image) {
							maxHeightOrWidth = 2 * maxHeightOrWidth;
						}
						return <ThreadMedia key={m.id} media={m} onMediaClicked={onMediaClicked} maxHeightOrWidth={maxHeightOrWidth} readOnly={mediaReadOnly} />;
					})}
				</div>
			);
		}

		return media.map(m => {
			return <ThreadMedia key={m.id} media={m} onMediaClicked={onMediaClicked} maxHeightOrWidth={mediaMaxHeightOrWidth} readOnly={mediaReadOnly} />;
		});
	}

	renderHtmlContent(id, contentHtml) {
		let cleanHTML = DOMPurify.sanitize(contentHtml);
		cleanHTML = UtilityService.replaceYoutubeLinks(cleanHTML);

		let key = parseInt(`${id}`, 10);

		let direction = UtilityService.detectLanguageDirection(cleanHTML);

		return (
			<p className="mb-message-content-blurb" dir={direction} key={key}>
				<div>
					<Linkify>{ReactHtmlParser(cleanHTML)}</Linkify>
				</div>
			</p>
		);
	}

	renderContent(id, content, contentHtml) {
		if (contentHtml) {
			return this.renderHtmlContent(id, contentHtml);
		}

		return content.split("\n").map((blurb, index) => {
			let key = parseInt(`${id}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let direction = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" dir={direction} key={key}>
					<Linkify>{blurb}</Linkify>
				</p>
			);
		});
	}

	renderForwardedMessage() {
		let { message } = this.state;
		let { t } = this.props;

		if (!message) {
			return null;
		}

		if (!message.forward_message_media_id && (!message.forward_message_content || message.forward_message_content.length < 1)) {
			return null;
		}

		let { onMediaClicked } = this.props;

		return (
			<div className="mb-message-team-forward">
				<div className="mb-message-team-forward__message">
					<div className="mb-message-team-forward__message__header">
						<div onClick={() => this.onGoToCustomerChat(message.forward_message_id)}>
							<Icon.CornerUpRight size={14} /> {t("Shared customer message from")} {message.forward_message_name}
						</div>
					</div>
					{message.forward_media_object && (
						<ThreadMedia key={message.forward_message_media_id} media={message.forward_media_object} onMediaClicked={onMediaClicked} idPrefix="tc-forward" />
					)}
					<div className="mb-message-team-forward__message__text">{this.renderContent(message.forward_message_id, message.forward_message_content)}</div>
				</div>
			</div>
		);
	}

	renderTeamMessage() {
		let { message, isMedia, deleted, isEdit, editedMessageText, editSaveLoading } = this.state;
		let { isReference, highlightReference, hideHoverOptions, messagePins, hideSaveControls, hasAssociatedTask } = this.props;
		let { id, content, content_html, reactions } = message;
		let { t } = this.props;

		let contentStyle = {};
		let isDeleted = deleted || message.status === "deleted";

		// If the content is simply one emoji, then increase the size of the emoji to font size 48
		if (content.length === 2 && UtilityService.isSingleEmoji(content)) {
			contentStyle = {
				fontSize: 48
			};
		}

		let isMessagePinned = messagePins ? messagePins.findIndex(pin => id === pin.message_id) >= 0 : false;
		let date = moment(message.created_at).format("h:mm a");

		let isReply = message.reply_id ? true : false;
		let classesApplied = "mb-message-team";

		if (isReference && highlightReference) {
			classesApplied += " mb-message-team--reference";
		}

		if (hideHoverOptions) {
			classesApplied += " mb-message-team--hide-options";
		}

		if (hasAssociatedTask) {
			classesApplied += " mb-message-team--task-incomplete";
		}

		return (
			<div className={classesApplied} ref={ref => (this.messageRef = ref)}>
				{isEdit && (
					<div className="mb-message-edit" onScroll={this.onEditInputScroll}>
						<Attachments
							ref={ref => (this.attachmentComponent = ref)}
							onChange={this.onAttachmentChanged}
							sizeLimit={ATTACHMENT_MEDIA.sizeLimit}
							inputComposeHeight={200}
							isRichtext={true}
							placed={true}
						/>
						<DHLexical
							ref={this.messageInputRichtext}
							onChange={this.onRichTextMessageInput}
							disabled={this.isDisabled()}
							placeholder={this.placeholderMessage()}
							shouldDisableEnterPlugin={false}
							onEnter={() => {
								this.onEditSave();
							}}
						/>

						{!hideSaveControls && (
							<div className="mb-message-edit__actions">
								<div
									id="mb-message-edit-button--success"
									onClick={this.onEditSave}
									className={`mb-message-edit-button mb-message-edit-button--success ${this.isDisabled() ? "mb-message-edit-button--disabled" : ""}`}
								>
									{editSaveLoading ? t("Saving ...") : t("Save")}
								</div>
								<div onClick={this.onEditCancel} className="mb-message-edit-button mb-message-edit-button--cancel">
									{t("Cancel")}
								</div>
							</div>
						)}
					</div>
				)}

				{!isEdit && !isDeleted && (
					<>
						<div className="mb-message-team-set">
							{isReply && this.renderReply()}
							{isMedia && this.renderMedia()}
							<div className="mb-message-team-content" style={contentStyle}>
								{content && this.renderContent(id, content, content_html)}
								<Reactions ref={ref => (this.reactionsComponent = ref)} messageId={id} reactions={reactions} />
							</div>
						</div>
						{!hideHoverOptions && (
							<>
								<div className="mb-message-date">{date}</div>
								{this.renderEdited(message)}
								<div ref={ref => (this.reactionButton = ref)} id="mb-message-reaction" className="mb-message-reaction" onClick={this.onReactionClick}>
									<Icon.Smile size="20" />
								</div>
								<div id="mb-message-team-context" className="mb-message-team-context" onClick={this.onContextClick}>
									<Icon.MoreVertical size="20" />
								</div>
							</>
						)}
					</>
				)}
				{isDeleted && this.renderDeletedMessage()}
				{isMessagePinned ? <Pin color="#333" size={20} /> : null}
				{this.renderContextMenu(isMessagePinned)}
			</div>
		);
	}

	renderContextMenu(isMessagePinned) {
		let { t } = this.props;

		let user = UserService.get();
		let { isContext, message } = this.state;
		let { sender_user_id, send_after } = message;

		let sendAfter = moment(send_after);
		let now = moment();
		let isSendingLater = now < sendAfter;

		let allowTaskCreation = user.GroupPermission.create_tasks;

		if (!isContext) {
			return null;
		}

		let modifier = "mb-message-context-menu--team";

		return (
			<div ref={ref => (this.context = ref)} className={`mb-message-context-menu ${modifier}`}>
				<div className="mb-message-context-menu-item" onClick={this.onMarkAsUnread}>
					{t("Mark as Unread")}
				</div>
				<div className="mb-message-context-menu-item" onClick={this.onReplyToMessage}>
					{t("Reply")}
				</div>
				{sender_user_id === user.id && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-update" onClick={this.onEditMessage}>
						{t("Edit Message")}
					</div>
				)}
				{sender_user_id === user.id && user.GroupPermission.delete_teamchat_messages && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-delete" onClick={this.onDeleteMessageClick}>
						{isSendingLater ? t("Cancel Message") : t("Delete Message")}
					</div>
				)}
				{allowTaskCreation && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-create-task" onClick={this.onCreateTaskMessageClick}>
						{t("Create Task")}
					</div>
				)}
				<div className="mb-message-context-menu-item" onClick={this.onPinMessage}>
					{isMessagePinned ? t("Unpin Message") : t("Pin Message")}
				</div>
			</div>
		);
	}

	renderDeletedMessage() {
		let { message } = this.state;
		let { t } = this.props;

		let { direction } = message;

		return (
			<div className={`mb-message-box ${direction === DIRECTION.out ? "mb-message-box--out" : ""} mb-message-box--removed`}>
				{t("This message has been deleted.")}
			</div>
		);
	}

	render() {
		let { message, previousMessage } = this.state;
		let { hideDate } = this.props;

		let isEvent = message.event_type !== "message";

		if (isEvent) {
			return (
				<React.Fragment>
					{!hideDate && this.renderDate(message, previousMessage)}
					<MessageEvent message={message} />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{!hideDate && this.renderDate(message, previousMessage)}
				{this.renderUnreadDivider(message, previousMessage)}
				{this.renderName(message, previousMessage)}
				{this.renderForwardedMessage()}
				{this.renderTeamMessage()}
			</React.Fragment>
		);
	}
}

export default withTranslation(null, { withRef: true })(TeamChatMessage);
