import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import WidgetConfigService from "../../../services/WidgetConfigService";
import UserService from "../../../services/UserService";

import DHSearchInput from "../../../components/common/DHSearchInput";
import DHModal from "../../../components/common/DHModal";

class TooltipImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profilePictures: [],
			activeImageKey: this.props.tooltipImage || WidgetConfigService.getDefaultTooltipImageUrl(),
			loading: true,
			searchTerm: "",
			filteredProfilePictures: []
		};

		this.defaultImageUrl = WidgetConfigService.getDefaultTooltipImageUrl();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onSetWebchatImageClick() {
		const { activeImageKey } = this.state;
		if (this.props.onUpdateImage && typeof this.props.onUpdateImage === "function") {
			this.props.onUpdateImage(activeImageKey);
			this.props.onHide();
		}
	}

	async fetchProfilePictures() {
		const { locationId } = this.props;
		try {
			const profilePictures = await WidgetConfigService.getProfilePictures(locationId);
			await this.update({ profilePictures, filteredProfilePictures: profilePictures });
		} catch (error) {
			console.log(error);
		}
		await this.update({ loading: false });
	}

	componentDidMount() {
		this.fetchProfilePictures();
	}

	onManageProfileClick() {
		this.props.history.push("/user-profile");
	}

	async setActiveImageKey(key) {
		const { activeImageKey } = this.state;
		if (activeImageKey === key) {
			await this.update({ activeImageKey: "" });
			return;
		}
		await this.update({ activeImageKey: key });
	}

	/**
	 *
	 * @param {String} value
	 */
	async filterProfilePictureResults() {
		const { profilePictures, searchTerm } = this.state;
		const filteredProfilePictures = profilePictures.filter(aProfilePicture => {
			let firstName = aProfilePicture.first_name || "";
			let lastName = aProfilePicture.last_name || "";

			if (firstName.toLowerCase().includes(searchTerm.toLowerCase()) || lastName.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			}
			return false;
		});
		await this.update({ filteredProfilePictures });
	}

	async onSearchChange(value) {
		await this.update({ searchTerm: value });
		await this.filterProfilePictureResults();
	}

	render() {
		const { onHide, showModal } = this.props;
		const { filteredProfilePictures, activeImageKey, loading, searchTerm, profilePictures } = this.state;
		let { t } = this.props;

		if (loading) {
			return null;
		}

		return (
			<DHModal title={t("Edit Image")} show={showModal} onHide={onHide}>
				<div className="widget-config__modal__body">
					<h3>{t("Select one of your teammates' profile picture!")}</h3>
					<br />
					<DHSearchInput
						placeholder={t("Search teammates...")}
						onChange={e => this.onSearchChange(e.target.value)}
						onSearchClear={() => this.onSearchChange("")}
						value={searchTerm}
					/>

					{profilePictures.length === 0 ? (
						<>
							<div className="widget-config__tooltip-image--no-pictures-container">
								<div className="Common__font--18">
									{t("No pictures available")}{" "}
									<span role="img" aria-label="sad-face">
										😞
									</span>
								</div>
								<br />
								<div className="Common__font--14 widget-config__tooltip-image--no-pictures-info">
									{t("None of your teammates have uploaded a custom profile picture. Go to Manage Profile and uploade a new image!")}
								</div>
							</div>
							<div className="mb-button mb-button--fit" onClick={() => this.onManageProfileClick()}>
								{t("Go to Manage Profile")}
							</div>
						</>
					) : (
						<>
							<div className="widget-config__modal__body-avatars">
								<div
									onClick={() => this.setActiveImageKey(this.defaultImageUrl)}
									className={`${
										activeImageKey === this.defaultImageUrl ? "widget-config__widget-tooltip--avatar-clicked " : ""
									}widget-config__widget-tooltip--avatar-small widget-config__widget-tooltip--avatar-gallery`}
								>
									<img src={this.defaultImageUrl} alt="Default Webchat Tooltip" />
									<span className="widget-config__user--name">{t("Default Webchat Tooltip")}</span>
									{activeImageKey === this.defaultImageUrl && (
										<span className="widget-config__gallery--check">
											<Icon.Check color="green" />
										</span>
									)}
								</div>
								{filteredProfilePictures.map(aProfilePicture => {
									const key = aProfilePicture.profile_picture_url;
									var fullName = UserService.createFullName({ firstName: aProfilePicture.first_name, lastName: aProfilePicture.last_name });
									return (
										<div
											onClick={() => this.setActiveImageKey(key)}
											key={key}
											className={`${
												activeImageKey === key ? "widget-config__widget-tooltip--avatar-clicked " : ""
											}widget-config__widget-tooltip--avatar-small widget-config__widget-tooltip--avatar-gallery`}
										>
											<img src={aProfilePicture.profile_picture_url} alt="Profile" />
											<span className="widget-config__user--name">{fullName}</span>
											{activeImageKey === key && (
												<span className="widget-config__gallery--check">
													<Icon.Check color="green" />
												</span>
											)}
										</div>
									);
								})}
							</div>
							<div className="mb-button mb-button--fit" onClick={() => this.onSetWebchatImageClick()} disabled={activeImageKey === ""}>
								{t("Set Webchat Image")}
							</div>
						</>
					)}
				</div>
			</DHModal>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(TooltipImage));
