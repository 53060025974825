import React from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import DashboardService from "../../services/DashboardService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import withLocation from "../../components/common/WithLocation";
import Alert from "../../components/common/Alert";
import AddDashboardCardModal from "./AddDashboardCardModal";

import { DASHBOARD_COLUMNS } from "../../constants/Dashboard";
import { STATUS } from "../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class Dashboards extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			searchTerm: "",
			loading: false,
			loadedAll: false,
			sortField: "",
			sortOrder: "",
			limit: 50,

			selectedDashboardId: "",
			selectedDashboardName: "",
			createDashboard: {},
			showConfirmDeleteModal: false,
			showCreateDashboardModal: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { limit } = this.state;

		this.update({ loading: true });

		let data = await DashboardService.fetch({
			locationId: UserService.getActiveLocation().id,
			status: [STATUS.active]
		});

		this.update({
			data,
			loading: false,
			loadedAll: data.length < limit,
			selectedDashboardId: "",
			selectedDashboardName: "",
			showConfirmDeleteModal: false,
			showCreateDashboardModal: false
		});
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	onManageDashboard = async (id = "create") => {
		this.props.history.push(`/dashboards/${id}`);
	};

	onConfirmDelete = async (id, name) => {
		this.update({
			showConfirmDeleteModal: true,
			selectedDashboardId: id,
			selectedDashboardName: name
		});
	};

	onConfirmDefault = async (id, name) => {
		let result = await DashboardService.update({ locationId: UserService.getActiveLocation().id, dashboardId: id, isDefault: true });
		let { t } = this.props;

		if (!result) {
			ToastService.error(t(`Failed to update the dashboard. Please try again.`));
			return;
		}

		ToastService.info(t(`Set new dashboard set as default.`));
		this.resetComponent();
	};

	onCloseDeleteModal = async confirm => {
		let { selectedDashboardId, selectedDashboardName } = this.state;
		let { t } = this.props;

		if (confirm) {
			// Delete
			let result = await DashboardService.update({ locationId: UserService.getActiveLocation().id, dashboardId: selectedDashboardId, status: STATUS.deleted });

			if (!result) {
				ToastService.error(t(`Failed to delete dashboard. Please try again.`));
			} else {
				ToastService.info(t(`Dashboard deleted.`));
			}
		}

		await this.update({ showConfirmDeleteModal: false });

		this.update({ selectedDashboardId: "", selectedDashboardName: "" });

		this.resetComponent();
	};

	onShowCreateDashboardModal = () => {
		this.update({ showCreateDashboardModal: true });
	};

	onHideCreateDashboardModal = () => {
		this.update({ showCreateDashboardModal: false });
	};

	onCreateDashboard = async (cardIds, name) => {
		let { t } = this.props;
		let user = UserService.get();

		let layout = [];
		for (let i = 0; i < cardIds.length; i++) {
			const cardId = cardIds[i];

			let card = DASHBOARD_CARDS[cardId];

			layout.push({
				i: card.id,
				x: 0,
				y: Infinity, // puts it at the bottom
				w: card.w,
				h: card.h
			});
		}

		let createDashboard = {
			locationId: UserService.getActiveLocation().id,
			authorUserId: user.id,
			isDefault: false,
			name: name,
			layout: JSON.stringify(layout)
		};

		// Create dashboard here
		let result = await DashboardService.create(createDashboard);

		if (!result) {
			ToastService.error(t(`Error creating dashboard.`));
			return;
		}

		ToastService.info(t(`Created dashboard.`));

		this.onHideCreateDashboardModal();
		this.resetComponent();
	};

	getHeaders() {
		let headers = {
			items: DASHBOARD_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let { t } = this.props;

		if (!recordData) {
			return null;
		}
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_reports;
		let allowDelete = user.GroupPermission.delete_reports;

		return [
			recordData.name,
			UserService.createFullName({ firstName: recordData.User.first_name, lastName: recordData.User.last_name }),
			recordData.is_default ? <Icon.Check /> : <Icon.X />,
			moment(recordData.created_at).format("ddd, MMM DD h:mm A"),
			<div className="dh-list__actions">
				<Action id={`update-${recordData.id}`} label={t("Update Dashboard")} icon={Icon.Edit} onClick={() => this.onManageDashboard(recordData.id)} />
				{allowUpdate && !recordData.is_default && (
					<>
						<Action
							id={`default-${recordData.id}`}
							label={t("Set as Default")}
							icon={Icon.CheckCircle}
							onClick={() => this.onConfirmDefault(recordData.id, recordData.name)}
						/>
					</>
				)}

				{allowDelete && !recordData.is_default && (
					<Action
						id={`delete-${recordData.id}`}
						label={t("Delete Rule")}
						icon={Icon.Trash2}
						onClick={() => this.onConfirmDelete(recordData.id, recordData.name)}
					/>
				)}
			</div>
		];
	};

	render = () => {
		let { data, loading, loadedAll, sortField, sortOrder, showConfirmDeleteModal, selectedDashboardName, showCreateDashboardModal } = this.state;
		let { t } = this.props;
		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_reports;

		return (
			<Page>
				<Header title="Dashboards">
					{allowCreate && <Action id="create" label={t("Create Rule")} icon={Icon.Plus} onClick={() => this.onShowCreateDashboardModal()} />}
				</Header>

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No dashboard rules found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<AddDashboardCardModal show={showCreateDashboardModal} onHide={this.onHideCreateDashboardModal} onAddCard={this.onCreateDashboard} />

				<Alert
					type="warning"
					show={showConfirmDeleteModal}
					title={t("Delete Dashboard")}
					confirm={t("Delete")}
					cancel={t("Cancel")}
					onClose={this.onCloseDeleteModal}
				>
					<>{t("Are you sure you would like to delete the {{dashboardName}} dashboard?", { dashboardName: selectedDashboardName })}</>
				</Alert>
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Dashboards)));
