import Kichiri from "./KichiriService";
import UserService from "./UserService";

export default {
	/**
	 * Check if the booking requests are permissible
	 */
	isPermissible() {
		try {
			const user = UserService.get();
			let location = UserService.getActiveLocation();

			return location.LocationFeature && location.LocationFeature.appointments && user.GroupPermission.view_appointments;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Fetch Synced Appointments
	 * @param {Object} params Query params
	 * @returns {Promise} A list of appointments
	 */
	async fetchAppointments(params) {
		let user = UserService.get();

		let query = {
			locationId: 0,
			contactId: null,
			crmType: null,
			searchTerm: "",
			sortField: "",
			sortOrder: "",
			limit: 50,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			let response = await Kichiri.synced_appointment.fetchAppointments({}, query, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Get an appointment
	 * @param {Number} appointmentId
	 * @returns {Promise} An appointment
	 */
	async getAppointment(appointmentId) {
		try {
			let user = UserService.get();

			let response = await Kichiri.synced_appointment.getAppointment({ appointmentId: appointmentId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch Appointment Notifications
	 * @param {Number} locationId
	 * @param {Object} params Query params
	 */
	async fetchAppointmentNotifications(locationId, params) {
		let user = UserService.get();

		let query = {
			sortField: "",
			sortOrder: "",
			limit: 30,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			let response = await Kichiri.appointments.getAppointmentNotifications({ locationId }, query, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetch Appointment Notifications
	 * @param {Number} id
	 * @param {String} status
	 */
	async updateAppointmentNotification({ id, status }) {
		try {
			let locationId = UserService.getActiveLocation().id;
			let response = await Kichiri.appointments.updateAppointmentNotification({ locationId, id, status }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Updates an appointment entry in the synced_appointment table
	 *
	 * @param {Integer} appointmentId
	 * @param {Boolean} doNotRemind
	 * @param {Boolean} dhConfirmed
	 *
	 * @returns {Object}
	 */
	async updateAppointment({ appointmentId, doNotRemind, dhConfirmed }) {
		try {
			let response = await Kichiri.synced_appointment.updateAppointment({ appointmentId, doNotRemind, dhConfirmed }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};
