import React, { Component } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import ToastService from "../../../../services/ToastService";
import LocationService from "../../../../services/LocationService";
import UserService from "../../../../services/UserService";
import UtilityService from "../../../../services/UtilityService";

import EditBookingSlotModal from "./EditBookingSlotModal";

import { SCHEDULE, BOOKING_BUCKETS } from "../../../../constants/LocationConstants";
import { DAY_OF_WEEK_LIST, DAYS_OF_WEEK_SHORT, MERIDIAN_TIMES_OPTIONS } from "../../../../constants/CommonConstants";

import "../../../../styles/css/scenes/booking-widget-booking-slots.css";

class BookingSlots extends Component {
	constructor(props) {
		super(props);
		this.state = {
			schedules: [],
			specialSchedules: [],
			bookingBuckets: [],

			selectedSchedule: null,
			showEditScheduleModal: false,
			loading: false,

			changesMade: false
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async resetComponent() {
		try {
			await this.fetchSchedules();
			await this.update({ bookingBuckets: Object.keys(BOOKING_BUCKETS).map(key => BOOKING_BUCKETS[key].value) });
		} catch (error) {
			console.log(error);
		}
	}

	fetchSchedules = async () => {
		try {
			let { t } = this.props;

			await this.update({ loading: true });

			let locationId = UserService.getActiveLocation().id;

			let schedules = await LocationService.getSchedulesForLocation(locationId, SCHEDULE.types.bookingRequestSlot);
			if (!schedules) {
				ToastService.error(t(`Unable to fetch appointment settings. Please try again.`));
				return;
			}

			let specialSchedules = await LocationService.getSchedulesForLocation(locationId, SCHEDULE.types.specialBusinessHours);
			if (!specialSchedules) {
				ToastService.error(t(`Unable to fetch special schedules. Please try again.`));
				return;
			}

			// TODO: DH-2660  We shall create a list of buckets based on friendly names, not based on the morning/afternoon/evening
			// let bookingBuckets = [];
			// for (let i = 0; i < schedules.length; i++) {
			// 	const schedule = schedules[i];
			// 	var foundIndex = bookingBuckets.findIndex(i => i === schedule.friendly_name);
			// 	if (foundIndex <= -1) {
			// 		bookingBuckets.push(schedule.friendly_name);
			// 	}
			// }

			await this.update({ schedules, specialSchedules });
		} catch (error) {
			console.log(error);
		}

		await this.update({ loading: false });
	};

	padStart(value) {
		return String(value).padStart(2, "0");
	}

	handleOnSubmit = async () => {
		await this.resetComponent();
	};

	onSelectBookingSlot = async schedule => {
		await this.update({
			showEditScheduleModal: true,
			selectedSchedule: schedule
		});
	};

	renderHolidayNotes() {
		let { specialSchedules } = this.state;
		let { t } = this.props;

		if (!specialSchedules || specialSchedules.length === 0) {
			return null;
		}

		// Filter it so we don't see past dates
		let filteredSpecialSchedule = specialSchedules.filter(sc => !moment(sc.start_date).isBefore());

		if (!filteredSpecialSchedule || filteredSpecialSchedule.length === 0) {
			return null;
		}

		return (
			<div className="bwbs__note-container">
				<div className="bwbs__note__title">
					<Icon.Info size="14" />
					{t(" Special Hours Affecting Booking Slots")}
				</div>
				{filteredSpecialSchedule.map((sc, index) => {
					let friendlyName = sc.friendly_name;
					let isClose = sc.closed === 1;
					let startDate = moment(sc.start_date).format("dddd, MMMM Do, GGGG");

					return (
						<div key={index}>
							{startDate} - {friendlyName} <span className="bwbs__note__day-status">{isClose ? t("(Closed)") : t("(Special Hours)")}</span>
						</div>
					);
				})}
			</div>
		);
	}

	renderBookingSlot(bookingBucket, day) {
		let { schedules } = this.state;
		let { t } = this.props;

		let schedule = schedules.find(s => s.day === day && s.friendly_name === bookingBucket);

		if (!schedule) {
			return null;
		}

		if (schedule.closed) {
			return (
				<div className="bwbs__bucket__day__btn bwbs__bucket__day__btn--closed" onClick={() => this.onSelectBookingSlot(schedule)}>
					{t("Closed")}
				</div>
			);
		}

		let open = schedule.timingsArray[0].open;
		let close = schedule.timingsArray[0].close;

		// Convert times to 12 hour time, with meridian
		let { hour: openTimeHour, minute: openTimeMinute, meridian: openTimeMeridian } = UtilityService.getTimeData(open);
		let { hour: closeTimeHour, minute: closeTimeMinute, meridian: closeTimeMeridian } = UtilityService.getTimeData(close);

		return (
			<div className="bwbs__bucket__day__btn" onClick={() => -this.onSelectBookingSlot(schedule)}>
				<div>
					{openTimeHour}:{this.padStart(openTimeMinute)} {MERIDIAN_TIMES_OPTIONS[openTimeMeridian].label}
				</div>
				<div>{t("to")}</div>
				<div>
					{closeTimeHour}:{this.padStart(closeTimeMinute)} {MERIDIAN_TIMES_OPTIONS[closeTimeMeridian].label}
				</div>
			</div>
		);
	}

	renderLoading() {
		return (
			<ContentLoader height={268} width={720}>
				<rect x="160" y="0" rx="5" ry="5" width="560" height="45" />
				<rect x="30" y="60" rx="5" ry="5" width="690" height="60" />
				<rect x="30" y="135" rx="5" ry="5" width="690" height="60" />
				<rect x="30" y="205" rx="5" ry="5" width="690" height="60" />
			</ContentLoader>
		);
	}

	renderDaysOfTheWeek() {
		return (
			<div className="bwbs__days">
				<div className="bwbs__days__day">&nbsp;</div>
				{DAY_OF_WEEK_LIST &&
					DAY_OF_WEEK_LIST.map(day => (
						<div key={day} className="bwbs__days__day">
							<div className="bwbs__bucket__day__text">{DAYS_OF_WEEK_SHORT[day.toLowerCase()]}</div>
						</div>
					))}
			</div>
		);
	}

	renderBookingSlots() {
		let { bookingBuckets } = this.state;

		return (
			bookingBuckets &&
			bookingBuckets.map((bb, key) => (
				<div key={key} className="bwbs__bucket">
					<div className="bwbs__bucket__name">{bb}</div>
					{DAY_OF_WEEK_LIST &&
						DAY_OF_WEEK_LIST.map(day => (
							<div key={`key-${day}-${bb}`} className="bwbs__bucket__day">
								{this.renderBookingSlot(bb, day)}
							</div>
						))}
				</div>
			))
		);
	}

	render() {
		let { showEditScheduleModal, selectedSchedule, loading } = this.state;

		if (loading) {
			return this.renderLoading();
		}

		let showEditBookingSlotModal = showEditScheduleModal && selectedSchedule;

		return (
			<>
				<div className="bwbs">
					{this.renderDaysOfTheWeek()}
					{this.renderBookingSlots()}
					{this.renderHolidayNotes()}
				</div>
				<EditBookingSlotModal
					show={showEditBookingSlotModal}
					schedule={selectedSchedule ? selectedSchedule : null}
					onHide={async () => {
						await this.update({ showEditScheduleModal: false });
						await this.update({ selectedSchedule: null });
					}}
					onSubmit={this.handleOnSubmit}
				/>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(BookingSlots);
