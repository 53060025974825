import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n/config";

import MessagesService from "../../services/MessagesService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import SupportChatService from "../../services/SupportChatService";
import LocationService from "../../services/LocationService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Filters from "../../components/common/Filters";
import SearchInput from "../../components/common/SearchInput";
import withLocation from "../../components/common/WithLocation";
import Voicemail from "./Voicemail";
import LandingPage from "../LandingPage/LandingPage";

import { STATUS } from "../../constants/CommonConstants";

import "./voicemail.css";

const VOICEMAIL_STATUS_FILTERS = {
	active: {
		id: "active",
		display: i18n.t("Open"),
		value: "active",
		label: "active",
		order: 1
	},
	inactive: {
		id: "inactive",
		display: i18n.t("Close"),
		value: "inactive",
		label: "inactive",
		order: 2
	}
};

class VoicemailInbox extends React.Component {
	constructor(props) {
		super(props);

		let { searchTerm, status } = queryString.parse(this.props.location.search);

		this.state = {
			data: [],

			loading: true,
			loadedAll: false,
			limit: 25,
			offset: 0,

			searchTerm: searchTerm || "",
			status: status ? status : STATUS.active,

			screenWidth: window.innerWidth
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions);
		this.resetComponent();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.update({ screenWidth: window.innerWidth });
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { searchTerm, status } = queryString.parse(this.props.location.search);

		await this.update({
			data: [],

			loading: true,
			loadedAll: false,
			limit: 25,
			offset: 0,

			searchTerm: searchTerm || "",
			status: status ? status : STATUS.active
		});
		this.fetchData();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let { searchTerm, status } = this.state;

		let params = {};

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (status) {
			params.status = status;
		}

		params = new URLSearchParams(params);
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	fetchData = async () => {
		let { data, status, searchTerm, limit, offset } = this.state;
		let { t } = this.props;

		await this.update({ loading: false });

		let voicemail = await MessagesService.fetchVoicemail({ locationId: UserService.getActiveLocation().id, status: [status], searchTerm, limit, offset });

		if (!voicemail) {
			ToastService.error(t("Error fetching Voicemail. Please try again."));
			await this.update({ loading: false });
			return;
		}

		let loadedAll = voicemail.length < limit;

		if (offset > 0) {
			data.push(...voicemail);
		} else {
			data = voicemail;
		}

		await this.update({ data, loading: false, loadedAll });
	};

	onGetStarted = () => {
		let { t } = this.props;
		SupportChatService.showNewMessage(t("Hi, I would like to set up Voicemail features. Would you be able to help?"));
	};

	onSearch = async value => {
		const searchTerm = value;
		await this.update({ searchTerm, offset: 0 });
		this.setUrlParams();
		await this.fetchData();
	};

	onStatusFilterSelected = async item => {
		await this.update({ status: item.id, offset: 0 });
		this.setUrlParams();
		await this.fetchData();
	};

	isStatusSelected = item => {
		return item === this.state.status;
	};

	getFilters = () => {
		let items = Object.keys(VOICEMAIL_STATUS_FILTERS)
			.map(item => {
				return { id: VOICEMAIL_STATUS_FILTERS[item].id, value: VOICEMAIL_STATUS_FILTERS[item].display, order: VOICEMAIL_STATUS_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		return {
			statuses: {
				title: "",
				items: items,
				onClick: this.onStatusFilterSelected,
				isSelected: this.isStatusSelected
			}
		};
	};

	onLoadMore = async () => {
		let { offset, limit } = this.state;

		await this.update({
			offset: offset + limit
		});

		await this.fetchData();
	};

	onVoicemailUpdated = async (voicemail, index) => {
		let { data } = this.state;

		// For status changes (the only updating we allow right now)
		let newData = [...data];
		newData.splice(index, 1);

		await this.update({ data: newData });
	};

	render = () => {
		let { data, loadedAll, screenWidth, status, searchTerm } = this.state;
		let { t } = this.props;

		let isVoicemailEnabled = LocationService.isVoicemailEnabled();

		if (!isVoicemailEnabled) {
			return (
				<Page>
					<LandingPage
						title={t("Voicemail")}
						text={t("Receive voicemail from customers and new leads.")}
						buttonText={t("Get Started")}
						onGetStartedClicked={this.onGetStarted}
						beta={false}
						newFeature={true}
					>
						<div className="landing-page__more">
							<div className="landing-page__block">
								<div className="landing-page__block__item">
									<img
										className="landing-page__block__item__img"
										alt={t("Accept Incoming Booking Requests")}
										src="https://cdn.demandhub.co/img/misc/Voicemail-call.png"
									/>
								</div>
								<div className="landing-page__block__item">
									<div className="landing-page__block__item__text">
										{t("Keep track of voicemail messages that come in and allow for customers to share their questions and feedback.")}
									</div>
								</div>
							</div>
							<div className="landing-page__block">
								<div className="landing-page__block__item">
									<img
										className="landing-page__block__item__img"
										alt={t("Communicate with customers")}
										src="https://cdn.demandhub.co/img/misc/Voicemail-transcription.png"
									/>
								</div>
								<div className="landing-page__block__item">
									<div className="landing-page__block__item__text">{t("Voicemail transcription allows voicemails to be written to text messages via AI.")}</div>
								</div>
								<div className="landing-page__block__item__text" />
							</div>

							<div className="landing-page__block">
								<div className="landing-page__block__item">
									<img className="landing-page__block__item__img" alt={t("Stay Organized")} src="https://cdn.demandhub.co/img/misc/Voicemail-revenue.png" />
								</div>
								<div className="landing-page__block__item">
									<div className="landing-page__block__item__text">{t("Improve customer communication and satisfaction.")}</div>
								</div>
							</div>
						</div>
					</LandingPage>
				</Page>
			);
		}

		return (
			<Page>
				<Header title={t("Voicemail")} />

				<div className="voicemail-inbox-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearch} initValue={searchTerm} leading={false} />
				</div>

				<Filters filters={this.getFilters()} />

				{data && data.length > 0 && (
					<div className="voicemail-inbox">
						{data.map((voicemail, index) => (
							<Voicemail
								key={voicemail.id}
								index={index}
								voicemail={voicemail}
								onUpdated={item => this.onVoicemailUpdated(item, index)}
								screenWidth={screenWidth}
							/>
						))}
					</div>
				)}

				{data && data.length < 1 && (
					<>
						<div className="voicemail-inbox__none">
							<img alt={t("Empty voicemail inbox")} className="voicemail-inbox__none__img" src="https://cdn.demandhub.co/web-app/assets/voicemail_inbox.png" />
							<div className="voicemail-inbox__none__text">
								{status === STATUS.active ? t("You don't have any voicemails to action. Good work!") : t("No voicemails found.")}
							</div>
						</div>
					</>
				)}

				{!loadedAll && data && data.length > 0 && (
					<div className="voicemail-inbox-load-more">
						<div className="mb-button" onClick={this.onLoadMore}>
							{t("Load More")}
						</div>
					</div>
				)}
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(VoicemailInbox)));
