import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import EditBrandModal from "./EditBrandModal";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import A2PService from "../../../services/A2PService";

import { A2P_BRAND_COLUMNS } from "../../../constants/A2P";

class A2PBrands extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			brands: [],
			loading: false,

			selectedBrandId: null,
			showCreateModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			brands: [],
			loading: false,

			selectedBrandId: null,
			showCreateModal: false
		});

		await this.fetchBrands();
	};

	fetchBrands = async () => {
		let companyId = UserService.getActiveCompany().id;

		await this.update({ loading: true });

		let brands = await A2PService.fetchBrands({ companyId });

		await this.update({ brands, loading: false });
	};

	getHeaders() {
		let headers = {
			items: A2P_BRAND_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		if (!recordData) {
			return null;
		}

		return [
			recordData.id,
			recordData.company_id,
			recordData.display_name,
			recordData.vertical,
			moment(recordData.created_at).format("MMM Do YYYY hh:mm a"),
			<div className="dh-list__actions">
				<Action id={`edit-${recordData.id}`} label="Edit" icon={Icon.Edit} onClick={() => this.onShowCreateModal(recordData.id)} />
			</div>
		];
	};

	onShowCreateModal = async dhBrandId => {
		await this.update({
			selectedBrandId: dhBrandId,
			showCreateModal: true
		});
	};

	onHideCreateModal = async () => {
		await this.update({
			selectedBrandId: null,
			showCreateModal: false
		});
	};

	onSave = async () => {
		await this.update({
			selectedCampaignId: null,
			showCreateModal: false
		});
		await this.fetchBrands();
	};

	render() {
		let { brands, loading, showCreateModal, selectedBrandId } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header>
					<Action id="create" label={t("Create Brand")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
				</Header>

				<List
					items={brands}
					loading={loading}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No brands found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				{!loading && <EditBrandModal show={showCreateModal} brandId={selectedBrandId} onHide={this.onHideCreateModal} onSave={this.onSave} />}
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(A2PBrands)));
