import React, { Component } from "react";
import UserService from "../../services/UserService";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import FaqModifier from "./FaqModifier";
import Alert from "../../components/common/Alert";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import DHModal from "../../components/common/DHModal";

import ChatbotService from "../../services/ChatbotService";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { CHATBOT_FAQ_COLUMNS } from "../../constants/Chatbots";

import "../../App.css";
import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/scenes/chatbot-faqs.css";

import withLocation from "../../components/common/WithLocation";
class ChatbotFaqs extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			loading: false,
			loadedAll: false,
			data: [],
			limit: 25,
			pageSize: 50,
			sortField: CHATBOT_FAQ_COLUMNS.question.id,
			sortOrder: SORT_ORDER.asc,

			modalTitle: t("Edit FAQ Entry"),
			toDeleteFaqId: 0,
			toEditFaq: {},
			showEditCreateModal: false,
			deleteSuccess: false,
			deleteError: false,
			newFaqId: -1
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = async location => {
		await this.fetchData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchData() {
		this.setState({ loading: true });
		let { sortField, sortOrder, limit } = this.state;

		try {
			let data = await ChatbotService.fetchFaqs({ sortField, sortOrder, limit });
			this.setState({ data: data, loading: false, loadedAll: data.length < limit });
		} catch (err) {
			console.error(err);
		}
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = CHATBOT_FAQ_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			return [recordData.question, recordData.answer, this.renderActions(recordData)];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	renderActions(record) {
		let { t } = this.props;
		let user = UserService.get();

		return (
			<div className="ChatbotFaqs__list__actions">
				{user.GroupPermission.update_chatbot_faqs && (
					<Action id={`faq-update-${record.id}`} label={t("Update")} icon={Icon.Edit} onClick={() => this.handleOnUpdateClick(record)} />
				)}
				{user.GroupPermission.update_chatbot_faqs && (
					<Action id={`faq-delete-${record.id}`} label={t("Delete")} icon={Icon.Trash2} onClick={() => this.confirmDelete(record)} />
				)}
			</div>
		);
	}

	confirmDelete = faq => {
		this.setState({
			toDeleteFaqId: faq.id,
			deleteAlertOpen: true
		});
	};

	handleOnDeleteClick = async () => {
		const { toDeleteFaqId } = this.state;
		try {
			await ChatbotService.deleteFaq(toDeleteFaqId);
			this.setState({ deleteSuccess: true }, () => {
				this.fetchData();
			});
		} catch (err) {
			console.error(err);
			this.setState({ deleteError: true });
		}
	};

	handleEditModalOnHide() {
		this.setState({ toEditFaq: {}, showEditCreateModal: false });
	}

	handleOnUpdateClick(faq) {
		this.setState({ toEditFaq: faq }, () => this.setState({ showEditCreateModal: true }));
	}

	createNewFaq = () => {
		let { t } = this.props;

		let newFaq = { id: this.state.newFaqId, question: "", answer: "" };
		this.setState({
			toEditFaq: newFaq,
			showEditCreateModal: true,
			modalTitle: t("Create FAQ Entry")
		});
	};

	getAlternativeQuestionsIcon() {
		let { t } = this.props;

		return (
			<div className="ChatbotFaqs__info-text">
				<i data-tip data-for="tooltip" className="fa fa-info-circle" aria-hidden="true" />
				<ReactTooltip id="tooltip" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
					{t("This question has alternative phrasings configured!")}
				</ReactTooltip>
			</div>
		);
	}

	render() {
		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let locationId = location.id;

		const {
			data,
			toEditFaq,
			showEditCreateModal,
			modalTitle,
			deleteAlertOpen,
			deleteError,
			deleteSuccess,
			loading,
			loadedAll,
			sortField,
			sortOrder
		} = this.state;
		let { t } = this.props;

		return (
			<>
				<div className="ChatbotFaq__header">
					<h3>{t("Here is a list of your configured FAQ's that your Chatbot can look up to provide your customers with autonomous responses!")}</h3>
					{user.GroupPermission.create_chatbot_faqs && (
						<div className="ChatbotFaq__add-button mb-button" onClick={this.createNewFaq}>
							{t("New FAQ Entry")}
							<Icon.Plus size="15" />
						</div>
					)}
				</div>

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no chatbots FAQs yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<Alert
					type="warning"
					show={deleteAlertOpen}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirmed => {
						if (confirmed) {
							this.handleOnDeleteClick();
						}
						this.setState({ deleteAlertOpen: false });
					}}
				>
					<div>{t("Click 'Yes' to delete this FAQ entry or 'No' to go back.")}</div>
				</Alert>

				<Alert
					type="error"
					show={deleteError}
					title={t("Failed")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ deleteError: false });
					}}
				>
					<div>{t("Unexpected error occured while deleting.")}</div>
				</Alert>

				<Alert
					type="success"
					show={deleteSuccess}
					title={t("Deleted")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ deleteSuccess: false });
					}}
				>
					<div>{t("The FAQ entry was deleted successfully.")}</div>
				</Alert>

				<DHModal show={showEditCreateModal} onHide={() => this.handleEditModalOnHide()} title={modalTitle}>
					<FaqModifier
						toEditFaq={toEditFaq}
						handleEditCreateModalOnHide={() => this.handleEditModalOnHide()}
						fetchData={() => this.fetchData()}
						locationId={locationId}
					/>
				</DHModal>
			</>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(ChatbotFaqs));
