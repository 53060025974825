import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import SideModal from "../../components/common/SideModal";
import ContactSelectorFilters from "./ContactSelectorFilters";

class ContactFiltersSideModal extends Component {
	render() {
		const { show, onHide, refreshContacts, tagsChange } = this.props;
		let { t } = this.props;

		return (
			<SideModal show={show} title={t("Filter Contacts")} titleIcon={Icon.Filter} onHide={onHide} closeOnClick={false}>
				<ContactSelectorFilters refreshContacts={refreshContacts} tagsChange={tagsChange} />
			</SideModal>
		);
	}
}

export default withTranslation(null, { withRef: true })(ContactFiltersSideModal);
