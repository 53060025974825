import i18n from "../i18n/config";

export const BOOKING_REQUESTS_FILTERS = {
	awaiting: {
		id: "awaiting",
		display: i18n.t("Awaiting"),
		order: 1
	},
	confirmed: {
		id: "confirmed",
		display: i18n.t("Confirmed"),
		order: 2
	},
	completed: {
		id: "completed",
		display: i18n.t("Completed"),
		order: 3
	},
	cancelled: {
		id: "cancelled",
		display: i18n.t("Cancelled"),
		order: 4
	},
	deleted: {
		id: "deleted",
		display: i18n.t("Deleted"),
		order: 5
	}
};

export const BOOKING_REQUESTS_COLUMNS = {
	date: {
		id: "date",
		value: i18n.t("Date"),
		sortable: true,
		sortField: "date",
		width: 1
	},
	time: {
		id: "time",
		value: i18n.t("Time"),
		sortable: false,
		sortField: "time",
		width: 1,
		widthMd: 0
	},
	reason: {
		id: "reason",
		value: i18n.t("Reason"),
		sortable: true,
		sortField: "reason",
		width: 1,
		widthMd: 0
	},
	note: {
		id: "note",
		value: i18n.t("Note"),
		sortable: true,
		sortField: "note",
		width: 1
	},
	requestedBy: {
		id: "requestedBy",
		value: i18n.t("Requested By"),
		sortable: false,
		sortField: "requested_by",
		width: 1
	},
	phone: {
		id: "phone",
		value: i18n.t("Phone"),
		sortable: false,
		sortField: "phone",
		width: 1
	},
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: false,
		sortField: "email",
		width: 1
	},
	offeredBy: {
		id: "offeredBy",
		value: i18n.t("Offered By"),
		sortable: false,
		sortField: "offered_by",
		width: 1,
		widthMd: 0
	},
	name: {
		id: "name",
		value: i18n.t("Service"),
		sortable: false,
		sortField: "name",
		width: 1,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const BOOKING_REQUESTS_STATUS = {
	awaiting: "awaiting",
	confirmed: "confirmed",
	completed: "completed",
	cancelled: "cancelled",
	deleted: "deleted"
};

export const EDIT_BOOKING_REQUESTS_TABS = {
	details: "details",
	actions: "actions"
};
