import React from "react";
import ContentLoader from "react-content-loader";
import Select from "react-select";
import moment from "moment";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import ToastService from "../../../../services/ToastService";
import UserService from "../../../../services/UserService";
import MessagesService from "../../../../services/MessagesService";
import NotificationService from "../../../../services/NotificationService";
import Alert from "../../../../components/common/Alert";

import CustomerChatScheduledMessagesListItem from "./CustomerChatScheduledMessagesListItem";

import "./customer-chat-scheduled-message-list.css";

class CustomerChatScheduledMessagesList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			queuedMessages: [],
			selectedMessage: null,

			// Modal Flags
			showConfirmDeleteModal: false
		};
	}

	componentDidMount() {
		let user = UserService.get();

		NotificationService.subscribeOnce("newInternalMessage", "ccsml_componentDidMount", async message => {
			if (message.contact_id && message.sender_user_id === user.id && message.is_scheduled_message) {
				this.resetComponent();
			}
		});

		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async (showLoading = true) => {
		let { t } = this.props;

		let locationId = UserService.getActiveLocation().id;

		if (showLoading) {
			await this.update({ loading: true });
		}

		let queuedMessages = await MessagesService.fetchCustomerQueuedMessages({ locationId });

		if (!queuedMessages) {
			ToastService.error(t("Failed to fetch scheduled messages"));
			await this.update({
				loading: false
			});
			return;
		}

		await this.update({
			loading: false,
			queuedMessages,
			showConfirmDeleteModal: false
		});
	};

	onCloseConfirmDelete = async confirm => {
		let { selectedMessage } = this.state;
		let { t } = this.props;

		if (!confirm) {
			await this.update({ showConfirmDeleteModal: false, selectedMessage: null });
			return;
		}

		let response = await MessagesService.updateMessage({ messageId: selectedMessage.id, status: "deleted" });
		if (!response) {
			ToastService.error(t("Failed to delete. Please try again."));
		} else {
			ToastService.info(t("Scheduled Message Deleted."));
		}

		NotificationService.publish("customerScheduledMessageChange");

		this.resetComponent(false);
	};

	onDeleteMessage = queuedMessage => {
		this.update({ showConfirmDeleteModal: true, selectedMessage: queuedMessage, editing: false });
	};

	renderBackAction = () => {
		let { onClose } = this.props;

		return (
			<div className="mb-ccsml__back__message" onClick={() => onClose()}>
				<Icon.ArrowLeftCircle size={24} />
			</div>
		);
	};

	render = () => {
		let { queuedMessages, loading, showConfirmDeleteModal } = this.state;
		let { t } = this.props;

		if (loading) {
			return (
				<div className="mb-ccsml">
					<div className="mb-ccsml__back">
						{this.renderBackAction()}
						{t("Loading Scheduled Messages...")}
					</div>
					<ContentLoader height={600} width={"100%"}>
						<rect x="20" y="0" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="155" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="310" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="465" rx="5" ry="5" width="98%" height="135" />
					</ContentLoader>
				</div>
			);
		}

		if (!queuedMessages || queuedMessages.length === 0) {
			return (
				<div className="mb-ccsml">
					<div className="mb-ccsml__back">
						{this.renderBackAction()}
						{t("No Scheduled Messages found.")}
					</div>
				</div>
			);
		}

		return (
			<div className="mb-ccsml">
				<div className="mb-ccsml__back">
					{this.renderBackAction()}
					{t("All Scheduled Messages.")}
				</div>
				{queuedMessages.map(queuedMessage => {
					// Don't show any scheduled message that are supposed to be sent already, let's see if this caused some funnies
					if (moment().isAfter(moment(queuedMessage.send_after))) {
						return null;
					}
					return (
						<CustomerChatScheduledMessagesListItem
							key={`${queuedMessage.id}-${queuedMessage.updated_at}`}
							queuedMessage={queuedMessage}
							onDeleteMessage={() => this.onDeleteMessage(queuedMessage)}
						/>
					);
				})}
				<Alert type="info" show={showConfirmDeleteModal} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onCloseConfirmDelete}>
					{t("Are you sure you would like to delete this scheduled message?")}
				</Alert>
			</div>
		);
	};
}

export default withTranslation(null, { withRef: true })(CustomerChatScheduledMessagesList);
