import React, { Component } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { Collapse } from "react-bootstrap";
import c3 from "c3";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import NPSService from "../../services/NPSService";

import Spinners from "../../components/common/Spinners";
import Dropdown from "../../components/common/Dropdown";
import withLocation from "../../components/common/WithLocation";
import SendNpsRequest from "../../scenes/NetPromoterScore/SendNpsRequest";

import { DATE_FORMAT } from "../../constants/CommonConstants";

import "../../styles/css/scenes/net-promoter-score.css";
import "../../styles/css/scenes/reviews.css";

class Suggestions extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			authToken: UserService.get() ? UserService.get().auth_token : "",
			locationId: undefined,
			suggestions: [],
			loading: true,
			fetchError: false,
			suggestionCount: 0,
			suggestionPage: 0,
			limit: 25, //suggestions per page
			offset: 0,
			scoreRange: [
				{ id: 0, name: "0" },
				{ id: 1, name: "1" },
				{ id: 2, name: "2" },
				{ id: 3, name: "3" },
				{ id: 4, name: "4" },
				{ id: 5, name: "5" },
				{ id: 6, name: "6" },
				{ id: 7, name: "7" },
				{ id: 8, name: "8" },
				{ id: 9, name: "9" },
				{ id: 10, name: "10" }
			],
			minScore: 0,
			currentMinScore: 0,
			maxScore: 10,
			currentMaxScore: 10,
			dateRange: 0,
			dateRangeString: "all_time",
			dateArray: [
				{ id: 0, name: t("All Time"), range: "all_time" },
				{ id: 1, name: t("This Week"), range: "this_week" },
				{ id: 2, name: t("This Month"), range: "this_month" },
				{ id: 3, name: t("Last Month"), range: "last_month" }
			],
			currentDateRange: 0,
			npsTotalRespondents: 0,
			npsScore: 0,
			promotersRatio: 0,
			passivesRatio: 0,
			detractorsRatio: 0,
			staticSuggestionFilterTopCss: 152,
			staticSuggestionFilterScrollThreshold: 130,
			staticSuggestionFilterScrollTop: "10px",
			hasSuggestionFilter: false,
			collapseFilterOpen: true,
			collapseFilterIcon: "glyphicon glyphicon-chevron-up",
			showNpsModal: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async refreshNPS(limit, offset, minScore, maxScore, dateRange, hasSuggestion) {
		let user = UserService.get();
		let { t } = this.props;

		if (!user) {
			return;
		}

		try {
			let location = UserService.getActiveLocation();
			let locationId = location.id;
			let authToken = user.auth_token;

			if (!locationId) {
				await this.update({ fetchError: true, loading: false });
				return;
			}

			let suggestionCount = await NPSService.count({ locationId, minScore, maxScore, dateRange, hasSuggestion });

			await this.update({ suggestionCount });

			minScore = minScore ? minScore : this.state.minScore;
			maxScore = maxScore ? maxScore : this.state.maxScore;

			let suggestions = await NPSService.fetch({ locationId, limit, offset, minScore, maxScore, dateRange, hasSuggestion });

			if (!suggestions) {
				throw new Error(t("Error fetching suggestions."));
			}

			await this.update({
				suggestions: suggestions,
				loading: false,
				locationId: locationId,
				authToken: authToken
			});

			await this.generateChart(minScore, maxScore, hasSuggestion);
		} catch (error) {
			await this.update({
				fetchError: true,
				loading: false
			});
		}
	}

	componentDidMount() {
		this.resetSuggestions();
		this.generateChart();
		window.addEventListener("scroll", () => this.handleScroll());
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", () => this.handleScroll());
	}

	handleScroll() {
		if (!this.suggestionsFilterRef) {
			return;
		}
		if (window.pageYOffset < this.state.staticSuggestionFilterScrollThreshold) {
			this.suggestionsFilterRef.style.top = this.state.staticSuggestionFilterTopCss - window.pageYOffset + "px";
			return;
		}
		this.suggestionsFilterRef.style.top = this.state.staticSuggestionFilterScrollTop;
	}

	async resetSuggestions() {
		let limit = this.state.limit;
		let offset = this.state.suggestionPage * limit;
		let minScore = this.state.minScore;
		let maxScore = this.state.maxScore;
		let dateRange = this.state.dateRange;
		let hasSuggestion = false;
		let newSuggestionPage = 0;

		await this.update({
			suggestionPage: newSuggestionPage,
			currentMinScore: minScore,
			currentMaxScore: maxScore,
			currentDateRange: dateRange,
			dateRangeString: "all_time",
			hasSuggestionFilter: hasSuggestion,
			npsScore: 0,
			npsTotalRespondents: 0,
			promotersRatio: 0,
			passivesRatio: 0,
			detractorsRatio: 0
		});
		await this.refreshNPS(limit, offset, minScore, maxScore, dateRange, hasSuggestion);
	}

	onLocationChanged = location => {
		this.resetSuggestions();
		this.generateChart();
	};

	getPageNumbers = () => {
		let currentPage = this.state.suggestionPage + 1;
		let totalPages = this.state.suggestionCount > 0 ? Math.ceil(this.state.suggestionCount / this.state.limit) : 1;
		return currentPage + " / " + totalPages;
	};

	handlePrevPageClick = event => {
		event.preventDefault();
		event.stopPropagation();

		if (this.state.suggestionPage <= 0) {
			return;
		}

		let newPage = this.state.suggestionPage - 1;
		let limit = this.state.limit;
		let offset = newPage * limit;
		let minScore = this.state.currentMinScore;
		let maxScore = this.state.currentMaxScore;
		let dateRange = this.state.currentDateRange;
		let hasSuggestion = this.state.hasSuggestionFilter;

		this.update({ suggestionPage: newPage });
		this.refreshNPS(limit, offset, minScore, maxScore, dateRange, hasSuggestion);
	};

	handleNextPageClick = event => {
		event.preventDefault();
		event.stopPropagation();

		if ((this.state.suggestionPage + 1) * this.state.limit < this.state.suggestionCount) {
			let newPage = this.state.suggestionPage + 1;
			let limit = this.state.limit;
			let offset = newPage * limit;
			let minScore = this.state.currentMinScore;
			let maxScore = this.state.currentMaxScore;
			let dateRange = this.state.currentDateRange;
			let hasSuggestion = this.state.hasSuggestionFilter;

			this.update({ suggestionPage: newPage });
			this.refreshNPS(limit, offset, minScore, maxScore, dateRange, hasSuggestion);
		}
	};

	handleFiltersOnMinScoreRangeChange = e => {
		this.setState({ currentMinScore: parseInt(e.target.value, 10) }, () => {
			if (this.state.currentMinScore > this.state.currentMaxScore) {
				this.setState({ currentMaxScore: this.state.maxScore }, () => {
					this.handleFiltersOnUpdate();
				});
			} else {
				this.handleFiltersOnUpdate();
			}
		});
	};

	handleFiltersOnMaxScoreRangeChange = e => {
		this.setState({ currentMaxScore: parseInt(e.target.value, 10) }, () => {
			this.handleFiltersOnUpdate();
		});
	};

	handleFiltersOnRangeChange = e => {
		let rangeId = e.target.value;
		let dateRange = "this_month";
		for (let i = 0; i < this.state.dateArray.length; i++) {
			const element = this.state.dateArray[i];
			if (element.id === rangeId) {
				dateRange = element.range;
				break;
			}
		}
		this.setState({ currentDateRange: parseInt(rangeId, 10), dateRangeString: dateRange }, () => {
			this.handleFiltersOnUpdate();
		});
	};

	handleFiltersOnUpdate = async () => {
		try {
			this.update({ loading: true });
			this.refreshNPS(
				this.state.limit,
				this.state.offset,
				this.state.currentMinScore,
				this.state.currentMaxScore,
				this.state.currentDateRange,
				this.state.hasSuggestionFilter
			);
			this.update({ loading: false, suggestionPage: 0 });
		} catch (error) {
			this.update({ loading: false });
		}
	};

	handleOpenNpsModal = async () => {
		await this.update({ showNpsModal: true });
	};

	onHideNpsModal = async () => {
		await this.update({ showNpsModal: false });
	};

	toggleFilterCollapse() {
		let isOpen = this.state.collapseFilterOpen;
		let clss = "glyphicon glyphicon-chevron-down";
		if (!isOpen) {
			clss = "glyphicon glyphicon-chevron-up";
		}

		this.update({ collapseFilterOpen: !isOpen, collapseFilterIcon: clss });
	}

	renderFilterForm(position) {
		let { t } = this.props;

		return (
			<div className="form-horizontal">
				<div className="form-group">
					<h3 className="col-sm-12">{t("Score")}</h3>
					<div className="stars-flexbox-container">
						<div id="filter-stars-min" className="stars-flexbox-children">
							<span>
								<i>{t("Min Score")}</i>
							</span>
							<Dropdown
								cClass="form-control"
								cStyle={{ marginBottom: "3.5%" }}
								data={this.state.scoreRange}
								value={this.state.currentMinScore}
								onChange={e => this.handleFiltersOnMinScoreRangeChange(e)}
							/>
						</div>
						<div id="filter-stars-max" className="stars-flexbox-children ">
							<span>
								<i>{t("Max Score")}</i>
							</span>
							<Dropdown
								id="fnctsts-stars-max-dropdown"
								cClass="form-control"
								cStyle={{ marginBottom: "3.5%" }}
								data={this.state.scoreRange.filter(s => s.id >= this.state.currentMinScore)}
								value={this.state.currentMaxScore}
								onChange={e => this.handleFiltersOnMaxScoreRangeChange(e)}
							/>
						</div>
					</div>
				</div>
				<br />
				<div className="form-group">
					<h3 className="col-sm-12">{t("Date Range")}</h3>
					<div key="date-dropdown" id="filter-form-dates" className="col-sm-12">
						<Dropdown
							id="fnctsts-suggestion-date-dropdown"
							cClass="form-control"
							cStyle={{ marginBottom: "3.5%" }}
							data={this.state.dateArray}
							value={this.state.currentDateRange}
							onChange={e => this.handleFiltersOnRangeChange(e)}
						/>
					</div>
				</div>
				<div className="form-group">
					<h3 className="col-sm-12">{t("Has suggestion")}</h3>
					<div className="col-sm-12">
						<ReactSwitch
							id={`has-suggestions-switch-${position}`}
							onChange={() =>
								this.setState({ hasSuggestionFilter: !this.state.hasSuggestionFilter }, () => {
									this.handleFiltersOnUpdate();
								})
							}
							checked={this.state.hasSuggestionFilter}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#4A90E2"
							type="text"
						/>
					</div>
				</div>
				<div className="form-group">
					<div className="col-sm-12 text-center">
						<button id={`reset-${position}`} className="btn btn-danger btn-block fnctst-reset-controls" onClick={() => this.resetSuggestions()}>
							{t("Reset")}
						</button>
					</div>
				</div>
				<div className="form-group" style={{ margin: "0" }}>
					<div className="col-sm-12 text-center">
						<small className="">
							{t("Suggestions on page:")} {this.state.suggestions.length}
						</small>
						<br />
						<small className="">
							{t("Total suggestions:")} {this.state.suggestionCount}
						</small>
					</div>
				</div>
			</div>
		);
	}

	generateChart = async (minScore, maxScore, hasSuggestion) => {
		try {
			let { t } = this.props;

			const dateRanges = { start: "", end: "" };
			let dateRange = this.state.dateRangeString;

			if (dateRange === "this_week") {
				// Get the date at start and end of the current week (Sunday to Saturday)
				dateRanges.start = moment()
					.startOf("week")
					.format(DATE_FORMAT);
				dateRanges.end = moment()
					.endOf("week")
					.format(DATE_FORMAT);
			} else if (dateRange === "this_month") {
				// Get the date at start and end days of the current month
				dateRanges.start = moment()
					.startOf("month")
					.format(DATE_FORMAT);
				dateRanges.end = moment()
					.endOf("month")
					.format(DATE_FORMAT);
			} else if (dateRange === "last_month") {
				// Get the date at start and end days of the previous month
				dateRanges.start = moment()
					.subtract(1, "month")
					.startOf("month")
					.format(DATE_FORMAT);
				dateRanges.end = moment()
					.subtract(1, "month")
					.endOf("month")
					.format(DATE_FORMAT);
			} else {
				// all_time
				// Get the date range from 2017-01-01 to present
				dateRanges.start = moment("2017-01-01").format(DATE_FORMAT);
				dateRanges.end = moment()
					.endOf("month")
					.format(DATE_FORMAT);
			}

			let location = UserService.getActiveLocation();
			let response = await AnalyticsService.fetchNps(location.id, dateRanges.start, dateRanges.end, minScore, maxScore, hasSuggestion);
			let promotersRatio = response.totalRespondents !== 0 ? (response.promoters / response.totalRespondents) * 100 : 0;
			let passivesRatio = response.totalRespondents !== 0 ? (response.passives / response.totalRespondents) * 100 : 0;
			let detractorsRatio = response.totalRespondents !== 0 ? (response.detractors / response.totalRespondents) * 100 : 0;

			this.setState(
				{
					npsTotalRespondents: response.totalRespondents,
					npsScore: response.nps,
					promotersRatio: parseFloat(promotersRatio).toFixed(0),
					passivesRatio: parseFloat(passivesRatio).toFixed(0),
					detractorsRatio: parseFloat(detractorsRatio).toFixed(0)
				},
				() => {
					c3.generate({
						bindto: "#dh-npsChart-suggestions",
						data: {
							columns: [
								["promoters", promotersRatio],
								["passives", passivesRatio],
								["detractors", detractorsRatio]
							],
							type: "donut"
						},
						color: {
							pattern: ["#5AB85B", "#F1AD4E", "#D9534F"]
						},
						donut: {
							title: t("NPS"),
							label: {
								show: false
							}
						},
						legend: {
							show: false
						}
					});
					//}, 0);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	render() {
		let { t } = this.props;

		let location = UserService.getActiveLocation();

		if (!location) {
			// No locations have been configured
			return <Redirect to="/dashboard" />;
		}

		// Get the user nps permission
		let user = UserService.get();
		const { loading, npsScore, npsTotalRespondents, detractorsRatio, passivesRatio, promotersRatio, dateRangeString, dateArray, showNpsModal } = this.state;

		if (loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}
		let selectedDateRange = dateArray.filter(array => array.range === dateRangeString).map(r => r.name)[0];
		return (
			<div id="suggestions-page">
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="col-sm-6 col-md-10 col-lg-10">
						<h2>
							{t("Suggestions")}
							<span className="hidden-xxs"> - {location.name}</span>
						</h2>
					</div>
					{user.GroupPermission.create_nps && (
						<div id="suggestions-page__open_nps_button">
							<button className="ladda-button button btn btn-primary btm-xs btn-success" onClick={this.handleOpenNpsModal}>
								{t("Send NPS Request")}
							</button>
						</div>
					)}
				</div>
				<div className="row NPS__filter--container" style={{ backgroundColor: "#ffffff" }}>
					<div className="col-lg-12 leaderboard-nav NPS__filter--container">
						<button
							className="btn btn-default btn-block"
							type="button"
							aria-expanded={this.state.collapseFilterOpen}
							aria-controls="collapse-suggestion-filter"
							onClick={() => this.toggleFilterCollapse()}
							style={{ marginTop: "30px" }}
						>
							{t("Filters")}{" "}
							<small>
								<i className={this.state.collapseFilterIcon} />
							</small>
						</button>
						<br />
						{this.state.collapseFilterOpen && (
							<Collapse in={this.state.collapseFilterOpen}>
								<div id="collapse-suggestion-filter">{this.renderFilterForm("top-panel")}</div>
							</Collapse>
						)}
					</div>
				</div>
				<div className="wrapper wrapper-content main-table-margin" style={{ paddingTop: "0px" }}>
					<div className="row">
						<div className="col-lg-12">
							<div className="ibox float-e-margins NPS__container reviews_container">
								<div
									className="NPS__side-panel"
									style={{
										top: this.state.staticSuggestionFilterTopCss
									}}
									ref={suggestionFilter => (this.suggestionsFilterRef = suggestionFilter)}
								>
									<div className="NPS__side-panel-content">{this.renderFilterForm("side-panel")}</div>
								</div>
								<div className="media ibox-content">
									<div className="media-body">
										<div className="col-lg-4 col-sm-12" style={{ height: "60px" }}>
											<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
												<div>
													<h1 style={{ fontSize: "3em", display: "inline-block" }}>
														<span
															className={`${
																npsScore < -50 ? "Analytics__nps--detractors" : npsScore < 50 ? "Analytics__nps--passives" : "Analytics__nps--promoters"
															}`}
														>
															<strong>{npsScore}</strong>
														</span>
													</h1>{" "}
													<div style={{ display: "inline-block", marginLeft: "10px" }}>
														<b>{selectedDateRange}</b>
														<br />
														<span>{t("{{ number }} responses", { number: npsTotalRespondents })}</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-12 hidden-lg hidden-md">
											<div style={{ height: "30px", border: "none" }} className="hidden-lg" />
										</div>
										<div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
											<div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-10px", marginBottom: "-15px" }}>
												<div id="dh-npsChart-suggestions" />
											</div>
										</div>
										<div className="col-sm-12 hidden-lg hidden-md">
											<div style={{ height: "30px", border: "none" }} className="hidden-lg" />
										</div>
										<div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
											<div className="hidden-lg hidden-md hidden-sm" style={{ height: "40px" }} />

											<div style={{ padding: "3% 0", textAlign: "center", width: "100%", height: "60px" }}>
												<span style={{ padding: "4px 8px", fontSize: "13px", display: "inline-block" }}>
													<strong>
														<span>{promotersRatio}%</span>
													</strong>{" "}
													<span className="Analytics__nps--promoters">
														<strong>{t("Promoters")}</strong>
													</span>
												</span>
												<br className="hidden-lg hidden-md hidden-sm" />
												<span style={{ padding: "4px 8px", fontSize: "13px", display: "inline-block" }}>
													<strong>
														<span>{passivesRatio}%</span>
													</strong>{" "}
													<span className="Analytics__nps--passives">
														<strong>{t("Passives")}</strong>
													</span>
												</span>
												<br className="hidden-lg hidden-md hidden-sm" />
												<span style={{ padding: "4px 8px", fontSize: "13px", display: "inline-block" }}>
													<strong>
														<span>{detractorsRatio}%</span>
													</strong>{" "}
													<span className="Analytics__nps--detractors">
														<strong>{t("Detractors")}</strong>
													</span>
												</span>
											</div>
											<div className="hidden-lg hidden-md hidden-sm" style={{ height: "50px" }} />
										</div>
									</div>
								</div>
								{this.state.suggestionCount > 0 ? (
									this.state.suggestions.map(row => {
										return (
											<div key={row.id} className="media ibox-content fnctst-nps-row">
												<div className="review-site-logo" style={{ height: "40px" }}>
													<div className="NPS__center-score">
														<div>
															{row.score > 8 ? (
																<span className="NPS__promoters fnctst-nps-score">{row.score}</span>
															) : row.score < 7 ? (
																<span className="NPS__detractors fnctst-nps-score">{row.score}</span>
															) : (
																<span className="NPS__passives fnctst-nps-score">{row.score}</span>
															)}
														</div>
														<div>
															{row.score > 8 ? (
																<p className="NPS__promoters">{t("Promoter")}</p>
															) : row.score < 7 ? (
																<p className="NPS__detractors">{t("Detractor")}</p>
															) : (
																<p className="NPS__passives">{t("Passive")}</p>
															)}
														</div>
													</div>
												</div>
												<div className="media-body">
													<div id="posted-at" className="review-date">
														{new Date(row.replied_at).toDateString()}
														<br />
													</div>
													<h4 id="posted-by" className="media-heading">
														<span className="fnctst-nps-name"> {row.name !== "" && !row.is_anonymous ? row.name : t("Anonymous")}</span>
														{!row.is_anonymous && <span> {row.phone ? row.phone : row.email}</span>}
													</h4>
													{/* <div id={`suggestion-details-${row.id}`} className="media-heading NPS-details">
													</div> */}
													<div className="fnctst-suggestion" id={`suggestions-${row.id}`}>
														{row.suggestion && row.suggestion !== "" ? row.suggestion : <i>{t("The user did not leave any suggestion")}</i>}
													</div>
												</div>
											</div>
										);
									})
								) : (
									<h2 className="ibox-content forum-post-container" style={{ marginTop: "15px" }}>
										{t("Sorry, no suggestions were found")}{" "}
										<span role="img" aria-label="sad-face">
											😞
										</span>
									</h2>
								)}
								<div className="media ibox-content">
									<div className="row">
										<div className="pagination-bottom">
											<button id="prevPage" className="btn btn-default btn-outline btn-sm pagination-item" onClick={this.handlePrevPageClick}>
												<i className="fa fa-chevron-left" />
											</button>
											<span className="table-curr-page pagination-item">{this.getPageNumbers()}</span>
											<button id="nextPage" className="btn btn-default btn-outline btn-sm pagination-item" onClick={this.handleNextPageClick}>
												<i className="fa fa-chevron-right" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SendNpsRequest show={showNpsModal} onHide={this.onHideNpsModal} />
			</div>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(Suggestions));
