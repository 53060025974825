import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { withTranslation, Trans } from "react-i18next";

import { CONVERSATION, EDIT_TEAMCHAT_TABS, CHANNEL_TYPE } from "../../../../constants/Messenger";

import Alert from "../../../../components/common/Alert";
import Modal from "../../../../components/common/DHModal";
import Tabs from "../../../../components/common/Tabs";
import Tab from "../../../../components/common/Tab";
import DHSelect from "../../../../components/common/DHSelect";

import TeamChatMediaGrid from "../Media/TeamChatMediaGrid";

import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import TeamChatService from "../../../../services/TeamChatService";
import NotificationService from "../../../../services/NotificationService";
import ToastService from "../../../../services/ToastService";

import "./edit-team-chat.css";
import Input from "../../../../components/common/Input";

class EditTeamChat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			/* Modal related state data */
			selectedTab: null,

			show: false,
			name: "",
			edited: false,
			members: [],
			isAddingMembers: false,
			users: [],
			filteredList: [],
			selectedList: [],
			type: CONVERSATION.dm,
			channelType: { value: CHANNEL_TYPE.general.id, label: CHANNEL_TYPE.general.label },
			channelTypeOptions: [
				{ value: CHANNEL_TYPE.general.id, label: CHANNEL_TYPE.general.label },
				{ value: CHANNEL_TYPE.broadcast.id, label: CHANNEL_TYPE.broadcast.label }
			],
			confirmLeave: false,
			cantLeave: false,
			confirmAdmin: false,
			confirmAdminRemoval: false,
			newAdminName: "",
			selectedUser: null,
			conversationId: null,
			companyId: null,
			userId: null,
			isAdmin: this.props.manageMode ? UserService.isSuperAdminOrCustomerSuccess() : false,
			isMentionsOnly: false,
			purgeStartDate: moment().format("YYYY-MM-DD"),
			purgeEndDate: moment().format("YYYY-MM-DD"),
			showConfirmPurgeModal: false,
			showConfirmArchiveModal: false,

			selectableMembers: [],
			mediaGridUserId: 0
		};

		this.name = "";
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async defaultTab => {
		let { show, conversationId } = this.props;
		let { t } = this.props;

		if (!conversationId) {
			return;
		}

		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let conversationDetails = await TeamChatService.fetchConversationDetails(conversationId);
		let users = await TeamChatService.fetchUsers({ companyId: location.company_id, enableTeamChat: true });

		if (!conversationDetails) {
			return;
		}

		this.name = conversationDetails.name;

		// Reset modal tab
		await this.tabReset(defaultTab);

		// Create an array of memebers to use in selectors
		let selectableMembers = [];

		for (let i = 0; i < conversationDetails.members.length; i++) {
			let currentTeammember = conversationDetails.members[i];
			selectableMembers.push({
				label: currentTeammember.first_name,
				value: currentTeammember.id
			});
		}

		selectableMembers.unshift({
			label: t("Select Team Member"),
			value: 0
		});

		let isAdmin = this.props.manageMode ? UserService.isSuperAdminOrCustomerSuccess() : conversationDetails.is_admin;

		await this.update({
			conversationId: conversationDetails.id,
			companyId: conversationDetails.company_id,
			name: conversationDetails.name,
			isMentionsOnly: conversationDetails.is_mentions_only,
			isAdmin: isAdmin,
			members: conversationDetails.members,
			type: conversationDetails.type,
			channelType: { value: CHANNEL_TYPE[conversationDetails.channel_type].id, label: CHANNEL_TYPE[conversationDetails.channel_type].label },
			userId: user.id,
			show,
			users,
			filteredList: users,
			selectedList: [],

			purgeStartDate: moment().format("YYYY-MM-DD"),
			purgeEndDate: moment().format("YYYY-MM-DD"),
			showConfirmPurgeModal: false,
			showConfirmArchiveModal: false,

			mediaGridUserId: 0,
			selectableMembers
		});
	};

	// Change the tab we are one
	onTabSelect = async tab => {
		this.update({ selectedTab: tab.id });
	};

	// Go back to the first tab
	tabReset = tab => {
		this.update({ selectedTab: tab || EDIT_TEAMCHAT_TABS.general });
	};

	onCancel = () => {
		let { conversationId, name, members } = this.state;

		if (this.props.onClose) {
			this.props.onClose({
				id: conversationId,
				name,
				members
			});
		}
	};

	onNameInput = async event => {
		await this.update({
			edited: event.target.value !== this.name,
			name: event.target.value
		});
	};

	onConversationSave = async () => {
		let { conversationId, name, channelType, edited } = this.state;
		let { t } = this.props;

		if (!edited) {
			return;
		}

		let success = await TeamChatService.updateInternalConversation({ conversationId, name, channelType: channelType.value });

		if (!success) {
			ToastService.error(t("An error occurred trying to change the name."));
			return;
		}

		await this.update({
			edited: false
		});

		ToastService.info(t("Successfully updated name!"));
	};

	onNotificationSelect = async isMentionsOnly => {
		let { conversationId } = this.state;
		let user = UserService.get();

		let success = await TeamChatService.updateUserPreferences(conversationId, user.id, isMentionsOnly);

		if (!success) {
			return;
		}

		await this.update({
			isMentionsOnly
		});

		TeamChatService.cacheNotificationPreference(conversationId, user.id, isMentionsOnly);
	};

	onAddMembers = async () => {
		this.update({
			isAddingMembers: true
		});
	};

	onAddMember = async user => {
		let { selectedList } = this.state;

		selectedList.push(user);

		await this.update({ selectedList });
	};

	async onRemoveSelectedMember(user) {
		let { selectedList } = this.state;

		if (selectedList.length === 0) {
			return;
		}

		let index = selectedList.findIndex(selectedUser => {
			return selectedUser.id === user.id;
		});

		if (index === -1) {
			return;
		}

		selectedList.splice(index, 1);

		await this.update({ selectedList });
	}

	onRemoveMember = async memberId => {
		let { conversationId } = this.state;
		let { t } = this.props;

		let success = await TeamChatService.removeMembers(conversationId, [memberId]);

		if (!success) {
			ToastService.error(t("An error occurred trying to remove users."));
			return;
		}

		this.resetComponent();

		ToastService.info(t("Successfully removed members"));

		// Tell mentions component we have changed members
		NotificationService.publish("teamChatUpdate", conversationId);
	};

	isUserSelected(userId) {
		let { selectedList } = this.state;

		return selectedList.some(selectedUser => {
			return selectedUser.id === userId;
		});
	}

	onConfirmAddMembers = async () => {
		let { conversationId, selectedList } = this.state;
		let { t } = this.props;

		let userIds = selectedList.map(user => {
			return user.id;
		});

		let success = await TeamChatService.addMembers(conversationId, userIds);

		if (!success) {
			ToastService.error(t("An error occurred trying to add users."));
			return;
		}

		ToastService.info(t("Successfully added members"));

		await this.update({
			selectedList: [],
			isAddingMembers: false
		});

		await this.resetComponent();

		// Tell mentions component we have changed members
		NotificationService.publish("teamChatUpdate", conversationId);
	};

	onCancelAddMembers = async () => {
		await this.update({
			selectedList: [],
			isAddingMembers: false
		});
	};

	onConfirmLeaveChat = () => {
		if (!this.canLeave()) {
			this.update({
				cantLeave: true
			});

			return;
		}

		this.update({
			confirmLeave: true
		});
	};

	isUserAdmin = () => {
		let { members, userId, isAdmin } = this.state;

		if (isAdmin) {
			return true;
		}

		for (const member of members) {
			if (member.is_admin && member.id === userId) {
				return true;
			}
		}

		return false;
	};

	canLeave = () => {
		let { members } = this.state;

		if (!this.isUserMember()) {
			return false;
		}

		if (this.isUserAdmin()) {
			let admins = members.filter(m => {
				return m.is_admin;
			});

			// You can leave if you're not the only admin
			return admins.length > 1;
		}

		return true;
	};

	onLeaveChat = async confirmed => {
		let { conversationId, userId } = this.state;
		let { t } = this.props;

		if (!confirmed) {
			this.update({
				confirmLeave: false
			});

			return;
		}

		let success = await TeamChatService.removeMembers(conversationId, [userId]);

		if (!success) {
			ToastService.error(t("An error occurred trying to leave chat."));

			this.update({
				confirmLeave: false
			});
			return;
		}

		ToastService.info("You have left the chat.");

		// TODO: Took the easy wait out, we should fire some prop function that will autoselect the next chat or something like that.
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	onPurgeStartDateChange = async value => {
		let { purgeEndDate } = this.state;

		if (!value) {
			this.update({ purgeStartDate: moment().format("YYYY-MM-DD") });
		} else {
			this.update({ purgeStartDate: moment(value).format("YYYY-MM-DD") });
		}

		// Correct end date if we've moved the start date
		if (moment(value).diff(moment(purgeEndDate), "days") >= 1) {
			this.update({ purgeEndDate: moment(value).format("YYYY-MM-DD") });
		}
	};

	onPurgeEndDateChange = async value => {
		let { purgeStartDate } = this.state;

		if (!value) {
			this.update({ purgeEndDate: moment().format("YYYY-MM-DD") });
		} else {
			this.update({ purgeEndDate: moment(value).format("YYYY-MM-DD") });
		}

		// Correct start date if we've moved the start date
		if (moment(purgeStartDate).diff(moment(value), "days") >= 1) {
			this.update({ purgeStartDate: moment(value).format("YYYY-MM-DD") });
		}
	};

	onConfirmNewAdmin = member => {
		this.update({
			confirmAdmin: true,
			selectedUser: member
		});
	};

	onConfirmAdminRemoval = member => {
		this.update({
			confirmAdminRemoval: true,
			selectedUser: member
		});
	};

	onAddNewAdmin = async confirmed => {
		let { conversationId, selectedUser, members } = this.state;
		let { t } = this.props;

		if (!confirmed) {
			this.update({
				confirmAdmin: false
			});

			return;
		}

		this.update({
			confirmAdmin: false
		});

		let success = await TeamChatService.updateUserPreferences(conversationId, selectedUser.id, selectedUser.is_mentions_only, true);

		if (!success) {
			ToastService.error(t("An error occurred trying to add an admin."));

			this.update({
				confirmAdmin: false,
				selectedUser: null
			});
			return;
		}

		this.update({
			confirmAdmin: false
		});

		for (let member of members) {
			if (member.id === selectedUser.id) {
				member.is_admin = true;
				break;
			}
		}

		ToastService.info(t("New admin added successfully."));

		this.update({ members });
		await this.resetComponent(EDIT_TEAMCHAT_TABS.members);
	};

	onRemoveAdmin = async confirmed => {
		let { conversationId, selectedUser, members } = this.state;
		let { t } = this.props;

		if (!confirmed) {
			this.update({
				confirmAdminRemoval: false
			});

			return;
		}

		this.update({
			confirmAdminRemoval: false
		});

		let success = await TeamChatService.updateUserPreferences(conversationId, selectedUser.id, selectedUser.is_mentions_only, false);

		if (!success) {
			ToastService.error(t("An error occurred trying to remove an admin."));

			this.update({
				confirmAdminRemoval: false,
				selectedUser: null
			});
			return;
		}

		this.update({
			confirmAdminRemoval: false
		});

		for (let member of members) {
			if (member.id === selectedUser.id) {
				member.is_admin = false;
				break;
			}
		}

		ToastService.info(t("Admin status removed successfully."));

		this.update({ members });
		await this.resetComponent(EDIT_TEAMCHAT_TABS.members);
	};

	onShowConfirmPurgeModal = () => {
		this.update({ showConfirmPurgeModal: true });
	};

	onshowConfirmArchiveModal = () => {
		this.update({ showConfirmArchiveModal: true });
	};

	onConfirmPurgeChat = async confirm => {
		let { conversationId, purgeStartDate, purgeEndDate } = this.state;
		let { t } = this.props;

		await this.update({ showConfirmPurgeModal: false });

		if (!confirm) {
			return;
		}

		let success = await TeamChatService.purgeTeamchatMessages({ conversationId, purgeStartDate, purgeEndDate });

		if (!success) {
			ToastService.error(t("An error occurred trying to purge messages."));
			return;
		}

		ToastService.info(t("Successfully purged messages!"));

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onConfirmArchiveChat = async confirm => {
		let { conversationId } = this.state;
		let { t } = this.props;

		await this.update({ showConfirmArchiveModal: false });

		if (!confirm) {
			return;
		}

		let success = await TeamChatService.archiveConversation({ conversationId });

		if (!success) {
			ToastService.error(t("An error occurred trying to delete the conversation."));
			return;
		}

		// TODO: Took the easy wait out, we should fire some prop function that will autoselect the next chat or something like that.
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	isUserMember = () => {
		let { members } = this.state;

		let user = UserService.get();

		// For supers and cs
		let isUserMember = members.find(m => m.id === user.id);

		if (!isUserMember) {
			return false;
		}

		return true;
	};

	showMediaTab = () => {
		let { companyId } = this.state;

		let user = UserService.get();

		// For supers and cs
		if (user.company_id !== companyId) {
			return false;
		}

		if (!this.isUserMember()) {
			return false;
		}

		return true;
	};

	showNotificationsTab = () => {
		let { members } = this.state;

		if (!members) {
			return false;
		}

		// For supers and cs
		if (!this.isUserMember()) {
			return false;
		}

		return true;
	};

	renderMedia() {
		let { conversationId, selectableMembers, mediaGridUserId } = this.state;
		let { t } = this.props;

		// Find the user in the select
		let userFilterLabel = selectableMembers.find(user => user.value === mediaGridUserId);
		userFilterLabel = typeof userFilterLabel === "undefined" ? t("Assigned Medium") : userFilterLabel.label;

		return (
			<>
				<div className="mb-etc__media__user_filter">
					<DHSelect
						id="media_grid_user_filter"
						name="media_grid_user_filter"
						className="mb-etc__select"
						options={selectableMembers}
						value={{ label: userFilterLabel, value: mediaGridUserId }}
						placeholder={t("User")}
						onChange={newStatus => {
							this.update({ mediaGridUserId: newStatus.value });
						}}
					/>
				</div>
				<TeamChatMediaGrid conversationId={conversationId} userId={mediaGridUserId} />
			</>
		);
	}

	renderPurge() {
		let { purgeStartDate, purgeEndDate } = this.state;
		let { t } = this.props;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		return (
			<div className="mb-etc__purge">
				<div className="mb-etc__purge__dates">
					<div className="mb-etc__purge__dates__datepicker fnctst-purge-start">
						<div className="mb-etc__purge__dates__datepicker__label">
							<span>{t("Purge Start Date")}</span>
							<div className="mb-etc__purge__dates__datepicker__label__info_icon" data-tip data-for="timezone-purge">
								<Icon.Info size="16" />
							</div>
						</div>
						<ReactTooltip id="timezone-purge" type="info" className="mb-react-tooltip" effect="solid" place="right" arrowColor="#333">
							{t("The date is in your local timezone")} <br /> {localTimeZone} ({localTimeZoneShortHand})
						</ReactTooltip>
						<DatePicker
							selected={new Date(moment(purgeStartDate).toISOString())}
							onChange={e => this.onPurgeStartDateChange(e)}
							dateFormat="E, MMM d y"
							className="Common__datepicker"
						/>
					</div>
					<div className="mb-etc__purge__dates__datepicker fnctst-purge-end">
						<div className="mb-etc__purge__dates__datepicker__label">{t("Purge End Date")}</div>
						<DatePicker
							selected={new Date(moment(purgeEndDate).toISOString())}
							onChange={e => this.onPurgeEndDateChange(e)}
							dateFormat="E, MMM d y"
							className="Common__datepicker"
						/>
					</div>
				</div>
				<div className="mb-etc__purge__action">
					<div className="mb-button" onClick={this.onShowConfirmPurgeModal}>
						{t("Purge Messages")}
					</div>
				</div>
			</div>
		);
	}

	renderActions() {
		let allowArchiveTeamChannel = this.isUserAdmin();
		let { t } = this.props;

		return (
			<div className="mb-etc__action-rows">
				{allowArchiveTeamChannel && (
					<div className="mb-etc__action-rows__row">
						<div className="mb-etc__action-rows__row__text">
							<div className="mb-etc__action-rows__row__title">{t("Conversation")}</div>
							<div className="mb-etc__action-rows__row__subtitle">{t("Archive this conversation for everyone")}</div>
						</div>
						<div className="mb-etc__action-rows__row__button">
							<div className="mb-button" onClick={this.onshowConfirmArchiveModal}>
								{t("Archive")}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}

	renderGeneral() {
		let { name, isAdmin, channelType, channelTypeOptions, edited, type } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-etc__general">
				<Input
					label={t("Group Name")}
					id="name"
					name="name"
					type="text"
					onChange={this.onNameInput}
					value={name}
					disabled={!isAdmin || type === CONVERSATION.dm}
				/>
				{type === CONVERSATION.channel && (
					<DHSelect
						label={t("Type")}
						name="channelType"
						id="channel_type"
						onChange={option => this.update({ channelType: option, edited: true })}
						value={channelType}
						options={channelTypeOptions}
						disabled={!isAdmin}
					/>
				)}
				{isAdmin && (
					<div className="mb-etc__general__actions">
						<div className={`mb-button ${edited ? "" : "mb-button--disabled"}`} onClick={this.onConversationSave}>
							{t("Save")}
						</div>
					</div>
				)}
			</div>
		);
	}

	renderNotifications() {
		let { isMentionsOnly } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-etc__np">
				<div>{t("Notification Preferences")}</div>
				<div
					id="mb-etc-pref-all"
					onClick={() => this.onNotificationSelect(false)}
					className={`mb-etc__np__item ${isMentionsOnly ? "" : "mb-etc__np__item--selected"}`}
				>
					<div className={`mb-etc__np__item-radio ${isMentionsOnly ? "" : "mb-etc__np__item-radio--selected"}`} />
					<div className="mb-etc__np__item-content">
						<div className="mb-etc__np__item-content-title">{t("All Activity")}</div>
						<div>{t("We'll send you a notification for everything sent into this group")}</div>
					</div>
				</div>
				<div
					id="mb-etc-pref-mentions"
					onClick={() => this.onNotificationSelect(true)}
					className={`mb-etc__np__item ${isMentionsOnly ? "mb-etc__np__item--selected" : ""}`}
				>
					<div className={`mb-etc__np__item-radio ${isMentionsOnly ? "mb-etc__np__item-radio--selected" : ""}`} />
					<div className="mb-etc__np__item-content">
						<div className="mb-etc__np__item-content-title">{t("@Mentions only")}</div>
						<div>{t("We'll only send you notifications when someone @mentions you or the @group")}</div>
					</div>
				</div>
			</div>
		);
	}

	renderMembers() {
		let { isAdmin, members, userId, isAddingMembers, filteredList, type } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-etc__members">
				<div>{t("Group Members")}</div>
				{isAdmin && !isAddingMembers && type === CONVERSATION.channel && (
					<div className="mb-etc__members__add" onClick={this.onAddMembers}>
						<div className="mb-etc__members__add__icon">
							<Icon.PlusCircle size="18" />
						</div>
						<div className="mb-etc__members__add__title">{t("Add Members")}</div>
					</div>
				)}
				<div className="mb-etc__members__list">
					{isAddingMembers &&
						filteredList.map((user, index) => {
							let isSelected = this.isUserSelected(user.id);
							var fullName = UserService.createFullName({ firstName: user.first_name, lastName: user.last_name });
							return (
								<div key={`${user.id}-memebers`} className="mb-etc__members__list__item">
									<div className="fnctst-mb-etc__members-name">{`${fullName}`}</div>
									{!isSelected && (
										<div className="mb-etc__members__list__item__add-icon" onClick={() => this.onAddMember(user)}>
											<Icon.PlusCircle size="18" />
										</div>
									)}
									{isSelected && (
										<div className="mb-etc__members__list__item__remove-icon" onClick={() => this.onRemoveSelectedMember(user)}>
											<Icon.XCircle size="18" />
										</div>
									)}
								</div>
							);
						})}
					{isAddingMembers && (
						<div className="mb-etc__members__actions">
							<div onClick={this.onConfirmAddMembers} className={`mb-etc__members__actions__item mb-etc__members__actions__item--save`}>
								{t("Add Members")}
							</div>
							<div onClick={this.onCancelAddMembers} className={`mb-etc__members__actions__item mb-etc__members__actions__item--cancel`}>
								{t("Cancel")}
							</div>
						</div>
					)}
					{!isAddingMembers &&
						members.map((member, index) => {
							var fullName = UserService.createFullName({ firstName: member.first_name, lastName: member.last_name });

							return (
								<div key={`${fullName ? fullName : index}-current-memebers`} className="mb-etc__members__list__item">
									<div className="fnctst-mb-etc__members-name">{`${fullName}`}</div>
									{member.is_admin && (
										<div className="mb-etc-member-item-status">
											<div>{t("Admin")}</div>
										</div>
									)}
									{!member.is_admin && isAdmin && (
										<div
											className="mb-etc-member-item-status mb-etc-member-item-status--button fnctst-mb-etc-members-item-make-admin-icon"
											onClick={() => this.onConfirmNewAdmin(member)}
										>
											<div>{t("Make Admin")}</div>
										</div>
									)}
									{member.is_admin && isAdmin && (
										<div
											className="mb-etc-member-item-status-red mb-etc-member-item-status-red--button fnctst-mb-etc-members-item-remove-admin-icon"
											onClick={() => this.onConfirmAdminRemoval(member)}
										>
											<div>{t("Remove Admin")}</div>
										</div>
									)}
									{member.id !== userId && isAdmin && type === CONVERSATION.channel && (
										<div className="mb-etc__members__list__item__remove-icon" onClick={() => this.onRemoveMember(member.id)}>
											<Icon.MinusCircle size="18" />
										</div>
									)}
								</div>
							);
						})}
				</div>

				{this.canLeave() && (
					<div className="mb-etc__members__leave" onClick={this.onConfirmLeaveChat}>
						{t("Leave Chat?")}
					</div>
				)}
			</div>
		);
	}

	render() {
		let {
			show,
			name,
			confirmLeave,
			cantLeave,
			confirmAdmin,
			confirmAdminRemoval,
			selectedUser,
			selectedTab,
			showConfirmPurgeModal,
			purgeStartDate,
			purgeEndDate,
			showConfirmArchiveModal
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowPurge = user.GroupPermission.purge_teamchat_messages;

		let allowArchiveTeamChannel = this.isUserAdmin();

		return (
			<>
				<Modal show={show} onHide={this.onCancel} title={t("Group Details")} className="mb-etc">
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={EDIT_TEAMCHAT_TABS.general} value={t("General")} />
						{this.showNotificationsTab() && <Tab id={EDIT_TEAMCHAT_TABS.notifications} value={t("Notifications")} />}
						<Tab id={EDIT_TEAMCHAT_TABS.members} value={t("Members")} />
						{this.showMediaTab() && <Tab id={EDIT_TEAMCHAT_TABS.media} value={t("Media")} />}
						{allowPurge && <Tab id={EDIT_TEAMCHAT_TABS.purge} value={t("Purge")} />}
						{allowArchiveTeamChannel && <Tab id={EDIT_TEAMCHAT_TABS.actions} value={t("Actions")} />}
					</Tabs>
					{selectedTab === EDIT_TEAMCHAT_TABS.general && this.renderGeneral()}
					{selectedTab === EDIT_TEAMCHAT_TABS.notifications && this.renderNotifications()}
					{selectedTab === EDIT_TEAMCHAT_TABS.members && this.renderMembers()}
					{this.showMediaTab() && selectedTab === EDIT_TEAMCHAT_TABS.media && this.renderMedia()}
					{allowPurge && selectedTab === EDIT_TEAMCHAT_TABS.purge && this.renderPurge()}
					{allowArchiveTeamChannel && selectedTab === EDIT_TEAMCHAT_TABS.actions && this.renderActions()}
				</Modal>

				<Alert type="warning" show={confirmLeave} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onLeaveChat}>
					<div>{t("Are you sure you would like to leave the {{name}} chat?", { name })}</div>
				</Alert>
				<Alert
					type="warning"
					show={cantLeave}
					title={t("Can't leave ...")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ cantLeave: false });
					}}
				>
					<div>{t("You need to make someone else an admin before leaving.")}</div>
				</Alert>
				<Alert type="warning" show={confirmAdmin} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onAddNewAdmin}>
					<div>{t("Are you sure you would like to make {{firstName}} an admin?`", { firstName: selectedUser ? selectedUser.first_name : "" })}</div>
				</Alert>
				<Alert type="warning" show={confirmAdminRemoval} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onRemoveAdmin}>
					<div>{t("Are you sure you would like to remove {{firstName}} as admin?`", { firstName: selectedUser ? selectedUser.first_name : "" })}</div>
				</Alert>

				<Alert
					type="warning"
					show={showConfirmPurgeModal}
					title={t("Confirm Message Purge")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmPurgeChat}
				>
					<div>{`Are you sure you would like to delete messages from ${moment(purgeStartDate).format("MMMM Do YYYY")} to ${moment(purgeEndDate).format(
						"MMMM Do YYYY"
					)} the chat?`}</div>
				</Alert>
				<Alert
					type="warning"
					show={showConfirmArchiveModal}
					title={t("Archive Conversation")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmArchiveChat}
				>
					<div>{t(`Are you sure you would like to archive this conversation for everyone in it?`)}</div>
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditTeamChat);
