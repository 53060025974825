import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class InviteGoal extends PureComponent {
	state = { monthlyInviteSatus: "on-track", monthlyInvites: 0, monthlyInviteGoal: 0, requiredInviteCount: 0 };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		let data = await LocationService.fetchInviteGoals(UserService.getActiveLocation().id);
		if (!data) {
			this.setState({ monthlyInviteSatus: "on-track", monthlyInvites: 0, monthlyInviteGoal: 0, requiredInviteCount: 0 });
			return;
		}

		this.setState({
			monthlyInviteSatus: data.monthlyInviteSatus,
			monthlyInvites: data.monthlyInvites,
			monthlyInviteGoal: data.monthlyInviteGoal,
			requiredInviteCount: data.requiredInviteCount
		});
	};

	render() {
		const { monthlyInviteSatus, monthlyInvites, monthlyInviteGoal, requiredInviteCount } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if ((isEnabled && !isEnabled()) || (isPermissible && !isPermissible())) {
			return (
				<div>
					<div>{cardName ? cardName : t("Invitation Goal This Month")}</div>
					<div className="text-center">
						{!isEnabled() ? <>{t("Review Invites are not enabled. Contact support to get started.")}</> : <>{t("Review Invite Data Restricted.")}</>}
					</div>
				</div>
			);
		}

		let invitePercent = 0;
		if (monthlyInviteGoal >= 0) {
			invitePercent = monthlyInvites / monthlyInviteGoal;
		}

		let isOffTrack = monthlyInviteSatus === "off-track";
		let offTrackText = "";
		if (isOffTrack) {
			if (requiredInviteCount === 1) {
				offTrackText = t("You're off track! Send 1 invite to catch up.");
			} else {
				offTrackText = t("You're off track! Send {{number}} invites to catch up.", { number: requiredInviteCount });
			}
		}

		return (
			<DashboardCard className={DASHBOARD_CARDS.inviteGoalThisMonth.className} height={DASHBOARD_CARDS.inviteGoalThisMonth.h}>
				<div>
					<span>{cardName ? cardName : t("Invitation Goal This Month")}</span>
					<small className="pull-right">
						{monthlyInvites}/{monthlyInviteGoal}
					</small>
				</div>
				<div className="progress progress-large">
					<div
						style={{ width: parseInt(invitePercent * 100, 10) + "%" }}
						className={isOffTrack ? "progress-bar progress-bar-danger" : "progress-bar progress-bar-success"}
					/>
				</div>
				<div className="text-center">
					{monthlyInviteSatus === "off-track" ? (
						<span className="text-danger">{offTrackText}</span>
					) : (
						<span className="text-success">{t("You're on track! Great work!")}</span>
					)}
				</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(InviteGoal));
