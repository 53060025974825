import i18n from "../i18n/config";

export const INBOX_TYPES = {
	general: "general",
	reviews: "reviews",
	leads: "leads",
	sales: "sales",
	service: "service",
	parts: "parts",
	reengagements: "reengagements"
};

export const INBOX_TYPES_OPTIONS = [
	{ label: i18n.t("General"), value: INBOX_TYPES.general },
	{ label: i18n.t("Reviews"), value: INBOX_TYPES.reviews },
	{ label: i18n.t("Leads"), value: INBOX_TYPES.leads },
	{ label: i18n.t("Sales"), value: INBOX_TYPES.sales },
	{ label: i18n.t("Service"), value: INBOX_TYPES.service },
	{ label: i18n.t("Parts"), value: INBOX_TYPES.parts },
	{ label: i18n.t("Reengagements"), value: INBOX_TYPES.reengagements }
];

export const INBOX_RULE_METADATA = {
	user: "userId",
	inbox: "inboxId",
	conversation: "conversationId"
};

export const INBOX_RULE_ATTRIBUTES = {
	delay: "delay",
	category: "category",
	action: "action",
	isRepeated: "is_repeated",
	metadata: "metadata"
};

export const INBOX_USERS_HEADERS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: false,
		width: 3
	},
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: false,
		width: 3
	},
	available: {
		id: "available",
		value: i18n.t("View Only"),
		sortable: false,
		width: 2
	},
	actions: {
		id: "remove",
		value: "",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const INBOX_TABS = {
	details: "details",
	users: "users",
	rules: "rules"
};
