import i18n from "../i18n/config";

export const WIDGET_NAME = {
	webchat: "webchat",
	reviewsWidget: "reviews_widget",
	embeddedReviewsWidget: "embedded_reviews_widget",
	embeddedBookingWidget: "embedded_booking_widget"
};

export const WIDGET_FRIENDLY_NAME = {
	webchat: i18n.t("Webchat"),
	reviews_widget: i18n.t("Reviews Widget"),
	embedded_reviews_widget: i18n.t("Embedded Reviews Widget"),
	embedded_booking_widget: i18n.t("Embedded Booking Widget")
};

export const WIDGET_REVIEW_CONFIG = {
	forceMode: [
		{ label: i18n.t("Auto"), value: "auto" },
		{ label: i18n.t("Mobile"), value: "mobile" },
		{ label: i18n.t("Desktop"), value: "desktop" }
	],
	position: [
		{ label: i18n.t("Bottom Left"), value: "bottom-left" },
		{ label: i18n.t("Bottom Right"), value: "bottom-right" },
		{ label: i18n.t("Mid Left"), value: "mid-left" },
		{ label: i18n.t("Mid Right"), value: "mid-right" }
	]
};

export const WIDGET_CONFIG = {
	positioningModes: [
		{ value: "desktop", label: i18n.t("Desktop") },
		{ value: "mobile", label: i18n.t("Mobile") }
	],
	offsetModes: {
		default: {
			value: "default",
			label: i18n.t("Default")
		},
		custom: {
			value: "custom",
			label: i18n.t("Custom Position In Url")
		}
	}
};

export const DEFAULT_WEBCHAT_POSITION = { label: i18n.t("Bottom Right"), value: "bottom-right" };

export const WEBCHAT_POSITIONS = [
	{ label: i18n.t("Bottom Right"), value: "bottom-right" },
	{ label: i18n.t("Bottom"), value: "bottom" },
	{ label: i18n.t("Bottom Left"), value: "bottom-left" },
	{ label: i18n.t("Left"), value: "left" },
	{ label: i18n.t("Top Left"), value: "top-left" },
	{ label: i18n.t("Top"), value: "top" },
	{ label: i18n.t("Top Right"), value: "top-right" },
	{ label: i18n.t("Right"), value: "right" }
];

export const WIDGET_TABS = {
	all: {
		id: "all",
		value: i18n.t("All")
	},
	microSite: {
		id: "microSite",
		value: i18n.t("Micro Site")
	},
	webChat: {
		id: "webChat",
		value: i18n.t("Web Chat")
	},
	bookingWidget: {
		id: "bookingWidget",
		value: i18n.t("Booking")
	},
	reviewsWidget: {
		id: "reviewsWidget",
		value: i18n.t("Reviews")
	},
	embeddedReviewsWidget: {
		id: "embeddedReviewsWidget",
		value: i18n.t("Embedded Reviews")
	}
};

export const BOOKING_TABS = {
	general: {
		id: "general",
		value: i18n.t("General")
	},
	bookingSlots: {
		id: "bookingSlots",
		value: i18n.t("Booking Slots")
	},
	services: {
		id: "services",
		value: i18n.t("Services")
	}
};

export const IFRAME_STYLES = {
	modal: {
		background: "rgba(0, 0, 0, 0)",
		width: "100vw",
		height: "100vh",
		border: "none",
		position: "fixed",
		left: "0px",
		top: "0px"
	},
	tooltip: {
		width: "350px",
		height: "200px",
		border: "none",
		position: "absolute",
		bottom: "85px"
	},
	bubble: {
		width: "300px",
		height: "100px",
		border: "none",
		position: "absolute",
		bottom: "0px",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
		overflow: "none"
	},
	positions: {
		bottomRight: "bottom-right",
		bottom: "bottom",
		bottomLeft: "bottom-left",
		left: "left",
		topLeft: "top-left",
		top: "top",
		topRight: "top-right",
		right: "right"
	},
	hubSpotChat: {
		background: "rgba(0, 0, 0, 0)",
		width: "100%",
		height: "100%",
		border: "none"
	}
};
