import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import posed from "react-pose";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import NotificationService from "../../services/NotificationService";
import LocationService from "../../services/LocationService";

import NotFound from "../../components/layouts/NotFound";
import withLocation from "../../components/common/WithLocation";
import ManageSignUp from "./SignUps/ManageSignUp";
import ManageCompareToolLead from "../CompareToolLeads/ManageCompareToolLead";

import { CONFIGURE_ROUTES, TOOLS_ROUTES, DATA_ROUTES, DEV_ROUTES } from "../../constants/CustomerSuccess";

import "../../scenes/MessengerBeta/Navbar/navbar.css";
import "../../styles/css/scenes/settings.css";

const Container = posed.div({
	visible: {
		y: 0,
		opacity: 1
	},
	hidden: {
		y: 0,
		opacity: 0
	}
});

const DEFAULT_ROUTE = "/customer-success/features";

class CustomerSuccess extends Component {
	constructor(props) {
		super(props);

		let selectedSetting = DEFAULT_ROUTE;

		if (props.location?.pathname) {
			selectedSetting = props.location.pathname;
		}

		this.state = {
			selectedSetting,
			locationData: null,
			loading: true,
			monthlyInviteGoal: 0,
			monthlyReviewGoal: 0,
			saveError: false,
			saveSuccess: false,
			successText: "",
			errorText: ""
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLocation();

		if (this.props.location.pathname === "/customer-success") {
			await this.update({
				currentRoute: DEFAULT_ROUTE
			});

			this.props.history.push(DEFAULT_ROUTE);
		}

		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			this.update({
				selectedSetting: location.pathname
			});
		});
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged() {
		this.fetchLocation();
	}

	async fetchLocation() {
		this.setState({ loading: true });

		let data = await LocationService.fetchLocation(UserService.getActiveLocation().id);

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({
			locationData: data,
			loading: false
		});
	}

	onClick = ({ route }) => {
		this.setState({ selectedSetting: route });
		this.props.history.push(route);
	};

	renderRouteSet({ routes, name }) {
		let { selectedSetting } = this.state;
		let { t } = this.props;

		// Current Route Set
		let routeSet = Object.keys(routes);

		return (
			<>
				<div className="settings__navbar__header">{name}</div>
				<div className="settings__navbar__list">
					{routeSet.map(key => {
						let currentRoute = routes[key];
						let shouldShowRoute = currentRoute.enabled() && !currentRoute.hideFromMenu;

						if (!shouldShowRoute) {
							return null;
						}

						let isSelected = selectedSetting === currentRoute.route;

						let styles = ["settings__navbar__list__item"];

						if (isSelected) {
							styles.push("settings__navbar__list__item--active");
						}

						return (
							<div className={styles.join(" ")} onClick={() => this.onClick({ route: currentRoute.route })}>
								{currentRoute.name}
							</div>
						);
					})}
				</div>
			</>
		);
	}

	renderRouteList({ routes }) {
		let routeSet = Object.keys(routes);

		return routeSet.map(key => {
			let currentRoute = routes[key];
			let isEnabled = currentRoute.enabled();

			if (!isEnabled) {
				return null;
			}

			return <Route key={key} exact path={currentRoute.route} component={currentRoute.component} />;
		});
	}

	render() {
		return (
			<Container className="settings" initialPose="hidden" pose="visible">
				<div className="settings__navbar">
					{this.renderRouteSet({ routes: CONFIGURE_ROUTES, name: "CUSTOMER SUCCESS" })}
					{this.renderRouteSet({ routes: TOOLS_ROUTES, name: "TOOLS" })}
					{this.renderRouteSet({ routes: DATA_ROUTES, name: "DATA" })}
					{this.renderRouteSet({ routes: DEV_ROUTES, name: "DEVELOPERS" })}
				</div>
				<Switch>
					{this.renderRouteList({ routes: CONFIGURE_ROUTES })}
					{this.renderRouteList({ routes: TOOLS_ROUTES })}
					{this.renderRouteList({ routes: DATA_ROUTES })}
					{this.renderRouteList({ routes: DEV_ROUTES })}

					{/* Odd Ball Routes */}
					{UserService.isSuperAdminOrCustomerSuccess() && <Route exact path="/customer-success/sign-ups/:signUpId" render={props => <ManageSignUp />} />}
					{UserService.get().GroupPermission.view_companies && (
						<Route exact path="/customer-success/reputation-report/manage/:compareToolLeadId" render={props => <ManageCompareToolLead {...props} />} />
					)}
					<Route key={"not-found"} path={"/*"}>
						<NotFound />
					</Route>
				</Switch>
			</Container>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(CustomerSuccess));
