import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import TagService from "../../services/TagService";

import UpdateTagsModal from "../Tags/UpdateTagsModal";

import "../../styles/css/scenes/contact-selector.css";

import { TAG_ACTIONS, TAG_TYPES } from "../../constants/Tags";

class ContactSelectorUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tagFilterOptions: [],
			selectedTagsFilter: [],
			showHeader: this.props.showHeader ? this.props.showHeader : false,
			confirmModalText: "",
			action: "",
			disabledButtons: true,
			showCreateTagModal: false,
			showButtons: typeof this.props.showButtons !== "undefined" ? this.props.showButtons : true
		};
	}

	componentWillUpdate(nextProps) {
		if (this.props.numSelectedContacts !== nextProps.numSelectedContacts) {
			let { selectedTagsFilter } = this.state;
			this.setState({ disabledButtons: (selectedTagsFilter && selectedTagsFilter.length === 0) || nextProps.numSelectedContacts === 0 });
		}
	}

	async componentDidMount() {
		await this.fetchTags();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchTags() {
		let { type } = this.props;

		try {
			let tags = await TagService.getTags(null, {
				type
			});
			tags = tags.map(t => {
				return { label: t.name, value: t.id };
			});
			this.setState({ tagFilterOptions: tags });
		} catch (error) {
			console.log(error);
		}
	}

	onUpdateModalHide() {
		this.setState({ showCreateTagModal: false });
	}

	async onCreateTagModalSubmit(name) {
		let { type } = this.props;
		let { t } = this.props;

		await this.update({ showCreateTagModal: false });

		let locationId = UserService.getActiveLocation().id;
		let tag = await TagService.createTag(locationId, name, type);

		if (!tag) {
			ToastService.error(t(`Error trying to create tag`));
			return;
		}
		ToastService.info(t(`Successfully created tag`));
		await this.fetchTags();

		let { selectedTagsFilter } = this.state;

		selectedTagsFilter.push({ value: tag.id, label: tag.name });

		await this.onSelectChange(selectedTagsFilter);

		if (this.props.refreshTags) {
			this.props.refreshTags();
		}
	}

	async onUpdateModalDelete(id, name) {
		let { t } = this.props;

		let response = await TagService.deleteTag(id);
		if (!response) {
			this.setState({
				showCreateTagModal: false
			});
			ToastService.error(t(`Error trying to delete tag`));

			return;
		}
		this.setState({
			showCreateTagModal: false
		});
		ToastService.info(t(`Successfully deleted tag`));

		this.fetchTags();
	}

	handleOnConfirmModal = async confirm => {
		let { action } = this.state;
		let { type } = this.props;
		let { t } = this.props;

		if (confirm && action !== "") {
			if (this.props.updateTagsForContacts) {
				let tags = this.state.selectedTagsFilter.map(t => t.value);
				let response = await this.props.updateTagsForContacts(tags, action, type);
				if (response) {
					let responseText = "";
					if (type === TAG_TYPES.tag) {
						if (action === TAG_ACTIONS.addToContacts) {
							responseText = t("Successfully added tags to selected contacts");
						} else if (action === TAG_ACTIONS.overwriteToContacts) {
							responseText = t("Successfully overwrote tags to selected contacts");
						} else if (action === TAG_ACTIONS.removeToContacts) {
							responseText = t("Successfully removed tags from selected contacts");
						}
					} else {
						if (action === TAG_ACTIONS.addToContacts) {
							responseText = t("Successfully added groups to selected contacts");
						} else if (action === TAG_ACTIONS.overwriteToContacts) {
							responseText = t("Successfully overwrote groups to selected contacts");
						} else if (action === TAG_ACTIONS.removeToContacts) {
							responseText = t("Successfully removed groups from selected contacts");
						}
					}
					ToastService.info(responseText);
				} else {
					let responseText = "";
					if (type === TAG_TYPES.tag) {
						if (action === TAG_ACTIONS.addToContacts) {
							responseText = t("There was an error adding tags to selected contacts");
						} else if (action === TAG_ACTIONS.overwriteToContacts) {
							responseText = t("There was an error overwriting tags for selected contacts");
						} else if (action === TAG_ACTIONS.removeToContacts) {
							responseText = t("There was an error removing tags from selected contacts");
						}
					} else {
						if (action === TAG_ACTIONS.addToContacts) {
							responseText = t("There was an error adding groups to selected contacts");
						} else if (action === TAG_ACTIONS.overwriteToContacts) {
							responseText = t("There was an error overwriting groups for selected contacts");
						} else if (action === TAG_ACTIONS.removeToContacts) {
							responseText = t("There was an error removing groups from selected contacts");
						}
					}
					ToastService.error(responseText);
				}
			}
		}
		this.setState({
			confirmModalText: "",
			action: ""
		});
	};

	onSelectChange = async selectedTagsFilter => {
		let { numSelectedContacts } = this.props;

		await this.update({ selectedTagsFilter });
		await this.update({
			disabledButtons: (this.state.selectedTagsFilter && this.state.selectedTagsFilter.length === 0) || numSelectedContacts === 0
		});

		if (this.props.onSelectChange) {
			this.props.onSelectChange(selectedTagsFilter);
		}
	};

	render() {
		let { tagFilterOptions, selectedTagsFilter, showHeader, showButtons, confirmModalText, disabledButtons, showCreateTagModal } = this.state;
		let { type } = this.props;
		let { t } = this.props;

		return (
			<div className={`contact-selector__update-contacts ${type === TAG_TYPES.tag ? "tags_section" : "groups_section"}`}>
				{showHeader && (
					<div className="contact-selector__update-contacts-header">
						{type === TAG_TYPES.tag ? t("Tags ") : t("Groups ")}
						<Icon.Plus
							className="contact-selector__update-contacts-header__icon fnctst-create-action"
							size="20"
							onClick={() => {
								this.setState({ showCreateTagModal: !this.state.showCreateTagModal });
							}}
						/>
					</div>
				)}
				<div className="contact-selector__update-contacts-column">
					<div>
						<div>
							<Select
								id={type === TAG_TYPES.tag ? "update-tags" : "update-groups"}
								key={`tag_${type}_${selectedTagsFilter.length}`}
								options={tagFilterOptions}
								value={selectedTagsFilter}
								isMulti={true}
								isClearable={true}
								placeholder={type === TAG_TYPES.tag ? t("Tags") : t("Groups")}
								onChange={this.onSelectChange}
							/>
						</div>
						{showButtons && (
							<div className="contact-selector__row-btns">
								<div
									className={`mb-button ${disabledButtons ? "mb-button--disabled" : ""}`}
									onClick={() => {
										if (disabledButtons) {
											return;
										}

										let newConfirmModalText = "";
										if (type === TAG_TYPES.tag) {
											newConfirmModalText = t(`Are you sure you want to add these tags to the selected contacts?`);
										} else {
											newConfirmModalText = t(`Are you sure you want to add these groups to the selected contacts?`);
										}

										this.setState({
											confirmModalText: newConfirmModalText,
											action: TAG_ACTIONS.addToContacts
										});
									}}
								>
									{t("Add")}
								</div>
								<div
									className={`mb-button ${disabledButtons ? "mb-button--disabled" : ""}`}
									onClick={() => {
										if (disabledButtons) {
											return;
										}

										let newConfirmModalText = "";
										if (type === TAG_TYPES.tag) {
											newConfirmModalText = t(`Are you sure you want to overwrite these tags for the selected contacts?`);
										} else {
											newConfirmModalText = t(`Are you sure you want to overwrite these groups for the selected contacts?`);
										}

										this.setState({
											confirmModalText: newConfirmModalText,
											action: TAG_ACTIONS.overwriteToContacts
										});
									}}
								>
									{t("Overwrite")}
								</div>
								<div
									className={`mb-button ${disabledButtons ? "mb-button--disabled" : ""}`}
									onClick={() => {
										if (disabledButtons) {
											return;
										}

										let newConfirmModalText = "";
										if (type === TAG_TYPES.tag) {
											newConfirmModalText = t(`Are you sure you want to remove these tags from the selected contacts?`);
										} else {
											newConfirmModalText = t(`Are you sure you want to remove these groups from the selected contacts?`);
										}

										this.setState({
											confirmModalText: newConfirmModalText,
											action: TAG_ACTIONS.removeToContacts
										});
									}}
								>
									{t("Remove")}
								</div>
							</div>
						)}
						<div>
							{confirmModalText && confirmModalText !== "" && (
								<div className="contact-selector__update-contacts__confirm">
									<div className="contact-selector__update-contacts__confirm-text text-center">
										<span>{confirmModalText}</span>
									</div>
									<div className="contact-selector__update-contacts__confirm-btns">
										<div className="mb-button mb-button--cancel" onClick={() => this.handleOnConfirmModal(false)}>
											{t("Cancel")}
										</div>
										<div className="mb-button" onClick={() => this.handleOnConfirmModal(true)}>
											{t("Okay")}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<UpdateTagsModal
					data={""}
					show={showCreateTagModal}
					onHide={() => this.onUpdateModalHide()}
					createMode={true}
					type={type || TAG_TYPES.tag}
					onSubmit={(createMode, id, name) => this.onCreateTagModalSubmit(name)}
				/>
			</div>
		);
	}
}
export default withTranslation(null, { withRef: true })(ContactSelectorUpdate);
