import React, { Component } from "react";
import * as Icon from "react-feather";
import CurrencyInput from "react-currency-input";
import numeral from "numeral";
import salesTax from "sales-tax";
import ContentLoader from "react-content-loader";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import { KEYS } from "../../../../constants/Messenger";
import { COUNTRY_CODE, INVOICE_LINE_ITEM_TYPE, STATE_CODE, PAYMENT_TYPES } from "../../../../constants/Payments";

import Checkbox from "../../../../components/common/Checkbox";
import Modal from "../../../../components/common/DHModal";
import Alert from "../../../../components/common/Alert";

import UserService from "../../../../services/UserService";
import PaymentService from "../../../../services/PaymentService";
import ToastService from "../../../../services/ToastService";
import UtilityService from "../../../../services/UtilityService";
import ContactService from "../../../../services/ContactService";

import "./invoice.css";

// TODO: We need to change this library at some point since the last release was in Feb 2018.
// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
CurrencyInput.prototype.componentDidMount = function() {
	if (!this.props.autoFocus) {
		let setSelectionRange_super = this.theInput.setSelectionRange;
		this.theInput.setSelectionRange = () => {};
		componentDidMount_super.call(this, ...arguments);
		this.theInput.setSelectionRange = setSelectionRange_super;
	} else {
		componentDidMount_super.call(this, ...arguments);
	}
};
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
CurrencyInput.prototype.componentDidUpdate = function() {
	if (!this.props.autoFocus) {
		let setSelectionRange_super = this.theInput.setSelectionRange;
		this.theInput.setSelectionRange = () => {};
		componentDidUpdate_super.call(this, ...arguments);
		this.theInput.setSelectionRange = setSelectionRange_super;
	} else {
		componentDidMount_super.call(this, ...arguments);
	}
};

class Invoice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			loading: false,
			lineItems: [
				{
					name: "",
					amount: 0,
					currency: PaymentService.getCurrency(),
					quantity: 1,
					type: INVOICE_LINE_ITEM_TYPE.general
				}
			],

			hasLineItemError: false,

			subtotal: 0,
			applyTaxes: true,
			taxes: 0,
			total: 0,

			taxObject: { rate: 0, type: "none" }
		};

		this.lastLineItemInput = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();

		document.addEventListener("keydown", this.onKey);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, contactId, type } = this.props;

		await this.update({ show, contactId, type });

		await this.updateContactData();
	};

	updateContactData = async () => {
		let { contactId } = this.state;

		if (!contactId) {
			return;
		}

		let contact = await ContactService.getContact(contactId);

		if (!contact) {
			return;
		}

		await this.update({
			contactName: contact.name
		});
	};

	onCreateInvoice = async () => {
		let { contactId, lineItems, applyTaxes, taxObject } = this.state;

		if (!this.isValid()) {
			return;
		}

		await this.update({
			loading: true
		});

		// Create line items
		for (let li of lineItems) {
			if (li.name === "" || li.amount === 0) {
				await this.update({ hasLineItemError: true, loading: false });
				return;
			}

			if (applyTaxes) {
				// We'll just update the tax rate on each line item
				// TODO: DH-3520, DH-3522 - eventually each line item should be able to toggle a tax rate, and we should also have the ability to predefine products/services with fixed prices and tax codes
				li.tax_rate = taxObject.rate;
				li.tax_name = `${t("Taxes")} (${taxObject.type.toUpperCase()} - ${taxObject.rate * 100}%)`;
			} else {
				li.tax_rate = 0;
				li.tax_name = t("none");
			}
		}

		let invoice = await PaymentService.createInvoice({
			contactId,
			lineItems,
			name: ""
		});

		// Invoice Failure
		if (!invoice) {
			ToastService.error(t(`Error creating Invoice. Please try again.`));
		}
		// Invoice Success
		else {
			ToastService.info(t(`Successfully created Invoice.`));
		}

		await this.update({
			loading: false,
			subtotal: 0,
			total: 0,
			taxes: 0,
			lineItems: [
				{
					name: "",
					amount: 0,
					currency: PaymentService.getCurrency(),
					quantity: 1,
					type: INVOICE_LINE_ITEM_TYPE.general
				}
			]
		});

		this.onClose();
	};

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onMessageInput = event => {
		this.update({
			messageInput: event.target.value
		});
	};

	onKey = e => {
		let { show } = this.props;

		if (!show) {
			return;
		}

		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		} else if (e.keyCode === KEYS.enter) {
			this.addLineItem();
		}
	};

	isValid = () => {
		let { subtotal } = this.state;

		return subtotal > 0;
	};

	onNameChange = async (event, index) => {
		let { lineItems } = this.state;

		lineItems = lineItems.slice();
		lineItems[index].name = event.target.value;

		await this.update({
			lineItems
		});

		await this.calculateSystemLineItems();
	};

	onAmountChange = async (event, maskedValue, floatValue, index) => {
		let { lineItems } = this.state;

		lineItems = lineItems.slice();
		lineItems[index].amount = floatValue;

		await this.update({
			lineItems
		});

		await this.calculateSystemLineItems();
	};

	addLineItem = async () => {
		let { lineItems } = this.state;

		lineItems.push({
			name: "",
			amount: 0,
			currency: PaymentService.getCurrency(),
			quantity: 1
		});

		await this.update({
			lineItems
		});

		await this.calculateSystemLineItems();

		if (this.lastLineItemInput) {
			this.lastLineItemInput.focus();
		}
	};

	removeLineItem = async index => {
		let { lineItems } = this.state;

		lineItems = lineItems.slice();
		lineItems.splice(index, 1);

		await this.update({
			lineItems
		});

		await this.calculateSystemLineItems();
	};

	toggleTaxes = async () => {
		let { applyTaxes } = this.state;

		await this.update({
			applyTaxes: !applyTaxes
		});

		await this.calculateSystemLineItems();
	};

	calculateTaxes = async () => {
		let { subtotal, applyTaxes } = this.state;
		let location = UserService.getActiveLocation();
		let tax = await salesTax.getSalesTax(COUNTRY_CODE[location.country], STATE_CODE[location.state_prov]);

		await this.update({
			applyTaxes: tax.type === "none" ? false : applyTaxes,
			taxObject: tax,
			taxes: tax.rate * subtotal
		});
	};

	calculateTotal = async () => {
		let { applyTaxes, subtotal, taxes } = this.state;

		let total = applyTaxes ? subtotal + taxes : subtotal;

		await this.update({
			total
		});
	};

	calculateSubtotal = async () => {
		let { lineItems } = this.state;
		let subtotal = 0;

		for (let li of lineItems) {
			subtotal += li.amount;
		}

		await this.update({ subtotal });
	};

	calculateSystemLineItems = async () => {
		await this.calculateSubtotal();
		await this.calculateTaxes();
		await this.calculateTotal();
	};

	renderLineItems() {
		let { lineItems, subtotal, taxes, total, applyTaxes, taxObject } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-pr__line-items">
				<div className="mb-pr__line-items__title">{t("Invoice Items:")}</div>
				{lineItems &&
					lineItems.length > 0 &&
					lineItems.map((li, index) => (
						<div key={index} className="mb-pr__line-items__item fnctst-line-item">
							<input
								ref={ref => {
									if (index === lineItems.length - 1) {
										this.lastLineItemInput = ref;
									}
								}}
								name="li-name"
								className="mb-pr__line-items__item__input fnctst-line-item-name"
								placeholder={t("My Awesome Service")}
								value={li.name}
								onChange={event => this.onNameChange(event, index)}
								autoComplete="false"
							/>
							<CurrencyInput
								className="mb-pr__line-items__item__input mb-pr__line-items__item__input--small fnctst-line-item-amount"
								value={li.amount}
								onChangeEvent={(event, maskedValue, floatValue) => this.onAmountChange(event, maskedValue, floatValue, index)}
							/>
							<div className="mb-pr__line-items__item__plus fnctst-add-line-item-plus" onClick={this.addLineItem}>
								{index === lineItems.length - 1 && <Icon.Plus size="24" />}
							</div>
							<div className="mb-pr__line-items__item__plus fnctst-add-line-item-plus" onClick={() => this.removeLineItem(index)}>
								{index !== 0 && <Icon.Trash size="24" />}
							</div>
						</div>
					))}
				<div className="mb-pr__line-items__subtotal">
					<div className="mb-pr__line-items__subtotal__title">{t("Sub Total:")}</div>
					<div className="mb-pr__line-items__subtotal__amount">{subtotal === 0 ? "0.00" : numeral(subtotal).format("$ 0,0[.]00")}</div>
					<div className="mb-pr__line-items__item__plus" />
				</div>
				<div className="mb-pr__line-items__taxes" style={{ opacity: applyTaxes ? 1 : 0.5 }}>
					<div className="mb-pr__line-items__taxes__title">{`${t("Taxes")} (${taxObject.type.toUpperCase()} - ${taxObject.rate * 100}%)`}</div>
					<div className="mb-pr__line-items__taxes__amount">{taxes === 0 ? "0.00" : numeral(taxes).format("$ 0,0[.]00")}</div>
					<div className="mb-pr__line-items__item__plus fnctst-toggle-tax" onClick={this.toggleTaxes}>
						<Icon.Percent size="24" />
					</div>
				</div>
				<div className="mb-pr__line-items__subtotal">
					<div className="mb-pr__line-items__subtotal__title">{t("Total:")}</div>
					<div className="mb-pr__line-items__subtotal__amount">{total === 0 ? "0.00" : numeral(total).format("$ 0,0[.]00")}</div>
					<div className="mb-pr__line-items__item__plus" />
				</div>
			</div>
		);
	}

	renderLoader() {
		return (
			<ContentLoader width={700} height={300}>
				<rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
				<rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
				<rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
				<rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
				<rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
			</ContentLoader>
		);
	}

	render() {
		let { show, loading, hasLineItemError, contactName } = this.state;
		let { t } = this.props;

		let title = "";
		let action = "";
		let description = "";

		title = t(`New Invoice`);
		action = t(`Create`);
		description = t("Create an Invoice for {{contactName}}. Start by adding new line items to your Invoice ...", { contactName });

		return (
			<>
				<Modal show={show} onHide={this.onClose} title={title}>
					<div className="mb-pr">
						{!loading && (
							<>
								<div className="mb-pr__description">{description}</div>

								<>{this.renderLineItems()}</>

								<div className="mb-pr__actions">
									<div className={`mb-button fnctst-send-payment-button ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onCreateInvoice}>
										{action}
									</div>
								</div>
							</>
						)}
						{loading && (
							<div className="mb-pr__loader">
								<div className="mb-pr__loader__title">{t("Creating ...")}</div>
								{this.renderLoader()}
							</div>
						)}
					</div>
				</Modal>
				<Alert
					type="error"
					show={hasLineItemError}
					title={t("Error updating line items")}
					confirm={t("OK")}
					onClose={() => {
						this.update({ hasLineItemError: false });
					}}
				>
					{t("One of your line items does not have a Label or Amount specified.")}
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Invoice);
