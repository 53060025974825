import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import posed, { PoseGroup } from "react-pose";
import ContentLoader from "react-content-loader";
import Select from "react-select";
import { withTranslation, Trans } from "react-i18next";

import { WIDGET_NAME } from "../../../constants/WidgetConstants";
import { WIDGET_REVIEW_CONFIG, WIDGET_CONFIG } from "../../../constants/WidgetConstants";

import withLocation from "../../../components/common/WithLocation";
import UnsavedChanges from "../../../components/common/UnsavedChanges";
import { ShowcaseList, ShowcaseRow } from "../../../components/common/ShowcaseList";
import EditCustomPositioningModal from "./EditCustomPositioningModal";
import ReviewsWidgetPreview from "./ReviewsWidgetPreview";

import WidgetConfigService from "../../../services/WidgetConfigService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/reviews-config.css";

export const Field = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

const DEFAULT = "default";

class ReviewsConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Widget Data
			isActive: false,

			// Reviews Config Keys
			forceMode: "",
			reviewsCount: 0,
			starsThreshold: 0,
			googleAnalytics: {},
			trackingId: "",
			zIndex: null,
			zIndexImportant: false,
			hideOnDevice: false,

			positioning: {}, // the entire positioning object from the widget config
			position: "", // where the widget should be position on the page (bottom-left, ...)
			devicePosition: WIDGET_CONFIG.positioningModes[0], // The screen size, desktop or mobile
			offset: { top: 0, bottom: 0, left: 0, right: 0 }, // The custom offset for the currently selecte device and position
			selectedPositionUrl: WIDGET_CONFIG.offsetModes.default.value, // Custom positioning by url
			showEditCustomUrlModal: false, // To show the add custom url modal

			// Regular state keys
			changesMade: false,
			locationId: null,
			widgetId: null,
			loading: true
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async () => {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	};

	async componentDidMount() {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	}

	async resetComponent() {
		try {
			let location = UserService.getActiveLocation();

			let widget = await WidgetConfigService.findWidget({
				locationId: location.id,
				name: WIDGET_NAME.reviewsWidget
			});

			let { force_mode, positioning, reviews_count, stars_threshold, googleAnalytics, zIndex, zIndexImportant } = widget.config;
			let { devicePosition, selectedPositionUrl } = this.state;

			if (!positioning) {
				positioning = {
					desktop: {
						default: {
							position: "bottom-right",
							hide: false,
							offset: {
								top: 0,
								bottom: 0,
								left: 0,
								right: 0
							}
						}
					},
					mobile: {
						default: {
							position: "bottom-right",
							hide: false,
							offset: {
								top: 0,
								bottom: 0,
								left: 0,
								right: 0
							}
						}
					}
				};
			}

			let positionOptions = WIDGET_REVIEW_CONFIG.position;

			// Create the readable label for the position
			let positionOption = positionOptions.find(p => p.value === positioning[devicePosition.value][selectedPositionUrl].position);

			let offset = positioning[devicePosition.value][selectedPositionUrl].offset;
			let hideOnDevice = positioning[devicePosition.value][selectedPositionUrl].hide;

			await this.update({
				isActive: widget.status === "active",

				// Reviews widget keys
				forceMode: force_mode,
				position: positionOption,
				positioning,
				reviewsCount: reviews_count,
				starsThreshold: stars_threshold,
				offset: offset || { top: 0, bottom: 0, left: 0, right: 0 },
				googleAnalytics: googleAnalytics || {},
				trackingId: googleAnalytics && googleAnalytics.trackingId ? googleAnalytics.trackingId : "",
				zIndex: zIndex || null,
				zIndexImportant,
				hideOnDevice: hideOnDevice,

				showEditCustomUrlModal: false,

				// Regular state keys
				locationId: location.id,
				widgetId: widget.id,
				changesMade: false
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	onCancel = () => {
		this.resetComponent();
	};

	onOffsetChange = (field, value) => {
		let { offset, selectedPositionUrl, devicePosition, positioning } = this.state;

		Object.assign(offset, { [field]: value });

		positioning[devicePosition.value][selectedPositionUrl].offset[field] = value;

		this.update({
			offset,
			positioning,
			changesMade: true
		});
	};

	onShowCustomPositioningModal = () => {
		this.update({ showEditCustomUrlModal: true, selectedPositionUrl: DEFAULT });
	};

	onAddCustomPositioningUrl = (url, oldUrl) => {
		let { positioning, devicePosition, selectedPositionUrl } = this.state;

		let positionOptions = WIDGET_REVIEW_CONFIG.position;
		let position = positionOptions.find(p => p.value === positioning[devicePosition.value][DEFAULT].position);
		let offset = positioning[devicePosition.value][DEFAULT].offset;

		positioning[devicePosition.value][url] = {
			position: position.value,
			hide: false,
			offset: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			}
		};

		// If this positioning url is being modified, keep the same positioning as before
		if (oldUrl === selectedPositionUrl && positioning[devicePosition.value][selectedPositionUrl] && selectedPositionUrl !== DEFAULT) {
			positioning[devicePosition.value][url] = positioning[devicePosition.value][selectedPositionUrl];
			position = positionOptions.find(p => p.value === positioning[devicePosition.value][url].position);
			offset = positioning[devicePosition.value][url].offset;
		}

		if (oldUrl !== DEFAULT) {
			delete positioning[devicePosition.value][oldUrl];
		}

		this.update({ positioning, position, offset, showEditCustomUrlModal: false, changesMade: true, selectedPositionUrl: url });
	};

	onEditCustomPositioning = (index, url) => {
		this.update({ selectedPositionUrl: url });
		this.onShowCustomPositioningModal();
	};

	onDeleteCustomPositioning = (index, event, url) => {
		event.preventDefault();
		event.stopPropagation();

		// Can't delete defualt
		if (url && url.toLowerCase() === DEFAULT) {
			return;
		}

		let { positioning, devicePosition } = this.state;

		if (!positioning[devicePosition.value][url]) {
			return;
		}

		delete positioning[devicePosition.value][url];

		let positionOptions = WIDGET_REVIEW_CONFIG.position;
		let position = positionOptions.find(p => p.value === positioning[devicePosition.value][DEFAULT].position);
		let offset = positioning[devicePosition.value][DEFAULT].offset;

		this.update({
			positioning,
			position: position,
			offset,
			changesMade: true,
			selectedPositionUrl: DEFAULT
		});
	};

	onCustomPositioningSelected = (index, url) => {
		let { offset, position, devicePosition, positioning, hideOnDevice } = this.state;

		offset = positioning[devicePosition.value][url].offset;
		position = positioning[devicePosition.value][url].position;
		hideOnDevice = positioning[devicePosition.value][url].hide;

		let positionOptions = WIDGET_REVIEW_CONFIG.position;
		position = positionOptions.find(p => p.value === position);

		this.update({
			positioning,
			offset,
			position,
			hideOnDevice,
			selectedPositionUrl: url
		});
	};

	onDevicePositionChange = option => {
		let { positioning } = this.state;

		let offset = positioning[option.value][DEFAULT].offset;
		let positionValue = positioning[option.value][DEFAULT].position;
		let hideOnDevice = positioning[option.value][DEFAULT].hide;

		let position = WIDGET_REVIEW_CONFIG.position.find(p => p.value === positionValue);

		this.update({ position, offset, devicePosition: option, selectedPositionUrl: DEFAULT, hideOnDevice });
	};

	onToggleHideWidget = (field, value) => {
		let { positioning, devicePosition, selectedPositionUrl, hideOnDevice } = this.state;

		positioning[devicePosition.value][selectedPositionUrl].hide = value;
		hideOnDevice = value;

		this.update({
			hideOnDevice,
			positioning,
			changesMade: true
		});
	};

	onPositionChange = option => {
		let { positioning, devicePosition, selectedPositionUrl } = this.state;

		positioning[devicePosition.value][selectedPositionUrl].position = option.value;

		this.update({
			position: option,
			positioning,
			changesMade: true
		});
	};

	onSave = async () => {
		let { isActive, widgetId, forceMode, positioning, reviewsCount, starsThreshold, googleAnalytics, trackingId, zIndex, zIndexImportant } = this.state;
		let { t } = this.props;

		let config = {};
		config.force_mode = forceMode;
		config.positioning = positioning;

		// Force reviews count to be between 20 and 100
		reviewsCount = Math.max(20, reviewsCount);
		reviewsCount = Math.min(100, reviewsCount);
		config.reviews_count = reviewsCount;

		// Force stars threshold it to be a number between 1 and 5
		starsThreshold = Math.max(1, starsThreshold);
		starsThreshold = Math.min(5, starsThreshold);
		config.stars_threshold = starsThreshold;

		if (!googleAnalytics) {
			googleAnalytics = {};
		}

		Object.assign(googleAnalytics, { trackingId });
		config.googleAnalytics = googleAnalytics;

		if (parseInt(zIndex) >= 0) {
			config.zIndex = parseInt(zIndex) || 0;
		}
		config.zIndexImportant = zIndexImportant;

		let newStatus = isActive ? "active" : "inactive";

		config = JSON.stringify(config);

		try {
			await WidgetConfigService.updateWidget({ widgetId, config, status: newStatus });

			// Let the parent component know we have updated
			if (this.props.onUpdate) {
				this.props.onUpdate();
			}

			await this.resetComponent();

			ToastService.info(t("Reviews config updated!"));
		} catch (error) {
			console.log(error);
			ToastService.error(t("Error updating the config!"));
		}
	};

	renderContentLoader() {
		let renderField = multiplier => {
			let offset = multiplier * 120;

			return (
				<>
					<rect x="30" y={20 + offset} rx="0" ry="0" width="150" height="10" />
					<rect x="30" y={40 + offset} rx="0" ry="0" width="350" height="10" />
					<rect x="500" y={20 + offset} rx="0" ry="0" width="40" height="20" />
					<rect x="30" y={100 + offset} rx="0" ry="0" width="550" height="2" />
				</>
			);
		};

		return (
			<ContentLoader speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
				{renderField(0)}
				{renderField(1)}
				{renderField(2)}
				{renderField(3)}
				{renderField(4)}
				<rect x="600" y="20" rx="0" ry="0" width="300" height="250" />
			</ContentLoader>
		);
	}

	Selector = ({ id, value, options, onChange }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return <Select id={id} onChange={onChange} value={value} isClearable={false} options={options} isDisabled={!allowUpdate} />;
	};

	Switch = ({ field, checked, onChange }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				onChange={newValue => {
					onChange(field, newValue);
				}}
				disabled={!allowUpdate}
			/>
		);
	};

	onChange = (field, value) => {
		this.update({
			[field]: value,
			changesMade: true
		});
	};

	renderPreview = () => {
		const {
			// Web Chat Config Keys
			forceMode,

			// Other keys related to position, eg showing the currently selected offset values
			positioning,
			position,

			// Regular state keys
			changesMade
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<div className="rwc__preview">
				<ReviewsWidgetPreview forceMode={forceMode} positioning={positioning} position={position} />
				<PoseGroup>
					{changesMade && allowUpdate && (
						<Field key="updateChanges" className="rwc__preview__update">
							<div className="mb-button" onClick={this.onSave}>
								{t("Save")}
							</div>
							<div className="mb-button mb-button--cancel" onClick={this.onCancel}>
								{t("Cancel")}
							</div>
						</Field>
					)}
				</PoseGroup>
			</div>
		);
	};

	render() {
		const {
			// Status indicator for webchat
			isActive,

			// Web Chat Config Keys
			forceMode,
			reviewsCount,
			starsThreshold,
			googleAnalytics,
			trackingId,
			zIndex,
			zIndexImportant,
			hideOnDevice,

			// Other keys related to position, eg showing the currently selected offset values
			positioning,
			position,
			offset,
			devicePosition,
			showEditCustomUrlModal,
			selectedPositionUrl,

			// Regular state keys
			changesMade,
			loading
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		// Get options for selects
		let positionOptions = WIDGET_REVIEW_CONFIG.position;
		let forceModeOptions = WIDGET_REVIEW_CONFIG.forceMode;

		// Create the readable label for the force mode
		let forceModeLabel = forceModeOptions.find(f => f.value === forceMode);
		forceModeLabel = typeof forceModeLabel === "undefined" ? t("Unknown Force Mode") : forceModeLabel.label;

		if (!LocationService.isReviewsWidgetPermissible()) {
			return <Redirect to="/settings" />;
		}

		if (loading) {
			return this.renderContentLoader();
		}

		return (
			<div className="rwc">
				<div className="rwc__options">
					<PoseGroup>
						<Field key="isActive" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Reviews Widget")}</div>
								<div className="rwc__options__field__label__description">
									{t("Enable Reviews Widget so your visitors can see your business reviews right on your website.")}
								</div>
							</div>
							<this.Switch field="isActive" checked={isActive} onChange={this.onChange} />
						</Field>
						<Field key="forceMode" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Force Mode")}</div>
								<div className="rwc__options__field__label__description">
									{t("If you would like to force the appearance to be larger or smaller regardless of screen size.")}
								</div>
							</div>
							<div className="rwc__options__field__select">
								<this.Selector
									id="forceMode"
									value={{ label: forceModeLabel, value: forceModeLabel }}
									options={forceModeOptions}
									onChange={option => {
										this.onChange("forceMode", option.value);
									}}
								/>
							</div>
						</Field>
						<Field key="reviewsCount" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Review Count")}</div>
								<div className="rwc__options__field__label__description">{t("A number between 20 and 100 representing the number of reviews to display.")}</div>
							</div>
							<div className="rwc__options__field__input">
								<input
									name="reviewsCount"
									id="reviewsCount"
									type="text"
									onChange={event => this.onChange([event.target.name], event.target.value)}
									value={reviewsCount}
									className="Common__input"
									disabled={!allowUpdate}
								/>
							</div>
						</Field>
						<Field key="starsThreshold" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Stars Threshold")}</div>
								<div className="rwc__options__field__label__description">
									{t("A number between 1 and 5 representing the minimal rating needed for a review to be displayed.")}
								</div>
							</div>
							<div className="rwc__options__field__input">
								<input
									name="starsThreshold"
									id="starsThreshold"
									type="text"
									onChange={event => this.onChange([event.target.name], event.target.value)}
									value={starsThreshold}
									className="Common__input"
									disabled={!allowUpdate}
								/>
							</div>
						</Field>
						{googleAnalytics && (
							<Field key="trackingId" className="rwc__options__field">
								<div className="rwc__options__field__label">
									<div className="rwc__options__field__label__title">{t("Tracking Id")}</div>
									<div className="rwc__options__field__label__description">{t("Add your Google Analytics tracking Id to see widget analytics.")}</div>
								</div>
								<div className="rwc__options__field__input">
									<input
										maxLength={65}
										name="trackingId"
										id="trackingId"
										onChange={e => this.onChange("trackingId", e.target.value)}
										value={trackingId}
										className="Common__input"
										disabled={!allowUpdate}
										placeholder="UA-000000-0"
									/>
								</div>
							</Field>
						)}

						<Field key="zIndex" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">Z Index</div>
								<div className="rwc__options__field__label__description">{t("The Z Index positioning value.")}</div>
							</div>
							<div className="rwc__options__field__input">
								<input
									maxLength={15}
									name="zIndex"
									id="zIndex"
									type="number"
									onChange={e => this.onChange("zIndex", e.target.value)}
									value={zIndex}
									className="Common__input"
									disabled={!allowUpdate}
									placeholder="10"
								/>
							</div>
						</Field>

						<Field key="zIndexImportant" className="rwc__options__field">
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">Z Index Important</div>
								<div className="rwc__options__field__label__description">{t("If the z-index css should use the !important rule.")}</div>
							</div>
							<this.Switch field="zIndexImportant" checked={zIndexImportant} onChange={this.onChange} />
						</Field>

						<Field key="positioningOptions" className="rwc__options__field rwc__options__field--positioning">
							<div className="rwc__options__field__label rwc__options__field__label--center">
								<div className="rwc__options__field__label__title rwc__options__field__label__title--large">{t("Widget Positioning")}</div>
								<div className="rwc__options__field__label__description">{t("Positioning on desktop, mobile, and on custom web pages.")}</div>
							</div>
							<br></br>
							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Device")}</div>
							</div>
							<div className="rwc__options__field__select">
								<this.Selector id="device-position" value={devicePosition} options={WIDGET_CONFIG.positioningModes} onChange={this.onDevicePositionChange} />
							</div>
							<ShowcaseList
								key="customPositioning"
								title={t("Positioning on Web Pages")}
								label={t("Url")}
								onAddClicked={this.onShowCustomPositioningModal}
								onEditClicked={this.onEditCustomPositioning}
								onDeleteClicked={this.onDeleteCustomPositioning}
								onItemClicked={this.onCustomPositioningSelected}
								allowUpdate={allowUpdate}
								orderable={false}
								noBorder={true}
							>
								{Object.keys(positioning[devicePosition.value]).map((url, index) => {
									return (
										<ShowcaseRow
											rowIndex={index}
											key={url}
											title={url}
											titleToTitleCase={false}
											disableTitles={[WIDGET_CONFIG.offsetModes.default.value]}
											selectedTitle={selectedPositionUrl}
											subtitle={""}
											description={index === 0 ? t("The default positioning for all webpages.") : ""}
											footer={""}
										/>
									);
								})}
							</ShowcaseList>

							<EditCustomPositioningModal
								show={showEditCustomUrlModal}
								url={selectedPositionUrl === DEFAULT ? "" : selectedPositionUrl}
								onHide={() => this.update({ showEditCustomUrlModal: false })}
								onSave={this.onAddCustomPositioningUrl}
							/>

							<Field key="hideOnDevice" className="rwc__options__field rwc__options__field--no-border">
								<div className="rwc__options__field__label">
									<div className="rwc__options__field__label__title">
										{t("Hide on {{device}}", { device: WIDGET_CONFIG.positioningModes[0].value === devicePosition.value ? "Desktop" : "Mobile" })}
									</div>
									<div className="rwc__options__field__label__description">
										{t("Stop showing the widget on {{device}}.", {
											device: WIDGET_CONFIG.positioningModes[0].value === devicePosition.value ? "desktop" : "mobile"
										})}
									</div>
								</div>
								<this.Switch field="hideOnDevice" checked={hideOnDevice} onChange={this.onToggleHideWidget} />
							</Field>

							<Field key="widgetPosition" className="rwc__options__field rwc__options__field--no-border">
								<div className="rwc__options__field__label">
									<div className="rwc__options__field__label__title">{t("Position")}</div>
									<div className="rwc__options__field__label__description">{t("Where on the website you would like the widget to hover.")}</div>
								</div>
								<div className="rwc__options__field__select">
									<this.Selector id="position" value={position} options={positionOptions} onChange={this.onPositionChange} />
								</div>
							</Field>

							<div className="rwc__options__field__label">
								<div className="rwc__options__field__label__title">{t("Offset")}</div>
								<div className="rwc__options__field__label__description">
									{t("Adjust the offset of the Reviews Widget to give more padding from the left/right or top/bottom.")}
								</div>
							</div>
							<div className="rwc__options__field__input">
								<div className="rwc__options__field__input__label">{t("Top")}</div>
								<input
									maxLength={65}
									name="top"
									id="topPosition"
									onChange={e => this.onOffsetChange("top", e.target.value)}
									value={offset.top}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="rwc__options__field__input__label">{t("Bottom")}</div>
								<input
									maxLength={65}
									name="bottom"
									id="bottomPosition"
									onChange={e => this.onOffsetChange("bottom", e.target.value)}
									value={offset.bottom}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="rwc__options__field__input__label">{t("Left")}</div>
								<input
									maxLength={65}
									name="left"
									id="leftPosition"
									onChange={e => this.onOffsetChange("left", e.target.value)}
									value={offset.left}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="rwc__options__field__input__label">{t("Right")}</div>
								<input
									maxLength={65}
									name="right"
									id="rightPosition"
									onChange={e => this.onOffsetChange("right", e.target.value)}
									value={offset.right}
									className="Common__input"
									disabled={!allowUpdate}
								/>
							</div>
						</Field>
					</PoseGroup>
				</div>
				{this.renderPreview()}
				{changesMade && <UnsavedChanges />}
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ReviewsConfig)));
