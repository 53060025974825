import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Collapse } from "react-bootstrap";
import ReactSwitch from "react-switch";
import { Redirect } from "react-router-dom";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import ContentLoader from "react-content-loader";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Select from "react-select";
import { Tag, Eye, EyeOff, Share2, Trash, Edit2, CornerDownRight } from "lucide-react";
import ReactTooltip from "react-tooltip";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ReviewSiteService from "../../services/ReviewSiteService";
import ReviewService from "../../services/ReviewService";
import { CsvService } from "../../services/CsvService";
import ToastService from "../../services/ToastService";

import Checkbox from "../../components/common/Checkbox";
import StarRating from "../../components/common/StarRating";
import Spinners from "../../components/common/Spinners";
import AttributorDropdown from "../../components/common/AttributorDropdown";
import Dropdown from "../../components/common/Dropdown";
import StarSelection from "../../components/common/StarSelection";
import withLocation from "../../components/common/WithLocation";
import Alert from "../../components/common/Alert";
import Modal from "../../components/common/DHModal";
import Action from "../../components/common/Action";
import ReviewResponse from "./ReviewResponse";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";

import { ShareableSites } from "../../constants/Review";
import { REVIEW_SITE_IDS_BY_NAME } from "../../constants/ReviewSitesConstants";
import GROUP_PERMISSIONS from "../../constants/GroupPermissions";

import "../../styles/css/scenes/leaderboard.css";
import "../../styles/css/scenes/reviews.css";
import "../../App.css";

class Reviews extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		let { minStars, maxStars, site, reviewAttributor, currentRange, needsResponse } = queryString.parse(this.props.location.search);

		this.state = {
			authToken: UserService.get().auth_token,
			locationData: null,
			requesterData: [],
			recentReviews: [],
			integrations: null,
			reviewCount: 0,
			reviewsPerPage: 25,
			reviewPage: 0,
			addFilterModalOpen: false,
			saving: false,
			selectedReviewAttributor: typeof reviewAttributor !== "undefined" ? reviewAttributor : -1,
			reviewAttributor: typeof reviewAttributor !== "undefined" ? reviewAttributor : -1,
			range: currentRange ? currentRange : 0,
			currentRange: currentRange ? currentRange : 0,
			minStars: 0,
			currentMinStars: minStars ? minStars : 0,
			maxStars: 5,
			currentMaxStars: maxStars ? maxStars : 5,
			selectedSiteValue: site ? site : 0,
			currentSiteValue: site ? site : 0,
			allReviewSites: [],
			activeReviewSites: [],
			activeFilter: false,
			filterString: "",
			responseSaveError: false,
			responseSaveErrorText: "",
			dateArray: [
				{ id: 0, name: t("All Time") },
				{ id: 1, name: t("This Week") },
				{ id: 2, name: t("This Month") },
				{ id: 3, name: t("Last Month") }
			],
			loading: true,
			showResponseDeleteSweetAlert: false,
			openResponseId: 0,
			propagateReviewModalOpen: false,
			reviewModalHeader: "",
			reviewModalBody: "",
			shareToSiteId: null,
			showPropagateSweetAlert: false,
			showPropagateErrorSweetAlert: false,
			propagateReviewLoading: false,
			needsResponseFilter: needsResponse || false,
			staticReviewFilterTopCss: 138,
			staticReviewFilterScrollThreshold: 150,
			staticReviewFilterScrollTop: "6px",
			confirmDeleteResponse: false,
			confirmDeleteResponseId: null,
			reviewsCsv: [],
			showCsvModal: false,
			csvDataLoading: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		let limit = this.state.reviewsPerPage;
		let offset = this.state.reviewPage * limit;
		let minRating = this.state.currentMinStars;
		let maxRating = this.state.currentMaxStars;
		let requester = this.state.selectedReviewAttributor;
		let site = this.state.selectedSiteValue;
		let range = this.state.range;

		this.refreshLocationData();
		this.refreshReviewData(limit, offset, minRating, maxRating, requester, site, range);
		this.updateAllReviewsData();
		window.addEventListener("scroll", () => this.handleScroll());
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handlePrevPageClick = () => {
		if (this.state.reviewPage > 0) {
			let newPage = this.state.reviewPage - 1;
			let limit = this.state.reviewsPerPage;
			let offset = newPage * limit;
			let minRating = this.state.minStars;
			let maxRating = this.state.maxStars;
			let requester = this.state.selectedReviewAttributor;
			let reviewSite = this.state.selectedSiteValue;
			let range = this.state.range;

			this.setState({ reviewPage: newPage });
			this.refreshReviewData(limit, offset, minRating, maxRating, requester, reviewSite, range);
		}
	};

	handleNextPageClick = () => {
		if ((this.state.reviewPage + 1) * this.state.reviewsPerPage < this.state.reviewCount) {
			let newPage = this.state.reviewPage + 1;
			let limit = this.state.reviewsPerPage;
			let offset = newPage * limit;
			let minRating = this.state.minStars;
			let maxRating = this.state.maxStars;
			let requester = this.state.selectedReviewAttributor;
			let reviewSite = this.state.selectedSiteValue;
			let range = this.state.range;

			this.setState({ reviewPage: newPage });
			this.refreshReviewData(limit, offset, minRating, maxRating, requester, reviewSite, range);
		}
	};

	handleSetRequesterClick = async event => {
		let user = UserService.get();
		let authToken = "";

		if (user !== null) {
			authToken = user.auth_token;
		}

		let reviewId = event.target.getAttribute("data-review");
		let requesterId = event.target.getAttribute("data-requester");

		await ReviewService.setRequester({
			reviewId: reviewId,
			requesterId
		});

		let limit = this.state.reviewsPerPage;
		let offset = this.state.reviewPage * limit;
		let minRating = this.state.minStars;
		let maxRating = this.state.maxStars;
		let requesterData = this.state.selectedReviewAttributor;
		let reviewSite = this.state.selectedSiteValue;
		let range = this.state.range;

		this.refreshReviewData(limit, offset, minRating, maxRating, requesterData, reviewSite, range);
	};

	handleResetRequesterClick = async event => {
		let user = UserService.get();
		let authToken = "";

		if (user !== null) {
			authToken = user.auth_token;
		}

		let reviewId = event.target.getAttribute("data-review");

		await ReviewService.setRequester({
			reviewId: reviewId
		});

		let limit = this.state.reviewsPerPage;
		let offset = this.state.reviewPage * limit;
		let minRating = this.state.minStars;
		let maxRating = this.state.maxStars;
		let requester = this.state.selectedReviewAttributor;
		let reviewSite = this.state.selectedSiteValue;
		let range = this.state.range;

		this.refreshReviewData(limit, offset, minRating, maxRating, requester, reviewSite, range);
	};

	onLocationChanged = async () => {
		if (UserService.get()) {
			// Make sure we reset the page when a location change is made
			let newReviewPage = 0;

			this.setState({ reviewPage: newReviewPage });

			let limit = this.state.reviewsPerPage;
			let offset = newReviewPage * limit;
			let minRating = this.state.minStars;
			let maxRating = this.state.maxStars;
			let requester = this.state.selectedReviewAttributor;
			let reviewSite = this.state.selectedSiteValue;
			let range = this.state.range;

			this.refreshLocationData();
			this.refreshReviewData(limit, offset, minRating, maxRating, requester, reviewSite, range);
		} else {
			this.setState({ dummyUpdate: true });
		}
	};

	async refreshLocationData() {
		let user = UserService.get();
		if (user) {
			let location = UserService.getActiveLocation();

			try {
				// Make an API call to get the location data
				let locationResponse = await LocationService.fetchLocation(location.id);
				let requesterList = await LocationService.requesterList(location.id);
				let integrations = await LocationService.hasIntegrations(location.id);

				let currentReviewSites = [];
				try {
					currentReviewSites = locationResponse && locationResponse.review_sites === "" ? [] : JSON.parse(locationResponse.review_sites);
				} catch (error) {
					console.log(`Error parsing json: ${error}`);
				}

				// Make an API call to get the requester list
				this.setState({
					locationData: locationResponse,
					activeReviewSites: currentReviewSites || [],
					requesterData: requesterList || [],
					integrations: integrations
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			this.setState({ dummyUpdate: true });
		}
	}

	async refreshReviewData(limit, offset, minStars, maxStars, requester, siteId, dateRange) {
		try {
			let user = UserService.get();

			if (!user) {
				this.setState({ dummyUpdate: true });
				return true;
			}

			let needsResponseFilter = this.state.needsResponseFilter;

			// Make an API call to get the total review count
			let count = await ReviewService.count({
				locationId: UserService.getActiveLocation().id,
				minRating: minStars,
				maxRating: maxStars,
				requesterId: requester,
				reviewSite: siteId,
				range: dateRange,
				needsResponse: needsResponseFilter ? 1 : 0
			});

			this.setState({ reviewCount: count });

			// Make an API call to get the recent reviews
			let reviews = await ReviewService.fetch({
				locationId: UserService.getActiveLocation().id,
				minRating: minStars,
				maxRating: maxStars,
				limit: limit,
				offset: offset,
				requesterId: requester,
				reviewSite: siteId,
				range: dateRange,
				needsResponse: needsResponseFilter ? 1 : 0
			});

			if (reviews.length === 0 && this.state.reviewPage > 0) {
				let currPage = this.state.reviewPage;
				this.setState({
					reviewPage: currPage - 1
				});

				this.refreshReviewData(limit, (currPage - 1) * limit, minStars, maxStars, requester, siteId, dateRange);
				this.setState({ loading: false });
				return;
			}

			let isActive = this.isFilterActive();

			this.setState({
				recentReviews: reviews,
				activeFilter: isActive
			});
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	}

	//For displaying current filters (future)

	// createFilterString = () => {
	// 	var filterStr = "";

	// 	if (this.state.minStars > 0) {
	// 		filterStr = filterStr + "Min Stars (" + this.state.minStars + ") ";
	// 	}
	// 	this.state.maxStars < 5 ? (filterStr = filterStr + "Max Stars (" + this.state.maxStars + ")") : "";

	// 	return filterStr;
	// };

	isFilterActive = () => {
		const { minStars, maxStars, range, selectedSiteValue, selectedReviewAttributor } = this.state;

		return minStars > 0 || maxStars < 5 || range !== 0 || selectedReviewAttributor !== -1 || selectedSiteValue !== 0;
	};

	handleModalOnHide = () => {
		this.handleFiltersOnCancel();
	};

	async onPostPropagatedReview(reviewId, shareToSiteId) {
		let { t } = this.props;

		this.setState({
			propagateReviewLoading: true
		});
		const review = this.state.reviewModalBody;
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";

		let success = await ReviewService.propagateReview({ reviewId, review, locationId, shareToSiteId });

		if (!success) {
			this.setState({ responseSaveError: true, responseSaveErrorText: t("Sorry, an error was encountered while posting the review. Please try again.") });

			this.setState({
				showPropagateErrorSweetAlert: true,
				propagateReviewLoading: false
			});
			this.handlePropReviewModalOnHide();
			return;
		}

		this.handlePropReviewModalOnHide();
		this.setState({
			showPropagateSweetAlert: true,
			propagateReviewLoading: false
		});
	}

	handlePropReviewModalOnHide = () => {
		this.setState({
			propagateReviewModalOpen: false,
			reviewModalHeader: "",
			reviewModalBody: "",
			propagateReviewId: null,
			saving: false
		});
	};

	handleOnPropagateReview(siteId, siteName, reviewFrom, reviewId, content, postedBy, rating, maxRating, reviewSiteId, link = "") {
		let { t } = this.props;

		let modalBody = "";
		if (content !== "") {
			content = `"${content}"`;
		}
		switch (reviewSiteId) {
			case REVIEW_SITE_IDS_BY_NAME.direct:
				modalBody = `${content} (${rating}/${maxRating} ${t("Rating")}) - ${t("Anonymous as reviewed directly")}`;
				break;
			case REVIEW_SITE_IDS_BY_NAME.custom:
				let domainRegex = /^https?:\/\/[www]{0,3}\.?(\w{1,}\.\w{2,})\//g;
				let match = domainRegex.exec(link);
				reviewFrom = match[1]; //review is from the website domain eg. homestars.com
				break;
			default:
				modalBody =
					`${content} (${rating}/${maxRating} ${t("Rating")}) - ` +
					t("{{postedBy}} as reviewed on {{reviewFrom}}", { postedBy: postedBy, reviewFrom: reviewFrom });
		}
		this.setState({
			propagateReviewModalOpen: true,
			reviewModalHeader: t("Post on {{theSiteName}}", { theSiteName: siteName }),
			reviewModalBody: modalBody,
			shareToSiteId: siteId,
			propagateReviewId: reviewId
		});
	}

	async onDownloadReviews() {
		let { currentMinStars, currentMaxStars, reviewAttributor, currentSiteValue, currentRange, needsResponseFilter } = this.state;

		this.setState({ csvDataLoading: true, showCsvModal: true });

		let data = await ReviewService.fetch({
			locationId: UserService.getActiveLocation().id,
			minRating: currentMinStars,
			maxRating: currentMaxStars,
			limit: 1000,
			offset: 0,
			requesterId: reviewAttributor,
			reviewSite: currentSiteValue,
			range: currentRange,
			needsResponse: needsResponseFilter ? 1 : 0
		});

		let locationName = UserService.getActiveLocation().name;
		data = data.map(review => {
			return {
				Location: locationName,
				Site: ReviewSiteService.getSiteNameFromId(review.site_id),
				"Posted By": review.posted_by,
				"Posted At": review.posted_at,
				Rating: review.rating,
				"Max Rating": review.max_rating,
				Comment: review.comments,
				Reply: review.reply,
				"Replied By": review.replied_by,
				"Replied At": review.replied_at,
				"Replied With DH": review.replied_with_app ? "yes" : "no"
			};
		});

		this.setState({ reviewsCsv: data, csvDataLoading: false });
	}

	setUrlParams() {
		if (this.props.history) {
			let { currentMinStars, currentMaxStars, currentSiteValue, reviewAttributor, currentRange, needsResponseFilter } = this.state;

			let params = {};

			if (currentMinStars) {
				params.minStars = currentMinStars;
			}

			if (currentMaxStars) {
				params.maxStars = currentMaxStars;
			}

			if (currentSiteValue) {
				params.site = currentSiteValue;
			}

			if (reviewAttributor || reviewAttributor === 0) {
				params.reviewAttributor = reviewAttributor;
			}

			if (currentRange) {
				params.currentRange = currentRange;
			}

			if (needsResponseFilter) {
				params.needsResponse = needsResponseFilter;
			}

			params = new URLSearchParams(params);
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
		}
	}

	renderPropagateReviewModal() {
		return (
			<Modal
				show={this.state.propagateReviewModalOpen}
				onHide={() => this.handlePropReviewModalOnHide()}
				title={
					<>
						<span>
							<img src={"img/sites/site-icon-" + this.state.shareToSiteId + ".png"} className="reviews__propagate-reviews__site-logo" alt="site-icon" />
						</span>
						{this.state.reviewModalHeader}
					</>
				}
			>
				{!this.state.saving ? this.renderPropReviewModalBody() : <Spinners loading={true} size="25px" type="circle-fade" />}
			</Modal>
		);
	}

	renderPropReviewModalBody() {
		let { t } = this.props;

		return (
			<div className="reviews__propagate-reviews">
				<p className="">{this.state.reviewModalBody}</p>
				<br />

				<div className="mb-button mb-button--fit" onClick={() => this.onPostPropagatedReview(this.state.propagateReviewId, this.state.shareToSiteId)}>
					{t("Post")}
				</div>
			</div>
		);
	}

	renderShareableSitesDropdown(row) {
		let { t } = this.props;

		let showShareableSites = ShareableSites;
		//if facebook has been configured
		if (this.state.locationData !== null) {
			let metaData = LocationService.getAndParseMetaData(this.state.locationData);

			let fbPlaceIdNotSet = metaData && metaData.facebook_place_id === "" && this.state.integrations && !this.state.integrations.facebook;
			//Only checks facebook for now
			if (fbPlaceIdNotSet) {
				return <div />;
			}
		}

		showShareableSites = ShareableSites.filter(site => site.id !== row.site_id); //get all shareable to sites that aren't this site
		// let showShareableSites = ShareableSites; //get all shareable to sites that aren't this site

		//if it has been shared
		let alreadySharedTo = row.shared_to_sites ? JSON.parse(row.shared_to_sites) : [];
		alreadySharedTo = row.shared_to_sites === null ? [] : alreadySharedTo;

		if (showShareableSites.length === 0) {
			return <div />;
		}

		return (
			<div className="review-tag-dropdown">
				<div data-toggle="dropdown" className="reviews__single-review__header-right-control" data-tip data-for={`review-share-tip-${row.id}`}>
					<Share2 width={16} height={16} />
					<ReactTooltip id={`review-share-tip-${row.id}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
						{t("Share")}
					</ReactTooltip>
				</div>

				<ul className="dropdown-menu pull-left">
					{showShareableSites.map(reviewSite => (
						<li key={`${row.id}-${reviewSite.id}`}>
							{/* eslint-disable-next-line */}
							<a
								id="reviewSiteList"
								data-review={row.id}
								rel="noopener noreferrer"
								data-requester={reviewSite.id}
								onClick={() =>
									this.handleOnPropagateReview(
										reviewSite.id,
										reviewSite.name,
										this.state.allReviewSites.filter(site => site.id === row.site_id)[0].name,
										row.id,
										row.comments,
										row.posted_by,
										row.rating,
										row.max_rating,
										row.site_id,
										row.link
									)
								}
							>
								{reviewSite.name} {alreadySharedTo.includes(reviewSite.id) && <i className="fa text-right"> ({t("Already shared")})</i>}
							</a>
						</li>
					))}
				</ul>
			</div>
		);
	}

	handleFiltersOnAttributorChange = async requesterId => {
		await this.update({ reviewAttributor: requesterId });
		await this.handleFiltersOnUpdate();
	};

	handleSiteFilterDropdown = async reviewSite => {
		await this.update({ currentSiteValue: reviewSite });
		await this.handleFiltersOnUpdate();
	};

	handleFiltersOnRangeChange = async dateRange => {
		await this.update({ currentRange: dateRange });
		await this.handleFiltersOnUpdate();
	};

	handleFiltersOnUpdate = e => {
		this.setState(
			{
				addFilterModalOpen: false,
				selectedSiteValue: this.state.currentSiteValue,
				minStars: this.state.currentMinStars,
				maxStars: this.state.currentMaxStars,
				range: this.state.currentRange,
				selectedReviewAttributor: this.state.reviewAttributor
			},
			() => {
				this.setUrlParams();
				this.refreshReviewData(
					this.state.limit,
					this.state.offset,
					this.state.currentMinStars,
					this.state.currentMaxStars,
					this.state.reviewAttributor,
					this.state.currentSiteValue,
					this.state.currentRange
				)
					.catch(error => {
						console.log("refresh data failed: " + error);
						this.setState({ loading: false });
					})
					.then(() => {
						this.setState({ loading: false });
					});
			}
		);
	};

	handleFiltersOnCancel = e => {
		this.setState({
			addFilterModalOpen: false,
			currentSiteValue: this.state.selectedSiteValue,
			currentMinStars: this.state.minStars,
			currentMaxStars: this.state.maxStars,
			currentRange: this.state.range,
			reviewAttributor: this.state.selectedReviewAttributor
		});
	};

	handleFilterOnReset = e => {
		this.resetFilters();
		this.setState({ addFilterModalOpen: false });
	};

	handleMinStarClick = async e => {
		var val = e;
		if (e > this.state.currentMaxStars) {
			val = this.state.currentMaxStars;
		}
		await this.update({ currentMinStars: val });
		await this.handleFiltersOnUpdate();
	};

	handleMaxStarClick = async e => {
		var val = e;
		if (val < this.state.currentMinStars) {
			val = this.state.currentMinStars;
		}
		await this.update({ currentMaxStars: val });
		await this.handleFiltersOnUpdate();
	};

	resetFilters = async () => {
		await this.update({
			selectedReviewAttributor: -1,
			reviewAttributor: -1,
			minStars: 0,
			currentMinStars: 0,
			maxStars: 5,
			currentMaxStars: 5,
			selectedSiteValue: 0,
			currentSiteValue: 0,
			range: 0,
			currentRange: 0,
			reviewPage: 0,
			needsResponseFilter: false
		});

		let limit = this.state.reviewsPerPage;
		let offset = 0;

		this.setUrlParams();

		this.refreshReviewData(limit, offset, 0, 5, -1, 0, 0);
	};

	async updateAllReviewsData() {
		try {
			let data = await ReviewService.getAllReviewSites();

			if (!data) {
				return;
			}

			this.setState({ allReviewSites: data });
		} catch (error) {
			console.log(error);
		}
	}

	getReplyDate = reviewData => {
		return reviewData.slice(0, 10).replace(/-/gi, "/");
	};

	renderFilterForm() {
		let { t } = this.props;

		// Transform requesterData to assignableRequesters with label and value
		let assignableRequesters = this.state.requesterData.map(requester => {
			let fullName = UserService.createFullName({ firstName: requester.first_name, lastName: requester.last_name });
			return {
				label: fullName,
				value: requester.id
			};
		});

		// Add "all" and "none" options to assignableRequesters array (for dropdown)
		assignableRequesters.unshift({ label: t("All"), value: -1 });
		assignableRequesters.unshift({ label: t("None"), value: 0 });

		// Find the label for the current requester value
		let requesterLabel = assignableRequesters.find(requester => requester.value === this.state.reviewAttributor);
		requesterLabel = typeof requesterLabel === "undefined" ? t("Requester") : requesterLabel.label;

		// Transform allReviewSites to assignableReviewSites with label and value
		let assignableReviewSites = this.state.allReviewSites.map(site => ({
			label: site.name,
			value: site.id
		}));

		// Add "all" option to assignableReviewSites array (for dropdown)
		assignableReviewSites.unshift({ label: t("All"), value: 0 });

		// Find the label for the current site value
		let reviewSiteLabel = assignableReviewSites.find(reviewSite => reviewSite.value === this.state.currentSiteValue);
		reviewSiteLabel = typeof reviewSiteLabel === "undefined" ? t("Review Site") : reviewSiteLabel.label;

		// Transform dateArray to assignableDateRanges with label and value
		let assignableDateRanges = this.state.dateArray.map(dateRange => ({
			label: dateRange.name,
			value: dateRange.id
		}));

		// Find the label for the current date range value
		let dateRangeLabel = assignableDateRanges.find(dateRange => dateRange.value === this.state.currentRange);
		dateRangeLabel = typeof dateRangeLabel === "undefined" ? t("Date Range") : dateRangeLabel.label;

		return (
			<div className="form-horizontal">
				<div className="reviews__side--panel-content__form-group">
					<h3 className="reviews__filter__title col-sm-12">{t("Star Rating")}</h3>
					<div className="stars-flexbox-container">
						<div id="filter-stars-min" className="stars-flexbox-children">
							<span>
								<span>{t("Minimum")}</span>
							</span>
							<StarSelection defaultRating={this.state.currentMinStars} maxRating={5} handleStarClick={e => this.handleMinStarClick(e)} />
						</div>
						<div id="filter-stars-max" className="stars-flexbox-children">
							<span>
								<span>{t("Maximum")}</span>
							</span>
							<StarSelection defaultRating={this.state.currentMaxStars} maxRating={5} handleStarClick={e => this.handleMaxStarClick(e)} />
						</div>
					</div>
				</div>
				<div className="reviews__side--panel-content__form-group">
					<h3 className="reviews__filter__title col-sm-12">{t("Review Site")}</h3>
					<div key="site-dropdown" id="filter-form-sites" className="col-sm-12">
						<Select
							id="review-site-dropdown"
							name="review_site_dropdown"
							options={assignableReviewSites}
							value={{ label: reviewSiteLabel, value: this.state.currentSiteValue }}
							className=""
							placeholder={t("Review Site")}
							onChange={reviewSite => {
								this.handleSiteFilterDropdown(reviewSite.value);
							}}
						/>
					</div>
				</div>
				<div className="reviews__side--panel-content__form-group">
					<h3 className="reviews__filter__title col-sm-12">{t("Review Attribution")}</h3>
					<div key="attribute-dropdown" id="filter-form-attributes" className="col-sm-12">
						<Select
							id="requester-dropdown"
							name="requester_dropdown"
							options={assignableRequesters}
							value={{ label: requesterLabel, value: this.state.reviewAttributor }}
							className=""
							placeholder={t("Requester")}
							onChange={requester => {
								this.handleFiltersOnAttributorChange(requester.value);
							}}
						/>
					</div>
				</div>
				<div className="reviews__side--panel-content__form-group">
					<h3 className="reviews__filter__title col-sm-12">{t("Date Range")}</h3>
					<div key="date-dropdown" id="filter-form-dates" className="col-sm-12">
						<Select
							id="daterange-dropdown"
							name="date_range_dropdown"
							options={assignableDateRanges}
							value={{ label: dateRangeLabel, value: this.state.currentRange }}
							className=""
							placeholder={t("Date Range")}
							onChange={dateRange => {
								this.handleFiltersOnRangeChange(dateRange.value);
							}}
						/>
					</div>
				</div>
				<div className="reviews__side--panel-content__form-group">
					<div
						className="reviews__side--panel-content__needs-response__option"
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.setState({ needsResponseFilter: !this.state.needsResponseFilter }, this.handleFiltersOnUpdate);
						}}
					>
						<Checkbox
							id="needs_response_filter"
							name="needs_response_filter"
							checked={this.state.needsResponseFilter}
							className="reviews__side--panel-content__needs-response__option__checkbox"
						/>
						<div>
							<div className="reviews__side--panel-content__needs-response__option__label">{t("Needs response")}</div>
						</div>
					</div>
					{/*
					<div
						className="col-sm-12"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center"
						}}
					>
						<div>
							<b>{t("All Reviews")}</b>
						</div>
						<div style={{ marginRight: "5px", marginLeft: "5px" }}>
							<ReactSwitch
								id="needs_response_filter"
								onChange={() => this.setState({ needsResponseFilter: !this.state.needsResponseFilter }, this.handleFiltersOnUpdate)}
								checked={this.state.needsResponseFilter}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#4A90E2"
								type="text"
							/>
						</div>
						<div>
							<b>{t("Needs Response")}</b>
						</div>
					</div>*/}
				</div>
				<br />
				<div className="reviews__side--panel-content__form-group">
					<div className="col-sm-12">
						<div className="review__size--panel-content__reset">
							<span className="mb-button" onClick={this.handleFilterOnReset}>
								{t("Reset")}
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getReviewPageNumbers = () => {
		let currentPage = this.state.reviewPage + 1;
		let totalPages = this.state.reviewCount > 0 ? Math.ceil(this.state.reviewCount / this.state.reviewsPerPage) : 1;
		return currentPage + " / " + totalPages;
	};

	async resetReviews() {
		let limit = this.state.reviewsPerPage;
		let offset = this.state.reviewPage * limit;
		let minRating = this.state.minStars;
		let maxRating = this.state.maxStars;
		let requester = this.state.selectedReviewAttributor;
		let site = this.state.selectedSiteValue;
		let range = this.state.range;

		this.refreshLocationData();
		this.refreshReviewData(limit, offset, minRating, maxRating, requester, site, range);
		this.updateAllReviewsData();
	}

	handleScroll() {
		if (!this.reviewsFilterRef) {
			return;
		}
		if (window.pageYOffset < this.state.staticReviewFilterScrollThreshold) {
			this.reviewsFilterRef.style.top = this.state.staticReviewFilterTopCss - window.pageYOffset + "px";
			return;
		}
		this.reviewsFilterRef.style.top = this.state.staticReviewFilterScrollTop;
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", () => this.handleScroll());
	}

	async onPostResponseClick(reviewId, response, siteId) {
		let { t } = this.props;

		let location = UserService.getActiveLocation();
		let repliedBy = UserService.get() ? UserService.get().first_name + " " + UserService.get().last_name : "";
		if (siteId === REVIEW_SITE_IDS_BY_NAME.google || siteId === REVIEW_SITE_IDS_BY_NAME.facebook) {
			repliedBy = location.name ? location.name : "";
		}

		let success = await ReviewService.setResponse({ reviewId, repliedBy, reply: response, locationId: location.id });

		if (!success) {
			this.setState({ responseSaveError: true, responseSaveErrorText: t("Sorry, an error was encountered while responding to the review. Please try again.") });
			return;
		}

		this.handleReviewResponseOnClose(reviewId);
		this.resetReviews();
	}

	handleReviewResponseToggle(id) {
		this.setState({ ["responseOpen" + id]: !this.state["responseOpen" + id], openResponseId: id });
	}

	handleOnGoogleResponseDelete(id) {
		this.setState({ confirmDeleteResponse: true, confirmDeleteResponseId: id });
	}

	onDeleteReviewResponse(confirm) {
		if (confirm) {
			this.onReviewResponseDelete(this.state.confirmDeleteResponseId);
			this.setState({ showResponseDeleteSweetAlert: true, confirmDeleteResponse: false, confirmDeleteResponseId: null });
		} else {
			this.setState({ confirmDeleteResponse: false });
		}
	}

	async onReviewResponseDelete(reviewId) {
		let { t } = this.props;

		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";

		let success = await ReviewService.deleteResponse({ reviewId, locationId });

		if (!success) {
			this.setState({ responseSaveError: true, responseSaveErrorText: t("Sorry, an error was encountered while deleting the review. Please try again.") });
			return;
		}

		this.handleReviewResponseOnClose(reviewId);
		this.resetReviews();
	}

	handleOnFacebookResponseDelete(id) {
		try {
			this.onReviewResponseDelete(id);
			this.setState({ showResponseDeleteSweetAlert: true });
		} catch (error) {
			console.log(error.stack);
			this.setState({ showResponseDeleteSweetAlert: false });
		}
	}

	handleReviewResponseOnClose(id) {
		this.setState({ ["responseOpen" + id]: false });
	}

	onToggleShowWidgets = async ({ id, showOnWidgets, index }) => {
		let { t } = this.props;

		let response = await ReviewService.updateReview({ reviewId: id, showOnWidgets });
		if (!response) {
			ToastService.error(t("Error trying to toggle show on widgets. Please try again."));
			return;
		}

		let { recentReviews } = this.state;

		recentReviews = [...recentReviews];

		recentReviews[index].show_on_widgets = showOnWidgets;

		this.setState({ recentReviews });

		ToastService.info(t("Review updated."));
	};

	renderReviews = () => {
		const { locationData, reviewCount, recentReviews, integrations } = this.state;
		let { t } = this.props;

		let self = this;
		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let metaData = LocationService.getAndParseMetaData(this.state.locationData);

		if (reviewCount < 1 || !recentReviews || recentReviews.length < 1) {
			return (
				<h2 className="ibox-content forum-post-container reviews__container__no-reviews">
					{t("Sorry, no reviews were found")}{" "}
					<span role="img" aria-label="sad-face">
						😞
					</span>
				</h2>
			);
		}

		let isSuperAdminOrCS = UserService.isSuperAdminOrCustomerSuccess();

		return recentReviews.map((row, index) => {
			const isCustom = ReviewSiteService.isCustomReviewSite(row.site_id);
			const imgUrl = isCustom ? (row.icon_url ? row.icon_url : `img/sites/site-icon-9.png`) : `img/sites/site-icon-${row.site_id}.png`;

			let showResponseControls =
				user.GroupPermission.respond_reviews &&
				((row.site_id === REVIEW_SITE_IDS_BY_NAME.google && metaData && metaData.gmb_location_id && metaData.gmb_location_id !== "brightlocal") ||
					(row.site_id === REVIEW_SITE_IDS_BY_NAME.facebook && metaData && metaData.facebook_place_id && integrations && integrations.facebook));

			let isCreateResponseOpen = this.state["responseOpen" + row.id] && this.state.openResponseId === row.id;

			let showReplyButton =
				user.GroupPermission.respond_reviews &&
				locationData &&
				row.reply === "" &&
				((row.site_id === REVIEW_SITE_IDS_BY_NAME.google && metaData && metaData.gmb_location_id && metaData.gmb_location_id !== "brightlocal") ||
					(row.site_id === REVIEW_SITE_IDS_BY_NAME.facebook && metaData && metaData.facebook_place_id && integrations && integrations.facebook) ||
					row.site_id === REVIEW_SITE_IDS_BY_NAME.direct) &&
				!isCreateResponseOpen;

			let showReplyToUpdatedReviewButton =
				user.GroupPermission.respond_reviews &&
				locationData &&
				row.site_id === REVIEW_SITE_IDS_BY_NAME.direct &&
				row.reply !== "" &&
				row.posted_at > row.replied_at;
			let showVisitReviewLink =
				user.GroupPermission.respond_reviews &&
				locationData &&
				row.site_id === 3 &&
				metaData &&
				metaData.facebook_place_id &&
				integrations &&
				integrations.facebook;
			let isNotFacebook = row.site_id !== REVIEW_SITE_IDS_BY_NAME.facebook;

			let hasReplyAlready = row.reply !== "";
			let replyName = "";
			let replyText = "";
			let replyDate = "";

			// Reply Name
			if (hasReplyAlready && row.replied_by) {
				replyName = `${t("Response By {{name}}", { name: row.replied_by })}`;

				if (
					(row.site_id === REVIEW_SITE_IDS_BY_NAME.google || row.site_id === REVIEW_SITE_IDS_BY_NAME.facebook) &&
					row.responder_first_name &&
					row.responder_last_name
				) {
					replyName += ` (${row.responder_first_name} ${row.responder_last_name})`;
				}
			} else if (hasReplyAlready) {
				replyName = t("Response By Business");
			} else if (isCreateResponseOpen) {
				replyName = user.first_name + " " + user.last_name;
			}

			// Reply Text
			if (row.reply) {
				replyText = row.reply;
			}

			// Reply date
			if (row.replied_at) {
				replyDate = moment(row.replied_at).format("ddd, MMM DD, YYYY");
			} else {
				replyDate = moment().format("ddd, MMM DD, YYYY");
			}

			return (
				<div key={row.id} id={`single-review-${row.id}`} className="reviews__single-review fnctst-single-review">
					<div className="review-site-logo">
						<img src={imgUrl} alt="site-icon" />
					</div>
					<div className="reviews__single-review__content">
						<div id={`media-body-${row.id}`} className="review__media-body">
							<div className="reviews__single-review__header-right">
								<div id={`review-date-${row.id}`} className="review-date">
									{moment(row.posted_at).format("ddd, MMM DD, YYYY")}
									<br />
								</div>
								{isSuperAdminOrCS && (
									<div className="reviews__single-review__header-right__show-on-widgets">
										<div
											className="reviews__single-review__header-right-control"
											onClick={() => this.onToggleShowWidgets({ id: row.id, showOnWidgets: !row.show_on_widgets, index })}
											data-tip
											data-for={`review-show-widgets-${row.id}`}
										>
											{row.show_on_widgets && <Eye width={19} height={19} />}
											{!row.show_on_widgets && <EyeOff width={19} height={19} />}
										</div>
										<ReactTooltip id={`review-show-widgets-${row.id}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
											{t("Show/Hide on Widgets")}
										</ReactTooltip>
									</div>
								)}
								{user.GroupPermission.update_reviews && row.site_id !== REVIEW_SITE_IDS_BY_NAME.direct && (
									<div className="review-tag-dropdown">
										<div data-toggle="dropdown" className="reviews__single-review__header-right-control" data-tip data-for={`review-tag-tip-${row.id}`}>
											<Tag width={16} height={16} />
											<ReactTooltip id={`review-tag-tip-${row.id}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
												{t("Choose user attribution")}
											</ReactTooltip>
										</div>
										<ul className="dropdown-menu pull-right">
											{self.state.requesterData.map(requester => {
												var fullName = UserService.createFullName({ firstName: requester.first_name, lastName: requester.last_name });
												return (
													<li key={requester.id}>
														{/* eslint-disable-next-line */}
														<a
															id={`requesterList-${row.id}`}
															data-review={row.id}
															rel="noopener noreferrer"
															data-requester={requester.id}
															onClick={self.handleSetRequesterClick}
														>
															{fullName}
														</a>
													</li>
												);
											})}
											<li className="divider" />
											<li>
												{/* eslint-disable-next-line */}
												<a id={`reset-requester-${row.id}`} rel="noopener noreferrer" data-review={row.id} onClick={self.handleResetRequesterClick}>
													{t("Reset")}
												</a>
											</li>
										</ul>
									</div>
								)}
								{this.renderShareableSitesDropdown(row)}
							</div>

							<div id={`posted-by-${row.id}`} className="reviews__media-heading">
								<div className="reviews__media-heading-inner">
									<div className="reviews__media-heading__contact-name fnctst-poster-name">{row.posted_by ? row.posted_by : t("Anonymous")}</div>
									<div className="reviews__media-heading__rating">
										<div id={`review-details-${row.id}`} className="fnctst-star-rating-container">
											<StarRating rating={row.rating} maxRating={row.max_rating} />
										</div>
									</div>
								</div>
								{row.site_id === REVIEW_SITE_IDS_BY_NAME.direct && row.request_recipient_phone ? (
									<div className="reviews__media-heading__contact fnctst-poster-contact"> {row.request_recipient_phone}</div>
								) : row.site_id === REVIEW_SITE_IDS_BY_NAME.direct && row.request_recipient_email ? (
									<div className="reviews__media-heading__contact fnctst-poster-contact"> {row.request_recipient_email}</div>
								) : (
									""
								)}
							</div>
							<div id={`title-${row.id}`}>
								{row.title ? (
									<span>
										<b>{row.title}</b>
										<br />
									</span>
								) : (
									""
								)}
							</div>
							<div id={`comments-${row.id}`} className="reviews__comment fnctst-review-comments">
								{row.comments && row.comments.length > 0 ? row.comments : <i>{t("The user did not leave any comments")}</i>}
							</div>
							<div className="review-reply__container">
								<div className="review-reply__arrow">
									<CornerDownRight width={16} height={16} />
								</div>
								<div className="review-reply">
									<div className="review-reply__top_section">
										<div className="reply-name">{replyName}</div>
										<div className="review-reply__top_section__spacer"></div>
										{(isCreateResponseOpen || hasReplyAlready) && <div className="reply-date">{replyDate}</div>}
										<div className="review-reply__top_section__controls_container">
											{!isCreateResponseOpen && hasReplyAlready && showResponseControls && (
												<>
													<div
														className="review-reply__top_section__controls_container__control"
														data-tip
														data-for={`review-tag-edit-${row.id}`}
														onClick={() => this.handleReviewResponseToggle(row.id)}
													>
														<Edit2 width={16} height={16} />
														<ReactTooltip
															id={`review-tag-edit-${row.id}`}
															className="mb-react-tooltip"
															arrowColor="#333"
															type="info"
															effect="solid"
															place="top"
														>
															{t("Edit Response")}
														</ReactTooltip>
													</div>
													{isNotFacebook && (
														<div
															className="review-reply__top_section__controls_container__control"
															data-tip
															data-for={`review-tag-trash-${row.id}`}
															onClick={() => this.handleOnGoogleResponseDelete(row.id)}
														>
															<Trash width={16} height={16} />
															<ReactTooltip
																id={`review-tag-trash-${row.id}`}
																className="mb-react-tooltip"
																arrowColor="#333"
																type="info"
																effect="solid"
																place="top"
															>
																{t("Delete Response")}
															</ReactTooltip>
														</div>
													)}
												</>
											)}
										</div>
									</div>
									{isCreateResponseOpen && (
										<div className={`review-responding__container ${row.reply !== "" ? "review-responding__container--pad-left" : ""}`}>
											<ReviewResponse
												locationId={location.id}
												reviewId={row.id}
												reviewRequestId={row.request_id}
												name={row.posted_by}
												onCancel={id => this.handleReviewResponseOnClose(id)}
												handleOnPostClick={(id, response) => this.onPostResponseClick(id, response, row.site_id)}
												value={row.reply}
												disabled={!user.GroupPermission.respond_reviews}
											/>
										</div>
									)}
									{!isCreateResponseOpen && <div className="reply-message">{replyText}</div>}
								</div>

								{showReplyToUpdatedReviewButton && (
									<span className="mb-button" onClick={() => this.handleReviewResponseToggle(row.id)}>
										{t("Reply To Updated Review")}
									</span>
								)}
							</div>

							<div className="review-footer">
								<div id={`site_id-${row.id}`} className="review-respond">
									{showReplyButton && (
										<span className="mb-button fnctst-reply-button" onClick={() => this.handleReviewResponseToggle(row.id)}>
											{t("Reply")}
										</span>
									)}
									<span> </span>
									{showVisitReviewLink && (
										<a
											href={"https://www.facebook.com/" + row.guid}
											target="_blank"
											rel="noopener noreferrer"
											className="btn btn-primary btn-sm btn-rounded btn-outline"
										>
											{t("Visit Review")}
										</a>
									)}
									<span> </span>
								</div>{" "}
								<div id={`see-review-${row.id}`}>
									{row.link && (
										<div className="review-respond">
											<a href={row.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm btn-rounded btn-outline">
												{t("See Review")}
											</a>
										</div>
									)}
								</div>
								<div className="review-tag">
									{row.requester_id > 0 ? (
										<div
											id={`requester-id-${row.id}`}
											className="review-tag__button btn btn-default btn-sm btn-rounded btn-outline reviews__buttons-default--disabled"
											type="button"
											data-toggle="tooltip"
											data-placement="top"
											title={
												row.request_created_at && row.request_recipient_name
													? t("Sent to {{name}} on {{date}}", { name: row.request_recipient_name, date: new Date(row.request_created_at).toDateString() })
													: t("Manually attributed")
											}
										>
											<Tag className="review-tag__button__icon" width={16} height={16} /> {row.requester_first_name + " " + row.requester_last_name}
										</div>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	renderAlerts = () => {
		let { t } = this.props;

		return (
			<>
				<Alert
					type="error"
					show={this.state.responseSaveError}
					title={t("Saving Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ responseSaveError: false });
					}}
				>
					<div>{this.state.responseSaveErrorText}</div>
				</Alert>
				<Alert
					type="info"
					show={this.state.confirmDeleteResponse}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirm => this.onDeleteReviewResponse(confirm)}
				>
					<div>{t("Are you sure you want to delete this review response?")}</div>
				</Alert>
				<Alert
					type="success"
					show={this.state.showResponseDeleteSweetAlert}
					title={t("Response Deleted")}
					confirm={t("OK")}
					onClose={() => this.setState({ showResponseDeleteSweetAlert: false })}
				>
					<div>{t("Review Response was deleted successfully.")}</div>
				</Alert>
				<Alert
					type="success"
					show={this.state.showPropagateSweetAlert}
					title={t("Review Shared")}
					confirm={t("OK")}
					onClose={() => this.setState({ showPropagateSweetAlert: false })}
				>
					<div>{t("The review has been shared successfully.")}</div>
				</Alert>
				<Alert
					type="error"
					show={this.state.showPropagateErrorSweetAlert}
					title={t("Review Sharing Error")}
					confirm={t("OK")}
					onClose={() => this.setState({ showPropagateErrorSweetAlert: false })}
				>
					<div>{t("There was an error while trying to share the review.")}</div>
				</Alert>
			</>
		);
	};

	render() {
		const { reviewsCsv } = this.state;
		let { t } = this.props;

		if (this.state.loading) {
			return (
				<Page>
					<Header title={t("Reviews")} />
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="tail-fade" size="120px" />
					</div>
				</Page>
			);
		}

		let location = UserService.getActiveLocation();

		if (!location) {
			// No locations have been configured
			return <Redirect to="/dashboard" />;
		}

		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<Page>
					<Header title={t("Reviews")}>
						<Action id="download" label={t("Download Reviews")} icon={Icon.Download} onClick={() => this.onDownloadReviews()} />
					</Header>

					<div className="wrapper wrapper-content main-table-margin reviews__table_wrapper" style={{ paddingTop: 0 }}>
						<div className="row">
							<div className="col-lg-12">
								<div className="ibox float-e-margins reviews__container">
									<div
										className="reviews__side--panel"
										style={{
											top: this.state.staticReviewFilterTopCss
										}}
										ref={reviewsFilter => (this.reviewsFilterRef = reviewsFilter)}
									>
										<div className="reviews__side--panel-content">{this.renderFilterForm()}</div>
									</div>
									{this.renderReviews()}
									<div className="media ibox-content">
										<div className="row">
											<div className="pagination-bottom">
												<button id="prevPage" className="btn btn-default btn-outline btn-sm pagination-item" onClick={this.handlePrevPageClick}>
													<i className="fa fa-chevron-left" />
												</button>
												<span className="table-curr-page pagination-item">{this.getReviewPageNumbers()}</span>
												<button id="nextPage" className="btn btn-default btn-outline btn-sm pagination-item" onClick={this.handleNextPageClick}>
													<i className="fa fa-chevron-right" />
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.renderPropagateReviewModal()}

					{this.renderAlerts()}
					<Modal show={this.state.showCsvModal} onHide={() => this.setState({ showCsvModal: false })} title={t("Download Contacts")}>
						<div className="modal__flex-container">
							{reviewsCsv && reviewsCsv.length > 0 ? (
								<>
									<p>{t("{{number}} reviews will be downloaded. Continue?", { number: reviewsCsv.length })}</p>
									<div className="mb-button mb-button--fit">
										<CSVLink data={reviewsCsv} filename={`${locationName}-reviews.csv`} target="_self">
											<span onClick={() => this.setState({ showCsvModal: false })}>{t("Download")}</span>
										</CSVLink>
									</div>
								</>
							) : this.state.csvDataLoading ? (
								<ContentLoader height={60} width={"100%"}>
									<rect x="0" y="0" rx="5" ry="5" width="50%" height="20" />
									<rect x="85%" y="30" rx="5" ry="5" width="70" height="30" />
								</ContentLoader>
							) : (
								<p>{t("No reviews found.")}</p>
							)}
						</div>
					</Modal>
				</Page>
			</>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Reviews)));
