import UserService from "../services/UserService";

import GROUP_PERMISSIONS from "../constants/GroupPermissions";

import SignUps from "../scenes/CustomerSuccess/SignUps/SignUps";
import Realtime from "../scenes/CustomerSuccess/Realtime/Realtime";
import LaggingLocations from "../scenes/CustomerSuccess/LaggingLocations/LaggingLocations";
import ManageTeamChat from "../scenes/Companies/ManageTeamChat";
import HostedMessaging from "../scenes/CustomerSuccess/HostedMessaging";
import Jobs from "../scenes/Admin/Jobs";
import CompareToolLeads from "../scenes/CompareToolLeads/CompareToolLeads";
import LocationsMap from "../scenes/CustomerSuccess/LocationsMap";
import FeatureMatrix from "../scenes/CustomerSuccess/FeatureMatrix/FeatureMatrix";
import CSTeamChat from "../scenes/CustomerSuccess/TeamChat/CSTeamChat";
import CSMessengerAnalytics from "../scenes/CustomerSuccess/Summary/CSMessengerAnalytics";
import CSReviewAnalytics from "../scenes/CustomerSuccess/Summary/CSReviewAnalytics";
import CSMessageMediums from "../scenes/CustomerSuccess/Summary/CSMessageMediums";
import LocationEmailReport from "../scenes/CustomerSuccess/LocationEmailReport";
import CompanyEmailReport from "../scenes/CustomerSuccess/CompanyEmailReport";
import LocationFeatures from "../scenes/Locations/LocationFeatures";
import GroupPermissions from "../scenes/Settings/GroupPermissions/GroupPermissions";
import Numbers from "../scenes/Settings/Numbers/Numbers";
import CRMIntegrations from "../scenes/CRMIntegrations/CRMIntegrations";
import A2P from "../scenes/Settings/A2P/A2P";
import APIKeys from "../scenes/Locations/APIKeys";

import i18n from "../i18n/config";

export const CONFIGURE_ROUTES = {
	features: {
		name: i18n.t("Features"),
		route: "/customer-success/features",
		component: LocationFeatures,
		enabled: () =>
			UserService.isSuperAdminOrCustomerSuccessOrReseller() || UserService.get().GroupPermission.type === GROUP_PERMISSIONS.restricted_customer_success.type
	},
	groupPermissions: {
		name: i18n.t("Group Permissions"),
		route: "/customer-success/group-permissions",
		component: GroupPermissions,
		enabled: () => UserService.isSuperAdminOrCustomerSuccess()
	},
	numbers: {
		name: i18n.t("Numbers"),
		route: "/customer-success/numbers",
		component: Numbers,
		enabled: () => UserService.isSuperAdminOrCustomerSuccess()
	},
	crmIntegrations: {
		name: i18n.t("CRM Integrations"),
		route: "/customer-success/crm-integrations",
		component: CRMIntegrations,
		enabled: () => UserService.get().GroupPermission.view_crm_integrations
	}
};

export const TOOLS_ROUTES = {
	signUps: {
		name: i18n.t("Sign Ups"),
		route: "/customer-success/sign-ups",
		component: SignUps,
		enabled: () => UserService.isSuperAdminOrCustomerSuccessOrAccountOwner()
	},
	featureMatrix: {
		name: i18n.t("Feature Matrix"),
		route: "/customer-success/feature-matrix",
		component: FeatureMatrix,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	hostedMessaging: {
		name: i18n.t("Hosted Messaging"),
		route: "/customer-success/hosted-messaging",
		component: HostedMessaging,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	manageTeamChat: {
		name: i18n.t("Manage Team Chat"),
		route: "/customer-success/manage-team-chat",
		component: ManageTeamChat,
		enabled: () => UserService.isSuperAdminOrCustomerSuccess()
	}
};

export const DEV_ROUTES = {
	apiKeys: {
		name: i18n.t("API Keys"),
		route: "/customer-success/api-keys",
		component: APIKeys,
		enabled: () => UserService.get().GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type
	},
	realtime: {
		name: i18n.t("Realtime"),
		route: "/customer-success/realtime",
		component: Realtime,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	a2p: {
		name: "A2P",
		route: "/customer-success/a2p",
		component: A2P,
		enabled: () => UserService.isSuperAdminOrCustomerSuccess()
	},
	jobs: {
		name: i18n.t("Scheduled Jobs"),
		route: "/customer-success/jobs",
		component: Jobs,
		enabled: () => UserService.get().GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type
	}
};

export const DATA_ROUTES = {
	laggingLocations: {
		name: i18n.t("Lagging Locations"),
		route: "/customer-success/lagging-locations",
		component: LaggingLocations,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	teamChat: {
		name: i18n.t("Team Chat"),
		route: "/customer-success/teamchat",
		component: CSTeamChat,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	messenger: {
		name: i18n.t("Messenger"),
		route: "/customer-success/messenger",
		component: CSMessengerAnalytics,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	reviews: {
		name: i18n.t("Reviews"),
		route: "/customer-success/reviews",
		component: CSReviewAnalytics,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	messengerMediums: {
		name: i18n.t("Messenger Mediums"),
		route: "/customer-success/messenger-mediums",
		component: CSMessageMediums,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	locationEmailReports: {
		name: i18n.t("Location Email Reports"),
		route: "/customer-success/location-email-reports",
		component: LocationEmailReport,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	companyEmailReports: {
		name: i18n.t("Company Email Reports"),
		route: "/customer-success/company-email-reports",
		component: CompanyEmailReport,
		enabled: () => UserService.get().GroupPermission.view_cs_reports
	},
	map: {
		name: i18n.t("Map"),
		route: "/customer-success/map",
		component: LocationsMap,
		enabled: () => UserService.get().GroupPermission.view_locations_map
	},
	reputationReport: {
		name: i18n.t("Reputation Report"),
		route: "/customer-success/reputation-report",
		component: CompareToolLeads,
		enabled: () => UserService.get().GroupPermission.view_companies
	}
};
